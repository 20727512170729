import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Button, SimpleSelect, TextInput } from 'vulpino';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import TitledSection from '../../../components/commons/TitledSection';
import BreadcrumbsNavigator from '../../../components/commons/BreadcrumbsNavigator';
import { RouteKey, paths } from '../../../navigation/constants';
import { createCalendar, getCalendar } from '../../../api/adminCalendar';
import { useUI } from '../../../contexts/UIContext';
import { ToastType } from '../../../components/commons/Toast';
import useDataSources, {
  DataSourceCollection,
} from '../../../hooks/useDataSources';

export const CalendarForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentCalendarId, setParentCalendarId] = useState('-1');
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const [calendarList, setCalendarList] = useState<any>(null);

  const { getOptions } = useDataSources();

  useEffect(() => {
    getOptions({
      collection: DataSourceCollection.CALENDAR,
    }).then((data: any) => {
      setIsLoading(false);
      setCalendarList(data);
    });
  }, []);

  const { data, ...queryProps } = useQuery({
    queryKey: [`getCalendar:${itemId}`],
    queryFn: async () => (await getCalendar(itemId)).data,
  });

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setParentCalendarId(data.parenCalendarId);
    }
  }, [data]);

  const { setToast } = useUI();

  const saveCalendar = async (e: any) => {
    e.preventDefault();
    const id = parseInt(parentCalendarId, 10);
    const body = {
      name,
      description,
      parentCalendarId: id > 0 ? id : null,
    };
    createCalendar(body, itemId)
      .then(() => {
        setToast({
          type: ToastType.SUCCESS,
          message: t(
            itemId ? 'page.calendar.toast.edit' : 'page.calendar.toast.new'
          ),
        });
        navigate(paths[RouteKey.CALENDAR], { replace: true });
      })
      .catch((err) => {
        setToast({
          type: ToastType.ERROR,
          message: t('page.calendar.toast.error'),
        });
      });
  };

  return (
    <div>
      <BreadcrumbsNavigator
        title={t(itemId ? 'page.calendar.editTitle' : 'page.calendar.title')}
        routes={[
          RouteKey.CALENDAR,
          itemId ? RouteKey.EDIT_CALENDAR : RouteKey.CREATE_CALENDAR,
        ]}
        current={itemId ? RouteKey.EDIT_CALENDAR : RouteKey.CREATE_CALENDAR}
      />
      <TitledSection
        title={t(itemId ? 'page.calendar.editTitle' : 'page.calendar.title')}
        variant="h1"
      >
        <form onSubmit={saveCalendar}>
          <Grid container rowGap={4}>
            <Grid item md={12} xs={12} mt={2}>
              <Typography variant="body1">
                {t(
                  itemId
                    ? 'page.calendar.editSubtitle'
                    : 'page.calendar.subtitle'
                )}
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid direction="column" spacing={2} container>
                <Grid item>
                  <TextInput
                    required
                    label={t('page.calendar.form.name')}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid direction="column" spacing={2} mx={2} container>
                <Grid item>
                  <TextInput
                    fullWidth
                    label={t('page.calendar.form.description')}
                    helperText={t('page.calendar.form.descHint')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
         {/* <Grid container rowGap={4}>
            <Grid item md={12} xs={12} mt={2}>
              <Typography variant="h3">
                {t('page.calendar.nestCalendar')}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="body1">
                {t('page.calendar.addCalendar')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              {!isLoading && (
                <SimpleSelect
                  sx={{ m: 1, minWidth: 200 }}
                  label={t('page.calendar.form.select')}
                  helperText={t('page.calendar.form.selectHint')}
                  variant="outlined"
                  fullWidth
                  value={parentCalendarId}
                  onChange={(val) => setParentCalendarId(val)}
                  options={[
                    { label: t('page.calendar.none'), value: '-1' },
                    ...calendarList
                      .filter((cal: any) => cal.id !== itemId?.toString())
                      .map((cal: any) => ({
                        value: cal.id,
                        label: cal.label,
                      })),
                  ]}
                />
              )}
            </Grid>
          </Grid>*/}
          <Grid container item xs={6} spacing={2} mt={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  navigate(paths[RouteKey.CALENDAR], { replace: true });
                }}
                fullWidth
                variant="secondary"
              >
                {t('page.calendar.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                disabled={!name}
                fullWidth
                variant="primary"
              >
                {t('page.calendar.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </TitledSection>
    </div>
  );
};
