import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getFinancialProductRequestDocumentFiles } from '../../api/adminFinancialProductRequest/service';
import { IFinancialProductRequestDetail } from '../../common/interfaces';
import StyledAlert from '../ui/StyledAlert';

import DocumentFileContainer from './DocumentFileContainer';
import CardContainer from './CardContainer';

interface DocumentDataCardProps {
  data?: IFinancialProductRequestDetail;
}

const DocumentDataCard = ({ data }: DocumentDataCardProps) => {
  const [translate] = useTranslation('global');
  const documentIds = useMemo(
    () => Object.keys(data?.documentData ?? {}),
    [data]
  );

  const { data: queryData, isLoading } = useQuery({
    queryKey: [`DocumentFiles:${documentIds.join(',')}`],
    queryFn: async () =>
      data
        ? getFinancialProductRequestDocumentFiles(
            data.id,
            data?.documentData ?? {}
          )
        : undefined,
    keepPreviousData: true,
  });

  if (!queryData || !data?.id) return null;

  if (!queryData.length) {
    return (
      <StyledAlert
        severity="info"
        title={translate(
          'page.financialProductRequest.documentation.emptyState.title'
        )}
      >
        <Typography>
          {translate(
            'page.financialProductRequest.documentation.emptyState.description'
          )}
        </Typography>
      </StyledAlert>
    );
  }

  return (
    <CardContainer isLoading={isLoading}>
      <Grid container spacing={2}>
        {queryData.map((file) => (
          <DocumentFileContainer
            key={file.id}
            requestId={data?.id}
            file={file}
            lg={4}
          />
        ))}
      </Grid>
    </CardContainer>
  );
};

export default DocumentDataCard;
