import { useMemo } from 'react';
import {
  Alert,
  Snackbar,
  SnackbarProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { merge, omit, pick } from 'lodash';
import { styled } from '@mui/system';

import { useUI } from '../../contexts/UIContext';
import { ITesteable } from '../../common/interfaces';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
}

const defaultToastProps = {
  [ToastType.SUCCESS]: {
    autoHideDuration: 3000,
  },
  [ToastType.ERROR]: {},
};

export interface IToastProps extends SnackbarProps, ITesteable {
  message: string;
  type: ToastType;
  onClose: () => void;
  open: boolean;
}

const StyledAlert = styled(Alert)(() => ({
  width: '100%',
}));

const Toast = ({
  dataTestId,
  message,
  type: severity,
  onClose,
  ...props
}: IToastProps) => {
  const [translate] = useTranslation('global');
  const { offset } = useUI();

  const theme = useTheme();

  const snackbarStyle = useMemo(
    () => ({
      width: `calc(100% - ${offset.left}px - ${theme.spacing(4)})`,
      top: `calc(${offset.top}px + ${theme.spacing(2)}) !important`,
      left: `calc(${offset.left}px + ${theme.spacing(2)}) !important`,
      right: `${theme.spacing(2)} !important`,
    }),
    [offset, theme]
  );

  const { snackbarProps, alertProps } = useMemo(() => {
    const computed = {
      ...defaultToastProps[severity],
      onClose,
      severity,
    };
    merge(computed, props);
    return {
      snackbarProps: omit(computed, ['severity']),
      alertProps: pick(computed, [
        'severity',
        computed.autoHideDuration === undefined ? 'onClose' : '',
      ]),
    };
  }, [severity, props, onClose]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={snackbarStyle}
      {...snackbarProps}
      data-testid={dataTestId}
    >
      <StyledAlert {...alertProps}>
        <Typography>{translate(message)}</Typography>
      </StyledAlert>
    </Snackbar>
  );
};

export default Toast;
