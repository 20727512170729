import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFinancialProductRequest } from '../../api/adminFinancialProductRequest/service';
import { paths, RouteKey } from '../../navigation/constants';
import { useUI } from '../../contexts/UIContext';
import { getFPRPendingTask } from '../../api/adminPlatformBPM/service';
import useFPRCards from '../../hooks/useFPRCards';
import { MenuItemType, Permissions } from '../../common/enums';

const FPRPendingTaskViewPage = ({ dataTestId = 'FPRPendingTaskViewPage' }) => {
  const params = useParams();
  const navigate = useNavigate();
  const activeSectionKey = params['*'] ?? '';
  const [translate] = useTranslation('global');

  const { setMenuOverride } = useUI();

  const { requestId, processInstanceId, taskId } = params;
  const { data: taskData } = useQuery({
    queryKey: [`fprPendingTask:${processInstanceId}:${taskId}`],
    queryFn: async () =>
      taskId && processInstanceId
        ? (await getFPRPendingTask(processInstanceId, taskId)).data
        : undefined,
    keepPreviousData: true,
  });

  const { data: requestData, ...requestQueryProps } = useQuery({
    queryKey: [`financialProductRequest:${requestId}`],
    queryFn: async () =>
      requestId
        ? (await getFinancialProductRequest(requestId)).data
        : undefined,
    keepPreviousData: true,
  });

  const { cards, commerceData } = useFPRCards({
    requestData,
    requestId,
    taskId,
    taskData,
  });

  useEffect(() => {
    const i18nParams = {
      publicId: requestData?.publicId,
    };

    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FPR_PENDING_TASK_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.FPR_PENDING_TASKS]),
      },
      items: cards.map((card) => ({
        key: card.key,
        permissions: Permissions.FPR_PENDING_TASK_READ,
        text: translate(
          `page.financialProductRequest.${card.key}.breadcrumb`,
          translate(
            `page.financialProductRequest.${card.key}.title`,
            i18nParams
          ),
          i18nParams
        ),
        path: generatePath(paths[RouteKey.FPR_PENDING_TASKS_VIEW], {
          '*': card.key,
          requestId,
          taskId,
          processInstanceId,
        }),
        type: MenuItemType.MENU,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, cards, requestId, taskId, processInstanceId, requestData]);

  const baseViewPageProps = useMemo(
    () => ({
      dataTestId,
      routeKey: RouteKey.FPR_PENDING_TASKS_VIEW,
      breadcrumb: translate(
        `page.financialProductRequest.${activeSectionKey}.breadcrumb`,
        translate(`page.financialProductRequest.${activeSectionKey}.title`)
      ),
      title: translate(
        `page.financialProductRequest.${activeSectionKey}.title`,
        ''
      ),
      routeTrail: [RouteKey.FPR_PENDING_TASKS],
      data: requestData,
      ...requestQueryProps,
    }),
    [requestData, activeSectionKey, requestQueryProps]
  );

  useEffect(() => {
    if (cards.length > 0 && activeSectionKey === '') {
      navigate(
        generatePath(paths[RouteKey.FPR_PENDING_TASKS_VIEW], {
          requestId,
          taskId,
          processInstanceId,
          '*': cards[0].key,
        }),
        {
          replace: true,
        }
      );
    }
  }, [requestId, activeSectionKey, cards]);

  const activeSection = useMemo(
    () =>
      cards.find(
        (entry) => activeSectionKey === '' || activeSectionKey === entry.key
      ),
    [cards, activeSectionKey]
  );

  const goBack = useCallback(() => {
    navigate(paths[RouteKey.FPR_PENDING_TASKS]);
  }, [navigate]);

  return (
    <>
      {activeSection?.render({
        requestData,
        commerceData,
        taskData,
        taskId,
        processInstanceId,
        requestId,
        dataTestId,
        baseViewPageProps,
        goBack,
      })}
    </>
  );
};

export default FPRPendingTaskViewPage;
