import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import keycloak from '../../keycloak';
import { RouteKey, paths } from '../../navigation/constants';

export const LogoutPage = ({ setIsLogout }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      setIsLogout(true);
      navigate(paths[RouteKey.INDEX]);
      await keycloak.logout();
    };
    logout();
  }, []);

  return <></>;
};
