import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';

const moneyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  maximumFractionDigits: 0
});

const batchMoneyFormatter = new Intl.NumberFormat('es-AR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const moneyFormatterWithoutDecimals = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  roundingMode: 'trunc',
});

const decimalsFormatter = new Intl.NumberFormat({
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  roundingMode: 'trunc'
});

export const formatToNumber = (value) => value?.replace(/\D/g, '');

export const formatMoneyWithoutDecimals = (value) => {
  if (value !== undefined) {
    return moneyFormatterWithoutDecimals.format(value);
  }
  return undefined;
};

export const formatMoney = (value) => {
  if (value !== undefined) {
    return moneyFormatter.format(value);
  }
  return undefined;
};

export const batchFormatMoney = (value) => {
  if (value !== undefined) {
    return batchMoneyFormatter.format(value);
  }
  return undefined;
};

export const dateFormatter = (date) => {
  const newDate = dayjs(date);
  return newDate.format('DD/MM/YYYY HH:mm:ss');
};

export const capitalizeEveryWord = (value) => {
  let response = value?.toLowerCase();
  if (value) {
    let words = value.split(' ');
    words.forEach((word, index) => {
      words[index] = word[0].toUpperCase() + word.substr(1);
    });
    response = words.join(' ');
  }
  return response;
};

export const formatPercentage = (value) => {
  let response = value * 100;
  return decimalsFormatter.format(response);
};

export const operativeControlDateFormatter = (date) => {
  const newDate = dayjs(date);
  return newDate.format('DD/MM/YYYY');
};