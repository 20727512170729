import { Grid, GridProps } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'vulpino';
import { IButtonConf, VButtonConf } from '../../common/interfaces';
import StyledButton from '../ui/StyledButton';

interface IFooterButtonsProps extends GridProps {
  buttonConfList?: IButtonConf[];
  buttonList?: VButtonConf[];
  direction?: 'row' | 'column';
}

const FooterButtons = ({
  direction = 'row',
  buttonConfList = [],
  buttonList = [],
  xs,
  sm,
  ...props
}: IFooterButtonsProps) => {
  const [translate] = useTranslation('global');

  const filtered = useMemo(
    () => buttonConfList.filter((button) => !button.hidden),
    [buttonConfList]
  );

  return (
    <Grid container m={-1} mt={2} {...props}>
      {filtered.map(
        (
          { children, dataTestId, labelKey, override, ...buttonProps },
          index
        ) => (
          <Grid
            p={1}
            key={`button-${index + 1}`}
            item
            md={direction === 'column' ? 12 : 12 / filtered.length}
            xs={xs? xs : 12}
            sm={sm? sm : 12}
          >
            {override || (
              <StyledButton
                data-testid={dataTestId}
                fullWidth
                size="large"
                {...buttonProps}
              >
                {labelKey && translate(labelKey)}
                {children}
              </StyledButton>
            )}
          </Grid>
        )
      )}
      {buttonList
        .filter((b) => !b.hidden)
        .map(({ children, labelKey, override, ...buttonProps }: any, index) => (
          <Grid
            textAlign="right"
            p={1}
            key={`button-${index + 1}`}
            item
            xs={direction === 'column' ? 12 : 12 / buttonList.length}
          >
            <Button {...buttonProps}>
              {labelKey && translate(labelKey)}
              {children}
            </Button>
          </Grid>
        ))}
    </Grid>
  );
};

export default FooterButtons;
