import { Grid, GridProps, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingImg from '../../assets/imgs/loader.gif';

const StyledImg = styled('img')(() => ({
  maxWidth: '100px'
}))

const Loading = () => {
  const [translate] = useTranslation('global');

  return (
    <Grid container justifyContent="center">
      <StyledImg
        src={LoadingImg}
        alt={translate('common.loading')}
      />
    </Grid>
  );
};
export default Loading;
