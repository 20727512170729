import {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react';
import { useMediaQuery } from '@react-hook/media-query';

import Toast, { IToastProps, ToastType } from '../components/commons/Toast';
import { drawerWidth } from '../navigation/MenuDrawer';
import type { IMenu } from '../navigation/constants';
import { IReportContext } from '../pages/Reports/interfaces';

export interface IUIContext {
  globalError?: Error;
  globalIsLoading?: boolean;
  setGlobalError: (error?: Error) => void;
  setGlobalIsLoading: (loading: boolean) => void;
  menuIsOpen: boolean;
  reportContext: IReportContext;
  setReportContext: (val: IReportContext) => void;
  menuOverride?: IMenu;
  setMenuOverride: (menu?: IMenu) => void;
  setMenuOpen: (open: boolean) => void;
  setToast: (toastProps: Partial<IToastProps>) => void;
  pushToast: (toastProps: Partial<IToastProps>) => void;
  offset: {
    top: number;
    left: number;
  };
}

const UIContext = createContext<IUIContext>({
  menuIsOpen: false,
  menuOverride: undefined,
  globalIsLoading: false,
  setMenuOverride: () => {},
  setMenuOpen: () => {},
  setToast: () => {},
  pushToast: () => {},
  offset: {
    top: 0,
    left: 0,
  },
  setGlobalError: () => {},
  setGlobalIsLoading: () => {},
  reportContext: {},
  setReportContext: () => {},
});

export const UIProvider = ({ children }: { children: ReactNode }) => {
  const isDesktop = useMediaQuery('only screen and (min-width: 760px)');
  const [menuIsOpen, setMenuOpen] = useState(isDesktop);
  const [menuOverride, menuOverrideSetter] = useState<IMenu | undefined>();
  const [globalError, setGlobalError] = useState<Error | undefined>();
  const [globalIsLoading, setGlobalIsLoading] = useState<boolean>(false);
  const [toasts, setToasts] = useState<any[]>([]);
  const [reportContext, setReportContext] = useState({});

  const offset = useMemo(
    () =>
      isDesktop
        ? {
            top: 64,
            left: menuIsOpen ? drawerWidth : 0,
          }
        : {
            top: 64,
            left: 0,
          },
    [isDesktop, menuIsOpen]
  );

  const removeToast = useCallback(
    (key: string) => {
      setToasts(toasts.filter((toast) => toast.key !== key));
    },
    [toasts]
  );

  const setMenuOverride = useCallback((menu?: IMenu) => {
    menuOverrideSetter(menu);
  }, []);

  const fillToastProps = useCallback(
    ({ type = ToastType.SUCCESS, ...toastProps }: Partial<IToastProps>) => {
      const key = `toast-${new Date().valueOf()}`;

      return {
        key,
        ...toastProps,
        type,
        open: true,
        onClose: () => {
          toastProps.onClose?.();
          removeToast(key);
        },
      };
    },
    []
  );

  const pushToast = useCallback(
    (toastProps: Partial<IToastProps>) => {
      setToasts([fillToastProps(toastProps), ...toasts]);
    },
    [toasts, removeToast]
  );

  const setToast = useCallback(
    (toastProps: Partial<IToastProps>) => {
      setToasts([fillToastProps(toastProps)]);
    },
    [removeToast]
  );

  const value = useMemo(
    () => ({
      globalError,
      globalIsLoading,
      isDesktop,
      menuIsOpen,
      menuOverride,
      offset,
      pushToast,
      setGlobalError,
      setGlobalIsLoading,
      setMenuOpen,
      setMenuOverride,
      setToast,
      reportContext,
      setReportContext,
    }),
    [
      globalError,
      globalIsLoading,
      isDesktop,
      menuIsOpen,
      menuOverride,
      offset,
      pushToast,
      setGlobalError,
      setGlobalIsLoading,
      setMenuOpen,
      setMenuOverride,
      setToast,
      reportContext,
      setReportContext,
    ]
  );

  return (
    <UIContext.Provider value={value}>
      {children}
      {toasts.map((props) => (
        <Toast key={props.key} {...props} dataTestId={`${props.key}_Toast`} />
      ))}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext(UIContext);
