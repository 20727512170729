import { useState, useEffect, useMemo, cloneElement } from 'react';
import { Button, Grid, ToggleButton, useTheme } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  IFilterAppliedOption,
  IFilterOption,
  IFilterDatasourceOption,
} from '../../common/interfaces';
import Filter from './Filter';
import {
  compositeSchemaForArrayForms as compositeSchemaForFieldArrayForms,
  IAttributeValidation,
} from '../../forms/schemaValidations/schemaValidations';
import TitledSection from './TitledSection';

const StyledContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '4px',
  padding: '10px 20px',
  marginBottom: '20px',
}));

type FilterContainerProps = {
  filterOptionList: IFilterOption[];
  filterAppliedList: IFilterAppliedOption[];
  translationKeyPrefix: string;
  onSubmitFilters: any;
  onDeleteAllFilters: any;
  onRemoveChip: Function;
  children: JSX.Element;
  onGetDatasource?: any;
  filterDatasourceOptionList?: IFilterDatasourceOption[];
  title?: string;
  titleSx?: any;
  clickAddButton?: any;
};

const FilterContainer = ({
  filterOptionList,
  filterAppliedList,
  translationKeyPrefix,
  onSubmitFilters,
  onDeleteAllFilters,
  onRemoveChip,
  children,
  onGetDatasource,
  filterDatasourceOptionList,
  title,
  titleSx = {},
  clickAddButton,
}: FilterContainerProps) => {
  const [translate] = useTranslation('global');
  const { palette } = useTheme();
  const [filterBtnSelected, setFilterBtnSelected] = useState<boolean>(false);
  const [filterAppliedStatus, setFilterAppliedStatus] =
    useState<boolean>(false);

  const createSchemaValidation = useMemo(() => {
    const attributeSchema: IAttributeValidation = filterOptionList.reduce(
      (obj: IAttributeValidation, item: IFilterOption) => ({
        ...obj,
        [item.name]: item.validation,
      }),
      {}
    );

    return compositeSchemaForFieldArrayForms(attributeSchema);
  }, [filterOptionList]);

  const methods = useForm({
    defaultValues: {
      filters: filterAppliedList,
    },
    resolver: yupResolver(
      yup.object().shape({
        filters: createSchemaValidation,
      })
    ),
  });

  const { control, handleSubmit, reset } = methods;

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'filters',
  });

  const handleAddFilter = (filter: IFilterOption) => {
    append(filter);
  };

  const handleOnRemoveFilter = (index: number) => {
    remove(index);
  };

  const handleOnResetFilter = () => {
    reset({
      filters: [],
    });
  };

  const handleDeleteAllFilters = () => {
    handleOnResetFilter();
    setFilterBtnSelected(false);
    onDeleteAllFilters();
  };

  const handleOnSubmitFilter = () => {
    handleSubmit(onSubmitFilters, console.log)();
  };

  const handleOnRemoveChip = (filterName: string) => {
    const currentFields = fields as unknown as IFilterAppliedOption[];
    const indexToRemove = currentFields.findIndex((f) => f.name === filterName);
    remove(indexToRemove);

    onRemoveChip(filterName);
  };

  const getFilterRemainingList = () => {
    const fieldsArray = fields as unknown as IFilterAppliedOption[];
    const filterRemaining = filterOptionList.filter(
      (filterOption) =>
        !fieldsArray.map((e) => e.name).includes(filterOption.name)
    );
    return filterRemaining;
  };

  useEffect(() => {
    setFilterAppliedStatus(filterAppliedList.length > 0);
  }, [filterAppliedList]);

  const newChildren = cloneElement(children, {
    filterAppliedList,
    translationKeyPrefix,
    onRemoveChip: handleOnRemoveChip,
  });

  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={12}>
          <TitledSection
            title={title}
            variant="h1"
            titleSx={{
              py: 2,
              ...titleSx,
            }}
            titleAfter={
              filterOptionList.length > 0 && (
                <>
                  {clickAddButton && (
                    <ToggleButton
                      value="check"
                      sx={{
                        border: filterAppliedStatus
                          ? `2px solid ${palette.primary.main}`
                          : '',
                      }}
                      onChange={clickAddButton}
                    >
                      {translate('component.filter.filtersAddLabel')}
                      <AddCircleOutlineOutlinedIcon
                        sx={{ color: palette.primary.main }}
                      />
                    </ToggleButton>
                  )}
                  {filterAppliedStatus ? (
                    <Button
                      sx={{ fontWeight: '500' }}
                      onClick={handleDeleteAllFilters}
                    >
                      {translate('component.filter.deleteFiltersLabel')}
                    </Button>
                  ) : (
                    ''
                  )}
                  <ToggleButton
                    value="check"
                    selected={filterBtnSelected}
                    sx={{
                      border: filterAppliedStatus
                        ? `2px solid ${palette.primary.main}`
                        : '',
                    }}
                    onChange={() => {
                      setFilterBtnSelected(!filterBtnSelected);
                    }}
                  >
                    {translate('component.filter.filtersLabel')}
                    {filterAppliedStatus ? (
                      <FilterAltIcon sx={{ color: palette.primary.main }} />
                    ) : (
                      <FilterAltOutlinedIcon
                        sx={{ color: palette.primary.main }}
                      />
                    )}
                  </ToggleButton>
                </>
              )
            }
          >
            {filterBtnSelected && (
              <StyledContainer item container xs={12}>
                <Filter
                  formName="filters"
                  filterOptionList={filterOptionList}
                  filterSelectedList={fields as unknown as IFilterOption[]}
                  filterDatasourceOptionList={filterDatasourceOptionList}
                  filterRemainingList={getFilterRemainingList()}
                  translationKeyPrefix={translationKeyPrefix}
                  onSubmitFilter={handleOnSubmitFilter}
                  onAddFilter={handleAddFilter}
                  onRemoveFilter={handleOnRemoveFilter}
                  onResetFilter={handleOnResetFilter}
                  onGetDatasource={onGetDatasource}
                />
              </StyledContainer>
            )}
            {newChildren}
          </TitledSection>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default FilterContainer;
