import fetch from '../api';
import { env } from '../env';
import type {
  IBatchUpdatesFilters,
  ILoanBatch,
  ILoanBatchLiquidation,
  ILoanBatchNotificationRequest,
  ILoanBatchNotificationResponse,
  ILoanBatchRequestFiltersState,
  ILoanBatchUpdateDetails,
  ILoanBatchUpdates,
  ILoanBatcUpdatehNotificationResponse,
  IoanBatchUpdateNotificationRequest,
  IPaginatedParams
} from '../../common/interfaces';

interface IGetLoanBatchtRequestListParams extends IPaginatedParams {
  filters?: ILoanBatchRequestFiltersState;
}

interface IGetBatchUpdateRequestListParams extends IPaginatedParams {
  filters?: IBatchUpdatesFilters;
}

export const getLoanBatchs = async ({
  filters = {},
  pageNumber = 1,
  pageSize = 10,
}: IGetLoanBatchtRequestListParams = {}): Promise<{
  data: ILoanBatch[];
}> =>
{
  if (filters?.loanBatchStatus === undefined) {
    filters.loanBatchStatus = 'PENDIENTE_APROBACION'
  }
  const response = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/loan-batchs`,
    method: 'get',
    params: {
      ...filters
    },
  });
  return response;
}

export const getLoanBatchsById = (
  batchId?: string
): Promise<{
  data: ILoanBatch;
}> =>
fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/loan-batchs/${batchId}`,
    method: 'get',
});

export const getLoanBatchsLiquidation = (
  batchId: string
): Promise<{
  data: ILoanBatchLiquidation;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/loan-batchs/${batchId}/liquidation`,
    method: 'get',
  });

export const postLoanBatchsNotification = (
  request : ILoanBatchNotificationRequest
) : Promise<{
  data: ILoanBatchNotificationResponse;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/portfolio-acquisition-profile-job-executions/loan-batch-notification`,
    method: 'post',
    data: request
  });

export const getBatchUpdates = async ({
  filters = {},
  pageNumber = 1,
  pageSize = 10,
}: IGetBatchUpdateRequestListParams = {}) : Promise<{
  data: ILoanBatchUpdates[];
}> =>
{
  if (filters?.batchUpdateStatus === undefined) {
    filters.batchUpdateStatus = 'PENDIENTE_APROBACION'
  }
  const response = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/loan-batchs/updates`,
    method: 'get',
    params: {
      ...filters
    },
  });
  return response;
}

export const getBatchUpdateDetails = (
  batchId: string,
  batchUpdateType: string
) : Promise<{
  data: ILoanBatchUpdateDetails;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/loan-batchs/updates/${batchUpdateType}/${batchId}`,
    method: 'get',
  });

export const postBatchsUpdateNotification = (
  request : IoanBatchUpdateNotificationRequest
) : Promise<{
  data: ILoanBatcUpdatehNotificationResponse;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/portfolio-acquisition-payment-job-executions/payment-notification`,
    method: 'post',
    data: request
});