import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { IValidationInfoInfo } from '../../common/interfaces';
import StatusBadge, { Status } from '../commons/StatusBadge';
import { ValidationStatus } from '../../common/enums';
import StyledDataGrid from '../commons/StyledDataGrid';
import StyledAlert from '../ui/StyledAlert';

import CardContainer from './CardContainer';

type ValidationInfoCardProps = {
  data: IValidationInfoInfo[];
};

const statusMapping = {
  [ValidationStatus.COMPLETED]: {
    color: Status.SUCCESS,
  },
  [ValidationStatus.PENDING]: {
    status: Status.WARNING,
  },
};

const ValidationInfoCard = ({ data = [] }: ValidationInfoCardProps) => {
  const [translate] = useTranslation('global');

  const columns: GridColDef<IValidationInfoInfo>[] = useMemo(
    () => [
      {
        field: 'completedDatetime',
        width: 200,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.validationInfoColumns.completedDatetime'
        ),
        renderCell: ({ row }) =>
          dayjs(row.completedDatetime).format(translate('date.timestamp')),
      },
      {
        field: 'code',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.validationInfoColumns.action'
        ),
      },
      {
        field: 'status',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.validationInfoColumns.status'
        ),
        renderCell: ({ row }) => (
          <StatusBadge
            {...statusMapping[row.status]}
            label={translate(
              `page.financialProductRequest.taskStatus.${row.status}`
            )}
          />
        ),
      },
    ],
    [translate]
  );

  if (data && !data.length) {
    return (
      <StyledAlert
        severity="info"
        title={translate(
          'page.financialProductRequest.validationInfo.emptyState.title'
        )}
        sx={{ width: '100%', mb: 4 }}
      >
        <Typography>
          {translate(
            'page.financialProductRequest.validationInfo.emptyState.description'
          )}
        </Typography>
      </StyledAlert>
    );
  }

  return (
    <CardContainer
      title={translate('page.financialProductRequest.validationInfo.title')}
    >
      <StyledDataGrid columns={columns} rows={data} hideFooter autoHeight />
    </CardContainer>
  );
};

export default ValidationInfoCard;
