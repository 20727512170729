import { ReactNode, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { Grid, SxProps, Theme } from '@mui/material';

import { ITesteable } from '../../common/interfaces';
import { RouteKey } from '../../navigation/constants';
import getError from '../../utils/apiUtils';

import Header from './Header';
import Loading from './Loading';
import MessageErrorResponse from './MessageErrorResponse';

type BaseTablePageProps = {
  routeKey: RouteKey;
  title?: string;

  children?: ReactNode;
  error?: any;
  data?: any;
  emptyState?: ReactNode;
  isLoading?: boolean;
  hasFilters?: boolean;
  xs?: SxProps<Theme>;
  errorMessage?: any;
} & Pick<UseQueryResult, 'isLoading' | 'error' | 'data' | 'refetch'> &
  ITesteable;

const BaseTablePage = ({
  dataTestId = 'BaseTablePage',
  routeKey,
  title,
  error,
  data,
  isLoading = false,
  emptyState,
  refetch,
  children,
  hasFilters = false,
  xs = {},
  errorMessage,
}: BaseTablePageProps) => {
  const errorResponse = useMemo(
    () => (error ? getError(error) : undefined),
    [error]
  );

  const isInitError = useMemo(() => !data && !!error, [data, error]);

  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);

  const isTableEmpty = useMemo(
    () => !isLoading && rowCount === 0 && !hasFilters && !error, // NO hay parametros de busqueda
    [rowCount, hasFilters, error, isLoading]
  );

  const showLoader = errorResponse === undefined && isLoading && !data;

  const showContent =
    errorResponse === undefined &&
    !isLoading &&
    !!data &&
    ((!isTableEmpty && emptyState) || !emptyState);

  return (
    <Grid
      container
      alignItems="center"
      sx={{ padding: '10px 20px 0', ...xs }}
      data-testid={dataTestId}
    >
      {showLoader && <Loading />}
      {showContent && (
        <>
          {title && (
            <Grid item container xs={12} sm={8}>
              <Header title={title} />
            </Grid>
          )}
          {children}
        </>
      )}
      {isTableEmpty && emptyState}
      {errorResponse !== undefined && (
        <MessageErrorResponse
          errorMessage={errorMessage}
          response={errorResponse}
          onClick={refetch}
          routeKey={routeKey}
          isInitError={isInitError}
        />
      )}
    </Grid>
  );
};

export default BaseTablePage;
