import { useParams } from 'react-router-dom';
import { useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { IFormRef, ITesteable } from '../../common/interfaces';
import { RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';
import useRoutePage from '../../hooks/useRoutePage';
import BaseViewHeader from '../../components/BaseViewHeader';
import DataFillIterator from '../../components/commons/DataFillIterator';
import { getDynamicForm, validateForm } from '../../api/dynamicForm/service';
import type { IDynamicFormDefinition } from '../../api/dynamicForm/interfaces';
import DynamicForm from '../../components/form/DynamicForm';
import { useUI } from '../../contexts/UIContext';
import { getErrorMessage } from '../../utils/apiUtils';
import { ToastType } from '../../components/commons/Toast';

interface IDynamicFormViewPageProps extends ITesteable {}

const DynamicFormViewPage = ({
  dataTestId = 'DynamicFormViewPage',
}: IDynamicFormViewPageProps) => {
  const [translate] = useTranslation('global');
  const params = useParams();
  const { name = '' } = params;
  const [busy, setBusy] = useState(false);

  const { setToast } = useUI();

  const formRef = useRef<IFormRef>();

  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.CODE_MAPPING_VIEW,
  });

  const goBack = useCallback(
    () => navigateToRoute(RouteKey.DYNAMIC_FORMS),
    [navigateToRoute]
  );

  const onSubmit = useCallback(
    async (payload: any) => {
      try {
        if (!name) return;
        setBusy(true);

        await validateForm({
          dynamicFormName: name,
          formElementData: payload,
          contextData: {},
        });

        setToast({
          message: translate('page.dynamicForm.validateToastSuccessMessage'),
          key: `${dataTestId}_Success`,
        });
      } catch (error) {
        const message = getErrorMessage(
          error,
          translate('page.dynamicForm.validateToastErrorMessage')
        );

        setToast({
          type: ToastType.ERROR,
          key: `${dataTestId}_Error`,
          message,
        });
      } finally {
        setBusy(false);
      }
    },
    [name, setToast, setBusy]
  );

  return (
    <BaseViewPage
      routeKey={RouteKey.DYNAMIC_FORMS_VIEW}
      routeTrail={[RouteKey.DYNAMIC_FORMS]}
      dataTestId={dataTestId}
      data={{}}
      isLoading={false}
      error={undefined}
      // @ts-ignore
      refetch={() => {}}
      buttonConfList={[
        {
          variant: 'outlined',
          dataTestId: `${dataTestId}_CancelButton`,
          onClick: goBack,
          labelKey: 'common.cancel',
          //loading: busy,
        },
        {
          labelKey: 'common.validate',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <DynamicForm
        dynamicFormName={name}
        formRef={formRef}
        onSubmit={onSubmit}
        busy={busy}
      />
    </BaseViewPage>
  );
};

export default DynamicFormViewPage;
