import { get } from 'lodash';

import { tenatization as mocks } from '../__mocks__/tenatization';

export const getTenantI18n = (tenant: string): Promise<any> => {
  const mock = get(mocks, `${tenant}.i18n`, {});
  return Promise.resolve(mock);
}

export const getTenantTheme = (tenant: string): Promise<any> => {
  const mock = get(mocks, `${tenant}.theme`, {});
  return Promise.resolve(mock);
}

export const getTenantSettings = (tenant: string): Promise<any> => {
  const mock = get(mocks, `${tenant}.settings`, {});
  return Promise.resolve(mock);
}

export default {};
