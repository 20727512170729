import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DataFillIterator from '../commons/DataFillIterator';
import { TDataFillValue } from '../commons/DataFill';
import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';

import CardContainer from './CardContainer';
import UserDatetimeValue from './UserDatetimeValue';

export const translationKeyPrefix =
  'page.financialProductRequest.commerceData.';

export interface ICommerceDataCard {}

type CommerceDataCardProps = {
  data: ICommerceDataCard;
};

const CommerceDataCard = ({ data }: CommerceDataCardProps) => {
  const [translate] = useTranslation('global');
  const { getValue } = useDataSources();

  const valueMapper = useCallback(
    async (key: string, value: TDataFillValue) => {
      if (['approval', 'delivery'].includes(key))
        return <UserDatetimeValue {...value} />;
      switch (key) {
        case 'originatorId':
          return getValue({
            collection: DataSourceCollection.ORIGINATORS,
            id: value,
          });
        case 'businessSchemaId':
          return getValue({
            collection: DataSourceCollection.BUSINESS_SCHEMAS,
            id: value,
          });
        case 'commerceId':
        case 'headquarterId':
          return getValue({
            collection: DataSourceCollection.COMMERCES,
            id: value,
          });
        case 'divisionId':
          return getValue({
            collection: DataSourceCollection.DIVISIONS,
            id: value,
          });
        case 'fundingEntityId':
          return getValue({
            collection: DataSourceCollection.FUNDING_ENTITIES,
            id: value,
          });
        case 'financialOfficeId':
          return getValue({
            collection: DataSourceCollection.FINANCIAL_OFFICES,
            id: value,
          });
        default:
      }
      return String(value);
    },
    [data]
  );

  if (!Object.keys(data).length) {
    return null;
  }

  return (
    <CardContainer
      title={translate('page.financialProductRequest.commerceData.title')}
    >
      <DataFillIterator
        object={data}
        translationKeyPrefix={translationKeyPrefix}
        valueMapper={valueMapper}
        gridItemProps={{
          lg: 4,
        }}
      />
    </CardContainer>
  );
};

export default CommerceDataCard;
