import { MouseEvent, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useMediaQuery } from '@react-hook/media-query';
import { Link, Outlet } from 'react-router-dom';
import { Avatar, List, Menu } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import logo from '../assets/imgs/logoOnscore.svg';
import { useUI } from '../contexts/UIContext';
import { useTenant } from '../contexts/TenantContext';
import MessageErrorResponse from '../components/commons/MessageErrorResponse';
import Loading from '../components/commons/Loading';
import OverlayContainer from '../components/commons/OverlayContainer';

import MenuDrawer, { drawerWidth } from './MenuDrawer';
import MenuDrawerSectionListItem from './MenuSectionListItem';
import { MenuItemsNav, paths, RouteKey } from './constants';

const StyledLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(4),
  marginLeft: theme.spacing(2),
}));

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'matchesDesktop',
})<{
  open?: boolean;
  matchesDesktop?: boolean;
}>(({ theme, open, matchesDesktop }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...((open || !matchesDesktop) && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }: { theme: any }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MainContent = () => {
  const theme = useTheme();
  const { settings } = useTenant();
  const matchesDesktop = useMediaQuery('only screen and (min-width: 760px)');

  const {
    menuIsOpen: open,
    setMenuOpen: setOpen,
    globalError,
    globalIsLoading,
  } = useUI();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  const itemClick = () => {
    if (!matchesDesktop) {
      setOpen(false);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={open}
        color="transparent"
        style={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: 'white' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? closeDrawer : handleDrawerOpen}
                edge="start"
                sx={{ mr: 2 }}
              >
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Link to={paths[RouteKey.INDEX]}>
                <img src={logo} alt="Logo" />
              </Link>
              {settings.logo && <StyledLogo src={settings.logo} alt="Logo" />}
            </Toolbar>
          </div>

          <div>
            <IconButton
              size="large"
              aria-label="Menú de usuario"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar>
                <AccountCircle />
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <List>
                <MenuDrawerSectionListItem
                  menuItems={MenuItemsNav}
                  onHandleItemClick={handleClose}
                />
              </List>
            </Menu>
          </div>
        </div>
      </AppBar>

      <MenuDrawer openMenu={open} onHandleItemClick={itemClick} />

      <Main open={open} matchesDesktop={matchesDesktop}>
        <DrawerHeader />
        {globalIsLoading ? (
          <OverlayContainer>
            <Loading />
          </OverlayContainer>
        ) : globalError ? (
          <OverlayContainer>
            <div>
              <MessageErrorResponse
                error={globalError}
                onClick={() => window.location.reload()}
                isInitError
              />
            </div>
          </OverlayContainer>
        ) : null}
        <Outlet />
      </Main>
    </div>
  );
};
export default MainContent;
