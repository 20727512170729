import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DataFillIterator from '../commons/DataFillIterator';
import { IIdentityVerificationInfo } from '../../common/interfaces';

import CardContainer from './CardContainer';
import StatusBadge from '../commons/StatusBadge';

type IdentityVerificationInfoCardProps = {
  data: IIdentityVerificationInfo;
};

export const translationKeyPrefix =
  'page.financialProductRequest.identityVerificationInfo.';

const IdentityVerificationInfoCard = ({
  data,
}: IdentityVerificationInfoCardProps) => {
  const [translate] = useTranslation('global');

  const valueMapper = useCallback(
    async (key: string, value: string) => {
      if (value === 'false') return translate('common.no');
      if (value === 'true') return translate('common.yes');
      return value;
    },
    [data]
  );

  if (!Object.keys(data?.identityVerificationData ?? {}).length) {
    return null;
  }

  return (
    <CardContainer
      title={translate(translationKeyPrefix + 'title')}
      afterTitle={
        data.identityVerified && (
          <StatusBadge
            fontSize="m"
            label={translate(`${translationKeyPrefix}identityVerified`)}
            sx={{ ml: 3 }}
          />
        )
      }
    >
      <DataFillIterator
        object={data.identityVerificationData}
        translationKeyPrefix={translationKeyPrefix}
        valueMapper={valueMapper}
        gridItemProps={{
          lg: 4,
        }}
      />
    </CardContainer>
  );
};

export default IdentityVerificationInfoCard;
