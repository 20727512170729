import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router';

import type { IButtonConf } from '../../common/interfaces';
import { RouteKey, paths } from '../../navigation/constants';

const variantProps: {
  [key in 'outlined' | 'contained' | 'text']: ButtonProps
}
   = {
  'outlined': {

  },
  'contained': {
  },
  'text': {
  },
};

interface StyledButtonProps extends IButtonConf {
  labelKey?: string;
  toRouteKey?: RouteKey;
  toRouteParams?: any;
}

const StyledButton = ({
  labelKey,
  children: childrenProp,
  loading = false,
  variant = 'contained',
  disabled,
  startIcon,
  dataTestId = 'StyledButton',
  toRouteKey,
  toRouteParams,
  onClick: onClickProp,
  ...props
}: StyledButtonProps) => {
  const [translate] = useTranslation('global');
  const navigate = useNavigate();

  const children = labelKey ? translate(labelKey) : childrenProp;

  const onClick = useMemo(() => toRouteKey ? () => {
      navigate(generatePath(paths[toRouteKey], toRouteParams ?? {}));
    } : onClickProp, [toRouteKey, toRouteParams, onClickProp, navigate]);

  return (
    <Button
      color="primary"
      fullWidth
      variant={variant}
      data-testid={dataTestId}
      {...variantProps[variant]}
      {...props}
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={loading ? undefined : startIcon}
    >
      {loading && <CircularProgress size={24} sx={{mr: 1}} />}
      {children}
    </Button>
  );
};

export default StyledButton;
