import { ReactNode, useMemo, useState } from 'react';
import {
  DataGridProps,
  GridColDef,
  GridRowId,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Box, Grid } from '@mui/material';

import { IButtonConf, IFilterAppliedOption } from '../../../common/interfaces';
import FilterChips from '../FilterChips';
import ToolButtonBar from '../ToolButtonBar';
import NoRowsOverlay from './Parts/NoRowsOverlay';
import StyledDataGrid from '../StyledDataGrid';

import PreviewPanel from './Parts/PreviewPanel';
import { TABLE_HEIGHT } from './constants';

interface IPreviewColumn<TRow extends GridValidRowModel>
  extends Omit<GridColDef<TRow>, 'renderCell' | 'field'> {
  render: (row?: TRow) => ReactNode;
}

interface ICustomDataGridProps<TRow extends GridValidRowModel>
  extends DataGridProps<TRow> {
  // overrides
  onPageChange: (newPage: number) => void;
  // maps to page
  pageNumber: number;
  // non-datagrid related
  filterAppliedList?: IFilterAppliedOption[];
  translationKeyPrefix?: string;
  onRemoveChip?: (filterName: string) => void;
  buttonConfList?: IButtonConf[];
  showFirstButton?: boolean;
  showLastButton?: boolean;
  previewColumn?: IPreviewColumn<TRow>;
  selectedRow?: TRow;
  rows: TRow[];
  filterValue?: string;
}

const CustomDataGrid = <TRow extends GridValidRowModel>({
  rows,
  onPageChange,
  pageNumber,
  filterAppliedList,
  translationKeyPrefix,
  onRemoveChip,
  buttonConfList,
  showFirstButton = false,
  showLastButton = false,
  previewColumn,
  selectedRow,
  filterValue,
  ...props
}: ICustomDataGridProps<TRow>) => {
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  const selectedRows = useMemo(
    () =>
      rows.filter((row) =>
        selectedIds.includes(props.getRowId ? props.getRowId(row) : row.id)
      ),
    [rows, selectedIds, props.getRowId]
  );

  return (
    <>
      {buttonConfList && (
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-end"
          gap={4}
          sx={{ margin: '15px 0' }}
        >
          <ToolButtonBar buttonConfList={buttonConfList} />
        </Grid>
      )}
      {filterAppliedList && translationKeyPrefix && onRemoveChip && (
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <FilterChips
            filterAppliedList={filterAppliedList}
            translationKeyPrefix={translationKeyPrefix}
            onRemoveChip={onRemoveChip}
            filterValue={filterValue}
          />
        </Grid>
      )}

      <Grid item xs={previewColumn ? 6 : 12} sx={{ display: 'flex' }}>
        <Box sx={{ height: TABLE_HEIGHT, flexGrow: 1 }}>
          <StyledDataGrid
            {...props}
            sx={{
              width: previewColumn ? '100%' : undefined,
            }}
            rows={rows}
            pagination
            rowHighlight
            page={pageNumber - 1}
            paginationMode="server"
            onPageChange={(newPage) => onPageChange(newPage + 1)}
            onSelectionModelChange={(ids) => setSelectedIds(ids)}
            components={{
              NoRowsOverlay,
              NoResultsOverlay: NoRowsOverlay,
            }}
            componentsProps={{
              pagination: {
                showFirstButton,
                showLastButton,
              },
            }}
          />
        </Box>
      </Grid>
      <PreviewPanel previewColumn={previewColumn} row={selectedRows[0]} />
    </>
  );
};

export default CustomDataGrid;
