import { useMemo } from 'react';
import React from 'react';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Grid, Button, styled, Typography, Paper, Table, TableContainer, TableBody, TableRow, TableCell} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { getLoanBatchs, getLoanBatchsById, getLoanBatchsLiquidation } from '../../api/adminBind/service';
import ProformaInvoiceTable from '../../components/PageOperativeControl/ProformaInvoiceTable';
import { paths, RouteKey } from '../../navigation/constants';
import Loading from '../../components/commons/Loading';
import { batchFormatMoney, formatPercentage, operativeControlDateFormatter } from '../../utils/formatterUtil';
import { NotFoundPage } from '../ErrorPages';

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

const CustomTabPanel = (props: { [x: string]: any; children: any; value: any; index: any; }) => {
  const { children, value, index, ...other } = props;

  return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={value}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
      <Box sx={{ p: 3 }}>{children}</Box>
      </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const translationKeyPrefix =
  'page.operativeControl.loanBatchLiquidation.';

export const OperativeControlViewPage = () => {
const [translate] = useTranslation('global');
const params = useParams();
const { batchId } = params;
const navigate = useNavigate();

const [value, setValue] = React.useState(0);
const [goToErrorPage, setGoToErrorPage] = React.useState(false);

const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
};

const { data: queryData, ...queryProps } = useQuery({
  queryKey: [`batchId:${batchId}:liquidation`],
  queryFn: async () => {
    try {
      const response = batchId ? (await getLoanBatchsLiquidation(batchId)) : undefined;
      return response!.data;
    } catch (error) {
      setGoToErrorPage(true);
      throw error;
    }
  },
});

const data = useMemo(() => queryData, [queryData]);

const rows = data?.rows;

const { data: loanBatchById } = useQuery({
  queryKey: [`batchId:${batchId}:byId`],
  queryFn: async () =>
      (await getLoanBatchsById(batchId))
      .data  
  });

const loanBatch = useMemo(() => loanBatchById, [loanBatchById]);

const loanBatchStatusPendingPreApproval = loanBatch?.status === 'PENDING_PRE_APPROVAL' || loanBatch?.status === 'CLIENT_REGISTRATION_SENT';



const goBack = () => {
  const urlViewRequest = generatePath(
    paths[RouteKey.OPERATIVE_CONTROL]
  );
  navigate(urlViewRequest);};

const handleSubmit = () => {
  navigate(generatePath(paths[RouteKey.OPERATIVE_CONTROL_CONFIRM],
    { batchId: batchId!.toString() }));
};

const showLoader = !data && !goToErrorPage;

const showContent = !showLoader && !goToErrorPage;

const handlePrint = () => {
  const contentToPrint = document.getElementById('printable-content')!.innerHTML;
    
  // Open a new window for printing
  const printWindow = window.open('', '', 'width=800,height=600');
  
  printWindow!.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            padding: 80px;
          }
          .print-content {
            width: 150%;
          }
        </style>
      </head>
      <body>
        <div class="print-content">
          ${contentToPrint}
        </div>
      </body>
    </html>
  `);
  
  printWindow!.document.close();
  
  printWindow!.print();
};

const batchTotalAmountDoc = (data?.batchOriginalCapitalAmount ?? 0) + (data?.batchOriginalInterestAmount ?? 0);
const batchTotalAmount = (data?.batchDiscountedAmount ?? 0) - (data?.batchDiscountedAmountIva ?? 0) - (data?.batchDiscountedAmountIngresosBrutos ?? 0);

return (
  <div id="liquidation">
    {showLoader && <Loading />}
    {goToErrorPage &&
    <>
    <Routes>
    <Route path="*" element={<NotFoundPage />}/>
    </Routes>
    </>
    }
    {showContent && 
    <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledLink
        to={
          (generatePath(paths[RouteKey.OPERATIVE_CONTROL]))
        }
      >
        Bandeja de control / 
      </StyledLink>
      <Typography color="text.primary" fontWeight="bold" marginLeft="10px">
        Lote {data?.assignorBatchId}
      </Typography>
    </div>
  
    <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
            <Tab label="Detalle liquidación" sx={{ textTransform: 'none' }}/>
            <Tab label="Detalle de préstamos rechazados" sx={{ textTransform: 'none' }} />
        </Tabs>
        </Box>
        <CustomTabPanel id="printable-content-0" value={value} index={0}>
          <div id="printable-content" style={{ width: '1500px' }}>
          <ol style={{ padding: 0, listStyleType: 'none' }}>
            <li style={{ marginBottom: '5px' }}>
              <strong>OTORGADO A :</strong> <span>{translate(data?.assignor!)}</span>
            </li>
            <li style={{ marginBottom: '5px' }}>
              <strong>T.N.A :</strong> <span>{formatPercentage(data?.tna)}%</span>  <span style={{marginLeft: '347px'}}>LOTE NRO. {data?.assignorBatchId}</span>
            </li>
            <li style={{ marginBottom: '5px' }}>
              <strong>Spread :</strong> <span>{formatPercentage(data?.batchSpread)}%</span> <span style={{marginLeft: '347px'}}>CESION DE CREDITOS SIN RECURSO</span>
            </li>
            <li style={{ marginBottom: '5px' }}>
              <strong>BADLAR :</strong> <span>{formatPercentage(data?.batchReferenceRate)}%</span> <span style={{marginLeft: '328px'}}>BUENOS AIRES, <strong style={{marginLeft: '20px'}}>{operativeControlDateFormatter(data?.liquidationDate)}</strong></span>
            </li>
          </ol>

          <h2>Liquidación proforma</h2>
          <ProformaInvoiceTable 
            rows={rows!}
          />
          <TableContainer  style={{ width: '20%', marginLeft: '70px', marginTop: '10px'}} component={Paper}>
            <Table>
              <TableBody>
                  <TableRow>
                    <TableCell align="center"><strong>Importe Total Doc.</strong></TableCell>
                    <TableCell align="right">{batchFormatMoney(batchTotalAmountDoc)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Aforo</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center"><strong>Importe Total</strong></TableCell>
                    <TableCell align="right"><strong>{batchFormatMoney(batchTotalAmountDoc)}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Gastos</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Impuestos</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Intereses</TableCell>
                    <TableCell align="right">{batchFormatMoney(data?.batchDiscountToApply)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">I.V.A.</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">I.V.A. P.E.R</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">I. Ley 25063 Art 5</TableCell>
                    <TableCell align="right">0,00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">NETO</TableCell>
                    <TableCell align="right"><strong>{batchFormatMoney(data?.batchDiscountedAmount)}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">IVA</TableCell>
                    <TableCell align="right">{batchFormatMoney(data?.batchDiscountedAmountIva)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Ingresos Brutos</TableCell>
                    <TableCell align="right">{batchFormatMoney(data?.batchDiscountedAmountIngresosBrutos)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center"><strong>Total a Acreeditar</strong></TableCell>
                    <TableCell align="right"><strong>{batchFormatMoney(batchTotalAmount)}</strong></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </div>

          <Grid container marginTop={4}>
          <Grid item xs={12} marginTop={4}>
                <Button onClick={handlePrint} variant="text" color="primary" startIcon={<PrintIcon />}>
                  Imprimir
                </Button>
          </Grid>
            {loanBatchStatusPendingPreApproval &&
            <Grid item xs={1} marginTop={4}>
              <Button onClick={goBack} variant="outlined" color="error">
                {translate('page.operativeControl.reject')}
              </Button>
            </Grid>
            }
            <Grid item xs={1} marginTop={4}>
              <Button onClick={goBack} variant="outlined">
                {translate('page.operativeControl.cancel')}
              </Button>
            </Grid>
            {loanBatchStatusPendingPreApproval &&
            <Grid item xs={1} marginTop={4}>
              <Button onClick={handleSubmit} variant="contained">
                {translate('page.operativeControl.confirm')}
              </Button>
            </Grid>
            }
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel id="printable-content-0" value={value} index={1}>
          <ol style={{ padding: 0, listStyleType: 'none' }}>
            <li style={{ marginBottom: '5px' }}>
              <strong>Cantidad de préstamos rechazados :</strong> <span style={{marginLeft: '20px'}}>{data?.batchRejectedNumber!}</span>
            </li>
            <li style={{ marginBottom: '5px' }}>
              <strong>Monto :</strong> <span style={{marginLeft: '20px'}}>$ {batchFormatMoney(data?.batchRejectedAmount!)}</span>
            </li>
          </ol>
        </CustomTabPanel>
    </Box>
    </>
    }
  </div>
);
};
