import useDataSources, { DataSourceCollection } from "../../hooks/useDataSources";

import PromisedValue from "./PromisedValue";

const PromisedDataSource = ({ collection, id }: { collection: DataSourceCollection, id?: string }) => {
  const { getValue } = useDataSources();

  if (!id) return null;

  return (
    <PromisedValue
      value={id}
      fn={getValue}
      arg={{
        collection,
        id,
      }}
    />
  );
};

export default PromisedDataSource;
