import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IFilterOption } from '../../../../../common/interfaces';
import { FilterOptionTypes } from '../../../../../common/enums';

export const useFilterOptions = () => {
  const [translate] = useTranslation('global');

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'name',
        attribute: 'name',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('page.calendar.events.form.required')),
      },
      {
        name: 'fromDate',
        attribute: 'fromDate',
        value: '',
        type: FilterOptionTypes.DATE_FROM,
        validation: yup
          .date()
          .required(translate('page.calendar.events.form.required'))
          .typeError(translate('page.calendar.events.form.format')),
      },
      {
        name: 'toDate',
        attribute: 'toDate',
        value: '',
        type: FilterOptionTypes.DATE_TO,
        validation: yup
          .date()
          .required(translate('page.calendar.events.form.required'))
          .typeError(translate('page.calendar.events.form.format')),
      },
    ],
    [translate]
  );
};

export default useFilterOptions;
