import { RangeOptionsType } from '../../components/DateRange/components';

enum DynamicFormType {
  MANUAL = 'MANUAL',
}

export enum FormElementType {
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
  MANUAL_TEXT = 'MANUAL_TEXT',
  MANUAL_COMBO = 'MANUAL_COMBO',
  MANUAL_CHECK = 'MANUAL_CHECK',
  MANUAL_DATE_RANGE = 'MANUAL_DATE_RANGE',
}

export interface IFormElement {
  multiple: any;
  allowMultipleFile: false;
  description: string;
  documentType: string;
  elementConditionalDefinitions: {
    formElementId: number;
    visibility: boolean;
  }[];
  formElementType: FormElementType;
  optionItems?: {
    key: string;
    value: string;
  }[];
  mask?: string;
  pattern: string;
  id: number;
  label: string;
  name: string;
  order: number;
  required: boolean;
  target: string;
  resolvedValue?: any;
  dateRangeOptions?: RangeOptionsType[];
  maxAmountDaysInRange?: number;
}

interface IFormElementGroup {
  id: number;
  label: string;
  name: string;
  order: number;
  formElements: IFormElement[];
}

export interface IDynamicForm {
  id: string;
  name: string;
  description: string;
  type: DynamicFormType;
}

export interface IDynamicFormDefinition {
  id: string;
  name: string;
  description: string;
  type: DynamicFormType;
  formElementGroups: IFormElementGroup[];
}
