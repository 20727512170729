import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import {
  IFinancialOfficeData,
  IFinancialOfficeDataUpdate,
  IFormRef,
} from '../../../common/interfaces';
import { useUI } from '../../../contexts/UIContext';
import { RouteKey, paths } from '../../../navigation/constants';
import { getErrorMessageFromValidationErrors } from '../../../utils/apiUtils';
import { ToastType } from '../../../components/commons/Toast';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import {
  createFinancialOffice,
  getFinancialOffice,
  updateFinancialOffice,
} from '../../../api/adminBusinessEnvironment/service';
import FinancialOfficeForm from '../../../components/FinancialOfficeForm';
import TitledSection from '../../../components/commons/TitledSection';
import usePermissions from '../../../hooks/usePermissions';
import Loading from '../../../components/commons/Loading';

const FinancialOfficeEdit = () => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const { isLoading } = usePermissions();

  const formRef = useRef<IFormRef>();

  const { setToast } = useUI();

  const navigate = useNavigate();
  const params = useParams();

  const financialOfficeId = params.financialOfficeId
    ? parseInt(params.financialOfficeId, 10)
    : undefined;

  const isUpdate = financialOfficeId !== undefined;

  const { data: financialOfficeData, ...financialOfficeQueryProps } =
    useQuery<IFinancialOfficeData>({
      queryKey: [`financialOffice:${financialOfficeId}`],
      queryFn: async () =>
        financialOfficeId
          ? (await getFinancialOffice(financialOfficeId)).data
          : {},
      keepPreviousData: false,
    });

  const goBack = () => {
    navigate(generatePath(paths[RouteKey.FINANCIAL_OFFICES]));
  };

  const onSubmit = async (payload: IFinancialOfficeDataUpdate) => {
    try {
      setBusy(true);

      if (isUpdate) {
        await updateFinancialOffice(financialOfficeId, payload);

        setToast({
          message: translate(
            'page.financialOfficeEdit.updateToastSuccessMessage'
          ),
          //key: `${dataTestId}_Success`,
        });
      } else {
        await createFinancialOffice(payload);
        setToast({
          message: translate(
            'page.financialOfficeEdit.createToastSuccessMessage'
          ),
          //key: `${dataTestId}_Success`,
        });
      }
      goBack();
    } catch (error) {
      const message = getErrorMessageFromValidationErrors(
        error,
        translate('page.financialOfficeEdit.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        //key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey =
    financialOfficeId === undefined
      ? RouteKey.FINANCIAL_OFFICE_NEW
      : RouteKey.FINANCIAL_OFFICE_EDIT;

  return isLoading ? (
    <Loading />
  ) : (
    <BaseViewPage
      //dataTestId={dataTestId}
      routeTrail={[RouteKey.FINANCIAL_OFFICES]}
      routeKey={currentRouteKey}
      data={financialOfficeData}
      {...financialOfficeQueryProps}
      buttonConfList={[
        {
          //dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          //dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={12} xs={24}>
        <TitledSection
          titleSx={{
            fontSize: '28px !important',
            fontWeight: '400 !important',
          }}
          titleKey={
            isUpdate
              ? 'page.financialOfficeEdit.update'
              : 'page.financialOfficeEdit.create'
          }
        />
        <FinancialOfficeForm
          //dataTestId={`${dataTestId}_Form`}
          formRef={formRef}
          financialOfficeId={financialOfficeId}
          data={financialOfficeData}
          // eslint-disable-next-line
          onError={console.log}
          onSubmit={onSubmit}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default FinancialOfficeEdit;
