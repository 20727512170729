import { useMemo } from 'react';
import { TextFieldProps, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SimpleSelect } from 'vulpino';
import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';

interface option {
  value: string;
  label: string;
  selected?: boolean;
}
interface category {
  title: string;
  data: option[];
}

type TextInputFormProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label?: string;
    labelKey?: string;
    translationPath?: string;
    dataTestId?: string;
    options: option[] | category[];
  };

const errorStyle = {
  marginTop: '3px',
  color: '#FB1531',
  fontSize: '12px',
};

const SimpleSelectInputForm = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  dataTestId,
  helperText,
  options,
  ...props
}: TextInputFormProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const label = useTranslate(labelKey, labelProp);
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <SimpleSelect
            label={label}
            helperText={helperText}
            variant="outlined"
            options={options}
            error={!!error}
            sx={{ width: '100%' }}
            {...field}
            InputProps={{ sx: { height: '56px !important' } }}
            SelectDisplayProps={{ style: { paddingLeft: '0px' } }}
          />
          {error && <Typography sx={errorStyle}>{error?.message}</Typography>}
        </>
      )}
    />
  );
};

export default SimpleSelectInputForm;
