import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import type { ITesteable } from '../../../common/interfaces';
import type { IFormField } from '../FormFields';

interface ICheckboxFilterProps
  extends Omit<CheckboxProps, 'name'>,
    IFormField,
    ITesteable {
  label: string;
  defaultValue?: string;
  variant?: string;
  helperText?: any;
  index: number;
}

const CheckboxFilter = ({
  name,
  label,
  mandatory = false,
  helperText,
  index,
  dataTestId,
  ...props
}: ICheckboxFilterProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      {...props}
      name={`${name}.${index}`}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <FormGroup>
            <FormControlLabel
              label={mandatory ? `${label}*` : label}
              control={
                <Checkbox
                  data-testid={dataTestId}
                  onChange={(event) => {
                    field.onChange({
                      ...field.value,
                      value: event.target.checked ? 'true' : 'false',
                    });
                  }}
                  indeterminate={
                    !['true', 'false'].includes(field.value?.value)
                  }
                  checked={field.value?.value === 'true'}
                  {...props}
                />
              }
            />
          </FormGroup>
          <FormHelperText>{error?.message ?? helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default CheckboxFilter;
