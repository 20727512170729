import { Breadcrumbs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths, RouteKey } from '../../navigation/constants';

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

interface BreadcrumbsNavigatorProps {
  title?: string;
  current?: RouteKey;
  routes?: RouteKey[];
  routePathParams?: { [key: string]: string | number };
}

interface BreadcrumbProps {
  title?: string;
  routeKey: RouteKey;
  current?: boolean;
  routePathParams?: { [key: string]: string | number };
}

const Breadcrumb = ({
  title,
  routeKey,
  current = false,
  routePathParams,
}: BreadcrumbProps) => {
  const [translate] = useTranslation('global');

  const label = useMemo(
    () => translate(`route.${routeKey}.label`, routeKey),
    [translate, routeKey]
  );

  const breadcrumb = useMemo(
    () => translate(`route.${routeKey}.breadcrumb`, label),
    [translate, routeKey]
  );

  const getPathWithParam = (
    path: string,
    params: { [key: string]: string | number }
  ) => {
    let response = path;
    if (path.includes(':')) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        response = response.replace(`:${key}`, params[key]?.toString());
      });
    }
    return response;
  };

  return current ? (
    <Typography color="text.primary" fontWeight={current ? 'bold' : undefined}>
      {title ?? breadcrumb}
    </Typography>
  ) : (
    <StyledLink
      to={
        routePathParams
          ? getPathWithParam(paths[routeKey], routePathParams)
          : paths[routeKey]
      }
    >
      {title ?? breadcrumb}
    </StyledLink>
  );
};

const BreadcrumbsNavigator = ({
  current,
  routes = [],
  title,
  routePathParams,
}: BreadcrumbsNavigatorProps) => (
  <Breadcrumbs aria-label="breadcrumb">
    {routes.map((routeKey: RouteKey) => (
      <Breadcrumb
        title={current === routeKey ? title : undefined}
        key={routeKey}
        routeKey={routeKey}
        current={current === routeKey}
        routePathParams={routePathParams}
      />
    ))}
  </Breadcrumbs>
);

export default BreadcrumbsNavigator;
