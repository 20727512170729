import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import useTranslate from '../../hooks/useTranslate';
import type { ITesteable } from '../../common/interfaces';

import AutoCompleteSelector from './AutoCompleteSelector';
import type { IFormField } from './FormFields';
import { getCatalogItems } from '../../api/adminPlatformCatalogs/service';

export interface CatalogSelectorProps<TParams>
  extends Omit<IFormField, 'type'>,
    ITesteable {
  name: string;
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  tableName: string;
  params?: TParams;
  selectorFullWidth?: boolean;
  isEnabled?: (args: { params?: TParams }) => boolean;
}

interface IOptionItem {
  code: string;
  description: string;
}

const CatalogSelector = <TParams,>({
  label: labelProp,
  name,
  fullWidth,
  labelKey,
  tableName,
  params,
  isEnabled,
  mandatory = false,
  selectorFullWidth = false,
  ...props
}: CatalogSelectorProps<TParams>) => {
  const queryClient = useQueryClient();

  const label = useTranslate(labelKey, labelProp, {
    mandatory,
  });

  const [options, setOptions] = useState<IOptionItem[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await queryClient.fetchQuery({
        queryKey: `catalog:${tableName}`,
        queryFn: () =>
          getCatalogItems({
            tableName,
          }),
      });

      setOptions(data.result);
    })();
  }, [tableName, params, isEnabled]);

  return (
    <AutoCompleteSelector<IOptionItem, string>
      name={name}
      fullWidth={fullWidth}
      {...props}
      label={label}
      options={options}
      selectorFullWidth={selectorFullWidth}
      {...{
        getOptionLabel: (option) => option.description,
        getOptionValue: (option) => option.code,
      }}
    />
  );
};

export default CatalogSelector;
