import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import BaseViewHeader from '../BaseViewHeader';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';
import { getInterestRateList } from '../../api/adminRates';
import useRoutePage from '../../hooks/useRoutePage';
import { RouteKey } from '../../navigation/constants';
import Loading from '../commons/Loading';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '5px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const dataTestId = 'InterestRateList';

const InterestRateList = ({ setEditMode }: { setEditMode: Function }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { checkPermissions } = usePermissions();
  const interestRateTableId = params.itemId ? parseInt(params.itemId, 10) : '';
  const interestRateGroupId = params.groupId
    ? parseInt(params.groupId, 10)
    : '';

  const { routeState, mergeRouteState, navigateToRoute } = useRoutePage({
    routeKey: RouteKey.VIEW_RATE_GROUP,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });
  const { pageNumber, pageSize } = routeState;
  const isSmallDevice = useMediaQuery('(max-width : 600px)');
  const {
    data,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList',
      pageNumber,
      pageSize,
      { interestRateTableId, interestRateGroupId },
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: { interestRateTableId, interestRateGroupId },
        })
      ).data,
    keepPreviousData: false,
  });
  const { isLoading } = usePermissions();

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <>
      <BaseViewHeader
        suptitle={
          <Typography variant="h2">
            {t('page.ratesGroupDetail.interestRatesList.title')}
          </Typography>
        }
        buttonList={[
          {
            onClick: () => setEditMode(true),
            //dataTestId: `${dataTestId}_EditButton`,
            endIcon: <BorderColorIcon />,
            labelKey: 'common.actionEdit',
            hidden: !checkPermissions(Permissions.INTEREST_RATE_CREATE),
            variant: 'primary',
          },
        ]}
      >
        {data?.result[0]?.interestRateGroup && (
          <Grid container>
            <Grid item>
              <Typography sx={{ fontSize: '20px', marginTop: '2px' }}>
                {data.result[0].interestRateGroup.name}
              </Typography>
            </Grid>
          </Grid>
        )}
      </BaseViewHeader>
      <Grid container>
        <Grid item xs={12} mb={4}>
          <Typography variant="h3">
            {t('page.ratesGroupDetail.interestRatesList.subtitle')}
          </Typography>
        </Grid>
        {data?.result[0]?.interestRates.map((interestRate: any) => (
          <>
            <StyledBox
              key={interestRate.fromTerm}
              sx={{
                width: isSmallDevice ? '100%' : '50%',
                paddingTop: '12px',
                paddingBottom: '12px',
                marginBottom: '18px',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('page.ratesGroupDetail.interestRatesList.termLabel')}
                  </Typography>
                  <Typography>
                    {t('page.ratesGroupDetail.interestRatesList.termValue', {
                      fromTerm: interestRate.fromTerm,
                      toTerm: interestRate.toTerm,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('page.ratesGroupDetail.interestRatesList.rateLabel')}
                  </Typography>
                  <Typography>{interestRate.rate}</Typography>
                </Grid>
              </Grid>
            </StyledBox>
            <div style={{ width: '50%' }} />
          </>
        ))}
      </Grid>
    </>
  );
};

export default InterestRateList;
