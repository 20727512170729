import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    // tertiary: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteColorOptions {
    main?: string;
    light?: string;
    dark?: string;
    contrastText?: string;
    default?: string;
  }

  interface newColors {
    foreground: PaletteColorOptions;
  }

  interface Palette extends newColors {}
  interface PaletteOptions extends newColors {}
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    list?: TypographyStyleOptions;
    p?: TypographyStyleOptions;
  }
}

// baseTheme: Just colors palette and typography
const baseTheme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    foreground: {
      default: '#000000',
    },
    primary: {
      main: '#034184',
    },
    secondary: {
      main: '#81E5FF',
    },
    success: {
      main: '#02C66A',
    },
    error: {
      main: '#FB1531',
    },
    warning: {
      main: '#F5A623',
      light: '#FFF9E5',
      dark: '#EA931A',
    },
    info: {
      main: '#3988FF',
    },
    tonalOffset: 0.5,
  },
  typography: {
    h1: {
      fontFamily: 'Inter',
      fontSize: '28px',
      fontWeight: 400,
      justifyContent: 'left',
    },
    h2: {
      fontFamily: 'Inter',
      fontSize: '24px',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
    },
    list: {
      fontFamily: 'Inter',
    },
    p: {
      fontFamily: 'Inter',
      fontSize: '14px',
    },

    body1: {
      fontFamily: 'Inter',
      fontSize: '16px',
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: '0.8rem',
    },

    fontFamily: 'Inter',
  },
});

// theme: baseTheme + components overrides
const theme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none !important',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: '700',
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
          '&:active': {
            borderWidth: '2px',
          },
          '&:focus': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none !important',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: '700',
          color: baseTheme.palette.grey[900],
          backgroundColor: baseTheme.palette.background.default,
          border: 'none',
          padding: '5px 10px',
          '&.Mui-selected': {
            color: baseTheme.palette.foreground.default,
            backgroundColor: baseTheme.palette.grey[200],
            border: `1px solid ${baseTheme.palette.grey[300]}`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            color: baseTheme.palette.foreground.default,
            backgroundColor: baseTheme.palette.grey[400],
            cursor: 'default',
          },
          '&$selected:hover': {
            color: baseTheme.palette.foreground.default,
            backgroundColor: baseTheme.palette.grey[300],
            cursor: 'default',
          },
        },
      },
    },

    // estilos personalizados de input
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: baseTheme.palette.grey[800],
          '&$disabled': {
            color: baseTheme.palette.grey[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: baseTheme.palette.grey[500],
        },
      },
    },
    // texto ingresado
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&$disabled': {
            color: baseTheme.palette.grey[500],
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontWeight: '900',
        },
        columnHeaders: {
          backgroundColor: baseTheme.palette.grey[100],
        },
        columnSeparator: {
          color: baseTheme.palette.grey[100],
        },
      },
    },
    // fin estilos personalizados de input
  },
});

export default theme;
