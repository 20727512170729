import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

interface useDataGridColumnsParams {
  translationKeyPrefix?: string;
}

const useDataGridColumns = (
  columns: GridColumns,
  { translationKeyPrefix }: useDataGridColumnsParams = {},
  deps: any[] = []
): GridColumns => {
  const [translate] = useTranslation('global');

  return useMemo(
    () => [
      ...columns.map((column) => ({
        sortable: false,
        flex: 1,
        headerName: translate(`${translationKeyPrefix}${column.field}`),
        ...column,
      })),
    ],
    [translate, ...deps]
  );
};

export default useDataGridColumns;
