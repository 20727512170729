import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import qs from 'qs';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import type { ITesteable, ICodeMappingData } from '../../common/interfaces';
import { RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';
import useRoutePage from '../../hooks/useRoutePage';
import { getCodeMapping } from '../../api/adminBusinessEnvironment/service';
import BaseViewHeader from '../../components/BaseViewHeader';
import DataFillIterator from '../../components/commons/DataFillIterator';
import { Permissions } from '../../common/enums';
import usePermissions from '../../hooks/usePermissions';

interface ICodeMappingViewPageProps extends ITesteable {}

const CodeMappingViewPage = ({
  dataTestId = 'CodeMappingViewPage',
}: ICodeMappingViewPageProps) => {
  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const { checkPermissions } = usePermissions();
  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.CODE_MAPPING_VIEW,
  });

  const location = useLocation();

  const { entityType: entityTypeQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const entityType =
    typeof entityTypeQuery === 'string' ? entityTypeQuery : undefined;

  const { data, ...queryProps } = useQuery<ICodeMappingData>({
    queryKey: [`codeMapping:${itemId}`],
    queryFn: async () =>
      itemId ? (await getCodeMapping(itemId)).data : ({} as ICodeMappingData),
    keepPreviousData: true,
  });

  const search = entityType ? `?entityType=${entityType}` : undefined;

  const handleEdit = () => {
    navigateToRoute(RouteKey.CODE_MAPPING_EDIT, {
      search,
      ...params,
    });
  };

  const goBack = useCallback(
    () =>
      navigateToRoute(RouteKey.CODE_MAPPINGS, {
        search,
      }),
    [navigateToRoute, entityType]
  );

  return (
    <BaseViewPage
      routeKey={RouteKey.CODE_MAPPING_VIEW}
      routeTrail={[RouteKey.CODE_MAPPINGS]}
      data={data}
      dataTestId={dataTestId}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          onClick: goBack,
          labelKey: 'common.cancel',
        },
      ]}
    >
      <BaseViewHeader
        title={data?.internalId}
        dataTestId={`${dataTestId}_Header`}
        buttonList={[
          {
            onClick: handleEdit,
            dataTestId: `${dataTestId}_EditButton`,
            endIcon: <BorderColorIcon />,
            labelKey: 'page.codeMapping.actionEdit',
            hidden: !checkPermissions(Permissions.CODE_MAPPING_UPDATE),
            variant: 'primary',
          }
        ]}
      >
        <DataFillIterator
          mt={1}
          translationKeyPrefix="page.codeMapping"
          object={[
            {
              key: '.form.entityType.label',
              value: data?.entityType,
            },
            {
              key: '.form.externalCode.label',
              value: data?.externalCode,
            },
            {
              key: '.form.internalId.label',
              value: data?.internalId,
            },
          ]}
        />
      </BaseViewHeader>
    </BaseViewPage>
  );
};

export default CodeMappingViewPage;
