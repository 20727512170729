import {
  Box,
  Grid,
  GridProps,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Trans } from 'react-i18next';
import { ITesteable } from '../../common/interfaces';
import useTranslate from '../../hooks/useTranslate';

type TVariant =
  | 'h5'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'inherit';
export interface TitledSectionProps extends GridProps, ITesteable {
  title?: string;
  titleKey?: string;
  helperText?: string;
  helperTextKey?: string;
  tooltip?: string;
  children?: ReactNode;
  variant?: TVariant;
  centered?: boolean;
  childrenProps?: GridProps;
  accordion?: boolean;
  titleAfter?: ReactNode;
  subTitle?: string;
  titleSx?: any;
  onToggleContent?: (shown: boolean) => void;
}

const variantDefaults: {
  [key in TVariant | 'base']: {
    containerGridProps?: GridProps;
    helperTextGridProps?: GridProps;
    titleGridProps?: GridProps;
    preProps?: GridProps;
  };
} = {
  base: {
    containerGridProps: {
      mt: 1,
    },
    preProps: {
      mb: 0.5,
    },
    helperTextGridProps: {},
  },
  h1: {
    containerGridProps: {},
  },
  h2: {
    preProps: {
      mb: 1.5,
    },
  },
  h3: {},
  h4: {
    containerGridProps: {
      mt: 1,
    },
    preProps: {
      mb: 1.5,
    },
  },
  h5: {
    preProps: {},
  },
  h6: {},
  subtitle1: {},
  subtitle2: {},
  body1: {},
  body2: {},
  caption: {},
  button: {},
  overline: {},
  inherit: {},
};

const TitledSection = ({
  dataTestId = 'TitledSection',
  title: titleProp,
  titleKey,
  titleAfter,
  subTitle,
  tooltip,
  helperTextKey,
  helperText: helperTextProp,
  children,
  variant = 'h4',
  centered = false,
  spacing,
  childrenProps,
  titleSx = {},
  accordion = false,
  onToggleContent,
  ...props
}: TitledSectionProps) => {
  const [showContent, setShowContent] = useState(!accordion);

  const title = useTranslate(titleKey, titleProp);
  const helperText = useTranslate(helperTextKey, helperTextProp);

  useEffect(() => {
    onToggleContent?.(showContent);
  }, [showContent]);

  const childrenContainerProps = useMemo(
    () =>
      centered
        ? {
            xs: 12,
            lg: 6,
            md: 8,
          }
        : {
            xs: 12,
          },
    [centered]
  );

  const items = useMemo(
    () =>
      Children.map(children, (child) =>
        isValidElement(child) ? cloneElement(child, childrenProps) : child
      ),
    [children, childrenProps]
  );

  return (
    <Grid
      flexWrap="nowrap"
      flexDirection="column"
      item
      container
      {...(variantDefaults.base.containerGridProps ?? {})}
      {...(variantDefaults[variant].containerGridProps ?? {})}
      {...props}
      data-testid={dataTestId}
    >
      {title && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
          alignItems="center"
          {...(variantDefaults.base.titleGridProps ?? {})}
          {...(variantDefaults[variant].titleGridProps ?? {})}
        >
          {accordion && (
            <Switch
              checked={showContent}
              onChange={() => setShowContent(!showContent)}
              sx={{ mr: 0.5, ml: -1.5 }}
            />
          )}
          <Trans i18nKey="global">
            <Typography variant={variant} sx={{ flexGrow: 1, ...titleSx }}>
              {title}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <IconButton sx={{ width: '30px', height: '30px' }}>
                    <HelpOutlineIcon fontSize="small" /> <br />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            {titleAfter}
          </Trans>
        </Grid>
      )}
      {subTitle && <Grid item xs={12}><Typography marginTop={2}>{subTitle}</Typography></Grid>}
      {showContent && helperText && (
        <Grid
          item
          xs={12}
          md={12}
          {...(variantDefaults.base.helperTextGridProps ?? {})}
          {...(variantDefaults[variant].helperTextGridProps ?? {})}
        >
          <Trans i18nKey="global" mt={1}>
            {helperText}
          </Trans>
        </Grid>
      )}
      <Box
        {...(variantDefaults.base.preProps ?? {})}
        {...(variantDefaults[variant].preProps ?? {})}
      />
      {showContent && (
        <Grid item container spacing={spacing} {...childrenContainerProps}>
          {items}
        </Grid>
      )}
    </Grid>
  );
};

export default TitledSection;
