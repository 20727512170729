import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import type { ITesteable } from '../../common/interfaces';
import type { IRoleData } from '../../api/adminUsersRoles/interfaces';
import { getRole } from '../../api/adminUsersRoles/service';
import useParsePermissions from '../../hooks/useParsePermissions';
import AppIdSection from './Parts/AppIdSection';
import ChipsGroup from '../../components/commons/ChipsGroup';
import TitledSection from '../../components/commons/TitledSection';
import BaseViewPage from '../../components/commons/BaseViewPage';
import { paths, RouteKey } from '../../navigation/constants';
import BaseViewHeader from '../../components/BaseViewHeader';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';

interface IRoleViewProps extends ITesteable {}

const RoleViewPage = ({ dataTestId = 'RoleViewPage' }: IRoleViewProps) => {
  const [translate] = useTranslation('global');

  const navigate = useNavigate();
  const params = useParams();
  const roleId = params.roleId ? parseInt(params.roleId, 10) : undefined;

  const { checkPermissions } = usePermissions();

  const { data, ...queryProps } = useQuery<IRoleData>({
    queryKey: [`role:${roleId}`],
    queryFn: async () => (roleId ? (await getRole(roleId)).data : {}),
    keepPreviousData: true,
  });

  const onCancel = () => {
    navigate(paths[RouteKey.ROLES]);
  };

  const onNavEdit = () => {
    navigate(generatePath(paths[RouteKey.ROLES_EDIT], params));
  };

  const metadataKeysChips = useMemo(
    () =>
      (data?.metadataRequiredKeys ?? [])?.map((key: string) => {
        const labelKey = `datasources.metadataKeys.${key}`;
        return {
          key,
          label: translate(labelKey, key),
        };
      }),
    [data?.metadataRequiredKeys]
  );

  const permissions = useMemo(() => data?.permissions ?? [], [data]);

  const { apps, permissions: byAppPermissions } = useParsePermissions({
    permissions,
  });

  const permissionsList = useMemo(
    () => (
      <Grid>
        {apps.length ? (
          apps.map((app) => (
            <AppIdSection
              dataTestId={dataTestId}
              key={app.key}
              app={app}
              permissions={byAppPermissions[app.key]}
            />
          ))
        ) : (
          <Typography>{translate('page.role.noPermissions')}</Typography>
        )}
      </Grid>
    ),
    [apps, byAppPermissions, dataTestId]
  );

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeKey={RouteKey.ROLES_VIEW}
      routeTrail={[RouteKey.ROLES]}
      data={data}
      {...queryProps}
      buttonConfList={[
        {
          onClick: onCancel,
          labelKey: 'page.role.buttonGoBackSecondaryCTA',
        },
      ]}
    >
      <BaseViewHeader
        title={`${translate('page.role.roleNameLabel')}: ${data?.roleName}`}
        buttonList={[
          {
            hidden:
              !checkPermissions(Permissions.ROLE_UPDATE) || data?.preserved,
            onClick: onNavEdit,
            variant: 'primary',
            dataTestId: `${dataTestId}_EditButton`,
            endIcon: <BorderColorIcon />,
            labelKey: 'page.role.actionEdit',
          }
         
        ]}
      />
      <Grid item container sm={6} xs={12} spacing={3}>
        {data?.description && (
          <Grid item xs={12} data-testid={`${dataTestId}_description`}>
            {data?.description}
          </Grid>
        )}
        <TitledSection
          variant="h4"
          title={translate('page.role.permissionsLabel')}
        >
          {permissionsList}
        </TitledSection>
        <TitledSection
          variant="h4"
          title={translate('common.additionalKeysLabel')}
        >
          <ChipsGroup
            chips={metadataKeysChips}
            dataTestId={`${dataTestId}_metadata`}
            emptyState={
              <Typography>{translate('page.role.noMetadataKeys')}</Typography>
            }
          />
        </TitledSection>
      </Grid>
    </BaseViewPage>
  );
};

export default RoleViewPage;
