import { useMemo } from 'react';
import { getTenantName } from '../utils/core';

const useResolveTenant = (): string =>
  useMemo(
    () =>
      getTenantName({
        hostname: window.location.hostname,
      }),
    [window.location.hostname]
  );

export default useResolveTenant;
