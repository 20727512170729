import * as yup from 'yup';
import dayjs from 'dayjs';
import { ICommerceBusinessSchemaQuotaAddFormData } from '../../common/interfaces';

export const buildFormData = ({
  validFrom,
  amount,
  exceptional,
  validFromMonth,
  validFromYear,
}: Partial<ICommerceBusinessSchemaQuotaAddFormData> = {}): Partial<ICommerceBusinessSchemaQuotaAddFormData> => ({
  validFrom: validFrom ?? dayjs(new Date()).toString(),
  amount,
  exceptional: exceptional ?? false,
  validFromMonth:
    validFromMonth ?? dayjs().add(1, 'month').get('month').toString(),
  validFromYear: validFromYear ?? dayjs().add(1, 'month').toDate().toString(),
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    validFrom: yup.string().required(translate('common.required')),
    amount: yup.string().required(translate('common.required')),
    exceptional: yup.bool().required(translate('common.required')),
    validFromMonth: yup.string().required(translate('common.required')),
    validFromYear: yup.string().required(translate('common.required')),
  };

  return yup.object().shape(shape);
};

export default {};
