import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { ERROR, SUCCESS } from './apiServicesConst';
import { translateApiErrorCode } from './apiUtils';
import { referenceConstant } from './referenceConstant';

const checkTypeOfFunc = (param) => typeof param === 'function';

const useApiMessage = (response, reference, isInitError = false) => {
  const [translate] = useTranslation('global');

  
  return useMemo(() => {
    if (isInitError) {
      return {
        status: ERROR,
        title: translate('error.global.onInitErrorTitle'),
        subtitle: translate('error.global.onInitErrorSubtitle'),
        buttonLabel: translate('error.global.onInitErrorButtonLabel'),
      };
    }

    const constant = referenceConstant[reference];

    if (response.status >= 200 && response.status <= 299) {
      return {
        status: SUCCESS,
        title: translate(constant?.titleSuccess),
        subtitle: checkTypeOfFunc(constant?.subtitleSuccess)
          ? constant?.subtitleSuccess(response)
          : translate(constant?.subtitleSuccess),
        buttonLabel:
          translate(constant?.buttonLabelSuccess) || 'OK',
      };
    }
    return {
      status: ERROR,
      title: translate(constant?.titleError) || '',
      subtitle: checkTypeOfFunc(constant?.subtitleError)
        ? constant?.subtitleError(response)
        : translate(constant?.subtitleError) || '',
      apiErrorExplanation: translateApiErrorCode(response) || '',
      buttonLabel:
        translate(constant?.buttonLabelError) || '',
    };

  }, [translate, response, reference, isInitError]);

};

export default useApiMessage;
