import { useEffect, useMemo, useState } from 'react';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BaseViewPage from '../../commons/BaseViewPage';
import BaseViewHeader from '../../BaseViewHeader';
import DataFill from '../../commons/DataFill';
import TitledSection from '../../commons/TitledSection';
import PromisedDataSource from '../../helpers/PromisedDataSource';
import DataFillIterator from '../../commons/DataFillIterator';
import PromisedPlatformCatalog from '../../helpers/PromisedPlatformCatalog';
import { RouteKey } from '../../../navigation/constants';
import usePermissions from '../../../hooks/usePermissions';
import { Permissions } from '../../../common/enums';
import { DataSourceCollection } from '../../../hooks/useDataSources';
import { PlatformCatalogKey } from '../../../hooks/usePlatformCatalogs';
import { getCommerce } from '../../../api/adminBusinessEnvironment/service';
import { paths } from '../../../navigation/constants';

const CommerceDetail = () => {
  const params = useParams();
  const itemId = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();

  const { data, ...queryProps } = useQuery({
    queryKey: [`getCommerce:${itemId}`],
    queryFn: async () => (await getCommerce(itemId!)).data,
  });

  const isBNPL = !!data?.bnplProviderMerchantId;
  const [isHeadquarter, setIsHeadquarter] = useState(false);

  const navigate = useNavigate();

  const onNavEdit = () => {
    navigate(generatePath(paths[RouteKey.COMMERCES_EDIT], params));
  };

  useEffect(() => {
    if (data && data.headquarterId === data.id) {
      setIsHeadquarter(true);
    }
  }, [data]);

  const addressData = useMemo(
    () => [
      {
        key: t('page.commerce.form.address.countryId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.COUNTRIES}
            id={data?.address.countryId}
          />
        ),
      },
      {
        key: t('page.commerce.form.address.stateId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.STATES}
            id={data?.address.stateId}
            params={pick(data?.address, ['countryId'])}
          />
        ),
      },
      {
        key: t('page.commerce.form.address.cityId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.CITIES}
            id={data?.address.cityId}
            params={pick(data?.address, ['countryId', 'stateId'])}
          />
        ),
      },
      {
        key: t('page.commerce.form.address.zipCodeId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.ZIP_CODES}
            id={data?.address.zipCodeId}
            params={{
              countryId: data?.address?.countryId,
              stateId: data?.address?.stateId,
              cityIds: [data?.address?.cityId],
            }}
          />
        ),
      },
      {
        key: t('page.commerce.form.address.street.label'),
        value: data?.address.street,
      },
    ],
    [t, data?.address]
  );

  return (
    <BaseViewPage
      // dataTestId={dataTestId}
      routeTrail={[RouteKey.COMMERCES]}
      routeKey={RouteKey.COMMERCES_VIEW}
      data={data}
      title={t('route.commerces_view.breadcrumb')}
      {...queryProps}
    >
      <BaseViewHeader
        //dataTestId={`${dataTestId}_Header`}
        buttonList={[
          {
            onClick: onNavEdit,
            //dataTestId: `${dataTestId}_EditButton`,
            endIcon: <BorderColorIcon />,
            labelKey: 'page.commerce.actionEdit',
            hidden: !checkPermissions(Permissions.COMMERCE_UPDATE),
            variant: 'primary',
          },
        ]}
        boxProps={{
          pt: 0,
        }}
      >
        <DataFill
          md={8}
          lg={8}
          translationKeyPrefix="page.commerce"
          title=".form.name.label"
          value={data?.name}
        />
        {data?.externalCode && (
          <DataFill
          md={8}
          lg={8}
          translationKeyPrefix="page.commerce"
          title=".form.externalCode.label"
          value={data?.externalCode}
          />
        )}
        {data?.description && (
          <DataFill
            md={8}
            lg={8}
            translationKeyPrefix="page.commerce"
            title=".form.description.label"
            value={data?.description}
          />
        )}
        <DataFill
          md={8}
          lg={8}
          translationKeyPrefix="page.commerce"
          title=".form.commerceTaxId.label"
          value={data?.commerceTaxId}
        />
        {data?.category && (
          <DataFill
            md={8}
            lg={8}
            translationKeyPrefix="page.commerce"
            title=".form.category.label"
            value={t(`datasources.CommerceCategory.${data?.category}`)}
          />
        )}
        {isBNPL && (
          <DataFill
            md={8}
            lg={8}
            translationKeyPrefix="page.commerce"
            value={t('page.commerce.form.isBNPL.label')}
          />
        )}
      </BaseViewHeader>

      <Grid item container sm={12} xs={24} spacing={3}>
        {data?.headquarterId && (
          <TitledSection
            titleKey="page.commerce.form.headquarterId.label"
            tooltip={t('page.commerce.form.headquarterId.label')}
            variant="h3"
            centered
          >
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.commerce"
              title=".form.headquarterId.label"
              value={
                isHeadquarter ? (
                  t('page.commerce.isHeadquarter')
                ) : (
                  <PromisedDataSource
                    collection={DataSourceCollection.COMMERCES}
                    id={String(data?.headquarterId)}
                  />
                )
              }
            />
          </TitledSection>
        )}
        <TitledSection
          titleKey="page.commerce.form.financialOfficesIds.label"
          tooltip={t('page.commerce.form.financialOfficesIds.label')}
          variant="h3"
          centered
        >
          <DataFill
            md={8}
            lg={8}
            translationKeyPrefix="page.commerce.form."
            title="financialOfficesIds.label"
            value={
              <PromisedDataSource
                collection={DataSourceCollection.FINANCIAL_OFFICES}
                id={String(data?.financialOfficesIds[0])}
              />
            }
          />
        </TitledSection>
        <TitledSection titleKey="page.commerce.form.address.label" variant="h3">
          <DataFillIterator
            gridItemProps={{
              lg: 6,
              md: 6,
            }}
            object={addressData}
            translationKeyPrefix="page.commerce.form."
          />
        </TitledSection>

        {(data?.bankAccounts ?? []).length > 0 && (
          <TitledSection
            titleKey="page.commerce.bankAccounts.title"
            spacing={2}
            variant="h3"
          >
            {(data?.bankAccounts ?? []).map((bankAccount: any, index: any) => (
              <TitledSection
                // eslint-disable-next-line react/no-array-index-key
                key={`bankAccount:${index}/${
                  (data?.bankAccounts ?? []).length
                }`}
                title={t('page.commerce.accountIndex', {
                  index: index + 1,
                })}
                variant="h5"
                spacing={3}
                mt={2}
              >
                <Grid item mt={3} sx={{ width: '100%' }}>
                  <DataFillIterator
                    translationKeyPrefix="page.commerce.form."
                    gridItemProps={{
                      lg: 6,
                      md: 6,
                    }}
                    object={[
                      {
                        key: 'fundingEntityId.label',
                        value: (
                          <PromisedDataSource
                            collection={DataSourceCollection.FUNDING_ENTITIES}
                            id={String(bankAccount.fundingEntityId)}
                          />
                        ),
                      },
                      {
                        key: 'bankId.label',
                        value: (
                          <PromisedPlatformCatalog
                            catalog={PlatformCatalogKey.BANKS}
                            id={bankAccount.bankId}
                          />
                        ),
                      },
                      {
                        key: 'accountType.label',
                        value: bankAccount.accountType,
                      },
                      {
                        key: 'accountNumber.label',
                        value: bankAccount.accountNumber,
                      },
                      bankAccount.identificationType
                        ? {
                            key: 'identificationType.label',
                            value: bankAccount.identificationType,
                          }
                        : { key: '', value: '' },
                      bankAccount.identificationNumber
                        ? {
                            key: 'identificationNumber.label',
                            value: bankAccount.identificationNumber,
                          }
                        : { key: '', value: '' },
                    ]}
                  />
                </Grid>
              </TitledSection>
            ))}
          </TitledSection>
        )}
      </Grid>
    </BaseViewPage>
  );
};

export default CommerceDetail;
