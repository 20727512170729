import { useMemo } from 'react';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { RouteKey } from '../../../navigation/constants';
import { DataSourceCollection } from '../../../hooks/useDataSources';
import { PlatformCatalogKey } from '../../../hooks/usePlatformCatalogs';
import { paths } from '../../../navigation/constants';
import PromisedPlatformCatalog from '../../../components/helpers/PromisedPlatformCatalog';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import BaseViewHeader from '../../../components/BaseViewHeader';
import DataFill from '../../../components/commons/DataFill';
import TitledSection from '../../../components/commons/TitledSection';
import PromisedDataSource from '../../../components/helpers/PromisedDataSource';
import DataFillIterator from '../../../components/commons/DataFillIterator';
import { IFinancialOfficeData } from '../../../common/interfaces';
import { getFinancialOffice } from '../../../api/adminBusinessEnvironment/service';
import { Permissions } from '../../../common/enums';
import usePermissions from '../../../hooks/usePermissions';

const FinancialOfficeDetail = () => {
  const params = useParams();

  const financialOfficeId = params.financialOfficeId
    ? parseInt(params.financialOfficeId, 10)
    : undefined;

  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();

  const { data: financialOfficeData, ...financialOfficeQueryProps } =
    useQuery<IFinancialOfficeData>({
      queryKey: [`financialOffice:${financialOfficeId}`],
      queryFn: async () =>
        financialOfficeId
          ? (await getFinancialOffice(financialOfficeId)).data
          : {},
      keepPreviousData: false,
    });

  const navigate = useNavigate();
  const onNavEdit = () => {
    navigate(
      generatePath(paths[RouteKey.FINANCIAL_OFFICE_EDIT], { financialOfficeId })
    );
  };

  const addressData = useMemo(
    () => [
      {
        key: t('page.financialOfficeDetail.address.countryId'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.COUNTRIES}
            id={financialOfficeData?.address.countryId}
          />
        ),
      },
      {
        key: t('page.financialOfficeDetail.address.stateId'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.STATES}
            id={financialOfficeData?.address.stateId}
            params={pick(financialOfficeData?.address, ['countryId'])}
          />
        ),
      },
      {
        key: t('page.financialOfficeDetail.address.cityId'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.CITIES}
            id={financialOfficeData?.address.cityId}
            params={pick(financialOfficeData?.address, [
              'countryId',
              'stateId',
            ])}
          />
        ),
      },
      {
        key: t('page.financialOfficeDetail.address.zipCodeId'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.ZIP_CODES}
            id={financialOfficeData?.address.zipCodeId}
            params={{
              countryId: financialOfficeData?.address?.countryId,
              stateId: financialOfficeData?.address?.stateId,
              cityIds: [financialOfficeData?.address?.cityId],
            }}
          />
        ),
      },
      {
        key: t('page.financialOfficeDetail.address.street'),
        value: financialOfficeData?.address.street,
      },
    ],
    [t, financialOfficeData?.address]
  );

  return (
    <BaseViewPage
      // dataTestId={dataTestId}
      routeTrail={[RouteKey.FINANCIAL_OFFICES]}
      routeKey={RouteKey.FINANCIAL_OFFICE_VIEW}
      data={financialOfficeData}
      title={t('page.financialOfficeDetail.title')}
      {...financialOfficeQueryProps}
    >
      <BaseViewHeader
        //dataTestId={`${dataTestId}_Header`}
        buttonList={[
          {
            onClick: onNavEdit,
            //dataTestId: `${dataTestId}_EditButton`,
            hidden: !checkPermissions(Permissions.FINANCIAL_OFFICE_UPDATE),
            endIcon: <BorderColorIcon />,
            labelKey: 'common.actionEdit',
            variant: 'primary',
            sx: { minWidth: '160px' },
            size: 'medium',
          },
        ]}
        boxProps={{
          pt: 0,
        }}
      >
        <Grid item container sm={12} xs={24} spacing={3}>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDetail."
              title="description"
              value={financialOfficeData?.description}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDetail."
              title="code"
              value={financialOfficeData?.code}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDetail."
              title="additionalData.allowDelivery"
              value={
                // @ts-ignore
                financialOfficeData?.additionalData?.allowDelivery ? 'Si' : 'No'
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDetail."
              title="active"
              value={financialOfficeData?.active ? 'Si' : 'No'}
            />
          </Grid>
        </Grid>
      </BaseViewHeader>

      <Grid item container sm={12} xs={24}>
        <Grid item sm={6} xs={12} sx={{ width: '100%' }}>
          <TitledSection
            titleKey="page.financialOfficeDetail.fundingEntityId"
            variant="h3"
          >
            <DataFillIterator
              gridItemProps={{
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
              }}
              translationKeyPrefix="page.financialOfficeDetail."
              object={[
                {
                  key: 'fundingEntityId',
                  value: (
                    <PromisedDataSource
                      collection={DataSourceCollection.FUNDING_ENTITIES}
                      id={String(financialOfficeData?.fundingEntityId)}
                    />
                  ),
                },
              ]}
            />
          </TitledSection>
        </Grid>
        <Grid item sm={6} xs={12} sx={{ width: '100%' }}>
          <TitledSection
            titleKey="page.financialOfficeDetail.divisionId"
            variant="h3"
          >
            <DataFillIterator
              gridItemProps={{
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
              }}
              translationKeyPrefix="page.financialOfficeDetail."
              object={[
                {
                  key: 'divisionId',
                  value: (
                    <PromisedDataSource
                      collection={DataSourceCollection.DIVISIONS}
                      id={String(financialOfficeData?.divisionId)}
                    />
                  ),
                },
              ]}
            />
          </TitledSection>
        </Grid>
        <Grid item mt={3} sx={{ width: '100%' }}>
          <TitledSection
            titleKey="page.financialOfficeDetail.address.label"
            variant="h3"
          >
            <DataFillIterator
              gridItemProps={{
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
              }}
              spacing={0}
              object={addressData}
              translationKeyPrefix="page.financialOfficeDetail."
            />
          </TitledSection>
        </Grid>
        <Grid item mt={3} sx={{ width: '100%' }}>
          <TitledSection
            titleKey="page.financialOfficeDetail.address.geographicalCoordinates"
            variant="h3"
          >
            <DataFill
              value={financialOfficeData?.address?.latitude}
              translationKeyPrefix="page.financialOfficeDetail.address."
              title="latitude"
              xs={12}
              sm={6}
              md={6}
              lg={6}
            />
            <DataFill
              value={financialOfficeData?.address?.longitude}
              translationKeyPrefix="page.financialOfficeDetail.address."
              title="longitude"
              xs={12}
              sm={6}
              md={6}
              lg={6}
            />
          </TitledSection>
        </Grid>
      </Grid>
    </BaseViewPage>
  );
};

export default FinancialOfficeDetail;
