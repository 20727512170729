import { useTranslation } from 'react-i18next';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import qs from 'qs';
import BaseTablePage from '../../../commons/BaseTablePage';
import CustomDataGrid from '../../../commons/CustomDataGrid';
import FilterContainer from '../../../commons/FilterContainer';
import { RouteKey } from '../../../../navigation/constants';
import { getDivisions } from '../../../../api/adminBusinessEnvironment/service';
import useRoutePage from '../../../../hooks/useRoutePage';
import {
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFinancialOfficeDivisionFiltersState,
} from '../../../../common/interfaces';
import Loading from '../../../commons/Loading';
import usePermissions from '../../../../hooks/usePermissions';
import useFiltersProps from '../../../../hooks/useFiltersProps';
import useFilterOptions from './useFilterOptions';
import MessageEmptyPageFirstAction from '../../../commons/MessageEmptyPageFirstAction';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const DivisionsList = () => {
  const { t } = useTranslation('global');
  const [busy, setBusy] = useState(false);

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IFinancialOfficeDivisionFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const {
    data,
    isLoading: isFetching,
    error,
    refetch,
    ...queryProps
  } = useQuery({
    queryKey: ['getDivisions', pageNumber, pageSize, filters],
    queryFn: async () =>
      (
        await getDivisions({
          pageNumber,
          pageSize,
          filters,
        })
      ).data,
    keepPreviousData: false,
  });

  const location = useLocation();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const [rows, setRows] = useState([]);
  const setTableData = async (divisions: any) => {
    setBusy(true);
    await setRows(divisions);
    setBusy(false);
  };
  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);

  useEffect(() => {
    if (data?.result) {
      setTableData(data.result);
    } 
  }, [data]);

  const columns = useMemo<GridColumns>(() => {
    const items: GridColumns = [
      {
        field: 'name',
        headerName: t('page.financialOffices.divisions.columnHeaders.name'),
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => row.name,
      },
      {
        field: 'description',
        headerName: t(
          'page.financialOffices.divisions.columnHeaders.description'
        ),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.description,
      },
      {
        field: 'active',
        headerName: t('page.financialOffices.divisions.columnHeaders.active'),
        minWidth: 80,
        sortable: false,
        renderCell: ({ row }) =>
          row.active ? t('common.yes') : t('common.no'),
      },
    ];
    return items;
  }, [tableName]);

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (row: any) => {
    navigateToRoute(RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW, {
      financialOfficeDivisionId: row.id,
    });
  };

  const filterOptionList = useFilterOptions();
  const { isLoading } = usePermissions();

  const errorMessage = {
    title: t('errors.loadingTable.title'),
    subtitle: t('errors.loadingTable.subtitle'),
  };

  return isLoading || isFetching || busy ? (
    <Loading />
  ) : (
    <BaseTablePage
      isLoading={isFetching}
      errorMessage={errorMessage}
      error={error}
      routeKey={RouteKey.FINANCIAL_OFFICES}
      data={data}
      refetch={refetch}
      {...queryProps}
    >
      {data?.result?.length === 0 && Object.keys(filters)?.length === 0 ? (
        <MessageEmptyPageFirstAction
          onClick={() => navigateToRoute(RouteKey.FINANCIAL_OFFICE_DIVISION_NEW)}
          title={t('page.financialOffices.divisions.error.title')}
          subtitle={t('page.financialOffices.divisions.error.subtitle')}
          buttonLabel={t('page.financialOffices.divisions.error.button')}
        />
      ) : (
        <FilterContainer
          title={t('page.financialOffices.divisions.filter.title')}
          filterOptionList={filterOptionList}
          filterDatasourceOptionList={filterDatasourceOptionList}
          translationKeyPrefix="page.financialOffices.divisions.filter."
          clickAddButton={() =>
            navigateToRoute(RouteKey.FINANCIAL_OFFICE_DIVISION_NEW)
          }
          {...filterProps}
        >
          <CustomDataGrid
            // @ts-ignore
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            onRowClick={({ row }) => handleView(row)}
            onPageChange={handleOnPageChange}
            onPageSizeChange={handleOnPageSizeChange}
            getRowId={(r: any) => r?.id}
            showFirstButton
            showLastButton
          />
        </FilterContainer>
      )}
    </BaseTablePage>
  );
};
