export const monthsSelect = [
  { label: 'Enero', value: '0' },
  { label: 'Febrero', value: '1' },
  { label: 'Marzo', value: '2' },
  { label: 'Abril', value: '3' },
  { label: 'Mayo', value: '4' },
  { label: 'Junio', value: '5' },
  { label: 'Julio', value: '6' },
  { label: 'Agosto', value: '7' },
  { label: 'Septiembre', value: '8' },
  { label: 'Octubre', value: '9' },
  { label: 'Noviembre', value: '10' },
  { label: 'Diciembre', value: '11' },
];

export default {};
