import * as yup from 'yup';
import { ICatalogItemData } from '../../common/interfaces';

export const buildFormData = (data?: ICatalogItemData) => ({
  tableName: '',
  code: '',
  description: '',
  ...data,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) =>
  yup.object().shape({
    code: yup.string().required(translate('common.required')),
    tableName: yup.string().required(translate('common.required')),
    description: yup.string().required(translate('common.required')),
  });

export default {};
