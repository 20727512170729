import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LaunchOutlined } from '@mui/icons-material';
import { GridRowId } from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'vulpino';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { deleteCalendar, getCalendar } from '../../../api/adminCalendar';
import BaseViewHeader from '../../../components/BaseViewHeader';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import { RouteKey, paths } from '../../../navigation/constants';
import useRoutePage from '../../../hooks/useRoutePage';
import { useUI } from '../../../contexts/UIContext';
import { ToastType } from '../../../components/commons/Toast';
import useDataSources, {
  DataSourceCollection,
} from '../../../hooks/useDataSources';
import usePermissions from '../../../hooks/usePermissions';
import { Permissions } from '../../../common/enums';

import { EventList } from './components';

export const CalendarView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState<string>(t('page.calendar.none'));

  const { getValue } = useDataSources();
  const { checkPermissions } = usePermissions();

  const { data, ...queryProps } = useQuery({
    queryKey: [`getCalendar:${itemId}`],
    queryFn: async () => (await getCalendar(itemId)).data,
  });

  const location = useLocation();

  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.VIEW_CALENDAR,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const search = tableName ? `?tableName=${tableName}` : undefined;

  const handleEdit = () => {
    navigateToRoute(RouteKey.EDIT_CALENDAR, {
      search,
      ...params,
    });
  };

  const handleView = (id: GridRowId) => {
    if (!id) return;
    navigateToRoute(RouteKey.VIEW_CALENDAR, {
      itemId: id.toString(),
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  };

  const { setToast } = useUI();
  const navigate = useNavigate();

  const handleDelete = async () => {
    deleteCalendar(itemId)
      .then(() =>
        setToast({
          type: ToastType.SUCCESS,
          message: t('page.calendar.toast.delete'),
        })
      )
      .catch((e) =>
        setToast({
          type: ToastType.ERROR,
          message: t('page.calendar.toast.deleteError'),
        })
      )
      .finally(() => {
        setIsOpen(false);
        navigate(paths[RouteKey.CALENDAR], { replace: true });
      });
  };

  useEffect(() => {
    getValue({
      collection: DataSourceCollection.CALENDAR,
      id: data?.parentCalendarId?.toString(),
    }).then((res: any) => {
      if (res) setName(res);
      else setName(t('page.calendar.none'));
    });
  }, [data]);

  return (
    <>
      <BaseViewPage
        routeKey={RouteKey.VIEW_CALENDAR}
        routeTrail={[RouteKey.CALENDAR]}
        data={data}
        {...queryProps}
      >
        <BaseViewHeader
          title={data?.name}
          suptitle={
            <Typography variant="h2">{t('page.calendar.main')}</Typography>
          }
          buttonList={[
            {
              hidden: !checkPermissions([
                Permissions.CALENDAR_CREATE,
                Permissions.CALENDAR_UPDATE,
              ]),
              onClick: handleEdit,
              variant: 'secondary',
              endIcon: <BorderColorIcon />,
              labelKey: 'page.calendar.actionEdit',
            },
            {
              hidden: !checkPermissions(Permissions.CALENDAR_DELETE),
              onClick: () => setIsOpen(true),
              variant: 'tertiary',
              endIcon: <DeleteOutlinedIcon />,
              labelKey: 'page.calendar.actionDelete',
            },
          ]}
        >
          <Grid container>
            <Grid item>
              <Typography variant="body1">{data?.description}</Typography>
            </Grid>
          </Grid>
          {/*<Grid container mt={4} alignItems="center" xs={12}>
            <Grid item xs={2}>
              <Typography variant="body2">
                {t('page.calendar.nested')}
              </Typography>
            </Grid>
            <Grid container xs={10} alignItems="center">
              <Grid item pr={1}>
                <Typography variant="body2" fontWeight="bold">
                  {name}
                </Typography>
              </Grid>;

              {name !== t('page.calendar.none') && (
                <Grid
                  item
                  onClick={() => handleView(data?.parentCalendarId)}
                  style={{ cursor: 'pointer', color: '#034184' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                      fontSize: '14px',
                    }}
                  >
                    <Link variant="body2">{t('page.calendar.open')}</Link>
                    <LaunchOutlined />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>*/}
        </BaseViewHeader>
      </BaseViewPage>
      {itemId && <EventList />}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent dividers>
          <DialogTitle textAlign="center" id="alert-dialog-title">
            <Grid container textAlign="center" justifyContent="center" py={2}>
              {t('page.calendar.modal.deleteTitle')}
            </Grid>
          </DialogTitle>
        </DialogContent>

        <DialogActions alignContent="center">
          <Grid container xs={12} spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="secondary"
                onClick={() => setIsOpen(false)}
              >
                {t('page.calendar.modal.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth onClick={handleDelete} autoFocus>
                {t('page.calendar.modal.accept')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
