import React from 'react';
import { useState, createContext, useContext } from 'react';

export type INavigationState = {
  [key: string]: any;
};

type INavigationInternal = {
  [key: string]: INavigationState;
};

export type INavigationStateContext = {
  getRouteState: (route: string) => INavigationState | undefined;
  setRouteState: (route: string, state: INavigationState) => void;
  cleanAllRouteState: () => void;
};

export const NavigationStateContext = createContext<INavigationStateContext>({
  getRouteState: () => ({}),
  setRouteState: () => {},
  cleanAllRouteState: () => {},
});

export const NavigationStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navigationState, setNavigationState] = useState<INavigationInternal>(
    {}
  );

  const getRouteState = (route: string): INavigationState =>
    navigationState[route];

  const setRouteState = (route: string, state: INavigationState) => {
    setNavigationState({ ...navigationState, [route]: state });
  };

  const cleanAllRouteState = () => {
    setNavigationState({});
  };

  const value: any = React.useMemo(
    () => ({
      getRouteState,
      setRouteState,
      cleanAllRouteState,
    }),
    [getRouteState]
  );

  return (
    <NavigationStateContext.Provider value={value}>
      {children}
    </NavigationStateContext.Provider>
  );
};

export const useNavigationStateContext = () =>
  useContext<any>(NavigationStateContext);
