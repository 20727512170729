import { useTranslation } from 'react-i18next';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import qs from 'qs';
import BaseTablePage from '../../../commons/BaseTablePage';
import CustomDataGrid from '../../../commons/CustomDataGrid';
import FilterContainer from '../../../commons/FilterContainer';
import { RouteKey } from '../../../../navigation/constants';
import useFilterOptions from './useFilterOptions';
import {
  getDivision,
  getFinancialOffices,
} from '../../../../api/adminBusinessEnvironment/service';
import useFiltersProps from '../../../../hooks/useFiltersProps';
import useRoutePage from '../../../../hooks/useRoutePage';
import {
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFinancialOfficeFiltersState,
} from '../../../../common/interfaces';
import Loading from '../../../commons/Loading';
import usePermissions from '../../../../hooks/usePermissions';
import { getZipCodeById } from '../../../../api/adminPlatformCatalogs/service';
import MessageEmptyPageFirstAction from '../../../commons/MessageEmptyPageFirstAction';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const FinancialOfficesList = () => {
  const { t } = useTranslation('global');
  const [busy, setBusy] = useState(false);

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.FINANCIAL_OFFICE_VIEW,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IFinancialOfficeFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const {
    data: financialOfficeData,
    isLoading: isFetching,
    error,
    refetch,
    ...queryProps
  } = useQuery({
    queryKey: ['getFinancialOffices', pageNumber, pageSize, filters],
    queryFn: async () =>
      (
        await getFinancialOffices({
          pageNumber,
          pageSize,
          filters,
        })
      ).data,
    keepPreviousData: false,
  });

  const location = useLocation();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const [rows, setRows] = useState();

  const setTableData = async (financialOffices: any) => {
    setBusy(true);
    const financialOfficesSorted = [...financialOffices];
    let promises: any = [];
    financialOfficesSorted?.forEach((financialOffice: any) => {
      promises.push(
        getZipCodeById({
          countryId: financialOffice.address.countryId,
          stateId: financialOffice.address.stateId,
          cityId: financialOffice.address.cityId,
          zipCodeId: financialOffice.address.zipCodeId,
        })
      );
      promises.push(getDivision(financialOffice?.divisionId));
    });
    const promisesReversed = promises.reverse(); // necessary for "pop"
    Promise.allSettled(promisesReversed).then(async (values) => {
      financialOfficesSorted.forEach((financialOffice: any) => {
        try {
          // @ts-ignore
          const zipResult: any = values?.pop()?.value?.data;
          // @ts-ignore
          const divisionResult: any = values?.pop()?.value?.data;
          financialOffice.address.zipCode = zipResult?.code;
          financialOffice.address.city = zipResult?.city?.description;
          financialOffice.address.state = zipResult?.city?.state?.description;
          financialOffice.division = divisionResult?.name;
        } catch (err) {
          console.log(err);
        }
      });
      // @ts-ignore
      await setRows(financialOfficesSorted);
      setBusy(false);
    });
  };
  const rowCount = useMemo(() => financialOfficeData?.totalItems ?? 0, [financialOfficeData]);

  useEffect(() => {
    if (financialOfficeData) {
      setTableData(financialOfficeData.result);
    }
  }, [financialOfficeData]);

  const columns = useMemo<GridColumns>(() => {
    const items: GridColumns = [
      {
        field: 'id',
        headerName: t(
          'page.financialOffices.financialOfficesList.columnHeaders.id'
        ),
        sortable: false,
        renderCell: ({ row }) => row.code,
      },
      {
        field: 'name',
        headerName: t(
          'page.financialOffices.financialOfficesList.columnHeaders.name'
        ),
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => row.description,
      },
      {
        field: 'address',
        headerName: t(
          'page.financialOffices.financialOfficesList.columnHeaders.address'
        ),
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const { street, city, state, zipCode } = row.address;
          return `${street}${city ? ', ' + city : ''}${
            state ? ', ' + state : ''
          }${zipCode ? ', CP' + zipCode : ''}`;
        },
      },
      {
        field: 'division',
        headerName: t(
          'page.financialOffices.financialOfficesList.columnHeaders.division'
        ),
        sortable: false,
        renderCell: ({ row }) => row.division, // getDivision
      },
      {
        field: 'active',
        headerName: t(
          'page.financialOffices.financialOfficesList.columnHeaders.active'
        ),
        sortable: false,
        renderCell: ({ row }) =>
          row.active ? t('common.yes') : t('common.no'),
      },
    ];

    return items;
  }, [tableName]);

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (row: any) => {
    navigateToRoute(RouteKey.FINANCIAL_OFFICE_VIEW, {
      financialOfficeId: row.id,
    });
  };

  const filterOptionList = useFilterOptions();
  const { isLoading } = usePermissions();
  const errorMessage = {
    title: t('errors.loadingTable.title'),
    subtitle: t('errors.loadingTable.subtitle'),
  };

  return isLoading || isFetching || busy ? (
    <Loading />
  ) : (
    <BaseTablePage
      isLoading={isFetching}
      errorMessage={errorMessage}
      error={error}
      routeKey={RouteKey.CALENDAR}
      data={financialOfficeData}
      refetch={refetch}
      {...queryProps}
    >
      {financialOfficeData?.result?.length === 0 && Object.keys(filters)?.length === 0 ? (
        <MessageEmptyPageFirstAction
          onClick={() => navigateToRoute(RouteKey.FINANCIAL_OFFICE_NEW)}
          title={t('page.financialOffices.financialOfficesList.error.title')}
          subtitle={t(
            'page.financialOffices.financialOfficesList.error.subtitle'
          )}
          buttonLabel={t(
            'page.financialOffices.financialOfficesList.error.button'
          )}
        />
      ) : (
        <FilterContainer
          title={t('page.financialOffices.financialOfficesList.filter.title')}
          filterOptionList={filterOptionList}
          filterDatasourceOptionList={filterDatasourceOptionList}
          translationKeyPrefix="page.financialOffices.financialOfficesList.filter."
          clickAddButton={() => navigateToRoute(RouteKey.FINANCIAL_OFFICE_NEW)}
          {...filterProps}
        >
          <CustomDataGrid
            // @ts-ignore
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            onRowClick={({ row }) => handleView(row)}
            onPageChange={handleOnPageChange}
            onPageSizeChange={handleOnPageSizeChange}
            getRowId={(r: any) => r?.id}
            showFirstButton
            showLastButton
          />
        </FilterContainer>
      )}
    </BaseTablePage>
  );
};
