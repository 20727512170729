import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { Permissions } from '../../../../common/enums';
import { RouteKey } from '../../../../navigation/constants';
import {
  getInterestRateList,
  updateInterestRateTable,
} from '../../../../api/adminRates';
import useFiltersProps from '../../../../hooks/useFiltersProps';
import useRoutePage from '../../../../hooks/useRoutePage';
import {
  IRatesGroupFiltersState,
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFormRef,
  IRateTableUpdateFormData,
} from '../../../../common/interfaces';
import usePermissions from '../../../../hooks/usePermissions';
import BaseViewHeader from '../../../BaseViewHeader';
import { RateTableForm } from '../../../RateTableForm/RateTableForm';
import { useUI } from '../../../../contexts/UIContext';
import { ToastType } from '../../../commons/Toast';
import { getErrorMessage } from '../../../../utils/apiUtils';
import Loading from '../../../commons/Loading';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const PaymentPlace = () => {
  const { t } = useTranslation('global');
  const params = useParams();
  const { setToast } = useUI();
  const interestRateTableId = params.itemId
    ? parseInt(params.itemId, 10)
    : undefined;

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.VIEW_RATES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;
  const [formData, setFormData] = useState<IRateTableUpdateFormData>();
  const [editMode, setEditMode] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { checkPermissions } = usePermissions();
  const { filters, ...filterProps } = useFiltersProps<IRatesGroupFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const fetchInterestRateListData = async () => {
    try {
      setIsFetching(true);
      const response = await getInterestRateList({
        pageNumber,
        pageSize,
        filters: { interestRateTableId },
      });
      setFormData({ ...response?.data?.result[0]?.interestRateTable });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchInterestRateListData();
  }, []);

  const onSubmit = async (requestData: IRateTableUpdateFormData) => {
    try {
      setIsFetching(true);
      await updateInterestRateTable(interestRateTableId, requestData);
      await fetchInterestRateListData();
      setEditMode(false);
      setToast({
        message: t('page.ratesGroup.paymentPlace.form.saveToastSuccessMessage'),
      });
    } catch (err) {
      const message = getErrorMessage(
        err,
        t('page.ratesGroup.paymentPlace.form.saveToastErrorMessage')
      );
      setToast({
        type: ToastType.ERROR,
        message,
      });
    } finally {
      setIsFetching(false);
    }
  };

  const { isLoading } = usePermissions();

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <>
      {!editMode && formData && (
        <BaseViewHeader
          emptyForwardSpaces
          buttonListDirection="row"
          buttonList={[
            {
              onClick: () => setEditMode(true),
              //dataTestId: `${dataTestId}_EditButton`,
              endIcon: <BorderColorIcon />,
              labelKey: 'page.businessSchemas.detail.actionEdit',
              hidden: !checkPermissions(Permissions.COMMERCE_UPDATE),
              variant: 'secondary',
              sx: { minWidth: '160px' },
            },
          ]}
          childrenProps={{
            sx: { backgroundColor: 'white' },
          }}
          boxProps={{
            border: '1px solid #E0E0E0',
            borderRadius: '5px',
            pb: '24px',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('page.ratesGroup.paymentPlace.form.labels.min')}
              </Typography>
              <Typography>{formData?.minimumTerm}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('page.ratesGroup.paymentPlace.form.labels.max')}
              </Typography>
              <Typography>{formData?.maximumTerm}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('page.ratesGroup.paymentPlace.form.labels.interval')}
              </Typography>
              <Typography>{formData?.termInterval}</Typography>
            </Grid>
          </Grid>
        </BaseViewHeader>
      )}
      {editMode && (
        <BaseViewHeader
          emptyForwardSpaces
          childrenProps={{
            sx: { backgroundColor: 'white' },
          }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <RateTableForm
              // dataTestId={`${dataTestId}_Form`}
              onSubmit={onSubmit}
              data={formData}
              onCancel={() => setEditMode(false)}
            />
          </Grid>
        </BaseViewHeader>
      )}
    </>
  );
};
