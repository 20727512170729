import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../components/ui/StyledButton';
import Image from '../../assets/imgs/404.svg';
import { RouteKey, paths } from '../../navigation/constants';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleRedirect = () => {
    navigate(paths[RouteKey.INDEX]);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      height="80vh"
    >
      <img src={Image} alt="Imagen de error" />
      <h3>{t('error.not_found.title')}</h3>
      <p>{t('error.not_found.description')}</p>
      <div style={{ width: '300px' }}>
        <StyledButton
          onClick={handleRedirect}
          data-testid="backToHome"
          size="large"
        >
          {t('error.not_found.button')}
        </StyledButton>
      </div>
    </Grid>
  );
};
