import { useCallback, useEffect, useMemo } from 'react';
import { GridColumns, GridRowId } from '@mui/x-data-grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BaseViewPage from '../../commons/BaseViewPage';
import BaseViewHeader from '../../BaseViewHeader';
import TitledSection from '../../commons/TitledSection';
import { RouteKey, paths } from '../../../navigation/constants';
import usePermissions from '../../../hooks/usePermissions';
import {
  getCommerceBusinessSchemaList,
  getCommerce,
} from '../../../api/adminBusinessEnvironment/service';
import StyledAlert from '../../ui/StyledAlert';
import BaseTablePage from '../../commons/BaseTablePage';
import CustomDataGrid from '../../commons/CustomDataGrid';
import useRoutePage from '../../../hooks/useRoutePage';
import {
  IBusinessSchemasFiltersState,
  IFilterAppliedOption,
} from '../../../common/interfaces';
import useFiltersProps from '../../../hooks/useFiltersProps';
import { useUI } from '../../../contexts/UIContext';
import { MenuItemType, Permissions } from '../../../common/enums';


const CommerceBusinessSchemas = () => {
  const params = useParams();
  const itemId = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();
  const { setMenuOverride } = useUI();
  interface IRouteState {
    pageNumber: number;
    pageSize: number;
    filterAppliedList: IFilterAppliedOption[];
  }
  const queryClient = useQueryClient();

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IBusinessSchemasFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

    const { data: businessSchemaData, ...businessSchemaQueryProps } = useQuery({
      //en la queryKey importa pasarle -> pageNumber, pageSize, filters para que ejecute la query de nuevo cuando cambia la pagina/tamaño de pagina
      queryKey: [`getBusinessSchemas:${itemId}`, pageNumber, pageSize, filters],
      queryFn: async () =>{
        const response = await getCommerceBusinessSchemaList({ pageNumber, pageSize, filters }, itemId)
        return response;},
    });


  const { data: commerceData, ...queryProps } = useQuery({
    queryKey: [`getCommerce:${itemId}`],
    queryFn: async () => (await getCommerce(itemId!)).data,
  });

  const rows = useMemo(() => businessSchemaData?.data, [businessSchemaData]);
  const rowCount = useMemo(() => businessSchemaData?.data.length, [businessSchemaData]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [`getBusinessSchemas:${itemId}`, pageNumber, pageSize, filters] });
  }, [businessSchemaData]);

  useEffect(() => {
    const isHeadquarter = commerceData?.id === commerceData?.headquarterId;
    if (!isHeadquarter) {
      navigateToRoute(RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW, {
        commerceId: itemId,
      });
    }
  }, [commerceData]);

  const goNew = useCallback(() => {
    navigateToRoute(RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW, {
      commerceId: itemId,
    });
  }, [navigateToRoute]);

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: t('common.back'),
        path: generatePath(paths[RouteKey.COMMERCES]),
      },
      items: ['commerceDetail', 'businessSchemas'].map((cardKey) => ({
        key: cardKey,
        text: t(
          `page.businessSchemas.drawerSubItems.${cardKey}.breadcrumb`,
          t(`page.businessSchemas.drawerSubItems.${cardKey}.title`)
        ),
        path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
          '*': cardKey,
          commerceId: itemId?.toString(),
        }),
        type: MenuItemType.MENU,
        permissions:
          cardKey === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : cardKey === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      })),
    });
    return () => setMenuOverride(undefined);
  }, []);

  const emptyState = (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <StyledAlert
        severity="info"
        title={t('page.businessSchemas.validationInfo.emptyState.title')}
        action={
          <Button
            variant="contained"
            endIcon={<ControlPointIcon />}
            onClick={goNew}
          >
            {t('page.businessSchemas.validationInfo.emptyState.add')}
          </Button>
        }
        style={{ padding: '15px' }}
      />
    </Box>
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'description',
        headerName: t('page.commerce.columnHeaders.name'),
        minWidth: 400,
        flex: 1,
        sortable: false,
      },
    ],
    [t]
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (id: GridRowId) => {
    navigateToRoute(RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW, {
      commerceId: itemId,
      businessSchemaId: id,
    });
  };


  return (
    <BaseViewPage
      routeTrail={[RouteKey.COMMERCES]}
      routeKey={RouteKey.COMMERCE_BUSINESS_SCHEMA}
      data={commerceData}
      {...queryProps}
      title={t('route.businessSchemas.breadcrumb')}
    >
      <BaseViewHeader
        boxProps={{
          pt: 0,
        }}
        paddingLeft="20px"
      >
        <Typography fontSize="28px" fontWeight="900" paddingTop="20px">
          {t('page.businessSchemas.form.name.label')}
        </Typography>
        <Typography fontSize="25px" fontWeight="500">
          {t(commerceData?.name)}
        </Typography>
        {commerceData?.description && (
          <Typography fontSize="18px" paddingTop="15px">
            {t(commerceData?.description)}
          </Typography>
        )}
      </BaseViewHeader>

      <Grid item sm={12} xs={24}>
        <Box paddingLeft="20px">
          <TitledSection
            titleKey="page.businessSchemas.form.description"
            variant="h3"
          />

          {businessSchemaData && businessSchemaData?.data?.length > 0 && (
            <Box display="flex" width="95%" justifyContent="flex-end">
              <Button
                variant="contained"
                endIcon={<ControlPointIcon />}
                onClick={goNew}
              >
                {t('page.businessSchemas.validationInfo.emptyState.add')}
              </Button>
            </Box>
          )}
        </Box>
        {businessSchemaData && (
          <BaseTablePage
            //error={error}
            routeKey={RouteKey.COMMERCES} //ponele
            data={businessSchemaData}
            emptyState={emptyState}
            {...businessSchemaQueryProps}
          >
            <CustomDataGrid
              rows={rows ?? []}
              columns={columns}
              rowCount={rowCount}
              pageNumber={pageNumber}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 30]}
              onRowClick={({ id }) => handleView(id)}
              onPageChange={handleOnPageChange}
              onPageSizeChange={handleOnPageSizeChange}
              getRowId={(r: any) => r.id}
              //buttonConfList={buttonConfList}
            />
          </BaseTablePage>
        )}
      </Grid>
    </BaseViewPage>
  );
};

export default CommerceBusinessSchemas;
