import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ICommerceData, ITesteable } from '../../common/interfaces';
import { paths, RouteKey } from '../../navigation/constants';
import { getCommerce } from '../../api/adminBusinessEnvironment/service';
import { PlatformCatalogKey } from '../../hooks/usePlatformCatalogs';
import PromisedPlatformCatalog from '../../components/helpers/PromisedPlatformCatalog';
import { MenuItemType, Permissions } from '../../common/enums';
import usePermissions from '../../hooks/usePermissions';
import { useUI } from '../../contexts/UIContext';
import useFPRCards from '../../hooks/useFPRCards';

interface ICommerceViewPageProps extends ITesteable {}

const CommerceViewPage = ({
  dataTestId = 'CommerceViewPage',
}: ICommerceViewPageProps) => {
  const [translate] = useTranslation('global');
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();
  const params = useParams();
  const commerceId = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;

  const { data: requestData, ...queryProps } = useQuery<ICommerceData>({
    queryKey: [`commerce:${commerceId}`],
    queryFn: async () =>
      commerceId ? (await getCommerce(commerceId)).data : {},
    keepPreviousData: true,
  });

  const { setMenuOverride } = useUI();

  const { cards } = useFPRCards({
    requestData,
    requestId: undefined,
    commerceId,
  });

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.COMMERCES]),
      },
      items: cards.map((card) => ({
        key: card.key,
        text: translate(
          `page.commerces.drawerSubItems.${card.key}.breadcrumb`,
          translate(`page.commerces.drawerSubItems.${card.key}.title`)
        ),
        path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
          '*': card.key,
          commerceId: commerceId?.toString(),
        }),
        type: MenuItemType.MENU,
        permissions:
          card.key === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : card.key === 'auditTrails'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ
            : card.key === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, cards, commerceId]);

  const onNavEdit = () => {
    navigate(generatePath(paths[RouteKey.COMMERCES_EDIT], params));
  };

  const addressData = useMemo(
    () => [
      {
        key: translate('page.commerce.form.address.countryId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.COUNTRIES}
            id={requestData?.address.countryId}
          />
        ),
      },
      {
        key: translate('page.commerce.form.address.stateId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.STATES}
            id={requestData?.address.stateId}
            params={pick(requestData?.address, ['countryId'])}
          />
        ),
      },
      {
        key: translate('page.commerce.form.address.cityId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.CITIES}
            id={requestData?.address.cityId}
            params={pick(requestData?.address, ['countryId', 'stateId'])}
          />
        ),
      },
      {
        key: translate('page.commerce.form.address.zipCodeId.label'),
        value: (
          <PromisedPlatformCatalog
            catalog={PlatformCatalogKey.ZIP_CODES}
            id={requestData?.address.zipCodeId}
            params={{
              countryid: requestData?.address?.countryId,
              stateId: requestData?.address?.stateId,
              cityIds: [requestData?.address?.cityId],
            }}
          />
        ),
      },
      {
        key: translate('page.commerce.form.address.street.label'),
        value: requestData?.address.street,
      },
    ],
    [translate, requestData?.address]
  );

  const activeSectionKey = params['*'] ?? '';

  const activeSection = useMemo(
    () =>
      cards.find(
        (entry) => activeSectionKey === '' || activeSectionKey === entry.key
      ),
    [cards, activeSectionKey]
  );

  const baseViewPageProps = useMemo(
    () => ({
      dataTestId,
      routeKey: RouteKey.COMMERCES_VIEW,
      breadcrumb: translate(
        `page.commerces.${activeSectionKey}.breadcrumb`,
        translate(`page.commerces.${activeSectionKey}.title`)
      ),
      title: translate(
        `page.financialProductRequest.${activeSectionKey}.title`,
        ''
      ),
      routeTrail: [RouteKey.COMMERCES],
      data: requestData,
      ...queryProps,
    }),
    [requestData, activeSectionKey, queryProps]
  );

  return (
    <>
      {activeSection?.render({
        requestData,
        commerceData: 'data',
        requestId: undefined,
        dataTestId,
        baseViewPageProps,
      })}
    </>
  );
};

export default CommerceViewPage;
