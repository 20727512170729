import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  FormElementType,
  IFormElement,
} from '../../api/dynamicForm/interfaces';
import { getDynamicFormDefinitionsElements } from '../../api/dynamicForm/service';

import TextInputForm from './TextInputForm';
import CheckBoxControl from './CheckBoxControl';
import AutoCompleteSelector from './AutoCompleteSelector';
import FileUploadControl from './FileUploadControl';
import { DateRange } from '../DateRange';
import { RangeOptionsType } from '../DateRange/components';

interface FormElementProps {
  formElement: IFormElement;
  formElements: IFormElement[];
  contextData: any;
  dynamicFormName: string;
  translationPrefix?: string;
  setError?: any;
  setClear?: any;
  clear?: boolean;
  formElementData?: {};
  translateOptions?: string;
}

const FormElement = ({
  formElement,
  contextData,
  formElementData,
  dynamicFormName,
  formElements,
  translationPrefix,
  setError,
  setClear,
  clear,
  translateOptions,
}: FormElementProps) => {
  const { control, watch } = useFormContext();
  const [busy, setBusy] = useState(false);
  const [translate] = useTranslation('global');
  const [options, setOptions] = useState<
    {
      key: string;
      value: string;
    }[]
  >(formElement.optionItems ?? []);
  const [conditionSource, setConditionSource] = useState<any>({});

  const queryClient = useQueryClient();

  const watched = watch();

  useEffect(() => {
    const source = {};
    (formElement.elementConditionalDefinitions ?? []).forEach((condition) => {
      const conditionElement = formElements.find(
        (element) => element.id === condition.formElementId
      );
      if (!conditionElement) return;
      Object.assign(source, {
        [conditionElement.name]: watched[conditionElement.name],
      });
    });
    if (!isEqual(conditionSource, source)) setConditionSource(source);
  }, [formElement, watched, conditionSource]);

  useEffect(() => {
    (async () => {
      if (Object.keys(conditionSource).length === 0) return;
      if (formElement.formElementType === FormElementType.MANUAL_COMBO) {
        const queryKey = `${dynamicFormName}:${
          formElement.name
        }:${JSON.stringify(conditionSource)}`;
        setBusy(true);

        const { data } = await queryClient.fetchQuery({
          queryKey,
          queryFn: async () =>
            getDynamicFormDefinitionsElements({
              dynamicFormName,
              formElementName: formElement.name,
              contextData,
              formElementData: formElementData ?? watched,
            }),
        });
        if (data.optionItems) {
          setOptions(data.optionItems);
        }
        setBusy(false);
      }
    })();
  }, [conditionSource, queryClient, dynamicFormName, contextData, watched]);

  const visibility = useMemo(
    () =>
      (formElement.elementConditionalDefinitions ?? []).reduce(
        (value, condition) => {
          if (condition.visibility === undefined) return value;
          if (!value) return value;

          const conditionElement = formElements.find(
            (element) => element.id === condition.formElementId
          );

          const conditionValue = conditionSource[conditionElement?.name ?? ''];

          return !!conditionValue;
        },
        true
      ),
    [conditionSource, formElement, formElements]
  );

  if (!visibility) return null;

  const commonProps = {
    disabled: busy,
    name: formElement.name,
    label: formElement.label,
    mandatory: formElement.required,
    defaultValue: formElement.resolvedValue,
    translationPath: translationPrefix,
  };

  switch (formElement.formElementType) {
    case FormElementType.MANUAL_TEXT:
      return (
        <TextInputForm
          {...commonProps}
          mask={
            formElement.resolvedValue === undefined ||
            formElement.resolvedValue === ''
              ? formElement.mask
              : undefined
          }
          disabled={
            formElement.resolvedValue === undefined ||
            formElement.resolvedValue === ''
              ? false
              : true
          }
          fullWidth
        />
      );
    case FormElementType.MANUAL_CHECK:
      return (
        <CheckBoxControl
          {...commonProps}
          mask={
            formElement.resolvedValue === undefined ||
            formElement.resolvedValue === ''
              ? formElement.mask
              : undefined
          }
        />
      );
    case FormElementType.MANUAL_COMBO:
      return (
        <AutoCompleteSelector
          {...commonProps}
          title={formElement.label}
          description={formElement.description}
          options={options}
          placeholder={formElement.label}
          getOptionLabel={(option) =>
            translateOptions
              ? translate(translateOptions + option.value, option.value)
              : option.value
          }
          getOptionValue={(option) => option.key}
          fullWidth
          multiple={formElement.multiple}
        />
      );
    case FormElementType.MANUAL_DATE_RANGE:
      return (
        <Controller
          control={control}
          name={formElement.name}
          defaultValue={formElement.resolvedValue}
          render={({ field }) => (
            <DateRange
              onChange={field.onChange}
              clear={clear}
              setClear={setClear}
              required={formElement.required}
              options={formElement.dateRangeOptions}
              customValue={
                formElement?.dateRangeOptions?.includes(RangeOptionsType.CUSTOM)
                  ? RangeOptionsType.CUSTOM
                  : undefined
              }
              onError={setError}
              formElement={formElement}
            />
          )}
        />
      );
    case FormElementType.MANUAL_UPLOAD:
      return <FileUploadControl {...commonProps} />;
    default:
      return <div>{formElement?.name}</div>;
  }
};

export default FormElement;
