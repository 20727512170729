import { useCallback, useEffect, useMemo, useState } from 'react';
import { merge } from 'lodash';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { styled } from '@mui/system';

import { getPermissionsList } from '../../api/adminUsersRoles/service';
import useParsePermissions from '../../hooks/useParsePermissions';

import { IPermissionsSelectorProps } from './types';
import { IPermissionData } from '../../api/adminUsersRoles/interfaces';

// Styled Components
const styles: { [key: string]: any } = {
  half: {
    overflow: 'visible',
    width: '50%',
    minWidth: '340px',
  },
};

const Container = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  overflowY: 'visible',
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.grey[300]}`,
  height: '600px',
  maxWidth: `calc(100vw - ${theme.spacing()} * 6)`,
}));

const LeftPanel = styled('div')(() => ({
  overflowY: 'auto',
}));

const RightPanel = styled('div')(({ theme }) =>
  merge({}, styles.half, {
    paddingLeft: '12px',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
  })
);

const StyledList = styled(List)(() =>
  merge({}, styles.half, {
    overflowY: 'auto',
  })
);

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const PermissionsSelector = ({
  value = [],
  onChange,
  dataTestId = 'PermissionsSelector',
}: IPermissionsSelectorProps) => {
  const [translate] = useTranslation('global');

  const [currentAppKey, setCurrentAppId] = useState<string>();

  const { data } = useQuery({
    queryKey: ['getPermissionsList'],
    queryFn: async () => (await getPermissionsList()).data,
  });

  const permissions = useMemo(() => data?.result ?? [], [data]);

  const { apps, permissions: byAppPermissions } = useParsePermissions({
    permissions,
    selected: value,
  });

  // Auto selects first appKey
  useEffect(() => {
    if (currentAppKey === undefined && apps.length > 0) {
      setCurrentAppId(apps[0].key);
    }
  }, [currentAppKey, apps]);

  const currentAppPermissions = byAppPermissions[currentAppKey ?? ''] ?? [];

  const getSelectedAppPermissions = useCallback(
    (appKey: string) => {
      const app = byAppPermissions[appKey] ?? [];
      return app.filter((permission) => permission.selected);
    },
    [currentAppPermissions]
  );

  const selectedAppPermissions = useMemo(
    () => getSelectedAppPermissions(currentAppKey ?? ''),
    [currentAppKey, getSelectedAppPermissions]
  );

  const allAppPermissionsSelected =
    selectedAppPermissions.length === currentAppPermissions.length;

  const togglePermission = useCallback(
    (id: number) => {
      onChange?.(
        value.includes(id)
          ? value.filter((filter) => filter !== id)
          : [...value, id]
      );
    },
    [value]
  );

  const toggleAll = useCallback(() => {
    const permissionIds = currentAppPermissions.map(
      (permission) => permission.id
    );
    onChange?.(
      allAppPermissionsSelected
        ? value.filter((filter) => !permissionIds.includes(filter))
        : [
            ...value,
            ...permissionIds.filter((filter) => !value.includes(filter)),
          ]
    );
  }, [
    value,
    allAppPermissionsSelected,
    currentAppPermissions,
    togglePermission,
  ]);

  return (
    <Container data-testid={dataTestId}>
      <StyledList disablePadding>
        {(apps ?? []).map((app) => {
          const selectedCount = getSelectedAppPermissions(app.key).length;
          return (
            <ListItem
              disablePadding
              selected={app.key === currentAppKey}
              key={app.key}
              onClick={() => setCurrentAppId(app.key)}
              data-testid={`li_${app.key}`}
            >
              <ListItemButton>
                <ListItemText primary={app.label} />
                {selectedCount > 0 && (
                  <Chip
                    label={translate('common.nSelected', { n: selectedCount })}
                    color="secondary"
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </StyledList>
      <RightPanel>
        {currentAppKey && (
          <StyledFormControlLabel
            data-testid="checkbox_*"
            control={
              <Checkbox
                checked={allAppPermissionsSelected}
                onChange={toggleAll}
                name="*"
              />
            }
            label={translate('page.role.allPermissions')}
          />
        )}
        <LeftPanel>
          <FormGroup>
            {currentAppPermissions.map((permission: any) => (
              <FormControlLabel
                data-testid={`checkbox_${permission.key}`}
                key={permission.key}
                control={
                  <Checkbox
                    checked={value.includes(permission.id)}
                    onChange={() => togglePermission(permission.id)}
                  />
                }
                label={permission.label}
              />
            ))}
          </FormGroup>
        </LeftPanel>
      </RightPanel>
    </Container>
  );
};

export default PermissionsSelector;
