import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import useTranslate from '../../hooks/useTranslate';
import type {
  ICommerceData,
  ITesteable,
  IBusinessSchemaData,
} from '../../common/interfaces';
import {
  getCommercesList,
  getBusinessSchemaList,
} from '../../api/adminBusinessEnvironment/service';
import AutoCompleteSelector from './AutoCompleteSelector';
import type { IFormField } from './FormFields';

export enum SearchableTable {
  COMMERCES = 'COMMERCES',
  BUSINESS_SCHEMA = 'BUSINESS_SCHEMA',
}

const tables = {
  [SearchableTable.COMMERCES]: {
    queryOptions: {
      queryKey: ['getCommercesList'],
      queryFn: () => getCommercesList(),
    },
    rowMapper: (row: ICommerceData) => ({
      id: row.id,
      label: row.name,
    }),
  },
  [SearchableTable.BUSINESS_SCHEMA]: {
    queryOptions: {
      queryKey: ['getBusinessSchemaList'],
      queryFn: () => getBusinessSchemaList(),
    },
    rowMapper: (row: IBusinessSchemaData) => ({
      id: row.id,
      label: row.description,
    }),
  },
};

interface IOptionItem {
  id: number;
  label: string;
}

export interface SearchSelectorProps<TParams>
  extends Omit<IFormField, 'type'>,
    ITesteable {
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  params?: TParams;
  isEnabled?: (args: { params?: TParams }) => boolean;
  table: SearchableTable;
  filter?: (row: any) => boolean;
  optionsMapper?: (options: IOptionItem[]) => IOptionItem[];
}

const SearchSelector = <TParams,>({
  label: labelProp,
  labelKey,
  params,
  table: tableKey,
  filter,
  optionsMapper,
  fullWidth,
  isEnabled,
  mandatory = false,
  ...props
}: SearchSelectorProps<TParams>) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const table = tables[tableKey];

  const label = useTranslate(labelKey, labelProp, {
    mandatory,
  });

  const [rawOptions, setOptions] = useState<IOptionItem[]>([]);

  useEffect(() => {
    (async () => {
      const enabled = isEnabled ? isEnabled({ params }) : true;
      if (!enabled) {
        return setOptions([]);
      }
      try {
        setIsLoading(true);
        // @ts-ignore
        const { data } = await queryClient.fetchQuery(table.queryOptions);

        const result = data?.result ?? [];
        const filtered = filter ? result.filter(filter) : result;
        // @ts-ignore
        return setOptions(filtered?.map(table.rowMapper));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [params, isEnabled, table, filter]);

  const options = useMemo(() => {
    if (rawOptions && optionsMapper) {
      return optionsMapper(rawOptions);
    }
    return rawOptions;
  }, [rawOptions]);

  return (
    <AutoCompleteSelector<IOptionItem, number>
      fullWidth={fullWidth}
      {...props}
      label={label}
      options={options}
      {...{
        getOptionLabel: (option) => option?.label,
        getOptionValue: (option) => option?.id,
      }}
    />
  );
};

export default SearchSelector;
