import dayjs from 'dayjs';
import {
  ICalendarEventFiltersState,
  IPaginatedParams,
  IUserFiltersState,
} from '../../common/interfaces';
import fetch from '../api';
import { env } from '../env';

interface IGetCalendarListParams extends IPaginatedParams {
  filters?: IUserFiltersState;
}
interface IGetCalendarEventListParams extends IPaginatedParams {
  filters?: ICalendarEventFiltersState;
}

export const getCalendarList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetCalendarListParams = {}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v2/calendars',
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export type CalendarData = {
  name: string;
  description: string | null;
  parentCalendarId: number | null;
};

export type EventData = {
  name: string;
  date: string;
};

export const createCalendar = (data: CalendarData, itemId?: number) => {
  const url = itemId ? `/v2/calendars/${itemId}` : '/v2/calendars';

  return fetch({
    baseURL: env.APIS_ONSCORE,
    url,
    method: itemId ? 'put' : 'post',
    data,
  });
};

export const createEvent = (data: EventData, itemId?: number, id?: number) => {
  const url = id
    ? `/v2/calendars/${itemId}/events/${id}`
    : `/v2/calendars/${itemId}/events`;

  return fetch({
    baseURL: env.APIS_ONSCORE,
    url,
    method: id ? 'put' : 'post',
    data,
  });
};

export const deleteCalendar = (id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${id}`,
    method: 'delete',
  });

export const deleteEvent = (itemId?: number, id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${itemId}/events/${id}`,
    method: 'delete',
  });

export const getCalendar = (id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${id}`,
    method: 'get',
  });

export const getEvent = (itemId?: number, id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${itemId}/events/${id}`,
    method: 'get',
  });

export const getEventList = (
  id?: number,
  filters?: ICalendarEventFiltersState
) => {
  if (filters?.fromDate !== undefined) {
    filters.fromDate = dayjs(filters.fromDate).format('YYYY-MM-DD');
  }
  if (filters?.toDate !== undefined) {
    filters.toDate = dayjs(filters.toDate).format('YYYY-MM-DD');
  }
  return fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${id}/events`,
    method: 'get',
    params: {
      sort: `date:desc`,
      ...filters,
    },
  });
};
