import { useEffect, useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import errorImage from '../../assets/imgs/errorImage.svg';
import useApiMessage from '../../utils/useApiMessage';
import { IApiError } from '../../common/interfaces';
import { RouteKey } from '../../navigation/constants';
import getError from '../../utils/apiUtils';

type MessageErrorResponseProps = {
  error?: any;
  response?: IApiError;
  onClick?: () => void;
  routeKey?: RouteKey;
  isInitError?: boolean;
  errorMessage?: any;
};

const MessageErrorResponse = ({
  error,
  response,
  onClick,
  routeKey,
  isInitError,
  errorMessage,
}: MessageErrorResponseProps) => {
  const errorResponse = useMemo(() => {
    if (error) return getError(error);
    return response;
  }, [error, response]);

  const message = useApiMessage(errorResponse, routeKey, isInitError);

  return (
    <Grid item container xs={12} justifyContent="center">
      <Grid item container justifyContent="center" direction="column">
        <img src={errorImage} alt="" height="180px" />
        <Box m={3} />
        <Typography
          variant="h2"
          align="center"
          data-testid="MessageErrorResponse_title"
        >
          {errorMessage?.title ?? message.title}
        </Typography>
        <Box m={1} />
        <Typography
          align="center"
          data-testid="MessageErrorResponse_apiErrorExplanation"
        >
          {errorMessage?.apiErrorExplanation ?? message.apiErrorExplanation}
        </Typography>
        <Typography align="center" data-testid="MessageErrorResponse_subtitle">
          {errorMessage?.subtitle ?? message.subtitle}
        </Typography>
        <Box m={2} />
      </Grid>
      {onClick && (
        <Grid item>
          <Button
            onClick={onClick}
            variant="contained"
            color="primary"
            data-testid="MessageErrorResponse_button"
          >
            {errorMessage?.buttonLabel ?? message.buttonLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default MessageErrorResponse;
