import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import TitledSection from '../../../components/commons/TitledSection';
import { RouteKey, paths } from '../../../navigation/constants';
import { styles } from './style';
import { ReportList } from '../../../components/ReportList';
import usePermissions from '../../../hooks/usePermissions';
import Loading from '../../../components/commons/Loading';
import { useUI } from '../../../contexts/UIContext';
import { searchReportDefinitions } from '../Service';
import { IReportDefinitionResponse } from '../interfaces';
import { getUserPermissions } from '../../../api/adminUsersRoles/service';



export const ReportPage = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { setReportContext } = useUI();

  const { data: reportDefinitions } = useQuery({
    queryKey: ['reportDefinitions'],
    queryFn: async () => {
      const {metadata} = (await getUserPermissions()).data
      let reportDefinitionsSearchResponse = (await searchReportDefinitions({})).data?.result
      return reportDefinitionsSearchResponse.filter(report => metadata.REPORT_TRANSACTION_ID_LIST.includes(report.id))
    },
    keepPreviousData: true,
  });

  const handleNavigate = (reportDefinition: IReportDefinitionResponse) => {
    setReportContext({
      reportDefinitionId: reportDefinition.id,
      reportDefinitionName: reportDefinition.name,
    });
    navigate(paths[RouteKey.REPORTS_FILTERS]);
  };

  const { username, isLoading } = usePermissions();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TitledSection title={t('page.reports.title')} variant="h1">
        <div>
          <h3>{t('page.reports.description')}</h3>
          <Typography variant="body2">
            {t('page.reports.typeSelection')}
          </Typography>
          {reportDefinitions?.map((definition) => (
            <button
              style={{ ...styles.button, marginRight: 25 }}
              type="button"
              onClick={() => handleNavigate(definition)}
            >
              <Typography variant="h1" color="#034184">
                {t(`page.reports.${definition.name}.buttonTitle`)}
              </Typography>
              <Typography variant="body2">
                {t(`page.reports.${definition.name}.buttonDescription`)}
              </Typography>
            </button>
          ))}
        </div>
      </TitledSection>
      <TitledSection variant="h1">
        <div>
          <h3>{t('page.reports.listTitle')}</h3>
          <Typography variant="body2" marginBottom={2}>
            {t('page.reports.listSubtitle')}
          </Typography>
          <ReportList username={username} />
        </div>
      </TitledSection>
    </>
  );
};
