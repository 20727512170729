import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import MultipleAutocomplete, {
  IMultipleAutoCompleteProps,
} from '../commons/MultipleAutoComplete';
import SingleAutoComplete from '../commons/SingleAutoComplete';
import { ITesteable } from '../../common/interfaces';
import TitledSection from '../commons/TitledSection';

interface IControlledMultipleAutoCompleteProps<TOption, TValue>
  extends Omit<
      IMultipleAutoCompleteProps<TOption, TValue>,
      'value' | 'error' | 'onChange' | 'multiple'
    >,
    ITesteable {
  defaultValue?: any;
  name: string;
  multiple?: boolean;
  title?: string;
  description?: string;
  selectorFullWidth?: boolean;
  disabled?: boolean;
}

const AutoCompleteSelector = <TOption, TValue>({
  name,
  defaultValue,
  multiple = false,
  title,
  description,
  // dataTestId = "AutoCompleteSelector",
  selectorFullWidth = false,
  disabled = false,
  ...props
}: IControlledMultipleAutoCompleteProps<TOption, TValue>) => {
  const { control } = useFormContext();
  return (
    <>
      {title ? (
        <Grid item container xs={12} sm={8}>
          <TitledSection variant="h4" title={title} />
        </Grid>
      ) : undefined}
      {description ? (
        <Grid item container xs={12} sm={8} sx={{ marginBottom: 2 }}>
          <Typography variant="body2">{description}</Typography>
        </Grid>
      ) : undefined}
      <Grid item container xs={12} sm={selectorFullWidth === true ? 12 : 6}>
        {multiple ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <MultipleAutocomplete<TOption, TValue>
                value={value ?? []}
                onChange={onChange}
                error={error}
                {...props}
                {...field}
                disabled={disabled}
              />
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <SingleAutoComplete<TOption, TValue>
                value={value ?? undefined}
                onChange={onChange}
                error={error}
                {...props}
                {...field}
                disabled={disabled}
              />
            )}
          />
        )}
      </Grid>
    </>
  );
};

export default AutoCompleteSelector;
