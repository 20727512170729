import { useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { getInterestRateList } from '../../../api/adminRates';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import { RouteKey, paths } from '../../../navigation/constants';
import usePermissions from '../../../hooks/usePermissions';
import { MenuItemType, Permissions } from '../../../common/enums';
import { useUI } from '../../../contexts/UIContext';
import InterestRateList from '../../../components/pagePartial/InterestRateList';
import useRoutePage from '../../../hooks/useRoutePage';
import RateGroupForm from '../../../components/RateGroupForm';
import Loading from '../../../components/commons/Loading';

export const RatesGroupDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { checkPermissions } = usePermissions();
  const interestRateTableId = params.itemId ? parseInt(params.itemId, 10) : '';
  const interestRateGroupId = params.groupId
    ? parseInt(params.groupId, 10)
    : '';

  const [editMode, setEditMode] = useState(false);
  const { setMenuOverride } = useUI();

  const { routeState, mergeRouteState, navigateToRoute } = useRoutePage({
    routeKey: RouteKey.VIEW_RATE_GROUP,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });
  const { pageNumber, pageSize } = routeState;

  const {
    data,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList',
      pageNumber,
      pageSize,
      { interestRateTableId, interestRateGroupId },
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: { interestRateTableId, interestRateGroupId },
        })
      ).data,
    keepPreviousData: false,
  });

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.INTEREST_RATE_READ,
        icon: <ArrowBackIcon />,
        text: t('common.back'),
        path: generatePath(paths[RouteKey.VIEW_RATES], {
          itemId: interestRateTableId,
        }),
      },
      items: ['interestRateTable'].map((cardKey) => ({
        key: cardKey,
        text: t('page.rates.menu.label'),
        path: generatePath(paths[RouteKey.RATES]),
        type: MenuItemType.MENU,
        permissions: Permissions.INTEREST_RATE_READ,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [data]);

  const { isLoading } = usePermissions();
  const routeKey: any = `${t('route.view_rate_group.label')}${
    data?.result[0]?.interestRateGroup?.name
      ? `: ${data?.result[0]?.interestRateGroup?.name}`
      : ''
  }`;

  return (
    isLoading || isFetching ? (
      <Loading />
    ) : (
    <BaseViewPage
      isLoading={isFetching}
      error={error}
      routeKey={routeKey}
      routeTrail={[RouteKey.RATES, RouteKey.VIEW_RATES]}
      routePathParams={{ itemId: interestRateTableId }}
      data={data?.result[0]}
      {...queryProps}
    >
      {!editMode && <InterestRateList setEditMode={setEditMode} />}
      {editMode && <RateGroupForm setEditMode={setEditMode} />}
    </BaseViewPage>
    )
  );
};
