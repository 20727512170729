import { useMemo } from 'react';
import { TextFieldProps } from '@mui/material';
import { NumberInput } from 'vulpino';
import { Controller, useFormContext } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';
import {
  formatToNumber,
  formatMoneyWithoutDecimals,
} from '../../utils/formatterUtil';

type CurrencyInputFormProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label?: string;
    labelKey?: string;
    defaultValue?: string;
    variant?: string;
    helperText?: any;
    translationPath?: string;
    mask?: string;
    dataTestId?: string;
  };

const CurrencyInputForm = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  mandatory = false,
  helperText,
  dataTestId,
  mask,
  disabled,
  variant,
  ...props
}: CurrencyInputFormProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const label = useTranslate(labelKey, labelProp);

  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumberInput
          variant={variant ?? 'outlined'}
          label={mandatory ? `${label}*` : label}
          helperText={error?.message || helperText}
          error={!!error}
          disabled={disabled}
          {...field}
          onChange={(event) => {
            let value = formatToNumber(event.target?.value);
            if (value) {
              value = formatMoneyWithoutDecimals(value);
            }
            field.onChange(value || undefined);
          }}
          inputProps={{ 'data-testid': dataTestId }}
          {...props}
        />
      )}
    />
  );
};

export default CurrencyInputForm;
