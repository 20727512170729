import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { GridColumns, GridRowId, GridRowParams } from '@mui/x-data-grid';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import dayjs from 'dayjs';
import FilterContainer from '../../components/commons/FilterContainer';
import {
  ICommerceFiltersState,
  IButtonConf,
  IFilterAppliedOption,
  ICommerceData,
} from '../../common/interfaces';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import MessageEmptyPageFirstAction from '../../components/commons/MessageEmptyPageFirstAction';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import { paths, RouteKey } from '../../navigation/constants';
import { getCommercesList } from '../../api/adminBusinessEnvironment/service';
import BaseTablePage from '../../components/commons/BaseTablePage';
import { getFinancialOfficesDataSource } from '../../api/adminDataSources/service';
import PromisedValue from '../../components/helpers/PromisedValue';
import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';

import useFilterOptions from './hooks/useFilterOptions';
import Loading from '../../components/commons/Loading';
import usePermissions from '../../hooks/usePermissions';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
}

const CommercesPage = ({ dataTestId = 'CommercesPage' }) => {
  const [translate] = useTranslation('global');
  const { getValue } = useDataSources();
  const navigate = useNavigate();
  const { isLoading } = usePermissions();
  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.COMMERCES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } = useFiltersProps<ICommerceFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const { data: financialOffices } = useQuery({
    queryKey: ['getFinancialOfficesDataSource', pageNumber, pageSize, filters],
    queryFn: async () => (await getFinancialOfficesDataSource()).data,
    keepPreviousData: true,
  });

  const {
    data: queryData,
    isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: ['getCommercesList', pageNumber, pageSize, filters],
    queryFn: async () =>
      (await getCommercesList({ pageNumber, pageSize, filters })).data,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const filterOptionList = useFilterOptions();

  const goToNewCommerce = () => {
    navigate(paths[RouteKey.COMMERCES_NEW]);
  };

  const goToFinancialOffices = () => {
    navigate(paths[RouteKey.FINANCIAL_OFFICES]);
  };

  const handleView = useCallback(
    (id: GridRowId) => {
      navigateToRoute(RouteKey.COMMERCES_VIEW, {
        '*': 'commerceDetail',
        commerceId: id.toString(),
      });
    },
    [navigate]
  );

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <DownloadForOfflineOutlinedIcon />,
      labelKey: 'component.toolBar.downloadLabelButton',
      disabled: rowCount <= 0,
      hidden: true,
    },
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      onClick: goToNewCommerce,
    },
  ];

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'externalCode',
        headerName: translate('page.commerce.columnHeaders.externalCode'),
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: translate('page.commerce.columnHeaders.name'),
        minWidth: 250,
        sortable: false,
      },
      {
        field: 'headquarterId',
        headerName: translate('page.commerce.columnHeaders.headquarterId'),
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) =>
          row?.isHeadquarter ? (
            <span>{translate('page.commerce.isHeadquarter')}</span>
          ) : (
            <PromisedValue
              value={row.headquarterId}
              fn={getValue}
              arg={{
                collection: DataSourceCollection.COMMERCES,
                id: row.headquarterId,
              }}
            />
          ),
      },
      {
        field: 'createdDate',
        headerName: translate('page.commerce.columnHeaders.createdDate'),
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) =>
          row.createdDate
            ? dayjs(row.createdDate).format(translate('DD-MM-YYYY'))
            : '-',
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 0.5,
        minWidth: 120,
        align: 'right',
        getActions: (params: GridRowParams<ICommerceData>) => [],
      },
    ],
    [translate]
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const emptyState =
    (financialOffices ?? []).length < 1 ? (
      <MessageEmptyPageFirstAction
        title={translate('page.commerce.noFinancialOfficesEmptyState.title')}
        subtitle={translate(
          'page.commerce.noFinancialOfficesEmptyState.subTitle'
        )}
        buttonLabel={translate(
          'page.commerce.noFinancialOfficesEmptyState.buttonLabel'
        )}
        onClick={goToFinancialOffices}
      />
    ) : (
      <MessageEmptyPageFirstAction
        title={translate('page.commerce.emptyState.title')}
        subtitle={translate('page.commerce.emptyState.subTitle')}
        buttonLabel={translate('page.commerce.emptyState.buttonLabel')}
        onClick={goToNewCommerce}
      />
    );

  return isFetching || isLoading ? (
    <Loading />
  ) : (
    <BaseTablePage
      error={error}
      routeKey={RouteKey.COMMERCES}
      dataTestId={dataTestId}
      data={queryData}
      {...queryProps}
    >
      {queryData?.result?.length === 0 && Object.keys(filters)?.length === 0 ? (
        emptyState
      ) : (
        <FilterContainer
          title={translate('page.commerce.title')}
          filterOptionList={filterOptionList}
          translationKeyPrefix="page.commerce.filters."
          {...filterProps}
        >
          <CustomDataGrid
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            onRowClick={({ id }) => handleView(id)}
            onPageChange={handleOnPageChange}
            onPageSizeChange={handleOnPageSizeChange}
            getRowId={(r: any) => r.id}
            buttonConfList={buttonConfList}
          />
        </FilterContainer>
      )}
    </BaseTablePage>
  );
};

export default CommercesPage;
