import { useTranslation } from 'react-i18next';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { ButtonProps, Typography } from '@mui/material';

import StyledButton from '../ui/StyledButton';

const RetryButton = ({ onClick }: ButtonProps) => {
  const [translate] = useTranslation('global');

  return (
    <Typography sx={({ palette }) => ({ color: palette.error.main })}>
      {translate('common.loadFailed')}
      <StyledButton
        variant="outlined"
        onClick={onClick}
        labelKey="common.retry"
        startIcon={<RefreshOutlinedIcon />}
      />
    </Typography>
  );
};
export default RetryButton;
