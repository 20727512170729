import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DataFillIterator from '../commons/DataFillIterator';

import CardContainer from './CardContainer';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';

type RequestContextCardProps = {
  data: Map<string, string>;
};

export const translationKeyPrefix =
  'page.financialProductRequest.requestContextData.';

const RequestContextCard = ({ data }: RequestContextCardProps) => {
  const [translate] = useTranslation('global');

  const { checkPermissions } = usePermissions();

  const valueMapper = useCallback(
    async (key: string, value: string) => {
      if (value === 'false') return translate('common.no');
      if (value === 'true') return translate('common.yes');
      return value;
    },
    [data]
  );

  if (!Object.keys(data ?? {}).length) {
    return null;
  }

  return (
    <Box hidden={!checkPermissions(Permissions.FINANCIAL_PRODUCT_REQUEST_CONTEXT_DATA_READ)}>
      <CardContainer 
      title={translate(translationKeyPrefix + 'title')}
      >
        <DataFillIterator
          object={data}
          translationKeyPrefix={translationKeyPrefix}
          valueMapper={valueMapper}
          gridItemProps={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            xl: 6
          }}
        />
      </CardContainer>
    </Box>
  );
};

export default RequestContextCard;
