import {
  IFinancialOfficeDataUpdate,
  IFinancialOfficeDivisionDataUpdate,
  IPaginatedParams,
  IRateGroupUpdateFormData,
  IRateTableUpdateFormData,
} from '../../common/interfaces';
import fetch from '../api';
import { env } from '../env';

interface IGetInterestRateListParams extends IPaginatedParams {
  filters?: {};
}

export const getInterestRateList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetInterestRateListParams = {}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: 'v1/interest-rate-table-groups',
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export type CalendarData = {
  name: string;
  description: string | null;
  date: number | null;
};

export const getRates = (id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/interest-rate-table-groups/${id}`,
    method: 'get',
  });

export const getEvent = (itemId?: number, id?: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${itemId}/events/${id}`,
    method: 'get',
  });

export const getEventList = (id?: number, filters?: any) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v2/calendars/${id}/events`,
    method: 'get',
    params: {
      ...filters,
    },
  });

export const searchInterestRateTable = (
  id?: number,
  {
    pageNumber = 1,
    pageSize = 1000,
    filters = {},
  }: IGetInterestRateListParams = {}
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/interest-rate-tables${id ? '/' + id : ''}`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const updateInterestRateTable = (
  id?: number,
  data?: IRateTableUpdateFormData
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/interest-rate-tables/${id}`,
    method: 'patch',
    data,
  });

export const updateInterestRateGroup = (
  id?: number,
  data?: IRateGroupUpdateFormData
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/interest-rate-table-groups/${id}`,
    method: 'put',
    data,
  });
