import { Grid, Typography } from '@mui/material';
import { styled, GridProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRadius: '5px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`,
}));

export interface UserDatetimeValueProps extends GridProps {
  username?: string;
  datetime?: string;
}

const UserDatetimeValue = ({
  username,
  datetime,
  ...props
}: UserDatetimeValueProps) => {
  const [translate] = useTranslation('global');
  return (
    <Grid item {...props}>
      {username && <Typography fontWeight="bold">{username}</Typography>}
      {datetime && (
        <Typography fontWeight="bold">
          {dayjs().format(translate('date.short-withtime'))}
        </Typography>
      )}
    </Grid>
  );
};

export default UserDatetimeValue;
