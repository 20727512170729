import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, styled } from '@mui/material';
import { Button, Divider } from 'vulpino';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';

import { ReportStatus } from '../../common/enums';
import { paths, RouteKey } from '../../navigation/constants';

import CardContainer from './CardContainer';
import {
  downloadReport,
  getReportDefinitionById,
} from '../../pages/Reports/Service';
import StatusBadge, { Status } from '../commons/StatusBadge';
import ReportFilterChips from '../commons/ReportFilterChips';

interface ReportExecutionCardCardProps {
  reportExecution: any;
}

const StyledValue = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 'bold',
}));

const ReportExecutionCard = ({
  reportExecution,
}: ReportExecutionCardCardProps) => {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data: reportDefinition } = useQuery({
    queryKey: ['getReportDefinition', reportExecution.reportDefinitionId],
    queryFn: async () =>
      (await getReportDefinitionById(reportExecution.reportDefinitionId)).data,
  });

  const statusMapping: any = {
    [ReportStatus.COMPLETED]: {
      status: Status.SUCCESS,
      labelKey: 'common.generated',
    },
    [ReportStatus.IN_PROGRESS]: {
      status: Status.WARNING,
      labelKey: 'common.inProgress',
    },
    [ReportStatus.FAILED]: {
      status: Status.FAILURE,
      labelKey: 'common.failed',
    },
  };

  const handleDownload = async (id: number) => {
    setLoading(true);

    const res = await downloadReport(id);
    const name = res.headers['content-disposition']
      .replace('inline; filename=', '')
      .replace(/"/g, '');
    const csvStr = res.data;

    fileDownload(csvStr, name);

    setLoading(false);
  };

  return (
    <CardContainer key={reportExecution.id}>
      <Grid container mb={1}>
        <Grid item xs={6}>
          <Typography variant="h3" fontWeight="regular">
            {reportDefinition ? reportDefinition.description : null}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          {!reportExecution.fileKey ||
          reportExecution.status === ReportStatus.FAILED ? (
            <Button
              disabled={loading}
              loadingProps={{
                leading: true,
              }}
              startIcon={<RefreshIcon />}
              variant="secondary"
              onClick={() =>
                navigate(paths[RouteKey.REPORTS_FILTERS], {
                  replace: true,
                  state: reportExecution.filtersUsed,
                })
              }
            >
              {t('page.reports.list.repeat')}
            </Button>
          ) : (
            <Button
              disabled={
                loading || reportExecution.status === ReportStatus.IN_PROGRESS
              }
              loadingProps={{
                leading: true,
              }}
              startIcon={<DownloadIcon />}
              variant="secondary"
              onClick={() => handleDownload(reportExecution.id)}
            >
              {t('page.reports.list.download')}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <StatusBadge
            {...{
              status: statusMapping[reportExecution.status].status,
              label: t(statusMapping[reportExecution.status].labelKey),
            }}
            sx={{ mt: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container mb={1}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container mb={1}>
        <Grid item xs={6}>
          <Typography>
            <StyledValue>
              {reportDefinition ? t(`page.reports.card.date`) : null}
            </StyledValue>
            {dayjs(reportExecution.executionDate).format('DD/MM/YYYY HH:mm')}
            hs
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          {reportExecution?.recipients?.length > 0 && (
            <Typography>
              <StyledValue>{t('page.reports.card.recipients')}</StyledValue>
              {reportExecution.recipients.join(', ')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container mb={1}>
        <Grid item xs={12}>
          <Typography>
            {reportDefinition ? (
              <>
                <StyledValue>
                  {t('page.reports.card.filters.title')}
                </StyledValue>
                <ReportFilterChips
                  reportDefinitionName={
                    reportDefinition.name === 'traces_report'
                      ? 'trace_report'
                      : reportDefinition.name
                  }
                  filters={reportExecution.filtersUsed}
                />
              </>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default ReportExecutionCard;
