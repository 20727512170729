import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'userName',
        attribute: 'userName',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('page.user.form.userName.required')),
      },
      {
        name: 'active',
        attribute: 'active',
        value: '',
        validation: yup
          .string()
          .required(translate('page.user.activeRequired')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.GENERIC_BOOLEAN,
      },
      {
        name: 'roleIds',
        attribute: 'roleIds',
        value: [],
        multiple: true,
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.ROLES,
        validation: yup.array().min(1, translate('page.user.roleIdsRequired')),
      },
      {
        name: 'commerceIds',
        attribute: 'commerceIds',
        value: [],
        multiple: true,
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.COMMERCES_USER,
        validation: yup.array().min(1, translate('common.required')),
      },
      {
        name: 'nationalIdNumber',
        attribute: 'nationalIdNumber',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('common.required')),
      },
    ],
    [translate]
  );
};

export default useFilterOptions;
