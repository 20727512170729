import type {
  IGetListResponse,
  IPaginatedParams,
  IUserFiltersState,
} from '../../common/interfaces';
import { pathReplace } from '../../utils/apiUtils';

import fetch from '../api';
import { env } from '../env';
import {
  DYNAMIC_FORMS_DEFINITIONS_ELEMENTS_PATH,
  DYNAMIC_FORMS_PATH,
  DYNAMIC_FORMS_VALIDATIONS_PATH,
  DYNAMIC_FORMS_DEFINITIONS_PATH,
  DYNAMIC_FORMS_GET_ONE_PATH,
} from './constants';
import type {
  IDynamicForm,
  IDynamicFormDefinition,
  IFormElement,
} from './interfaces';

interface IGetDynamicFormsParams extends IPaginatedParams {
  filters?: IUserFiltersState;
}

interface IValidateFormParams {
  dynamicFormName: string;
  contextData?: {};
  formElementData?: {};
}

interface IGetDynamicFormDefinitionsElements {
  dynamicFormName: string;
  formElementName: string;
  contextData?: {};
  formElementData?: {};
}

interface IGetDynamicFormDefinitions {
  dynamicFormName: string;
  contextData?: {};
  formElementData?: {};
}

export const getDynamicFormDefinitions = ({
  dynamicFormName,
  contextData = {},
  formElementData = {},
}: IGetDynamicFormDefinitions): Promise<{
  data: IDynamicFormDefinition;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: DYNAMIC_FORMS_DEFINITIONS_PATH,
    method: 'post',
    data: {
      dynamicFormName,
      contextData,
      formElementData,
    },
  });

export const getDynamicFormDefinitionsElements = ({
  dynamicFormName,
  formElementName,
  contextData = {},
  formElementData = {},
}: IGetDynamicFormDefinitionsElements): Promise<{
  data: IFormElement;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: DYNAMIC_FORMS_DEFINITIONS_ELEMENTS_PATH,
    method: 'post',
    data: {
      dynamicFormName,
      formElementName,
      contextData,
      formElementData,
    },
  });

export const getDynamicForms = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetDynamicFormsParams = {}): Promise<IGetListResponse<IDynamicForm>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: DYNAMIC_FORMS_PATH,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const getDynamicForm = (
  id: string
): Promise<{
  data: IDynamicFormDefinition;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: pathReplace(DYNAMIC_FORMS_GET_ONE_PATH, {
      id,
    }),
    method: 'get',
  });

export const validateForm = ({
  dynamicFormName,
  contextData = {},
  formElementData = {},
}: IValidateFormParams): Promise<{
  data: IDynamicFormDefinition;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: DYNAMIC_FORMS_VALIDATIONS_PATH,
    method: 'post',
    data: {
      dynamicFormName,
      contextData,
      formElementData,
    },
  });
