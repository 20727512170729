import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IFilterAppliedOption } from '../../../../common/interfaces';
import { FilterOptionTypes } from '../../../../common/enums';
import { DataSourceCollection } from '../../../../hooks/useDataSources';

export const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();
  return useMemo<IFilterAppliedOption[]>(
    () => [
      {
        name: 'name',
        attribute: 'name',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
      {
        name: 'description',
        attribute: 'description',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
      {
        name: 'active',
        attribute: 'active',
        value: 'true',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.GENERIC_BOOLEAN,
      },
    ],
    [translate, queryClient]
  );
};

export default useFilterOptions;
