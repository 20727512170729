import { useCallback, useMemo } from 'react';

import { IFilterAppliedOption } from '../common/interfaces';

interface IUseFiltersProps {
  appliedFilters: any[];
  onChange: (changed: IFilterAppliedOption[]) => void;
}

const useFiltersProps = <IFiltersState>({
  appliedFilters = [],
  onChange,
}: IUseFiltersProps) => {
  const filters = useMemo<IFiltersState>(
    () =>
      appliedFilters.reduce(
        (obj: IFiltersState, item: IFilterAppliedOption) => ({
          ...obj,
          [item.attribute]: item.value,
        }),
        {}
      ),
    [appliedFilters]
  );

  const onDeleteAllFilters = useCallback(() => onChange?.([]), [onChange]);

  const onRemoveChip = useCallback(
    (filterName: string) =>
      onChange?.(appliedFilters.filter((f) => f.name !== filterName)),
    [onChange]
  );

  const onSubmitFilters = useCallback(
    (data: { filters: IFilterAppliedOption[] }) =>
      onChange?.([...data.filters]),
    [onChange]
  );

  return {
    filters,
    filterAppliedList: appliedFilters,
    onDeleteAllFilters,
    onRemoveChip,
    onSubmitFilters,
  };
};

export default useFiltersProps;
