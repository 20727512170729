import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { GridSelectionModel } from '@mui/x-data-grid';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import dayjs from 'dayjs';
import FilterContainer from '../../components/commons/FilterContainer';
import type { IButtonConf, IFPRPendingTask } from '../../common/interfaces';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import MessageEmptyPageFirstAction from '../../components/commons/MessageEmptyPageFirstAction';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import { RouteKey } from '../../navigation/constants';
import BaseTablePage from '../../components/commons/BaseTablePage';
import useFilterOptions from './hooks/useFilterOptions';
import type {
  IFPRPendingTasksPageFiltersState,
  IFPRPendingTasksPageRouteState,
} from './interfaces';
import useDataGridColumns from '../../hooks/useDataGridColumns';
import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';
import PromisedValue from '../../components/helpers/PromisedValue';
import { getFPRPendingTasks } from '../../api/adminPlatformBPM/service';
import Loading from '../../components/commons/Loading';
import { formatMoney } from '../../utils/formatterUtil';

const FPRPendingTasksPage = ({ dataTestId = 'FPRPendingTasksPage' }) => {
  const [translate] = useTranslation('global');
  const { getValue } = useDataSources();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IFPRPendingTasksPageRouteState>({
      routeKey: RouteKey.CODE_MAPPINGS,
      initialState: {
        pageNumber: 1,
        pageSize: 50,
        filterAppliedList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IFPRPendingTasksPageFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const {
    data: queryData,
    isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: ['getFPRPendingTasks', pageNumber, pageSize, filters],
    queryFn: async () =>
      (await getFPRPendingTasks({ pageNumber, pageSize, filters })).data,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const filterOptionList = useFilterOptions();

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <DownloadForOfflineOutlinedIcon />,
      labelKey: 'component.toolBar.downloadLabelButton',
      disabled: rowCount <= 0,
      hidden: true,
    },
  ];

  const showAmount = (element: any) => {
    switch (element.status) {
      case 'ACTIVATION_PROCESS':
      case 'FINALIZED':
        if (
          element.selectedFinancialProducts &&
          element.selectedFinancialProducts.length > 0
        ) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  };

  const columns = useDataGridColumns(
    [
      {
        field: 'startDateTime',
        width: 180,
        flex: 0,
        renderCell: ({ row }) =>
          row.startDateTime
            ? dayjs(row.startDateTime).format(translate('date.timestamp'))
            : '',
      },
      {
        field: 'publicId',
        minWidth: 200,
      },
      {
        field: 'customerCode',
        minWidth: 160,
      },
      {
        field: 'customerFullName',
        minWidth: 200,
      },
      {
        field: 'capitalAmount',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          showAmount(row)
            ? row.selectedFinancialProducts.map((product: any) => {
                if (product.financialProductType === 'LOAN') {
                  return formatMoney(product.capitalAmount);
                }
                return '';
              })
            : '',
      },
      {
        field: 'commerceId',
        minWidth: 150,
        renderCell: ({ row }) => (
          <PromisedValue
            value={row.commerceId}
            fn={getValue}
            arg={{
              collection: DataSourceCollection.COMMERCES,
              id: row.commerceId,
            }}
          />
        ),
      },
      {
        field: 'businessSchemaId',
        minWidth: 150,
        renderCell: ({ row }) => (
          <PromisedValue
            value={row.businessSchemaId}
            fn={getValue}
            arg={{
              collection: DataSourceCollection.BUSINESS_SCHEMAS,
              id: row.businessSchemaId,
            }}
          />
        ),
      },
      {
        field: 'taskDefinitionId',
        minWidth: 200,
        renderCell: ({ row }) =>
          translate(
            `page.financialProductRequestPendingTask.${row.taskDefinitionId}`,
            `${row.taskDefinitionId}`
          ),
      },
    ],
    {
      translationKeyPrefix:
        'page.financialProductRequestPendingTask.columnHeaders.',
    },
    []
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleOnSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };
  //cambiar pagina sin tareas pendientes!!!!!!
  const emptyState = <MessageEmptyPageFirstAction />;

  const handleView = useCallback(
    (requestId: string, processInstanceId: string, taskId: string) => {
      navigateToRoute(RouteKey.FPR_PENDING_TASKS_VIEW, {
        requestId,
        processInstanceId,
        taskId,
      });
    },
    [navigateToRoute]
  );

  return isFetching ? (
    <Loading />
  ) : (
    <BaseTablePage
      error={error}
      routeKey={RouteKey.FPR_PENDING_TASKS}
      dataTestId={dataTestId}
      data={queryData}
      emptyState={emptyState}
      hasFilters={!isEmpty(filters)}
      {...queryProps}
    >
      <FilterContainer
        title={translate('page.financialProductRequestPendingTask.title')}
        filterOptionList={filterOptionList}
        translationKeyPrefix="page.financialProductRequestPendingTask.filter"
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 30, 50, 100]}
          onRowClick={({ row }) =>
            handleView(
              row.financialProductRequestId,
              row.processInstanceId,
              row.taskId
            )
          }
          selectionModel={selectionModel}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          onSelectionModelChange={handleOnSelectionModelChange}
          getRowId={(r: IFPRPendingTask) => r.taskId}
          buttonConfList={buttonConfList}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};

export default FPRPendingTasksPage;
