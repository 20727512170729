/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import i18next from 'i18next';

export type IAttributeValidation = {
  [attributeName: string]: yup.BaseSchema;
};

/**
 * Returns the ArraySchema object that allows to validate fields in forms with array fields
 * For example:
 *   if 'attributeSchema' param is
 *      {
 *         "roleName": yup.string().required(i18next.t('page.role.form.roleName.required
 *         "description": yup.string().required(i18next.t('page.role.inputDescriptionRequired'))
 *      }
 * 
 * @returns the following yup schema:
 * 
 * yup.array().of(
    yup.object().shape({
        value: yup.string()
            .when('name', {
                is: 'roleName',
                then: yup.string().required(i18next.t('page.role.form.roleName.required')),
            })
            .when('name', {
                is: 'description',
                then: yup.string().required(i18next.t('page.role.inputDescriptionRequired'))
            })
    })
 * 
 */
export const compositeSchemaForArrayForms = (
  attributeSchema: IAttributeValidation
) => {
  let generatedArraySchema = yup.mixed();

  Object.keys(attributeSchema).forEach((attribute: string) => {
    generatedArraySchema = generatedArraySchema.when('name', {
      is: attribute,
      then: attributeSchema[attribute],
    });
  });

  return yup.array().of(yup.object().shape({ value: generatedArraySchema }));
};

export const loginFormSchema = () =>
  yup.object().shape({
    username: yup
      .string()
      .required(i18next.t('page.login.inputUsernameRequired')),
    password: yup
      .string()
      .required(i18next.t('page.login.inputPasswordRequired')),
  });
