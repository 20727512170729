import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  IFinancialProductRequestDetail,
  TButtonDefinition,
} from '../../common/interfaces';
import DataFill from '../commons/DataFill';
import { dateFormatter } from '../../utils/formatterUtil';
import BaseViewHeader, { BaseViewHeaderProps } from '../BaseViewHeader';
import RequestStatusBadge from '../RequestStatusBadge';
import ToolButtonBar from '../commons/ToolButtonBar';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';

interface FPRHeaderProps extends BaseViewHeaderProps {
  data: IFinancialProductRequestDetail;
  full?: boolean;
  showDates?: boolean;
  showStatus?: boolean;
  nameAsData?: boolean;
  supButtons?: TButtonDefinition[];
  permissionsSupButtons?: any;
}

const FPRHeader = ({
  data,
  full = false,
  showDates = false,
  showStatus = false,
  nameAsData = false,
  supButtons = [],
  permissionsSupButtons = undefined,
  ...props
}: FPRHeaderProps) => {
  const [translate] = useTranslation('global');
  const { checkPermissions } = usePermissions();

  const title = !nameAsData && (
    <Grid container alignItems="center">
      <AccountCircleIcon sx={{ fontSize: '27px', mr: 1 }} color="primary" />
      {data.customerFullName}
    </Grid>
  );

  const suptitle = (full || showDates) && (
    <Grid item container mb={2} direction="row">
      <Grid
        item
        sx={{ display: 'flex', flexGrow: 1 }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography>
          {translate('page.financialProductRequest.header.startDateTitle')}{' '}
          <b>{dateFormatter(data.startDate)}</b>{' '}
        </Typography>
        <Typography>&nbsp;/&nbsp;</Typography>
        <Typography>
          {translate('page.financialProductRequest.header.expirationDateTitle')}{' '}
          <b>{dateFormatter(data.expirationDate)}</b>
        </Typography>
      </Grid>
      <Grid item>
        {(permissionsSupButtons
          ? checkPermissions(permissionsSupButtons)
          : true) &&
          supButtons.length > 0 && (
            <ToolButtonBar buttonConfList={supButtons} />
          )}
      </Grid>
    </Grid>
  );

  const subtitle = showStatus && (
    <Grid mt={1} container direction="row" alignItems="center">
      <Typography mr={1}>
        {translate('page.financialProductRequest.header.statusTitle')}
      </Typography>
      <RequestStatusBadge
        status={data.status}
        fontWeight="bold"
        fontSize="medium"
      />
      <Typography>&nbsp;&nbsp;</Typography>
      <Typography mr={1}>
        {data.rejectedCode !== undefined
          ? translate(
              `page.financialProductRequest.rejectedCode.${data.rejectedCode}`,
              `${data.rejectedDescription ?? ""}`
            )
          : ''}
      </Typography>
    </Grid>
  );

  return (
    <BaseViewHeader
      title={title}
      suptitle={suptitle}
      subtitle={subtitle}
      {...props}
    >
      {(!!title || !!subtitle || !!suptitle) && (
        <Grid item container xs={12} direction="column" mb={2} mt={2}>
          <Divider />
        </Grid>
      )}
      <Grid item container spacing={2} alignItems="flex-start">
        <DataFill
          key="header.publicId"
          title="publicIdTitle"
          value={data.publicId}
          translationKeyPrefix="page.financialProductRequest.header."
        />
        {nameAsData && (
          <DataFill
            key="header.customerFullName"
            title="customerFullNameTitle"
            value={data.customerFullName}
            translationKeyPrefix="page.financialProductRequest.header."
          />
        )}
        <DataFill
          key="header.customerCode"
          title="customerCodeTitle"
          value={data.customerCode}
          translationKeyPrefix="page.financialProductRequest.header."
        />

        <DataFill
          key="header.customerTaxId"
          title="customerTaxIdTitle"
          value={data.customerTaxId !== undefined ? data.customerTaxId : ''}
          translationKeyPrefix="page.financialProductRequest.header."
        />
      </Grid>
    </BaseViewHeader>
  );
};

export default FPRHeader;
