import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useTranslate = (key?: string, fallback?: string, { mandatory = false } = {}) => {
  const [translate] = useTranslation('global');

  return useMemo(() => {
    const string = key ? translate(key, fallback) : fallback;
    return mandatory ? `${string}*` : string;
  }, [fallback, key, mandatory]);
}

export default useTranslate;
