import { useImperativeHandle, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  IFormProps,
  IOptionItem,
  ICatalogItemData,
  ICatalogItemFormData,
  ITesteable,
} from '../../common/interfaces';
import TitledSection from '../commons/TitledSection';
import { buildFormData, buildSchema } from './builders';
import FormFields from '../form/FormFields';

interface ICatalogItemFormProps
  extends IFormProps<ICatalogItemFormData>,
    ITesteable {
  itemId?: number;
  tableName?: string;
  data?: ICatalogItemData;
  metadataKeysOptionList?: Array<IOptionItem>;
}

const CatalogItemForm = ({
  itemId,
  data,
  tableName,
  dataTestId = 'CatalogItemForm',
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: ICatalogItemFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate }));

  const methods = useForm<ICatalogItemFormData>({
    defaultValues: buildFormData(data),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setSchema(buildSchema({ translate }));
  }, [translate]);

  const onSubmit = async (dataForm: ICatalogItemFormData) => {
    onSubmitProp?.(dataForm);
  };

  useImperativeHandle(
    formRef,
    () => ({
      ...methods,
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2}>
          <TitledSection
            variant="h2"
            title={`${translate(
              itemId ? 'page.catalog.actionEdit' : 'page.catalog.actionNew'
            )}${tableName ? ` - ${tableName}` : ''}`}
          >
            <Grid item sm={6} xs={12}>
            <FormFields
                dataTestId={dataTestId}
                translationPrefix="page.catalog.form"
                fields={[
                  {
                    name: 'tableName',
                    hidden: !!tableName,
                  },
                  {
                    name: 'code',
                  },
                  {
                    name: 'description',
                  },
                ]}
              />
            </Grid>
          </TitledSection>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CatalogItemForm;
