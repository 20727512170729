import * as yup from 'yup';
import { IRateTableUpdateFormData } from '../../common/interfaces';

export const buildFormData = ({
  minimumTerm,
  maximumTerm,
  termInterval,
}: Partial<IRateTableUpdateFormData> = {}): Partial<IRateTableUpdateFormData> => ({
  minimumTerm,
  maximumTerm,
  termInterval,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    minimumTerm: yup
      .string()
      .required(translate('common.required'))
      .min(1, translate('common.required')),
    maximumTerm: yup
      .string()
      .required(translate('common.required'))
      .min(1, translate('common.required')),
    termInterval: yup
      .string()
      .required(translate('common.required'))
      .min(1, translate('common.required')),
  };

  return yup.object().shape(shape);
};

export default {};
