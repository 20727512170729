import { Autocomplete, Grid, styled, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { generatePath, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "vulpino";
import { getLoanBatchsLiquidation, postLoanBatchsNotification } from "../../api/adminBind/service";
import { ILoanBatchNotificationRequest } from "../../common/interfaces";
import BaseViewPage from "../../components/commons/BaseViewPage";
import { paths, RouteKey } from "../../navigation/constants";
import { useUI } from "../../contexts/UIContext";
import { ToastType } from "../../components/commons/Toast";
import Loading from "../../components/commons/Loading";

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
  textDecoration: 'none',
  marginRight: '10px'
}));

export const OperativeControlConfirmPage = () => {
const [translate] = useTranslation('global');
const navigate = useNavigate();
const parameters = useParams();
const { batchId } = parameters;

const { setToast } = useUI();

const [loading, setLoading] = useState(false);

const TRANSFER_ASSIGNORS_ACCOUNT = 'Transferencia por Compra de Cartera';
const CREDIT_ASSIGNORS_CURRENT_ACCOUNT = 'Crédito por Compra de Cartera con Crédito en Cta. Cte.';
const businessOptions = [TRANSFER_ASSIGNORS_ACCOUNT, CREDIT_ASSIGNORS_CURRENT_ACCOUNT];

const goBack = () => {
  const urlViewRequest = generatePath(
    paths[RouteKey.OPERATIVE_CONTROL_VIEW],
    {batchId}
  );
  navigate(urlViewRequest);
};

const { data: queryData, ...queryProps } = useQuery({
    queryKey: [`batchId:${batchId}`],
    queryFn: async () =>
        batchId ?
    (await getLoanBatchsLiquidation(batchId))
        .data : undefined,
    keepPreviousData: true,
  });

const data = useMemo(() => queryData, [queryData]);

const showLoader = !data;

const showContent = !showLoader;

const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    setSelectedMethod(newValue);
  };


const onSubmit = async () => {
  setLoading(true);

  const disbursementType = selectedMethod === 'Transferencia por Compra de Cartera' ? 'TRANSFER_ASSIGNORS_ACCOUNT' : 'CREDIT_ASSIGNORS_CURRENT_ACCOUNT';

  const requestData: ILoanBatchNotificationRequest = {
      assignor: data?.assignor!,
      assignee: data?.assignee!,
      loanBatchId: Number(batchId!),
      params: {
          disbursementType: disbursementType!,
      },
  };
  
  try {
    const response = (await postLoanBatchsNotification(requestData)).data;

    if (response.status === 'COMPLETED' || response.status === 'STARTING') {
      setToast({
        key: 'success',
        message: translate('page.operativeControl.PRE_APPROVED_LOAN_SUCCESS_TOAST'),
      });
    } else {
      setToast({
        type: ToastType.ERROR,
        message: translate('page.operativeControl.PRE_APPROVED_LOAN_ERROR_TOAST'),
      });
    }
  } catch (error) {
    setToast({
      type: ToastType.ERROR,
      message: translate('page.operativeControl.PRE_APPROVED_LOAN_ERROR_TOAST'),
    });
  } finally {
    setLoading(false);
    navigate(generatePath(paths[RouteKey.OPERATIVE_CONTROL]));
  }
};

return (
  <div>
  {loading && <Loading />} 
  {!loading && 
    <>
  <div>
    {showLoader && <Loading />}
    {showContent &&  
      <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledLink
          to={
            (generatePath(paths[RouteKey.OPERATIVE_CONTROL]))
          }
        >
          Bandeja de control / 
        </StyledLink>
        <StyledLink
          to={
            generatePath(
              paths[RouteKey.OPERATIVE_CONTROL_VIEW],
              { batchId}
            )
          }
        >
          Lote {data?.assignorBatchId} / 
        </StyledLink>
        <Typography color="text.primary" fontWeight="bold">
          Confirmar
        </Typography>
      </div>
      <h3>Confirmar liquidación</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={businessOptions}
            value={selectedMethod} 
            onChange={handleChange} 
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccionar método de desembolso"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container item xs={6} spacing={2} marginTop={4}>
          <Grid item xs={6}>
              <Button onClick={goBack} fullWidth variant="secondary">
              {translate('page.operativeControl.cancel')}
              </Button>
          </Grid>
          <Grid item xs={6}>
              <Button onClick={onSubmit} fullWidth variant="primary">
              {translate('page.operativeControl.confirm')}
              </Button>
          </Grid>
      </Grid>
      </>
      }
  </div>
  </>
  }
</div>
);
}