import { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'vulpino';
import Loading from '../../../components/commons/Loading';
import usePermissions from '../../../hooks/usePermissions';
import TitledSection from '../../../components/commons/TitledSection';
import { FinancialOfficesList } from '../../../components/pagePartial/FinancialOffices/FinancialOffices/FinancialOfficesList';
import { DivisionsList } from '../../../components/pagePartial/FinancialOffices/Divisions/DivisionsList';

export const FinancialOffices = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const options = [
    {
      label: t('page.financialOffices.tabs.first'),
      value: '1',
    },
    {
      label: t('page.financialOffices.tabs.second'),
      value: '2',
    },
  ];

  const { isLoading } = usePermissions();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div>
        <TitledSection variant="h1" title={t('page.financialOffices.title')} />
      </div>
      <Tabs
        style={{ borderBottom: '3px solid #E0E0E0' }}
        value={value}
        options={options}
        onChange={handleChange}
        tabProps={{ sx: { p: 2, marginLeft: 3 } }}
      >
        <Box>
          <FinancialOfficesList />
        </Box>
        <Box>
          <DivisionsList />
        </Box>
      </Tabs>
    </>
  );
};
