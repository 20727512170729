import * as React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Grid } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

import { batchFormatMoney } from '../../utils/formatterUtil';
import { getBatchUpdateDetails} from '../../api/adminBind/service';
import Loading from '../commons/Loading';

const CustomTabPanel = (props: { [x: string]: any; children: any; value: any; index: any; }) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={value}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

type PaymentNavBarProps = {
  batchId?: string;
  updateType: string;
  assignorBatchId: string;
  assignor: string;
};

export const PaymentNavBar = ({
  batchId,
  updateType,
  assignorBatchId,
  assignor
}:PaymentNavBarProps) => {
  const isLoading = true;
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  const handlePrint = () => {
    const contentToPrint = document.getElementById('printable-content-' + value)!.innerHTML;
      
    // Open a new window for printing
    const printWindow = window.open('', '', 'width=800,height=600');
    
    printWindow!.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 80px;
            }
            .print-content {
              width: 150%;
            }
          </style>
        </head>
        <body>
          <div class="print-content">
            ${contentToPrint}
          </div>
        </body>
      </html>
    `);
    
    printWindow!.document.close();
    
    printWindow!.print();
  };

  const shouldFetchUpdateDetails = updateType !== undefined && batchId !== undefined;

  const { data: updateData} = useQuery({
    queryKey: ['batchUpdatesDetails', batchId, updateType],
    queryFn: async () => shouldFetchUpdateDetails ?
      (await getBatchUpdateDetails(batchId!.toString(), updateType))
        .data
      : null,
    enabled: shouldFetchUpdateDetails,
    keepPreviousData: true,
   });

   const loanUpdateDetail = useMemo(() => updateData, [updateData]);

   const showLoader = isLoading && !loanUpdateDetail;

   const showContent = !showLoader;

   const totalRounding = (loanUpdateDetail?.accumulatedPrincipalRounding ?? 0) + (loanUpdateDetail?.accumulatedInterestRounding ?? 0);

   const getDownloadRejectPayments = async (
  ) => {
    try {
      const response = await fetch(
        `https://api.onscore-dev.pcnt.io/v1/portfolio-acquisition-download-executions/download?assignorBatchId=${assignorBatchId}&assignor=${assignor}`,
        {
          method: "GET",
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      const blob = await response.blob(); 
      const url = window.URL.createObjectURL(blob); 
      const a = document.createElement("a");
      a.href = url;
      a.download = "DETALLE_PAGOS_RECHAZADOS.zip";
      document.body.appendChild(a);
      a.click(); 
      a.remove();
      window.URL.revokeObjectURL(url); 
  
    } catch (error) {
      throw new Error("Download failed:", error!);
    }
  };
  

  return (
    <div>
      {showLoader && <Loading />}
      {showContent && 
    <>
    <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab id="rendi" label="Rendición" sx={{ textTransform: 'capitalize' }}/>
            <Tab label="Pagos cancelatorios" sx={{ textTransform: 'capitalize' }} />
            <Tab label="Desprocesos" sx={{ textTransform: 'capitalize' }} />
            <Tab label="Redondeos" sx={{ textTransform: 'capitalize' }} />
          </Tabs>
        </Box>
        <CustomTabPanel id="printable-content-0" value={value} index={0}>
          <ol style={{ padding: 0, listStyleType: 'none'}}>
            <li style={{ marginBottom: '5px' }}>
              <strong>Monto total de Pagos Informados:</strong> 
              <span style={{marginLeft: '148px'}}>$ {batchFormatMoney(loanUpdateDetail?.paymentBatchTotalAmount)}</span>
            </li>
            <li style={{ padding: 0, marginTop: '20px' }}>
              <strong>Detalle imputación:</strong>
            </li>
            <li style={{ marginTop: '10px' }}>
              Capital:
              <span style={{marginLeft: '355px'}}>$ {batchFormatMoney(loanUpdateDetail?.principalTotalAmount)}</span> 
            </li>
            <li>
              Intereses: 
              <span style={{marginLeft: '339px'}}>$ {batchFormatMoney(loanUpdateDetail?.interestTotalAmount)}</span>
            </li>
            <li>
              Punitorios: 
              <span style={{marginLeft: '333px'}}>$ {batchFormatMoney(loanUpdateDetail?.penaltyTotalAmount)}</span>
            </li>
            <li>
              Redondeo: 
              <span style={{marginLeft: '332px'}}>$ {batchFormatMoney(totalRounding)}</span>
            </li>
          </ol>
          <ol style={{ padding: 0, listStyleType: 'none' }}>
          <li style={{ marginBottom: '5px'}}> <strong>Montos Rechazados NO imputados por concepto:</strong></li> 

          <li style={{ marginTop: '10px' }}>Capital: <span style={{marginLeft: '350px'}}>$ {batchFormatMoney(loanUpdateDetail?.rejectedPrincipalTotalAmount)}</span></li> 
          <li >Intereses: <span style={{marginLeft: '333px'}}>$ {batchFormatMoney(loanUpdateDetail?.rejectedInterestTotalAmount)}</span></li> 
          <li >Punitorios: <span style={{marginLeft: '326px'}}>$ {batchFormatMoney(loanUpdateDetail?.rejectedPenaltyTotalAmount)}</span></li> 
          </ol>


        </CustomTabPanel>

        {value === 0 && 
        <div>          
          <Grid item xs={12} marginTop={2}>
              <Button onClick={() => getDownloadRejectPayments()} variant="text" color="primary" startIcon={<DownloadIcon />}>
                    Descarga pagos rechazados
              </Button>
          </Grid>
          </div>
        }



      <CustomTabPanel id="printable-content-1" value={value} index={1}>
        <ol style={{ padding: 0}}><strong>Monto de Pagos Cancelatorios Informados:</strong></ol>
        <ol style={{ padding: 0, margin: 0 }}>Cancelación: <span style={{marginLeft: '310px'}}>$ {batchFormatMoney(loanUpdateDetail?.cancellationPaymentsTotalAmount)}</span></ol>      
        <ol style={{ padding: 0, margin: 0 }}>Cancelación anticipada: <span style={{marginLeft: '228px'}}>$ {batchFormatMoney(loanUpdateDetail?.earlyCancellationPaymentsTotalAmount)}</span></ol>

        <ol style={{ padding: 0 }}><strong>Cantidad de Préstamos Cancelados:</strong></ol>
        <ol style={{ padding: 0, margin: 0 }}>Cancelación: <span style={{marginLeft: '310px'}}>{loanUpdateDetail?.amountOfLoansWithCancellationPayments}</span></ol>
        <ol style={{ padding: 0, margin: 0 }}>Cancelación anticipada: <span style={{marginLeft: '227px'}}>{loanUpdateDetail?.amountOfLoansWithEarlyCancellationPayments}</span></ol>
      </CustomTabPanel>

      <CustomTabPanel id="printable-content-2" value={value} index={2}>
        <ol style={{ padding: 0}}><strong>Cantidad de Préstamos Impactados:</strong> <span style={{marginLeft: '100px'}}>{loanUpdateDetail?.amountOfLoansWithUnprocessPayments}</span></ol>
        <ol style={{ padding: 0}}><strong>Cantidad de Cuotas Desprocesadas:</strong> <span style={{marginLeft: '98px'}}>{loanUpdateDetail?.amountOfUnprocessPayments}</span></ol>
        <ol style={{ padding: 0}}><strong>Monto Total Original a Desprocesar:</strong> <span style={{marginLeft: '103px'}}>$ {batchFormatMoney(loanUpdateDetail?.unprocessPaymentsTotalAmount)}</span></ol>
      </CustomTabPanel>

      <CustomTabPanel id="printable-content-0" value={value} index={3}>
          <ol style={{ padding: 0, listStyleType: 'none' }}>
            <li style={{ marginTop: '20px' }}>
              <strong>Monto redondeo acumulado de pagos APROBADOS:</strong> 
            </li>
            <li style={{ marginTop: '5px' }}>
              Capital:
              <span style={{marginLeft: '374px'}}>$ {batchFormatMoney(loanUpdateDetail?.accumulatedPrincipalRounding!)}</span> 
            </li>
            <li style={{ marginTop: '5px' }}>
              Interés:
              <span style={{marginLeft: '375px'}}>$ {batchFormatMoney(loanUpdateDetail?.accumulatedInterestRounding!)}</span> 
            </li>
          </ol>
        </CustomTabPanel>

      <Grid item xs={12} marginTop={2}>
        <Button onClick={handlePrint} variant="text" color="primary" startIcon={<PrintIcon />}>
              Imprimir
        </Button>
      </Grid>

    </Box>
    </>
    }
    </div>
  );
}
