import { IPaginatedParams, IUserFiltersState } from '../../common/interfaces';
import fetch from '../api';
import { env } from '../env';

interface IGetReportListParams extends IPaginatedParams {
  filters?: IUserFiltersState;
}

export const getReportList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetReportListParams = {}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/report-executions',
    method: 'get',
    params: {
      sort: 'executionDate:DESC',
      pageNumber,
      pageSize,
      ...filters,
    },
  });
