import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import qs from 'qs';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import type { ICatalogItemData, ITesteable } from '../../common/interfaces';
import { RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';
import useRoutePage from '../../hooks/useRoutePage';
import BaseViewHeader from '../../components/BaseViewHeader';
import DataFillIterator from '../../components/commons/DataFillIterator';
import { getCatalogItem } from '../../api/adminPlatformCatalogs/service';
import usePermissions from '../../hooks/usePermissions';

interface ICatalogItemViewPageProps extends ITesteable {}

const CatalogItemViewPage = ({
  dataTestId = 'CatalogItemViewPage',
}: ICatalogItemViewPageProps) => {
  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.CODE_MAPPING_VIEW,
  });

  const location = useLocation();
  const { checkPermissions } = usePermissions();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const { data, ...queryProps } = useQuery<ICatalogItemData>({
    queryKey: [`catalogItem:${itemId}`],
    queryFn: async () =>
      itemId
        ? (await getCatalogItem(itemId)).data
        : ({
            tableName,
          } as ICatalogItemData),
    keepPreviousData: true,
  });

  const search = tableName ? `?tableName=${tableName}` : undefined;

  const handleEdit = () => {
    navigateToRoute(RouteKey.CATALOG_ITEM_EDIT, {
      search,
      ...params,
    });
  };

  const goBack = useCallback(
    () =>
      navigateToRoute(RouteKey.CATALOG_ITEMS, {
        search,
      }),
    [navigateToRoute, tableName]
  );

  return (
    <BaseViewPage
      routeKey={RouteKey.CATALOG_ITEM_VIEW}
      routeTrail={[RouteKey.CATALOG_ITEMS]}
      data={data}
      dataTestId={dataTestId}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          onClick: goBack,
          labelKey: 'common.cancel',
        },
      ]}
    >
      <BaseViewHeader
        title={data?.description}
        dataTestId={`${dataTestId}_Header`}
        buttonList={[
          {
            hidden: true,
            onClick: handleEdit,
            dataTestId: `${dataTestId}_EditButton`,
            endIcon: <BorderColorIcon />,
            labelKey: 'page.catalog.actionEdit',
            variant: 'primary',
          }
        ]}
      >
        <DataFillIterator
          mt={1}
          translationKeyPrefix="page.catalog"
          object={[
            {
              key: '.form.tableName.label',
              value: data?.tableName,
            },
            {
              key: '.form.code.label',
              value: data?.code,
            },
          ]}
        />
      </BaseViewHeader>
    </BaseViewPage>
  );
};

export default CatalogItemViewPage;
