import { useEffect, useState } from 'react';

import usePlatformCatalogs, {
  PlatformCatalogKey,
} from '../../hooks/usePlatformCatalogs';
import useTranslate from '../../hooks/useTranslate';
import type { ITesteable } from '../../common/interfaces';

import AutoCompleteSelector from './AutoCompleteSelector';
import type { IFormField } from './FormFields';

export interface PlatformCatalogSelectorProps<TParams>
  extends Omit<IFormField, 'type'>,
    ITesteable {
  name: string;
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  selectorFullWidth?: boolean;
  catalog: PlatformCatalogKey;
  params?: TParams;
  isEnabled?: (args: { params?: TParams }) => boolean;
  setRefOptions?: Function;
  filterField?: string;
}

interface IOptionItem {
  id: number;
  label: string;
}

const PlatformCatalogSelector = <TParams,>({
  label: labelProp,
  labelKey,
  catalog,
  params,
  isEnabled,
  name,
  fullWidth,
  selectorFullWidth,
  mandatory = false,
  setRefOptions,
  filterField,
  ...props
}: PlatformCatalogSelectorProps<TParams>) => {
  const label = useTranslate(labelKey, labelProp, {
    mandatory,
  });

  const { getOptions } = usePlatformCatalogs();
  const [options, setOptions] = useState<IOptionItem[]>([]);

  const searchOptions = async (request: any) => {
    const enabled = isEnabled ? isEnabled({ params: request }) : true;
    if (!enabled) {
      setRefOptions?.([]);
      return setOptions([]);
    }
    try {
      const data = await getOptions({
        catalog,
        params: request ?? {},
      });
      setRefOptions?.(data);
      return setOptions(data);
    } catch (error) {
      setRefOptions?.([]);
      return setOptions([]);
    }
  };

  useEffect(() => {
    (async () => {
      await searchOptions(params);
    })();
  }, [getOptions, params]);

  const filterInput = (event: any, input: any) => {
    if (filterField) {
      if (input?.length > 0) {
        let request: any = { ...params };
        request[filterField] = input;
        searchOptions(request);
      } else {
        searchOptions(params);
      }
    }
  };

  return (
    <AutoCompleteSelector<IOptionItem, any>
      name={name}
      fullWidth={fullWidth}
      selectorFullWidth={selectorFullWidth}
      {...props}
      label={label}
      options={options}
      onInputChange={filterField ? filterInput : () => {}}
      {...{
        getOptionLabel: (option) => option.label,
        getOptionValue: (option) => option.id,
      }}
    />
  );
};

export default PlatformCatalogSelector;
