import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';

import { ICartItem, ICommerceInfo } from '../../common/interfaces';

import CardContainer from './CardContainer';
import StyledDataGrid from '../commons/StyledDataGrid';


type CartInfoCardProps = {
  data: ICommerceInfo;
};

const columns: GridColDef<ICartItem>[] = [
  {
    field: 'description',
    sortable: false,
    width: 400,
  },
  {
    field: 'quantity',
    sortable: false,
    renderCell: ({ row }) => `${row.quantity} u.`,
  },
];

const CartInfoCard = ({ data }: CartInfoCardProps) => {
  const [translate] = useTranslation('global');

  if (!data?.bnplOperationId || !(data?.cartInfo ?? []).length) return null;
  
  return (
    <CardContainer
      title={translate('page.financialProductRequest.cartInfo.title')}
    >
      <StyledDataGrid
        hideColumnsHeaders
        columns={columns}
        rows={data.cartInfo ?? []}
        getRowId={(row) => row.description}
        hideFooter
        autoHeight
      />
    </CardContainer>
  );
};

export default CartInfoCard;
