import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import emptyPageImage from '../../assets/imgs/emptyPage.svg';

type MessageEmptyPageFirstActionProps = {
  onClick?: () => void;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
};

const MessageEmptyPageFirstAction = ({
  onClick,
  title,
  subtitle,
  buttonLabel,
}: MessageEmptyPageFirstActionProps) => {
  const [translate] = useTranslation('global');

  return (
    <>
      <Grid item container xs={12} justifyContent="center">
        <Grid item container justifyContent="center" direction="column">
          <img src={emptyPageImage} alt="" height="180px" />
          <Box m={3} />
          <Typography variant="h2" align="center">
            {title ?? translate('common.emptyState.title')}
          </Typography>
          <Box m={1} />
          <Typography align="center">
            {subtitle ?? translate('common.emptyState.subtitle')}
          </Typography>
          <Box m={2} />
        </Grid>
        {onClick && (
          <Grid item>
            <Button onClick={onClick} variant="contained" color="primary">
              {buttonLabel ?? translate('common.emptyState.buttonLabel')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MessageEmptyPageFirstAction;
