import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import qs from 'qs';
import { GridColumns, GridRowId } from '@mui/x-data-grid';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { RouteKey } from '../../../navigation/constants';
import usePermissions from '../../../hooks/usePermissions';
import Loading from '../../../components/commons/Loading';
import { searchInterestRateTable } from '../../../api/adminRates';
import useRoutePage from '../../../hooks/useRoutePage';
import BaseTablePage from '../../../components/commons/BaseTablePage';
import CustomDataGrid from '../../../components/commons/CustomDataGrid';
import FilterContainer from '../../../components/commons/FilterContainer';
import useFilterOptions from './hooks/useFilterOptions';
import useFiltersProps from '../../../hooks/useFiltersProps';
import {
  IRatesFiltersState,
  IFilterAppliedOption,
} from '../../../common/interfaces';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
}

export const RatesPage = () => {
  const { t } = useTranslation('global');
  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.RATES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
      },
    });
  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IRatesFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const {
    data,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: ['searchInterestRateTable', pageNumber, pageSize, filters],
    queryFn: async () =>
      (
        await searchInterestRateTable(undefined, {
          pageNumber,
          pageSize,
          filters,
        })
      ).data,
    keepPreviousData: false,
  });

  const location = useLocation();
  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const rows = useMemo(() => data?.result, [data]);
  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);
  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: t('page.rates.columnHeaders.name'),
        minWidth: 200,
        flex: 1,
        sortable: true,
      },
      {
        field: 'description',
        headerName: t('page.rates.columnHeaders.description'),
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'lastModifiedDate',
        headerName: t('page.rates.columnHeaders.date'),
        minWidth: 120,
        flex: 1,
        renderCell: ({ row }) =>
          row.lastModifiedDate
            ? dayjs(row.lastModifiedDate).format('DD/MM/YYYY')
            : '-',
      },
    ],
    [tableName]
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (id: GridRowId) => {
    navigateToRoute(RouteKey.VIEW_RATES, {
      itemId: id?.toString(),
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  };

  const { isLoading } = usePermissions();
  const filterOptionList = useFilterOptions();

  useEffect(() => {
    mergeRouteState({ filterAppliedList });
  }, []);

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <BaseTablePage
      isLoading={isFetching}
      error={error}
      routeKey={RouteKey.RATES}
      data={data}
      {...queryProps}
    >
      <FilterContainer
        title={t('page.rates.filter.title')}
        titleSx={{
          fontSize: '28px !important',
          fontWeight: 'normal',
        }}
        filterOptionList={filterOptionList ?? []}
        translationKeyPrefix="page.rates.filter."
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          onRowClick={({ id }) => handleView(id)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          getRowId={(r: any) => r.id}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};
