import * as yup from 'yup';
import type { IRoleData } from '../../api/adminUsersRoles/interfaces';

export const buildFormData = (data?: IRoleData) => ({
  roleName: data?.roleName ?? '',
  metadataRequiredKeys: data?.metadataRequiredKeys ?? [],
  description: data?.description ?? '',
  permissionIds: (data?.permissions ?? []).map((permission) => permission.id),
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) =>
  yup.object().shape({
    roleName: yup.string().required(translate('page.role.form.roleName.required')),
  });

export default {};
