import { CSSProperties } from 'react';

interface StyleProp {
  [name: string]: CSSProperties;
}

export const styles: StyleProp = {
  button: {
    textAlign: 'left',
    border: '1px solid #D8D8D8',
    borderRadius: '10px',
    background: 'transparent',
    padding: '25px 50px 25px 25px',
    marginTop: 25,
    cursor: 'pointer',
  },
};
