import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';

import AutoCompleteSelector from './AutoCompleteSelector';
import useTranslate from '../../hooks/useTranslate';
import { ITesteable } from '../../common/interfaces';

interface TagSelectorProps extends ITesteable {
  name: string;
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  mandatory?: boolean;
}

interface IOptionItem {
  id: number;
  label: string;
}

const TagSelector = ({
  label: labelProp,
  labelKey,
  name,
  fullWidth,
  mandatory = false,
  ...props
}: TagSelectorProps) => {
  const [translate] = useTranslation('global');

  const label = useTranslate(labelKey, labelProp);

  const onNewOption = useCallback((inputValue: string) => {
    if (!inputValue) return undefined;
    return () => ({
      value: inputValue,
      label: translate('common.addX', { key: inputValue }),
    });
  }, []);

  return (
    <AutoCompleteSelector<IOptionItem, number>
      name={name}
      fullWidth={fullWidth}
      {...props}
      label={label}
      freeSolo
      multiple
      onNewOption={onNewOption}
      separator=","
      {...{
        getOptionLabel: (option) =>
          isFunction(option) ? option().label : option,
        getOptionValue: (option) =>
          isFunction(option) ? option().value : option,
      }}
    />
  );
};

export default TagSelector;
