import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ICommerceBusinessSchemaCreateQuotaData,
  IFormRef,
  ITesteable,
} from '../../common/interfaces';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { paths, RouteKey } from '../../navigation/constants';
import {
  createCommerceBusinessSchemaQuota,
  getCommerceBusinessSchemas,
} from '../../api/adminBusinessEnvironment/service';
import BaseViewPage from '../../components/commons/BaseViewPage';
import CommerceBusinessSchemaQuotaForm from '../../components/CommerceBusinessSchemaQuotaForm/CommerceBusinessSchemaQuotaForm';
import { MenuItemType, Permissions } from '../../common/enums';

interface ICommerceBusinessSchemaQuotaAddPageProps extends ITesteable {}

const CommerceBusinessSchemaQuotaAddPage = ({
  dataTestId = 'ICommerceBusinessSchemaQuotaAddPage',
}: ICommerceBusinessSchemaQuotaAddPageProps) => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const formRef = useRef<IFormRef>();
  const { setToast, setMenuOverride } = useUI();
  const navigate = useNavigate();
  const params = useParams();

  const commerceId = parseInt(params.commerceId ?? '', 10);
  const businessSchemaId = parseInt(params.businessSchemaId ?? '', 10);
  const quotaId = parseInt(params.quotaId ?? '', 10);

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW], {
          commerceId,
          businessSchemaId,
        }),
      },
      items: ['commerceDetail', 'businessSchemas'].map((cardKey) => ({
        key: cardKey,
        text: translate(
          `page.businessSchemas.drawerSubItems.${cardKey}.breadcrumb`,
          translate(`page.businessSchemas.drawerSubItems.${cardKey}.title`)
        ),
        path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
          '*': cardKey,
          commerceId: commerceId?.toString(),
        }),
        type: MenuItemType.MENU,
        permissions:
          cardKey === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : cardKey === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, commerceId, businessSchemaId]);

  const { data: commerceBusinessSchemaData, ...queryProps } = useQuery({
    queryFn: async () =>
      (await getCommerceBusinessSchemas(commerceId!, businessSchemaId!)).data
        ?.result[0],
  });

  const goBack = () => {
    navigate(
      generatePath(paths[RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW], {
        commerceId,
        businessSchemaId,
      })
    );
  };

  const onSubmit = async (payload: ICommerceBusinessSchemaCreateQuotaData) => {
    try {
      setBusy(true);
      if (quotaId) {
        //await updateCommerce(quotaId, payload);
      } else {
        await createCommerceBusinessSchemaQuota(
          commerceBusinessSchemaData.id,
          payload
        );
      }

      setToast({
        message: translate(
          'page.businessSchemaQuotaAdd.saveToastSuccessMessage'
        ),
        key: `${dataTestId}_Success`,
      });
      goBack();
    } catch (error) {
      const message = getErrorMessage(
        error,
        translate('page.businessSchemaQuotaAdd.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey =
    quotaId !== undefined
      ? RouteKey.COMMERCES_EDIT
      : RouteKey.COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW;

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeTrail={[
        RouteKey.COMMERCES,
        RouteKey.COMMERCE_BUSINESS_SCHEMA,
        RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW,
      ]}
      routePathParams={{ commerceId, businessSchemaId }}
      routeKey={currentRouteKey}
      data={commerceBusinessSchemaData}
      breadcrumb={translate('page.businessSchemaQuotaAdd.breadcrumb')}
      title={translate('page.businessSchemaQuotaAdd.title')}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          labelKey: 'common.cancel',
          onClick: goBack,
          variant: 'outlined',
        },
        {
          dataTestId: `${dataTestId}_SubmitButton`,
          labelKey: 'common.save',
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <CommerceBusinessSchemaQuotaForm
        dataTestId={`${dataTestId}_Form`}
        formRef={formRef}
        onSubmit={onSubmit}
      />
    </BaseViewPage>
  );
};

export default CommerceBusinessSchemaQuotaAddPage;
