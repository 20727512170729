import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { IFilterOption } from '../../../common/interfaces';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();

  return useMemo<IFilterOption[]>(() => [], [translate, queryClient]);
};

export default useFilterOptions;
