import { useImperativeHandle, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  IFormProps,
  IOptionItem,
  ICodeMappingData,
  ICodeMappingFormData,
  ITesteable,
} from '../../common/interfaces';
import TitledSection from '../commons/TitledSection';
import { buildFormData, buildSchema } from './builders';
import FormFields from '../form/FormFields';

interface ICodeMappingFormProps
  extends IFormProps<ICodeMappingFormData>,
    ITesteable {
  itemId?: number;
  entityType?: string;
  data?: ICodeMappingData;
  metadataKeysOptionList?: Array<IOptionItem>;
}

const CodeMappingForm = ({
  itemId,
  data,
  entityType,
  dataTestId = 'CodeMappingForm',
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: ICodeMappingFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate }));

  const methods = useForm<ICodeMappingFormData>({
    defaultValues: buildFormData(data),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setSchema(buildSchema({ translate }));
  }, [translate]);

  const onSubmit = async (dataForm: ICodeMappingFormData) => {
    onSubmitProp?.(dataForm);
  };

  useImperativeHandle(
    formRef,
    () => ({
      ...methods,
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2}>
          <TitledSection
            variant="h2"
            title={`${translate(
              itemId
                ? 'page.codeMapping.actionEdit'
                : 'page.codeMapping.actionNew'
            )}${entityType ? ` - ${entityType}` : ''}`}
          >
            <Grid item sm={6} xs={12}>
              <FormFields
                dataTestId={dataTestId}
                translationPrefix="page.codeMapping.form"
                fields={[
                  {
                    name: 'entityType',
                    hidden: !!entityType,
                  },
                  {
                    name: 'externalService',
                  },
                  {
                    name: 'externalCode',
                  },
                  {
                    name: 'internalId',
                  },
                ]}
              />
            </Grid>
          </TitledSection>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CodeMappingForm;
