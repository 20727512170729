import { useMemo } from "react";
import { useQuery } from "react-query";
import { getRolesList } from "../../api/adminUsersRoles/service";
import ChipsGroup from "../../components/commons/ChipsGroup";
import { getTextMetrics } from "../../utils/browser";

interface RolesColumnProps {
  roles: {id: number}[];
  columnWidth?: number;
}

const RolesColumn = ({ columnWidth = 0, roles = [] }: RolesColumnProps) => {
  const {
    data,
  } = useQuery({
    queryKey: ['getRolesListData'],
    queryFn: async () =>
      (await getRolesList()).data,
    keepPreviousData: true,
  });

  const parsed = useMemo(() => {
    const allRoles = data?.result ?? [];

    // TODO: Move this logic to <ChipsGroup />

    return roles.reduce((ret: {
      chips: {key: string; label: string}[];
      chipsWidth: number;
      maxed: boolean;
    }, role, index) => {
      if (ret.maxed) return ret;
      const found = allRoles.find((find) => find.id === role.id);
      const label = found ? found.roleName : String(role.id);
      const moreCount = roles.length - index;
      const width = getTextMetrics(label)?.width ?? 0;
      const moreLabel = `+${moreCount}`;
      const moreLabelWidth = getTextMetrics(moreLabel)?.width ?? 0;

      if (ret.chipsWidth + width + (moreCount > 0 ? moreLabelWidth : 0) > columnWidth) {
        return {
          ...ret,
          maxed: true,
          chips: [
            ...ret.chips,
            {
              key: 'more',
              label: moreLabel,
            }
          ]
        }
      }

      return {
        ...ret,
        chipsWidth: ret.chipsWidth + width,
        chips: [
          ...ret.chips,
          {
            key: `key:${role.id}`,
            label,
          },
        ]
      };
    }, {
      chips: [],
      maxed: false,
      chipsWidth: 0,
    });
  }, [roles, data, columnWidth]);

  if (!parsed.chips.length || !data) return null;
  
  return (
    <ChipsGroup chips={parsed.chips} color="secondary" chipSx={{ m: 0.2 }}/>
  );
}

export default RolesColumn;
