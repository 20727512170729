import * as yup from 'yup';
import { ICommerceData, ICommerceFormData } from '../../common/interfaces';

export const buildFormData = ({
  additionalData = {},
  financialOfficesIds,
  headquarterId,
  bankAccounts = [],
  ...data
}: Partial<ICommerceData> = {}): Partial<ICommerceFormData> => ({
  tags: [],
  bankAccounts: bankAccounts.map(({ fundingEntityId, ...bankAccount }) => ({
    fundingEntityId: String(fundingEntityId),
    ...bankAccount,
  })),
  ...data,
  additionalDataEntries: Object.entries(additionalData).map(([key, value]) => ({
    key,
    value,
  })),
  headquarterId:
    headquarterId === undefined || headquarterId === data.id
      ? -1
      : headquarterId,
  financialOfficeId:
    (financialOfficesIds ?? []).length > 0
      ? String(financialOfficesIds?.[0])
      : undefined,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    name: yup.string().required(translate('common.required')),
    address: yup.object().shape({
      street: yup.string().required(translate('common.required')),
      cityId: yup.mixed().required(translate('common.required')),
      stateId: yup.number().required(translate('common.required')),
      countryId: yup.number().required(translate('common.required')),
      zipCodeId: yup.mixed().required(translate('common.required')),
    }),
    commerceTaxId: yup.string().required(translate('common.required')),
    category: yup.string().required(translate('common.required')),
    financialOfficeId: yup.number().required(translate('common.required')),
    bankAccounts: yup.array().of(
      yup.object().shape(
        {
          accountNumber: yup.string().required(translate('common.required')),
          accountType: yup.string().required(translate('common.required')),
          fundingEntityId: yup.number().required(translate('common.required')),
          bankId: yup.number().required(translate('common.required')),
          identificationType: yup.string().when('identificationNumber', {
            is: (identificationNumber: string) => identificationNumber,
            then: yup.string().required(translate('common.required')),
          }),
          identificationNumber: yup.string().when('identificationType', {
            is: (identificationType: string) => identificationType,
            then: yup.string().required(translate('common.required')),
          }),
        },
        [
          ['identificationType', 'identificationNumber'],
          ['identificationNumber', 'identificationType'],
        ]
      )
    ),
    additionalDataEntries: yup.array().of(
      yup.object().shape({
        key: yup
          .string()
          .required(translate('common.required'))
          .matches(/^(\w+)$/, translate('common.invalid')),
        value: yup.string().required(translate('common.required')),
      })
    ),
  };

  return yup.object().shape(shape);
};

export default {};
