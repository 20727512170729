import * as yup from 'yup';

export const buildFormData = ({ interestRates }: any = {}): any => ({
  interestRates,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    interestRates: yup
      .array()
      .of(
        yup.object({
          fromTerm: yup
            .string()
            .required(translate('common.required'))
            .matches(/^[1-9]+\d*$/, translate('common.min')),
          toTerm: yup
            .string()
            .required(translate('common.required'))
            .matches(/^[1-9]+\d*$/, translate('common.min')),
          rate: yup
            .string()
            .required(translate('common.required'))
            .matches(/^[1-9]+\d*$/, translate('common.min')),
        })
      )
      .min(1, translate('common.required')),
  };
  return yup.object().shape(shape);
};

export default {};
