import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseForm from '../commons/BaseForm';
import usePermissions from '../../hooks/usePermissions';
import {
  IFormProps,
  IFinancialOfficeDivisionDataUpdate,
  ITesteable,
} from '../../common/interfaces';

import { buildFormData, buildSchema } from './builders';
import Loading from '../commons/Loading';
import { FormFieldType } from '../form/FormField';
import FormFields from '../form/FormFields';
import TitledSection from '../commons/TitledSection';

interface IFinancialOfficeDivisionFormProps
  extends IFormProps<IFinancialOfficeDivisionDataUpdate>,
    ITesteable {
  isUpdate: boolean;
  data?: IFinancialOfficeDivisionDataUpdate;
}

export const FinancialOfficeDivisionForm = ({
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
  isUpdate,
  dataTestId = 'FinancialOfficeDivisionForm',
}: IFinancialOfficeDivisionFormProps) => {
  const { t } = useTranslation('global');
  const [schema, setSchema] = useState(buildSchema({ translate: t }));
  const formData = useMemo(() => buildFormData(data ?? {}), [data]);
  const methods = useForm<IFinancialOfficeDivisionDataUpdate>({
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });
  const { isLoading } = usePermissions();

  return isLoading ? (
    <Loading />
  ) : (
    <BaseForm<IFinancialOfficeDivisionDataUpdate>
      //dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmitProp}
      onError={onError}
      formRef={formRef}
      formData={formData}
    >
      <TitledSection
        helperTextKey="page.financialOfficeDivisionEdit.helperText"
        style={{ paddingTop: '0px' }}
      >
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeDivisionEdit.form"
          fields={[
            {
              name: 'name',
            },
            {
              name: 'description',
            },
          ]}
          childrenProps={{
            sx: { marginTop: '20px' },
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeDivisionEdit.form"
          fields={[
            {
              name: 'active',
              mandatory: false,
              disabled: !isUpdate,
              type: FormFieldType.CHECKBOX,
              childrenProps: {
                style: { marginTop: '15px' },
              },
            },
          ]}
        />
      </TitledSection>
    </BaseForm>
  );
};
