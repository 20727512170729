import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';

type CheckBoxControlProps = Omit<IFormField, 'type'> & {
  label?: string;
  labelKey?: string;
  defaultValue?: string;
  variant?: string;
  helperText?: any;
  translationPath?: string;
  mask?: string;
  dataTestId?: string;
  disabled?: boolean;
};

const CheckBoxControl = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  mandatory = false,
  disabled = false,
  helperText,
  dataTestId,
  mask,
  ...props
}: CheckBoxControlProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const label = useTranslate(labelKey, labelProp);

  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <FormGroup>
            <FormControlLabel
              label={mandatory ? `${label}*` : label}
              disabled={disabled}
              control={
                <Checkbox
                  data-testid={dataTestId}
                  onChange={(event) => {
                    field.onChange(!!event.target.checked);
                  }}
                  indeterminate={![true, false].includes(field.value)}
                  checked={field.value ?? false}
                  {...props}
                />
              }
            />
          </FormGroup>
          <FormHelperText>{error?.message ?? helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default CheckBoxControl;
