import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'publicId',
        attribute: 'publicIds',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .matches(/^[0-9]+$/, {
            excludeEmptyString: true,
            message: translate(
              'page.financialProductRequest.inputPublicIdInvalid'
            ),
          })
          .max(
            15,
            translate('page.financialProductRequest.inputPublicIdMaxLength')
          )
          .required(
            translate('page.financialProductRequest.inputPublicIdRequired')
          ),
      },
      {
        name: 'customerCode',
        attribute: 'customerCodes',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(
            translate('page.financialProductRequest.inputCustomerCodeRequired')
          ),
      },
      {
        name: 'sellerName',
        attribute: 'sellerName',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(
            translate('page.financialProductRequest.inputSellerNameRequired')
          ),
      },
      {
        name: 'customerTaxId',
        attribute: 'customerTaxId',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(
            translate('page.financialProductRequest.inputCustomerTaxIdRequired')
          ),
      },
      {
        name: 'customerFullName',
        attribute: 'customerFullName',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(
            translate(
              'page.financialProductRequest.inputCustomerFullNameRequired'
            )
          ),
      },
      {
        name: 'originatorId',
        attribute: 'originatorIds',
        value: '',
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.ORIGINATORS,
        validation: yup
          .string()
          .required(
            translate('page.financialProductRequest.inputOriginatorIdRequired')
          ),
      },
      {
        name: 'businessSchemaId',
        attribute: 'businessSchemaId',
        value: '',
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.BUSINESS_SCHEMAS,
        validation: yup
          .string()
          .required(
            translate(
              'page.financialProductRequest.inputBusinessSchemaIdRequired'
            )
          ),
      },
      {
        name: 'financialProductRequestStatus',
        attribute: 'status',
        value: [],
        multiple: true,
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.STATUSES,
        validation: yup
          .array()
          .min(
            1,
            translate('page.financialProductRequest.inputStatusRequired')
          ),
      },
      {
        name: 'financialOfficesIds',
        attribute: 'financialOfficesIds',
        value: [],
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.FINANCIAL_OFFICES,
        multiple: true,
        validation: yup
          .array()
          .min(
            1,
            translate(
              'page.financialProductRequest.inputFinancialOfficeIdsRequired'
            )
          ),
      },
      {
        name: 'commerceIds',
        attribute: 'commerceIds',
        value: [],
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.COMMERCES,
        multiple: true,
        validation: yup
          .array()
          .min(
            1,
            translate('page.financialProductRequest.inputCommerceIdsRequired')
          ),
      },
      {
        name: 'divisionIds',
        attribute: 'divisionIds',
        value: [],
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.DIVISIONS,
        multiple: true,
        validation: yup
          .array()
          .min(
            1,
            translate('page.financialProductRequest.inputDivisionIdsRequired')
          ),
      },
      {
        name: 'startDateFrom',
        attribute: 'startDateFrom',
        value: '',
        type: FilterOptionTypes.DATE_FROM,
        validation: yup
          .date()
          .required(
            translate('page.financialProductRequest.inputStartDateFromRequired')
          )
          .typeError(
            translate('page.financialProductRequest.inputStartDateFromRequired')
          ),
      },
      {
        name: 'startDateTo',
        attribute: 'startDateTo',
        value: '',
        type: FilterOptionTypes.DATE_TO,
        validation: yup
          .date()
          .required(
            translate('page.financialProductRequest.inputStartDateToRequired')
          )
          .typeError(
            translate('page.financialProductRequest.inputStartDateToRequired')
          ),
      },
    ],
    [translate]
  );
};

export default useFilterOptions;
