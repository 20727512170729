import { isFunction } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from 'react-query';
import {
  getBNPLProvidersDataSource,
  getDivisionsDataSource,
  getFinancialOfficesDataSource,
  getFundingEntitiesDataSource,
  getOriginatorsDataSource,
  getReportDefinitionsDatasource,
} from '../api/adminDataSources/service';
import { MetadataKey } from '../common/enums';
import { getUserPermissions } from '../api/adminUsersRoles/service';
import { searchCommerces } from '../api/adminBusinessEnvironment/service';
import { getUserAssignableRolesList } from '../api/adminUsersRoles/service';

type IMetadataKeyParams = {
  [key in MetadataKey]: any;
};

interface UseMetadataKeysUI {
  metadataKeys: MetadataKey[];
}

const useMetadataKeysUI = ({
  metadataKeys,
}: UseMetadataKeysUI): {
  key: MetadataKey;
  type?: string;
  props?: {
    queryOptions?: QueryOptions<any>;
  };
}[] => {
  const [translate] = useTranslation('global');

  const metadataKeysParams = useMemo<IMetadataKeyParams>(
    () => ({
      [MetadataKey.COMMERCE_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['searchCommerces, getUserPermissions'],
            queryFn: async () => {
              let allCommerces = (await searchCommerces({})).data.result;
              let userCommerces = (await getUserPermissions()).data?.metadata
                .COMMERCE_ID_LIST;
              let selectableCommerces;

              if (userCommerces?.length > 0) {
                selectableCommerces = allCommerces.filter(
                  (commerce: any) =>
                    userCommerces.includes(commerce.headquarterId) ||
                    userCommerces.includes(commerce.id)
                );
              } else {
                selectableCommerces = allCommerces;
              }

              return selectableCommerces.map((row: any) => ({
                key: row.id,
                value: row.name,
              }));
            },
          },
        },
      },
      [MetadataKey.DIVISION_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getDivisionsDataSource'],
            queryFn: async () =>
              (await getDivisionsDataSource()).data.map((row) => ({
                key: parseInt(row.key, 10),
                value: row.value,
              })),
          },
        },
      },
      [MetadataKey.FINANCIAL_OFFICE_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getFinancialOfficesDataSource'],
            queryFn: async () =>
              (await getFinancialOfficesDataSource()).data.map((row) => ({
                key: parseInt(row.key, 10),
                value: row.value,
              })),
          },
        },
      },
      [MetadataKey.ORIGINATOR_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getOriginatorsDataSource'],
            queryFn: async () =>
              (await getOriginatorsDataSource()).data.map((row) => ({
                key: parseInt(row.key, 10),
                value: row.value,
              })),
          },
        },
      },
      [MetadataKey.CUSTOMER_CODE_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          freeSolo: true,
          separator: ',',
          getOptionLabel: (option: string | Function) =>
            isFunction(option) ? option().label : option,
          getOptionValue: (option: string | Function) =>
            isFunction(option) ? option().value : option,
          onNewOption: (inputValue: string) => {
            if (!inputValue) return null;
            return () => ({
              value: inputValue,
              label: translate('common.addX', { key: inputValue }),
            });
          },
        },
      },
      [MetadataKey.FUNDING_ENTITY_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getFundingEntitiesDataSource'],
            queryFn: async () =>
              (await getFundingEntitiesDataSource()).data.map((row) => ({
                key: parseInt(row.key, 10),
                value: row.value,
              })),
          },
        },
      },
      [MetadataKey.FINANCIAL_PRODUCT_REQUEST_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          freeSolo: true,
          separator: ',',
          getOptionLabel: (option: string | Function) =>
            isFunction(option) ? option().label : option,
          getOptionValue: (option: string | Function) =>
            isFunction(option) ? option().value : option,
          onNewOption: (inputValue: string) => {
            if (!inputValue) return null;
            return () => ({
              value: inputValue,
              label: translate('common.addX', { key: inputValue }),
            });
          },
        },
      },
      [MetadataKey.BNPL_PROVIDER_ID]: {
        type: 'single-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getBNPLProvidersDataSource'],
            queryFn: async () =>
              (await getBNPLProvidersDataSource()).data.map((row) => ({
                key: row.key,
                value: row.value,
              })),
          },
        },
      },
      [MetadataKey.UNRESTRICTED_RESOURCE_ACCESS]: {
        type: 'checkbox',
        props: {},
      },
      [MetadataKey.BPM_CANDIDATE_GROUPS]: {
        type: 'multiple-autocomplete',
        props: {
          freeSolo: true,
          separator: ',',
          getOptionLabel: (option: string | Function) =>
            isFunction(option) ? option().label : option,
          getOptionValue: (option: string | Function) =>
            isFunction(option) ? option().value : option,
          onNewOption: (inputValue: string) => {
            if (!inputValue) return null;
            return () => ({
              value: inputValue,
              label: translate('common.addX', { key: inputValue }),
            });
          },
        },
      },
      [MetadataKey.REPORT_TRANSACTION_ID_LIST]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getReportDefinitionsDataSource'],
            queryFn: async () =>
              (await getReportDefinitionsDatasource()).data.map((row) => ({
                key: parseInt(row.key, 10),
                value: translate('datasources.reportNames.' + row.value),
              })),
          },
        },
      },
      [MetadataKey.ROLES_ALLOWED_TO_ASSIGN]: {
        type: 'multiple-autocomplete',
        props: {
          queryOptions: {
            queryKey: ['getRolesDataSource'],
            queryFn: async () =>
              (await getUserAssignableRolesList()).map((row) => ({
                key: row.id,
                value: row.roleName,
              })),
          },
        },
      },
    }),
    [translate]
  );
  return useMemo(
    () =>
      metadataKeys.map((key) => ({
        key,
        ...metadataKeysParams[key],
      })),
    [metadataKeys]
  );
};

export default useMetadataKeysUI;
