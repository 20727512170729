import { Fragment, useCallback, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ILoanProduct, ICreditCardProduct } from '../../common/interfaces';
import {
  CreditCardProductStatus,
  FinancialProductType,
  LoanProductStatus,
} from '../../common/enums';
import DataFillIterator from '../commons/DataFillIterator';
import StatusBadge from '../commons/StatusBadge';
import StyledHR from '../commons/StyledHR';
import CardContainer from './CardContainer';
import { formatMoney } from '../../utils/formatterUtil';


/* 
    OTROS ESTADOS

    <Box sx={{ background: '#3988FF', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> En proceso de onboarding</Typography>
    <Box sx={{ background: '#F5A623', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> Pendiente de aprobación</Typography>
    <Box sx={{ background: '#F5A623', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> Lista para el alta</Typography>
    <Box sx={{ background: '#F5A623', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> En proceso de alta</Typography>
    <Box sx={{ background: '#02C66A', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> Finalizada</Typography>
    <Box sx={{ background: '#FB1531', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> Expirada</Typography>
    <Box sx={{ background: '#FB1531', borderRadius: '50%', width: '10px', height: '10px', marginRight: '5px' }} /><Typography> Rechazada</Typography>
  */

type SelectedFinancialProductsCardProps = {
  selectedFinancialProductList:
    | Array<ILoanProduct | ICreditCardProduct>
    | undefined;
  requestContextData: any
};

const statusMapping = {
  // Product Statuses
  [LoanProductStatus.ACTIVE]: {
    color: 'success',
  },
  [LoanProductStatus.CREATED]: {
    color: 'warning',
  },
  [LoanProductStatus.APPROVED]: {
    color: 'warning',
  },
  [LoanProductStatus.REJECTED]: {
    color: 'error',
  },
  [LoanProductStatus.EXPIRED]: {
    color: 'error',
  },
  [LoanProductStatus.PRE_APPROVED]: {
    color: 'warning',
  },
  [LoanProductStatus.READY_FOR_CREATION]: {
    color: 'warning',
  },
  [LoanProductStatus.RETURNED]: {
    color: 'error',
  },
  [LoanProductStatus.DISBURSED]: {},
  // Credit Card Statuses
  [CreditCardProductStatus.ACTIVE]: {
    color: 'success',
  },
  [CreditCardProductStatus.APPROVED]: {
    color: 'warning',
  },
  [CreditCardProductStatus.REJECTED]: {
    color: 'error',
  },
  [CreditCardProductStatus.EXPIRED]: {
    color: 'error',
  },
  [CreditCardProductStatus.CC_CREATION]: {
    color: 'warning',
  },
  [CreditCardProductStatus.READY_FOR_SHIPPING]: {
    color: 'warning',
  },
  [CreditCardProductStatus.SHIPPING]: {
    color: 'warning',
  },
};

const SelectedFinancialProductsCard = ({
  selectedFinancialProductList = [],
  requestContextData
}: SelectedFinancialProductsCardProps) => {
  const [translate] = useTranslation('global');

  const loanLabels: Array<string> = [
    'financialLine',
    'loanMaximumAmount',
    'capitalAmount',
    'tna',
    'numberOfInstallments'
  ];

  // @ts-ignore
  if(selectedFinancialProductList[0]?.totalAmountDisbursed){
    loanLabels.splice(3, 0, "totalAmountDisbursed")
  }

  // @ts-ignore
  if(selectedFinancialProductList[0]?.externalLoanId){
    loanLabels.push("externalLoanId")
  }
  
  useEffect(() => {
      if(requestContextData.loanMaximumAmount && selectedFinancialProductList[0]?.financialProductType === FinancialProductType.LOAN ){
        // @ts-ignore
        selectedFinancialProductList[0].loanMaximumAmount = requestContextData.loanMaximumAmount
      }
  }, []);


  const valueMapper = useCallback(
    async (key: any, value: any) => {
      switch (key) {
        case 'capitalAmount':
          value = formatMoney(value)
          break;
          case 'totalAmountDisbursed':
            value = formatMoney(value)
            break;
          case 'loanMaximumAmount':
            value = formatMoney(value)
            break;  
        default:
          break;
      }
      return value;
    },
    []
  );


  const creditCardLabels: Array<string> = ['financialLine', 'amount'];

  const styles = {
    box: {
      background: '#F5A623',
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      marginRight: '5px',
    },
  };

  if (!selectedFinancialProductList.length) {
    return null;
  }

  return (
    <CardContainer
      title={translate(
        'page.financialProductRequest.selectedFinancialProducts.title'
      )}
    >
      {selectedFinancialProductList?.map((product, index) => (
        <Fragment key={product.id}>
          {index > 0 && <StyledHR sx={{ mt: 2 }} />}
          {product.financialProductType === FinancialProductType.LOAN ? (
            <>
              <Grid item container xs={12} pt={2} pb={2}>
                <Grid item xs={12} mb={1}>
                  <Typography variant="h4" component="h3">
                    {translate(
                      'page.financialProductRequest.products.LOAN.title'
                    )}
                  </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center">
                  <StatusBadge
                    color="grey"
                    {...statusMapping[product.financialProductStatus]}
                    label={translate(
                      `page.financialProductRequest.products.LOAN.status.${product.financialProductStatus}`
                    )}
                    fontSize="medium"
                  />
                </Grid>
              </Grid>
              <DataFillIterator
                object={product}
                labelsToShow={loanLabels}
                translationKeyPrefix="page.financialProductRequest.products.LOAN."
                gridItemProps={{
                  lg: 4,
                }}
                valueMapper={valueMapper}
              />
            </>
          ) : (
            product.financialProductType ===
              FinancialProductType.CREDIT_CARD && (
              <>
                <Grid item container xs={12} pt={2} pb={2}>
                  <Grid item xs={12} mb={1}>
                    <Typography variant="h4" component="h3">
                      {translate(
                        'page.financialProductRequest.products.CREDIT_CARD.title'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item container direction="row" alignItems="center">
                    <StatusBadge
                      color="grey"
                      {...statusMapping[product.financialProductStatus]}
                      label={translate(
                        `page.financialProductRequest.products.CREDIT_CARD.status.${product.financialProductStatus}`
                      )}
                      fontSize="medium"
                    />
                  </Grid>
                </Grid>
                <DataFillIterator
                  object={product}
                  labelsToShow={creditCardLabels}
                  translationKeyPrefix="page.financialProductRequest.products.CREDIT_CARD."
                  gridItemProps={{
                    lg: 4,
                  }}
                />
              </>
            )
          )}
        </Fragment>
      ))}
    </CardContainer>
  );
};

export default SelectedFinancialProductsCard;
