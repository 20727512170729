import axios from 'axios';
import qs from 'qs';
import keycloak from '../keycloak';
import { getTenantName } from '../utils/core';

export const instance = axios.create({
  timeout: 50000,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: 'repeat',
      indices: false,
      skipNulls: true,
    }),
});

instance.interceptors.request.use(
  async (config) => {
    if (keycloak.token) {
      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      config.headers!['X-TENANT'] = getTenantName();
      return config;
    }
    return config;
  },
  (error) => {
    throw error;
  }
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    originalConfig.retry = originalConfig.retry ?? 0;

    if (
      err.response &&
      err.response.status === 401 &&
      originalConfig.retry === 0
    ) {
      originalConfig.retry += 1;
      await keycloak.updateToken(5);
      return instance(originalConfig);
    }
    throw err;
  }
);

const fetch = (config: any) =>
  instance
    .request(config)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });

export default fetch;
