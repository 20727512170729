import { useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ITesteable } from '../../../common/interfaces';
import { IRichPermissionData } from '../../../hooks/useParsePermissions';
import ChipsGroup from '../../../components/commons/ChipsGroup';

interface AppIdSectionProps extends ITesteable {
  app: {
    key: string;
    label: string;
  };
  permissions: IRichPermissionData[];
}

const AppIdSection = ({
  dataTestId = 'AppIdSection',
  app,
  permissions = [],
}: AppIdSectionProps) => {
  const chips = useMemo(
    () =>
      permissions.map((permission) => ({
        key: permission.key,
        label: permission.label,
      })),
    [permissions]
  );

  return (
    <Grid item key={app.key}>
      <Typography
        variant="h5"
        fontWeight="700"
        data-testid={`${dataTestId}_app_${app.key}`}
      >
        {app.label}
      </Typography>
      <Box pt={2}>
        <ChipsGroup chips={chips} dataTestId={`${dataTestId}_permission`} />
      </Box>
    </Grid>
  );
};

export default AppIdSection;
