import { Autocomplete, Grid, TextField, Typography, styled } from "@mui/material";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "vulpino";
import { paths, RouteKey } from "../../navigation/constants";
import { getBatchUpdates, postBatchsUpdateNotification } from "../../api/adminBind/service";
import { IoanBatchUpdateNotificationRequest } from "../../common/interfaces";
import { ToastType } from "../../components/commons/Toast";
import { useUI } from "../../contexts/UIContext";
import Loading from "../../components/commons/Loading";

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
  textDecoration: 'none',
  marginRight:"10px"
}));

export const PaymentBatchConfirmPage = () => {
const [translate] = useTranslation('global');
const navigate = useNavigate();
const parameters = useParams();
const { batchId } = parameters;

const [loading, setLoading] = useState(false);

const { setToast } = useUI();

const TRANSFER_ASSIGNORS_ACCOUNT = 'Transferencia por Compra de Cartera';
const CREDIT_ASSIGNORS_CURRENT_ACCOUNT = 'Crédito por Compra de Cartera con Crédito en Cta. Cte.';
const businessOptions = [TRANSFER_ASSIGNORS_ACCOUNT, CREDIT_ASSIGNORS_CURRENT_ACCOUNT];

const goBack = () => {
  const urlViewRequest = generatePath(
    paths[RouteKey.PAYMENT_BATCH],
    {batchId}
  );
  navigate(urlViewRequest);
};

const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    setSelectedMethod(newValue);
};

const filters = {
  batchUpdateStatus: '' 
};

const { data: queryData} = useQuery({
  queryKey: ['batchUpdates'],
  queryFn: async () =>
    (await getBatchUpdates({filters}))
      .data,
  keepPreviousData: true,
  });

const rows = useMemo(() => queryData, [queryData]);
const loanUpdate = rows?.find((elem) => elem.batchId.toString() === batchId?.toString());

const onSubmit = async () => {
  setLoading(true);
  const disbursementType = selectedMethod === 'Transferencia por Compra de Cartera' ? 'TRANSFER_ASSIGNORS_ACCOUNT' : 'CREDIT_ASSIGNORS_CURRENT_ACCOUNT';

  const requestData: IoanBatchUpdateNotificationRequest = {
    assignorBatchId: Number(loanUpdate?.assignorBatchId!),
    assignor: loanUpdate?.assignor!,
    paymentType: disbursementType,
    paymentBatchId: Number(batchId!)
  };

  try {
  const response = (await postBatchsUpdateNotification(
      requestData
    )).data;

  if (response.status === 'COMPLETED' || response.status === 'STARTING'){
    setToast({
      key: `success`,
      message: translate('page.paymentBatch.PRE_APPROVED_LOAN_UPDATE_SUCCESS_TOAST'),
    });
  } else {
    setToast({
      type: ToastType.ERROR,
      message: translate('page.paymentBatch.PRE_APPROVED_LOAN_UPDATE_ERROR_TOAST'),
    });
  }
  } catch (error) {
    setToast({
      type: ToastType.ERROR,
      message: translate('page.paymentBatch.PRE_APPROVED_LOAN_UPDATE_ERROR_TOAST'),
    });
  } finally {
    setLoading(false);
    navigate(generatePath(paths[RouteKey.PAYMENT_BATCH]));
  }
};

return (
<div>
  {loading && <Loading />} 
  {!loading && 
    <>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <StyledLink
      to={
        (generatePath(paths[RouteKey.PAYMENT_BATCH]))
      }
    >
      Bandeja de control /
    </StyledLink>
    <StyledLink
      to={
        generatePath(
          paths[RouteKey.PAYMENT_BATCH])
      }
    >
      Lote {loanUpdate?.assignorBatchId} /
    </StyledLink>
    <Typography color="text.primary" fontWeight="bold">
      Confirmar
    </Typography>
  </div>
    <h3>Confirmar Pagos</h3>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={businessOptions}
          value={selectedMethod} 
          onChange={handleChange} 
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccionar método de desembolso"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
    <Grid container item xs={6} spacing={2} marginTop={4}>
        <Grid item xs={6}>
            <Button onClick={goBack} fullWidth variant="secondary">
            {translate('page.operativeControl.cancel')}
            </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onSubmit} fullWidth variant="primary">
          {translate('page.operativeControl.confirm')}
          </Button>
        </Grid>
    </Grid>
    </>
  }
</div>
);
}