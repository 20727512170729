import React, { Fragment, useCallback } from 'react';
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Divider } from 'vulpino';

import { MenuItemType } from '../common/enums';

import { RouteKey, paths } from './constants';
import type { IMenuSectionListItem, IMenuSubListItem } from './constants';
import usePermissions from '../hooks/usePermissions';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(5),
}));

interface MenuSectionListItemProps extends ListItemButtonProps {
  menuItems: IMenuSectionListItem[];
  onHandleItemClick: (x: boolean) => void;
}

const MenuSectionListItem = ({
  menuItems,
  onHandleItemClick,
  ...props
}: MenuSectionListItemProps) => {
  const location = useLocation();
  const [translate] = useTranslation('global');
  const { checkPermissions, username } = usePermissions();
  const navigate = useNavigate();

  //const isNotAllowed = useRouteValidation();
  const isNotAllowed = false;

  const [openSubMenu, setOpenSubMenu] = React.useState(false);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleItemClick = () => {
    onHandleItemClick(false);
  };

  const getMenuConf = useCallback(
    (menuItem: IMenuSectionListItem | IMenuSubListItem) => {
      if (menuItem.type === MenuItemType.DIVIDER) {
        return {};
      }
      const disabled = false; // debera ser el resultado de evaluar permisos isNotAllowed()

      const pathname = (() => {
        if (menuItem.path) return menuItem.path;
        if (menuItem.routeKey) return paths[menuItem.routeKey];
        return undefined;
      })();

      const text = (() => {
        if (menuItem.text) return translate(menuItem.text);
        if (menuItem.routeKey)
          return translate(`route.${menuItem.routeKey}.label`);
        return pathname;
      })();

      const buttonProps = pathname
        ? {
            component: Link,
            to: { pathname },
          }
        : {
            onClick: () => {
              if (menuItem.key === 'logout')
                navigate(paths[RouteKey.LOGOUT], { replace: true });
              else menuItem.onClick();
            },
          };

      return {
        text,
        buttonProps: {
          selected: pathname === location.pathname,
          disabled,
          ...buttonProps,
        },
      };
    },
    [translate, location]
  );

  return (
    <>
      {menuItems.map((menuItem: IMenuSectionListItem) => {
        if (menuItem.type === MenuItemType.DIVIDER) return null;
        const { text, buttonProps } = getMenuConf(menuItem);

        return (
          <Fragment key={`menu_${text}`}>
            {menuItem.type === MenuItemType.MENU &&
              menuItem?.permissions &&
              (menuItem?.key === 'logout' ||
                checkPermissions(menuItem.permissions)) && (
                <>
                  {menuItem?.key === 'logout' && (
                    <>
                      <Grid container textAlign="center" paddingX={2}>
                        <ListItemText secondary={username} />
                      </Grid>
                      <Divider variant="middle" style={{ marginBottom: 10 }} />
                    </>
                  )}
                  <ListItemButton
                    onClick={handleItemClick}
                    {...props}
                    {...buttonProps}
                  >
                    <StyledListItemIcon>{menuItem.icon}</StyledListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </>
              )}
            {menuItem.type === MenuItemType.GROUP &&
              menuItem?.items?.some((item: IMenuSubListItem) =>
                checkPermissions(item.permissions)
              ) && (
                <Fragment key={`group_${text}`}>
                  <ListItemButton onClick={handleClick} {...props}>
                    {menuItem?.icon && (
                      <StyledListItemIcon>{menuItem.icon}</StyledListItemIcon>
                    )}
                    <ListItemText>
                      {translate(menuItem.text ?? '')}
                    </ListItemText>
                    {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  {menuItem.items !== undefined && (
                    <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {menuItem.items.map((subMenuItem: IMenuSubListItem) => {
                          const {
                            text: subMenuText,
                            buttonProps: subMenuButtonProps,
                          } = getMenuConf(subMenuItem);
                          return (
                            checkPermissions(subMenuItem.permissions) && (
                              <ListItemButton
                                sx={{ pl: 9 }}
                                key={'submenu_' + subMenuText}
                                onClick={handleItemClick}
                                {...subMenuButtonProps}
                              >
                                <ListItemText primary={subMenuText} />
                              </ListItemButton>
                            )
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </Fragment>
              )}
          </Fragment>
        );
      })}
    </>
  );
};

export default MenuSectionListItem;
