import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { FilterOptionTypes } from '../../../common/enums';
import { IFilterAppliedOption, IFilterOption, IOptionItem } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();
  return useMemo<IFilterAppliedOption[]>(
    () => [
      {
        name: 'quota_in_force_at_moment',
        attribute: 'quotaDefinitionType',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.QUOTA_TYPE,
      },
      // {
      //   name: 'validFrom',
      //   attribute: 'validFrom',
      //   value: '',
      //   validation: yup.string().required(translate('common.required')),
      //   type: FilterOptionTypes.DATE_FROM,
      // },
      // {
      // {
      //   name: 'exceptional',
      //   attribute: 'exceptional',
      //   value: '',
      //   validation: yup.string().required(translate('common.required')),
      //   type: FilterOptionTypes.DATASOURCE,
      //   collection: DataSourceCollection.GENERIC_BOOLEAN,
      // },
    ],
    [translate, queryClient]
  );
};

export default useFilterOptions;
