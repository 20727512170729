import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { styled, Box } from '@mui/system';
import { Typography } from '@mui/material';
import {
  IIndexedTrace,
  ITrace,
} from '../../../api/adminFinancialProductRequest/interfaces';
import useRoutePage from '../../../hooks/useRoutePage';
import { RouteKey } from '../../../navigation/constants';
import {
  IFilterAppliedOption,
  IFilterDatasourceOption,
} from '../../../common/interfaces';
import { getProcessInstanceTraces } from '../../../api/adminFinancialProductRequest/service';
import CustomDataGrid from '../../commons/CustomDataGrid';
import StatusBadge from '../../commons/StatusBadge';
import { Status } from '../../commons/StatusBadge';

const StyledPre = styled('pre')(() => ({
  margin: 0,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));

type TracesCardProps = {
  processInstanceId: string;
};

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

const TracesCard = ({ processInstanceId }: TracesCardProps) => {
  const [translate] = useTranslation('global');

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
    initialState: {
      pageNumber: 1,
      pageSize: 100,
      filterAppliedList: [],
      filterDatasourceOptionList: [],
    },
  });

  const { pageNumber, pageSize } = routeState;

  const queryResult = useQuery({
    queryKey: [`traces:${processInstanceId}`],
    queryFn: async () => {
      let searchResult = (
        await getProcessInstanceTraces({ trackingId: processInstanceId })
      ).data;
      searchResult.result = searchResult.result.filter((trace) =>
        //@ts-ignore
        typeof trace.output.data === 'string'
          ? //@ts-ignore
            !trace.output.data.includes('OFFLINE') &&
            //@ts-ignore
            !trace.output.data.includes('Not currently in business hours')
          : true
      );
      return searchResult;
    },
    keepPreviousData: true,
  });

  const { data: queryData } = queryResult;

  const [rows, setRows] = useState<IIndexedTrace[] | undefined>([]);

  // Computed
  const computed = useMemo(() => queryData?.result, [queryData]);

  const outputHasError = (stringParameter: string) => {
    const lowercasedStr = stringParameter.toLowerCase();
    let hasError =
      lowercasedStr.includes('exception') || lowercasedStr.includes('error');
    return hasError;
  };

  useEffect(() => {
    if (computed) {
      let size = computed?.length ? computed.length : 0;
      for (let i = 0; i < size; i += 1) {
        if (outputHasError(JSON.stringify(computed[i].output))) {
          computed[i].hasError = true;
        }
      }
      setRows(computed);
    }
  }, [computed]);

  const columns: GridColDef<IIndexedTrace>[] = useMemo(
    () => [
      {
        field: 'name',
        maxWidth: 550,
        flex: 350,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.traces.columns.trace'
        ),
        renderCell: ({ row }) => (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>
              {' '}
              {`${row.index + 1}. `}
              {translate(
                `page.financialProductRequest.traces.values.${row.name}`
              )}
            </Typography>
            {row.hasError && <StatusBadge status={Status.FAILURE} />}
          </Box>
        ),
      },
    ],
    [translate]
  );

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleOnPageChange = (newPage: number) =>
    mergeRouteState({
      pageNumber: newPage,
    });

  const handleOnPageSizeChange = (newPageSize: number) =>
    mergeRouteState({
      pageSize: newPageSize,
    });

  return (
    <Box display="column" width="100%">
      <Typography fontSize="26px" fontWeight="500" marginBottom="10px">
        {translate('page.financialProductRequest.traces.title')}
      </Typography>
      <Typography marginBottom="30px">
        {translate('page.financialProductRequest.traces.description')}
      </Typography>
      <Box display="flex" width="100%">
        <CustomDataGrid
          style={{ display: 'flex' }}
          getRowId={(row) => row.index}
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          showFirstButton
          showLastButton
          disableSelectionOnClick={false}
          rowsPerPageOptions={[10, 30, 50, 100]}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          translationKeyPrefix="page.financialProductRequest.traces"
          previewColumn={{
            headerName: translate(
              'page.financialProductRequest.traces.columns.value'
            ),
            render: (row) =>
              row && (
                <pre>
                  <Typography fontWeight={700} display="inline-block">
                    {translate('page.financialProductRequest.traces.date') +
                      ': '}
                  </Typography>
                  <Typography display="inline-block" marginBottom="20px">
                    {row.executionStartDate}
                  </Typography>

                  <Typography fontWeight={700}>Input</Typography>
                    {row.input && JSON.stringify(row.input, null, 3)}
                  
                  <Typography fontWeight={700}>Output</Typography>
                  <StyledPre max-width="400px">
                  {row.output && JSON.stringify(row.output, null, 3)}
                  </StyledPre>
                </pre>
              ),
          }}
        />
      </Box>
    </Box>
  );
};

export default TracesCard;
