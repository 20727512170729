import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getFinancialProductRequestAuditTrails } from '../../api/adminFinancialProductRequest/service';
import { TAuditTrail } from '../../common/interfaces';

import RequestStatusBadge from '../RequestStatusBadge';
import StyledDataGrid from '../commons/StyledDataGrid';

import CardContainer from './CardContainer';

type AuditTrailsCardProps = {
  requestId: string;
};

const AuditTrailsCard = ({ requestId }: AuditTrailsCardProps) => {
  const [translate] = useTranslation('global');

  const {
    data: queryData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [`financialProductRequestAuditTrails:${requestId}`],
    queryFn: async () =>
      requestId
        ? (
            await getFinancialProductRequestAuditTrails(requestId, {
              code: 'CHANGE_STATUS',
            })
          ).data
        : undefined,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const columns: GridColDef<TAuditTrail>[] = useMemo(
    () => [
      {
        field: 'auditDate',
        width: 200,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.auditTrailsColumns.auditDate'
        ),
        renderCell: ({ row }) =>
          dayjs(row.auditDate).format(translate('date.timestamp')),
      },
      {
        field: 'status',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.auditTrailsColumns.status'
        ),
        renderCell: ({ row }) => (
          <RequestStatusBadge status={row.extraData['NEW-STATUS']} />
        ),
      },
      {
        field: 'username',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.auditTrailsColumns.username'
        ),
      },
    ],
    [translate]
  );

  return (
    <CardContainer isLoading={isLoading} error={error}>
      <StyledDataGrid
        loading={isLoading}
        columns={columns}
        rows={rows ?? []}
        getRowId={(row) => row.auditDate}
        hideFooter
        autoHeight
      />
    </CardContainer>
  );
};

export default AuditTrailsCard;
