import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { RouteKey } from '../../../navigation/constants';
import { paths } from '../../../navigation/constants';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import BaseViewHeader from '../../../components/BaseViewHeader';
import DataFill from '../../../components/commons/DataFill';
import { IFinancialOfficeDivisionDataUpdate } from '../../../common/interfaces';
import { getDivision } from '../../../api/adminBusinessEnvironment/service';
import usePermissions from '../../../hooks/usePermissions';
import { Permissions } from '../../../common/enums';

const FinancialOfficeDivisionDetail = () => {
  const params = useParams();

  const financialOfficeDivisionId = params.financialOfficeDivisionId
    ? parseInt(params.financialOfficeDivisionId, 10)
    : undefined;

  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();

  const {
    data: financialOfficeDivisionData,
    ...financialOfficeDivisionQueryProps
  } = useQuery<IFinancialOfficeDivisionDataUpdate>({
    queryKey: [`financialOfficeDivision:${financialOfficeDivisionId}`],
    queryFn: async () =>
      financialOfficeDivisionId
        ? (await getDivision(financialOfficeDivisionId)).data
        : {},
    keepPreviousData: false,
  });

  const navigate = useNavigate();
  const onNavEdit = () => {
    navigate(
      generatePath(paths[RouteKey.FINANCIAL_OFFICE_DIVISION_EDIT], {
        financialOfficeDivisionId,
      })
    );
  };

  return (
    <BaseViewPage
      // dataTestId={dataTestId}
      routeTrail={[RouteKey.FINANCIAL_OFFICES]}
      routeKey={RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW}
      data={financialOfficeDivisionData}
      title={t('page.financialOfficeDivisionDetail.title')}
      {...financialOfficeDivisionQueryProps}
    >
      <BaseViewHeader
        //dataTestId={`${dataTestId}_Header`}
        buttonList={[
          {
            onClick: onNavEdit,
            //dataTestId: `${dataTestId}_EditButton`,
            hidden: !checkPermissions(Permissions.DIVISION_UPDATE),
            endIcon: <BorderColorIcon />,
            labelKey: 'common.actionEdit',
            variant: 'primary',
            sx: { minWidth: '160px' },
            size: 'medium',
          },
        ]}
        boxProps={{
          pt: 0,
        }}
      >
        <Grid item container sm={12} xs={24} spacing={3}>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDivisionDetail."
              title="name"
              value={financialOfficeDivisionData?.name}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDivisionDetail."
              title="active"
              value={financialOfficeDivisionData?.active ? 'Si' : 'No'}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.financialOfficeDivisionDetail."
              title="description"
              value={financialOfficeDivisionData?.description}
            />
          </Grid>
        </Grid>
      </BaseViewHeader>
    </BaseViewPage>
  );
};

export default FinancialOfficeDivisionDetail;
