import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import { Grid } from '@mui/material';
import MainContent from './MainContent';
import { paths, RouteKey } from './constants';
import usePermissions from '../hooks/usePermissions';
import Loading from '../components/commons/Loading';
import { UnauthPage, NotFoundPage } from '../pages/ErrorPages';
import { protectedNavTree } from './RouteTree';
import MessageErrorResponse from '../components/commons/MessageErrorResponse';
import { LogoutPage } from '../pages/LogoutPage';

// eslint-disable-next-line arrow-body-style
const AppRouter = () => {
  const { isLoading, error, checkPermissions, getFirstAvailableRoute } =
    usePermissions();

  const [isLogout, setIsLogout] = useState(false);

  const refreshPage = () => window.location.reload();

  if (error) return <MessageErrorResponse onClick={refreshPage} isInitError />;

  return isLogout || isLoading ? (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item>
        <Loading />
      </Grid>
    </Grid>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={paths[getFirstAvailableRoute(protectedNavTree) as RouteKey]}
              replace
            />
          }
        />
        <Route element={<MainContent />}>
          {/* Check user permissions to build auth n unauth path tree */}
          {protectedNavTree.map((item) =>
            item.permissions && checkPermissions(item.permissions) ? (
              <Route key={item.routeKey} path={paths[item.routeKey]}>
                {(item.routes ?? []).map((subItem) =>
                  subItem.permissions &&
                  checkPermissions(subItem.permissions) ? (
                    <Route
                      key={subItem.routeKey}
                      path={paths[subItem.routeKey]}
                      element={<subItem.Component />}
                    />
                  ) : (
                    <Route
                      key={subItem.routeKey}
                      path={paths[subItem.routeKey]}
                      element={<UnauthPage />}
                    />
                  )
                )}
                <Route index element={<item.Component />} />
              </Route>
            ) : (
              <Route
                key={item.routeKey}
                path={paths[item.routeKey]}
                element={<UnauthPage />}
              >
                {(item.routes ?? []).map((subItem) => (
                  <Route
                    key={subItem.routeKey}
                    path={paths[subItem.routeKey]}
                    element={<UnauthPage />}
                  />
                ))}
              </Route>
            )
          )}
        </Route>
        <Route
          path={paths[RouteKey.LOGOUT]}
          element={<LogoutPage setIsLogout={setIsLogout} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
