import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFinancialProductRequest } from '../../api/adminFinancialProductRequest/service';
import { paths, RouteKey } from '../../navigation/constants';
import { useUI } from '../../contexts/UIContext';
import { MenuItemType, Permissions } from '../../common/enums';
import useFPRCards from '../../hooks/useFPRCards';
import usePermissions from '../../hooks/usePermissions';

const FinancialProductRequestViewPage = ({
  dataTestId = 'FinancialProductRequestViewPage',
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const activeSectionKey = params['*'] ?? '';
  const [translate] = useTranslation('global');

  const { checkRoutePermissions } = usePermissions();

  const { setMenuOverride } = useUI();

  const { requestId } = params;

  useEffect(() => {
    if (!checkRoutePermissions(activeSectionKey)) {
      navigate(`/financialProductsRequests/${requestId}/details`, {
        replace: true,
      });
    }
  }, [activeSectionKey]);

  const { data: requestData, ...queryProps } = useQuery({
    queryKey: [`financialProductRequest:${requestId}`],
    queryFn: async () =>
      requestId
        ? (await getFinancialProductRequest(requestId)).data
        : undefined,
    keepPreviousData: true,
  });

  const { cards, commerceData } = useFPRCards({
    requestData,
    requestId,
  });

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS]),
      },
      items: cards.map((card) => ({
        key: card.key,
        text: translate(
          `page.financialProductRequest.${card.key}.breadcrumb`,
          translate(`page.financialProductRequest.${card.key}.title`)
        ),
        path: generatePath(paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW], {
          '*': card.key,
          requestId,
        }),
        type: MenuItemType.MENU,
        permissions:
          card.key === 'documentation'
            ? Permissions.FRONTEND_FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : card.key === 'auditTrails'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ
            : card.key === 'validations'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_VALIDATION_INFO_READ
            : card.key === 'traces'
            ? Permissions.TRACE_READ
            : card.key === 'processVariables'
            ? Permissions.FRONTEND_WORKFLOW_BUSINESS_INSTANCE_VARIABLE_READ
            : card.key === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
            

      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, cards, requestId]);

  const baseViewPageProps = useMemo(
    () => ({
      dataTestId,
      routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW,
      breadcrumb: translate(
        `page.financialProductRequest.${activeSectionKey}.breadcrumb`,
        translate(`page.financialProductRequest.${activeSectionKey}.title`)
      ),
      title: translate(
        `page.financialProductRequest.${activeSectionKey}.title`,
        ''
      ),
      routeTrail: [RouteKey.FINANCIAL_PRODUCTS_REQUESTS],
      data: requestData,
      ...queryProps,
    }),
    [requestData, activeSectionKey, queryProps]
  );

  useEffect(() => {
    if (cards.length > 0 && activeSectionKey === '') {
      navigate(
        generatePath(paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW], {
          '*': cards[0].key,
          requestId,
        }),
        {
          replace: true,
        }
      );
    }
  }, [activeSectionKey, cards]);

  const activeSection = useMemo(
    () =>
      cards.find(
        (entry) => activeSectionKey === '' || activeSectionKey === entry.key
      ),
    [cards, activeSectionKey]
  );

  return (
    <>
      {activeSection?.render({
        requestData,
        commerceData,
        requestId,
        dataTestId,
        baseViewPageProps,
      })}
    </>
  );
};

export default FinancialProductRequestViewPage;
