import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'code',
        attribute: 'code',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('common.required')),
      },
      {
        name: 'tableName',
        attribute: 'tableName',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('common.required')),
      },
      {
        name: 'active',
        attribute: 'active',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.GENERIC_BOOLEAN,
      },
    ],
    [translate]
  );
};

export default useFilterOptions;
