import { Fragment, isValidElement } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TButtonDefinition } from '../../common/interfaces';

export interface ToolButtonBarProps {
  buttonConfList: TButtonDefinition[];
}

const ToolButtonBar = ({ buttonConfList }: ToolButtonBarProps) => {
  const [translate] = useTranslation('global');

  return (
    <>
      {buttonConfList.map((buttonDefinition: TButtonDefinition, index) => {
        if (isValidElement(buttonDefinition))
          return (
            <Fragment key={`button_${index + 0}`}>{buttonDefinition}</Fragment>
          );
        // @ts-ignore
        const { hidden, labelKey, ...buttonProps } = buttonDefinition;
        return hidden ? null : (
          <Button key={`button_${labelKey}`} {...buttonProps}>
            {labelKey ? translate(labelKey) : null}
            {buttonProps.children ?? null}
          </Button>
        );
      })}
    </>
  );
};

export default ToolButtonBar;
