import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useNavigationStateContext } from '../contexts/useNavigationStateContext';
import { paths, RouteKey, settings } from '../navigation/constants';

interface IUseRoutePageProps<IRouteState> {
  routeKey: RouteKey;
  initialState?: Partial<IRouteState>;
}

const useRoutePage = <IRouteState>({
  routeKey,
  initialState = {},
}: IUseRoutePageProps<IRouteState>) => {
  const navigate = useNavigate();

  const stateContext = useNavigationStateContext();

  const routeSettings = useMemo(() => settings[routeKey], [routeKey]);

  const routeState: IRouteState =
    stateContext.getRouteState(routeKey) ?? initialState;

  const setRouteState = useCallback(
    (state: IRouteState) => {
      stateContext.setRouteState(routeKey, state);
    },
    [stateContext]
  );

  const mergeRouteState = useCallback(
    (state: Partial<IRouteState>) => {
      stateContext.setRouteState(routeKey, {
        ...routeState,
        ...state,
      });
    },
    [routeState, stateContext]
  );

  const resetRouteState = useCallback(() => {
    stateContext.setRouteState(routeKey, initialState);
  }, [initialState]);

  const navigateToRoute = useCallback(
    (
      toRouteKey: RouteKey,
      {
        search = undefined,
        ...params
      }: {
        search?: string;
        [key: string]: any;
      } = {}
    ) => {
      navigate({
        pathname: generatePath(paths[toRouteKey], params),
        search,
      });
    },
    [navigate]
  );

  return {
    routeSettings,
    routeState,
    setRouteState,
    mergeRouteState,
    resetRouteState,
    navigateToRoute,
  };
};

export default useRoutePage;
