import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'publicId',
        attribute: 'publicId',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
      {
        name: 'startDateFrom',
        attribute: 'startDateFrom',
        value: '',
        type: FilterOptionTypes.DATE_FROM,
        validation: yup.date().required(translate('common.required')),
      },
      {
        name: 'startDateTo',
        attribute: 'startDateTo',
        value: '',
        type: FilterOptionTypes.DATE_TO,
        validation: yup.date().required(translate('common.required')),
      },
      {
        name: 'customerCode',
        attribute: 'customerCode',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
      {
        name: 'businessSchemaId',
        attribute: 'businessSchemaId',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.BUSINESS_SCHEMAS,
      },
      {
        name: 'commerceId',
        attribute: 'commerceId',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.COMMERCES,
      },
      {
        name: 'taskDefinitionIds',
        attribute: 'taskDefinitionIds',
        value: [],
        multiple: true,
        type: FilterOptionTypes.STRING,
        validation: 
        yup.array().min(1, translate('common.required')),
      },
    ],
    [translate, queryClient]
  );
};

export default useFilterOptions;
