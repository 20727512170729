import * as yup from 'yup';
import { ICodeMappingData } from '../../common/interfaces';

export const buildFormData = (data?: ICodeMappingData) => ({
  externalCode: '',
  externalService: '',
  entityType: '',
  internalId: '',
  ...data,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) =>
  yup.object().shape({
    externalCode: yup.string().required(translate('common.required')),
    externalService: yup.string().required(translate('common.required')),
    entityType: yup.string().required(translate('common.required')),
    internalId: yup.string().required(translate('common.required')),
  });

export default {};
