import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { IFormField } from '../FormFields';

type TextInputFilterProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label: string;
    defaultValue?: string;
    variant?: string;
    helperText?: any;
    index: number;
    dataTestId?: string;
    multiple?: boolean;
  };

const TextInputFilter = ({
  name,
  label,
  mandatory = false,
  dataTestId,
  helperText,
  index,
  multiple = false,
  ...props
}: TextInputFilterProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={`${name}.${index}`}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const value = multiple
          ? (field.value?.value ?? []).join(',')
          : field.value?.value ?? '';
        return (
          <TextField
            helperText={error?.message || helperText}
            error={!!error}
            label={mandatory ? `${label}*` : label}
            {...field}
            value={value}
            inputProps={{
              'data-testid': dataTestId,
            }}
            onChange={(event) => {
              const changed = multiple
                ? (event.target.value ?? '').split(',')
                : event.target.value;
              field.onChange({
                ...field.value,
                value: changed,
              });
            }}
            {...props}
          />
        );
      }}
    />
  );
};

export default TextInputFilter;
