import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from 'react-query';
import BaseForm from '../commons/BaseForm';
import usePermissions from '../../hooks/usePermissions';
import { RouteKey } from '../../navigation/constants';
import useFiltersProps from '../../hooks/useFiltersProps';
import useRoutePage from '../../hooks/useRoutePage';
import {
  IRatesGroupFiltersState,
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IRateGroupUpdateFormData,
  IFormProps,
  IButtonConf,
  IFormRef,
} from '../../common/interfaces';
import TextInputForm from '../form/TextInputForm';

import { buildFormData, buildSchema } from './builders';
import FooterButtons from '../commons/FooterButtons';
import {
  getInterestRateList,
  updateInterestRateGroup,
} from '../../api/adminRates';
import { getErrorMessage } from '../../utils/apiUtils';
import { ToastType } from '../commons/Toast';
import { useUI } from '../../contexts/UIContext';
import Loading from '../commons/Loading';

interface IRateGroupFormProps extends IFormProps<IRateGroupUpdateFormData> {
  setEditMode?: Function;
}

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const RateGroupForm = ({
  onError,
  setEditMode,
}: IRateGroupFormProps) => {
  const [busy, setBusy] = useState(false);
  const { setToast } = useUI();
  const { t } = useTranslation('global');
  const params = useParams();
  const interestRateTableId = params.itemId
    ? parseInt(params.itemId, 10)
    : undefined;

  const interestRateGroupId = params.groupId
    ? parseInt(params.groupId, 10)
    : undefined;

  const isSmallDevice = useMediaQuery('(max-width : 600px)');
  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.VIEW_RATES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const {
    data,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList2',
      pageNumber,
      pageSize,
      { interestRateTableId, interestRateGroupId },
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: { interestRateTableId, interestRateGroupId },
        })
      ).data,
    keepPreviousData: false,
  });

  const formRef = useRef<IFormRef>();
  const { checkPermissions } = usePermissions();
  const { filters, ...filterProps } = useFiltersProps<IRatesGroupFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters: any[]) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const [schema, setSchema] = useState(buildSchema({ translate: t }));
  const [formData, setFormData] = useState(
    useMemo(() => buildFormData(data?.result?.[0]), [data?.result?.[0]])
  );

  const goBack = () => (setEditMode ? setEditMode(false) : console.log(''));

  const [methods, setMethods] = useState(
    useForm<IRateGroupUpdateFormData>({
      defaultValues: buildFormData(data?.result?.[0]),
      resolver: yupResolver(schema),
    })
  );

  const removeElement = async (index: number) => {
    data?.result?.[0].interestRates?.splice(index, 1);
    const response = { interestRates: data?.result?.[0].interestRates };
    setFormData(response);
    methods.reset(response);
  };

  const addElement = async () => {
    data?.result?.[0].interestRates?.push({
      fromTerm: undefined,
      toTerm: undefined,
      rate: undefined,
    });
    setFormData({ interestRates: data?.result?.[0].interestRates });
  };

  const getButtons = (index: number, list: any): IButtonConf[] => {
    let buttons: IButtonConf[] = [
      {
        labelKey: 'common.delete',
        onClick: () => removeElement(index),
        fullWidth: false,
        variant: 'text',
        color: 'inherit',
        startIcon: <DeleteOutlineOutlinedIcon />,
        sx: { fontSize: 'small' },
      },
    ];
    if (index + 1 === list?.length) {
      buttons.push({
        labelKey: 'common.add',
        onClick: addElement,
        fullWidth: false,
        variant: 'text',
        color: 'inherit',
        startIcon: <AddCircleOutlineIcon />,
        sx: { fontSize: 'small' },
      });
    }
    return buttons;
  };

  const onSubmit = async (dataFormGroup: IRateGroupUpdateFormData) => {
    try {
      setBusy(true);
      await updateInterestRateGroup(data?.result?.[0].id, dataFormGroup);
      setToast({
        message: t('page.ratesGroupDetail.form.saveToastSuccessMessage'),
      });
      goBack();
    } catch (err) {
      const message = getErrorMessage(
        err,
        t('page.ratesGroupDetail.form.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    const response = { interestRates: data?.result?.[0].interestRates };
    setFormData(response);
    methods.reset(response);
  }, [data]);

  const { isLoading } = usePermissions();

  return (
    isLoading || isFetching ? (
      <Loading />
    ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {' '}
            {t('page.ratesGroupDetail.form.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} mb={3}>
          <Typography sx={{ fontSize: '14px' }}>
            {t('page.ratesGroupDetail.form.description')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <b> {t('page.ratesGroupDetail.form.paragraphTitle')}</b>
          </Typography>
          <Typography>{data?.result[0]?.interestRateGroup?.name}</Typography>
        </Grid>
      </Grid>
      {data?.result?.[0]?.interestRates && formData && (
        <BaseForm<IRateGroupUpdateFormData>
          //dataTestId={dataTestId}
          methods={methods}
          onSubmit={onSubmit}
          onError={onError}
          formRef={formRef}
          formData={formData}
          title=""
        >
          {data?.result?.[0]?.interestRates.length > 0 && (
            <Grid container spacing={3} pl={3} mt={3}>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight="bold">
                  {t('page.ratesGroupDetail.form.termLabel')}
                </Typography>
              </Grid>
              {!isSmallDevice && (
                <Grid item sm={6}>
                  <Typography fontWeight="bold">
                    {t('page.ratesGroupDetail.form.rateLabel')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} />
              {data?.result?.[0]?.interestRates?.map(
                (interestRate: any, index: number) => (
                  <>
                    <Grid item xs={12} sm={3}>
                      <TextInputForm
                        name={`interestRates[${index}].fromTerm`}
                        labelKey={t('page.ratesGroupDetail.form.fromTermLabel')}
                        mandatory
                        fullWidth
                        onlyNumbers
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextInputForm
                        name={`interestRates[${index}].toTerm`}
                        labelKey={t('page.ratesGroupDetail.form.toTermLabel')}
                        mandatory
                        fullWidth
                        onlyNumbers
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextInputForm
                        name={`interestRates[${index}].rate`}
                        labelKey={t('page.ratesGroupDetail.form.rateLabel')}
                        mandatory
                        fullWidth
                        onlyNumbers
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FooterButtons
                        sm={12}
                        xs={6}
                        mt={0}
                        buttonConfList={getButtons(
                          index,
                          data?.result?.[0]?.interestRates
                        )}
                      />
                    </Grid>
                  </>
                )
              )}
            </Grid>
          )}
        </BaseForm>
      )}
      <Grid item xs={12} sm={8}>
        <FooterButtons
          buttonConfList={[
            {
              variant: 'outlined',
              onClick: goBack,
              labelKey: 'common.cancel',
            },
            data?.result?.[0]?.interestRates.length > 0
              ? {
                  labelKey: 'common.confirm',
                  loading: busy,
                  onClick: () => formRef?.current?.submit(),
                }
              : {
                  labelKey: 'common.add',
                  onClick: addElement,
                  startIcon: <AddCircleOutlineIcon />,
                },
          ]}
        />
      </Grid>
    </>)
  );
};
