import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { styled } from '@mui/system';
import { IIndexedProcessVariable } from '../../../api/adminFinancialProductRequest/interfaces';

import FilterContainer from '../../commons/FilterContainer';
import useRoutePage from '../../../hooks/useRoutePage';
import { RouteKey } from '../../../navigation/constants';
import {
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFilterOption,
} from '../../../common/interfaces';
import useFiltersProps from '../../../hooks/useFiltersProps';
import { getFinancialProductRequestProcessVariables } from '../../../api/adminFinancialProductRequest/service';
import CustomDataGrid from '../../commons/CustomDataGrid';
import { ProcessVariableType } from '../../../api/adminFinancialProductRequest/enums';

import useFilterOptions from './useFilterOptions';

const StyledPre = styled('div')(() => ({
  margin: 0,
  whiteSpace: 'pre-wrap',
}));

type ProcessVariablesCardProps = {
  processInstanceId: string;
};

const activeSection = 'processVariables';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

const ProcessVariablesCard = ({
  processInstanceId,
}: ProcessVariablesCardProps) => {
  const [translate] = useTranslation('global');

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
    initialState: {
      pageNumber: 1,
      pageSize: 25,
      filterAppliedList: [],
      filterDatasourceOptionList: [],
    },
  });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IFilterOption>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const queryResult = useQuery({
    queryKey: [
      'financialProductRequestProcessVariables',
      processInstanceId,
      pageNumber,
      pageSize,
      filters,
    ],
    queryFn: async () =>
      (
        await getFinancialProductRequestProcessVariables(processInstanceId, {
          pageNumber,
          pageSize,
          filters,
        })
      ).data,
    keepPreviousData: true,
  });

  const { data: queryData }: any = queryResult;

  const columns: GridColDef<IIndexedProcessVariable>[] = useMemo(
    () => [
      {
        field: 'creationDate',
        width: 200,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.processVariables.columns.creationDate'
        ),
        renderCell: ({ row }) =>
          row.creationDate
            ? dayjs(row.creationDate).format(translate('date.timestamp'))
            : '',
      },
      {
        field: 'name',
        flex: 1,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.processVariables.columns.name'
        ),
      },
    ],
    [translate]
  );

  const filterOptionList = useFilterOptions();

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleOnPageChange = (newPage: number) =>
    mergeRouteState({
      pageNumber: newPage,
    });

  const handleOnPageSizeChange = (newPageSize: number) =>
    mergeRouteState({
      pageSize: newPageSize,
    });

  return (
    <FilterContainer
      filterOptionList={filterOptionList ?? []}
      filterDatasourceOptionList={filterDatasourceOptionList}
      translationKeyPrefix="page.financialProductRequest.filter"
      title={translate(
        `page.financialProductRequest.${activeSection}.title`,
        ''
      )}
      {...filterProps}
    >
      <CustomDataGrid
        getRowId={(row) => row.index}
        rows={rows ?? []}
        columns={columns}
        rowCount={rowCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
        showFirstButton
        showLastButton
        disableSelectionOnClick={false}
        rowsPerPageOptions={[10, 30, 50, 100]}
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        translationKeyPrefix="page.financialProductRequest.chip"
        previewColumn={{
          headerName: translate(
            'page.financialProductRequest.processVariables.columns.value'
          ),
          render: (row) =>
            row && (
              <StyledPre>
                {(() => {
                  switch (row.type) {
                    case ProcessVariableType.OBJECT:
                      return JSON.stringify(row.value, null, 2);
                    default:
                      return row.value;
                  }
                })()}
              </StyledPre>
            ),
        }}
      />
    </FilterContainer>
  );
};

export default ProcessVariablesCard;
