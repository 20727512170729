import { useImperativeHandle, useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { pick, uniq } from 'lodash';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';

import TextInputForm from '../form/TextInputForm';
import type {
  IFormProps,
  IOptionItem,
  ITesteable,
  IUserFormData,
} from '../../common/interfaces';
import type { IUserData } from '../../api/adminUsersRoles/interfaces';
import AutoCompleteSelector from '../form/AutoCompleteSelector';
import TitledSection from '../commons/TitledSection';
import { buildFormData, buildSchema } from './builders';
import useMetadataKeysUI from '../../hooks/useMetadataKeysUI';
import { getUserAssignableRolesList } from '../../api/adminUsersRoles/service';

interface IUserFormProps extends IFormProps<IUserFormData>, ITesteable {
  userId?: string;
  data?: IUserData;
  metadataKeysOptionList?: Array<IOptionItem>;
}

const UserForm = ({
  dataTestId = 'UserForm',
  userId,
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: IUserFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate }));

  const methods = useForm<IUserFormData>({
    defaultValues: buildFormData(data),
    resolver: yupResolver(schema),
  });

  const { data: rolesList } = useQuery({
    queryKey: ['getRolesListx'],
    queryFn: async () => {
      let result = await getUserAssignableRolesList()
      return result
    },
    keepPreviousData: false,
  });

  const roleIdsValue = methods.watch('roleIds');

  const metadataKeys = useMemo(
    () =>
      uniq(
        (roleIdsValue ?? [])
          .map((id) => {
            const find = rolesList?.find((role) => role.id === id);
            return find?.metadataRequiredKeys ?? [];
          })
          .reduce((ret, keys) => [...ret, ...keys], [])
      ),
    [roleIdsValue, rolesList]
  );

  useEffect(() => {
    setSchema(buildSchema({ translate, metadataKeys, userId }));
  }, [translate, metadataKeys, userId]);

  const onSubmit = async (dataForm: IUserFormData) => {
    const payload: IUserFormData = {
      fullName: dataForm.fullName,
      nationalIdNumber: dataForm.nationalIdNumber,
      userName: dataForm.userName,
      roleIds: dataForm.roleIds,
      metaData: pick(dataForm.metaData, metadataKeys),
      email: dataForm.email
    };

    if (userId === undefined) {
      payload.password = dataForm.password;
    }

    onSubmitProp?.(payload);
  };

  const metadataKeysOptions = useMetadataKeysUI({
    metadataKeys,
  });

  const accessPlatformOptions = ['Admin', 'Rapicompras'];

  useImperativeHandle(
    formRef,
    () => ({
      ...methods,
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  return (
    <>
      <TitledSection
        variant="h1"
        title={translate(
          userId ? 'page.user.actionEdit' : 'page.user.formNameLabel'
        )}
        mb={3}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} data-testid={dataTestId}>
            <TitledSection
              variant="h2"
              title={translate('page.user.personalInformation')}
              rowSpacing={1}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextInputForm
                    name="fullName"
                    label={translate('page.user.fullNameLabel')}
                    mandatory
                    fullWidth
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      'data-testid': `${dataTestId}_fullName`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputForm
                    name="nationalIdNumber"
                    label={translate('page.user.nationalIdNumberLabel')}
                    mandatory
                    fullWidth
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      'data-testid': `${dataTestId}_nationalIdNumber`,
                    }}
                  />
                </Grid>
              </Grid>
            </TitledSection>
            <TitledSection
              variant="h2"
              title={translate('page.user.userLabel')}
              rowSpacing={1}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextInputForm
                    name="userName"
                    disabled={!!userId}
                    label={translate('page.user.userLabel')}
                    mandatory
                    helperText={translate('page.user.userNameHelperText')}
                    fullWidth
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      'data-testid': `${dataTestId}_userName`,
                    }}
                  />
                </Grid>
              </Grid>
            </TitledSection>

            {userId === undefined && (
              <TitledSection
                variant="h4"
                title={translate('page.user.passwordLabel')}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2" mb={1.5}>
                      {translate('page.user.passwordDescrption')}
                    </Typography>
                    <TextInputForm
                      name="password"
                      disabled={!!userId}
                      label={translate('page.user.passwordLabel')}
                      mandatory
                      helperText={translate('page.user.passwordHelperText')}
                      fullWidth
                      inputProps={{
                        minLength: 6,
                        maxLength: 30,
                        'data-testid': `${dataTestId}_password`,
                      }}
                    />
                  </Grid>
                </Grid>
              </TitledSection>
            )}

            <TitledSection
              variant="h4"
              title={translate('page.user.emailLabel')}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" mb={1.5}>
                    {translate('page.user.emailDescrption')}
                  </Typography>
                  <TextInputForm
                    name="email"
                    label={translate('page.user.emailLabel')}
                    helperText={translate('page.user.emailHelperText')}
                    fullWidth
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      'data-testid': `${dataTestId}_email`,
                    }}
                  />
                </Grid>
              </Grid>
            </TitledSection>
            <TitledSection
              variant="h4"
              title={translate('page.user.rolesLabel')}
            >
              <AutoCompleteSelector
                name="roleIds"
                options={rolesList ?? []}
                getOptionLabel={(option) => option?.roleName}
                getOptionValue={(option) => option?.id}
                label={translate('page.user.rolesLabel')}
                multiple
                fullWidth
              />
            </TitledSection>
            {metadataKeysOptions.length > 0 && (
              <TitledSection title={translate('common.additionalKeysLabel')}>
                <Grid container spacing={2}>
                  {metadataKeysOptions.map(({ key, type, props }) => (
                    <TitledSection
                      key={key}
                      variant="h5"
                      title={translate(`datasources.metadataKeys.${key}`)}
                      helperText={translate(`page.user.${key}_description`, {
                        defaultValue: '',
                      })}
                    >
                      {type === 'multiple-autocomplete' && (
                        <AutoCompleteSelector
                          name={`metaData.${key}`}
                          label={translate(`datasources.metadataKeys.${key}`)}
                          getOptionLabel={(option: IOptionItem) => option.value}
                          getOptionValue={(option: IOptionItem) => option.key}
                          hideInputLabel
                          fullWidth
                          multiple
                          {...(props ?? {})}
                        />
                      )}
                      {type === 'single-autocomplete' && (
                        <AutoCompleteSelector
                          name={`metaData.${key}`}
                          label={translate(`datasources.metadataKeys.${key}`)}
                          getOptionLabel={(option: IOptionItem) => option.value}
                          getOptionValue={(option: IOptionItem) => option.key}
                          hideInputLabel
                          fullWidth
                          {...(props ?? {})}
                        />
                      )}
                      {type === 'checkbox' && (
                        <Controller
                          // @ts-ignore
                          name={`metaData.${key}`}
                          control={methods.control}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl error={!!error}>
                              <FormGroup>
                                <FormControlLabel
                                  label={translate(
                                    `datasources.metadataKeys.${key}`
                                  )}
                                  control={
                                    <Checkbox
                                      onChange={(event) => {
                                        field.onChange(event.target.checked);
                                      }}
                                      indeterminate={field.value === undefined}
                                      // @ts-ignore
                                      checked={field.value}
                                      {...props}
                                    />
                                  }
                                />
                              </FormGroup>
                              {error && (
                                <FormHelperText>{error.message}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      )}
                    </TitledSection>
                  ))}
                </Grid>
              </TitledSection>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default UserForm;
