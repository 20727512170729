import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { getZipCodeById } from '../../api/adminPlatformCatalogs/service';
import DataFillIterator from '../commons/DataFillIterator';

import CardContainer from './CardContainer';
import usePlatformCatalogs from '../../hooks/usePlatformCatalogs';

type CustomerDataCardProps = {
  data: Map<string, string>;
};

export const translationKeyPrefix =
  'page.financialProductRequest.customerData.';

const CustomerDataCard = ({ data }: CustomerDataCardProps) => {
  const [translate] = useTranslation('global');
  const { getDescriptionValue } = usePlatformCatalogs();

  const queryClient = useQueryClient();
  const { requestId } = useParams();

  const map = new Map(Object.entries(data));

  const filterDefaultValues = () => {
    if (map.has('customerEmailPin')) {
      map.delete('customerEmailPin');
    }
    if (map.has('customerCellphonePin')) {
      map.delete('customerCellphonePin');
    }
    return Object.fromEntries(map);
  };

  const [dataFill, setDataFill] = useState<object>(filterDefaultValues());

  const valueMapper = useCallback(
    async (key: string, value: string) => {
      if (value === 'false') return translate('common.no');
      if (value === 'true') return translate('common.yes');
      switch (key) {
        case 'customerGender':
          return getDescriptionValue({
            tableName: 'gender',
            code: value,
          });
        case 'customerCivilStatus':
          return getDescriptionValue({
            tableName: 'maritalStatus',
            code: value,
          });
        default:
          break;
      }
      return value;
    },
    [data, map]
  );

  if (!Object.keys(data).length) {
    return null;
  }

  useEffect(() => {
    (async () => {
      if (
        Number(map.get('customerAddressCountry')) &&
        Number(map.get('customerAddressProvince')) &&
        Number(map.get('customerAddressCity')) &&
        Number(map.get('customerAddressCP'))
      ) {
        const { data: addressData } = await queryClient.fetchQuery({
          queryKey: [`AddressData:${requestId}`],
          queryFn: () =>
            getZipCodeById({
              countryId: Number(map.get('customerAddressCountry')),
              stateId: Number(map.get('customerAddressProvince')),
              cityId: Number(map.get('customerAddressCity')),
              zipCodeId: Number(map.get('customerAddressCP')),
            }),
        });
        map.set(
          'customerAddressCountry',
          addressData.city.state.country.description
        );
        map.set('customerAddressProvince', addressData.city.state.description);
        map.set('customerAddressCity', addressData.city.description);
        map.set('customerAddressCP', addressData.code);

        setDataFill(Object.fromEntries(map));
      }
    })();
  }, [data]);

  return (
    <CardContainer title={translate(translationKeyPrefix + 'title')}>
      <DataFillIterator
        object={dataFill}
        translationKeyPrefix={translationKeyPrefix}
        valueMapper={valueMapper}
        gridItemProps={{
          lg: 4,
        }}
      />
    </CardContainer>
  );
};

export default CustomerDataCard;
