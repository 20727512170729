import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';

type HeaderProps = {
  title: string;
};

const Header = ({ title = '' }: HeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        component="header"
        sx={{
          width: '100%',
          padding: '20px 0',
        }}
      >
        <Typography variant="h1" component="h1">
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default Header;
