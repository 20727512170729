import { Grid, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { IButtonConf, ITesteable } from '../../common/interfaces';
import useRoutePage from '../../hooks/useRoutePage';
import { RouteKey } from '../../navigation/constants';
import getError from '../../utils/apiUtils';
import BreadcrumbsNavigator from './BreadcrumbsNavigator';
import FooterButtons from './FooterButtons';
import Loading from './Loading';
import MessageErrorResponse from './MessageErrorResponse';

export type BaseViewPageProps = {
  routeKey: RouteKey;
  routeTrail?: RouteKey[];
  title?: string;
  breadcrumb?: string;
  children?: ReactNode;
  buttonConfList?: IButtonConf[];
  hideBreadcrumbs?: boolean;
  routePathParams?: { [key: string]: string | number };
} & Pick<UseQueryResult, 'isLoading' | 'error' | 'data' | 'refetch'> &
  ITesteable;

const BaseViewPage = ({
  dataTestId = 'BaseViewPage',
  routeKey,
  title,
  breadcrumb,
  error,
  data,
  isLoading = false,
  refetch,
  routeTrail = [],
  buttonConfList,
  children,
  hideBreadcrumbs = false,
  routePathParams,
}: BaseViewPageProps) => {
  useRoutePage({
    routeKey,
  });

  const errorResponse = useMemo(
    () => (error ? getError(error) : undefined),
    [error]
  );

  const isInitError = useMemo(() => !data && !!error, [data, error]);

  const showLoader = errorResponse === undefined && isLoading && !data;

  const showContent = errorResponse === undefined && !isLoading && !!data;

  return (
    <>
      {!hideBreadcrumbs && (
        <BreadcrumbsNavigator
          title={breadcrumb ?? title}
          routes={[...routeTrail, routeKey]}
          current={routeKey}
          routePathParams={routePathParams}
        />
      )}
      <Grid container data-testid={dataTestId} mt={3}>
        {showLoader && (
          <Grid item sm={6} xs={12}>
            <Loading />
          </Grid>
        )}
        {showContent && title && (
          <Grid item xs={12} mb={2}>
            <Typography variant="h1">{title}</Typography>
          </Grid>
        )}
        {showContent && children}
        {errorResponse !== undefined && (
          <MessageErrorResponse
            response={errorResponse}
            onClick={() => refetch?.()}
            routeKey={routeKey}
            isInitError={isInitError}
          />
        )}
        {showContent && buttonConfList?.length && (
          <Grid item container xs={12}>
            <Grid item sm={6} xs={12}>
              <FooterButtons buttonConfList={buttonConfList} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BaseViewPage;
