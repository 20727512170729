import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import {
  IFormProps,
  ITesteable,
  ICommerceBusinessSchemaQuotaAddFormData,
} from '../../common/interfaces';
import { buildFormData, buildSchema } from './builders';
import BaseForm from '../commons/BaseForm';
import StyledAlert from '../ui/StyledAlert';
import { getBusinessSchemaList } from '../../api/adminBusinessEnvironment/service';
import CurrencyInputForm from '../form/CurrencyInputForm';
import DateInputForm from '../form/DateInputForm';
import RadioInputForm from '../form/RadioInputForm';
import { formatToNumber } from '../../utils/formatterUtil';
import SimpleSelectInputForm from '../form/SimpleSelectInputForm';
import { monthsSelect } from '../../utils/constants';

interface ICommerceBusinessSchemaQuotaFormProps
  extends IFormProps<ICommerceBusinessSchemaQuotaAddFormData>,
    ITesteable {
  commerceBusinessSchemaId?: number;
  data?: ICommerceBusinessSchemaQuotaAddFormData;
}

const CommerceBusinessSchemaQuotaForm = ({
  commerceBusinessSchemaId,
  //dataTestId = 'CommerceBusinessSchemaQuotaForm',
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: ICommerceBusinessSchemaQuotaFormProps) => {
  const routeTranslate = 'page.businessSchemaQuotaAdd.form.';
  const [translate] = useTranslation('global');
  const minDateValidFrom = dayjs();
  const maxDateValidFrom = dayjs().add(2, 'year');
  const minDateValidFromYear = dayjs().add(1, 'month');
  const maxDateValidFromYear = dayjs().add(2, 'year').add(1, 'month');
  const radioInputsValues = [
    {
      label: translate(`${routeTranslate}inputs.exceptional.no`),
      value: false,
      disabled: false,
    },
    {
      label: translate(`${routeTranslate}inputs.exceptional.yes`),
      value: true,
      disabled: false,
    },
  ];
  const [schema, setSchema] = useState(buildSchema({ translate }));
  const { data: businessSchemaData, ...businessSchemaQueryProps } = useQuery({
    queryKey: ['getBusinessSchemaListx'],
    queryFn: () => getBusinessSchemaList(),
  });

  const methods = useForm<ICommerceBusinessSchemaQuotaAddFormData>({
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });
  const formData = useMemo(() => buildFormData(data ?? {}), [data]);

  // Skip sonarlint validation error (value -> string into boolean)
  const exceptional =
    methods.watch('exceptional')?.toString() === 'true' ? true : false;
  const validFromYear = methods.watch('validFromYear');

  const [months, setMonths] = useState(monthsSelect);

  const filterMonthsSelector = (date: any) => {
    if (new Date(date).getFullYear() > new Date().getFullYear()) {
      setMonths(monthsSelect);
    } else {
      setMonths(
        monthsSelect.filter(
          (element) =>
            Number.parseInt(element.value, 10) > new Date().getMonth()
        )
      );
    }
  };
  useEffect(() => filterMonthsSelector(validFromYear), [validFromYear]);

  const onSubmit = async (
    dataForm: ICommerceBusinessSchemaQuotaAddFormData
  ) => {
    let validFrom;
    if (exceptional) {
      validFrom = new Date(dataForm.validFrom);
    } else {
      validFrom = new Date(
        dayjs(validFromYear).get('year'),
        Number.parseInt(dataForm.validFromMonth, 10)
      );
      validFrom.setDate(dayjs(validFrom).endOf('month').get('date'));
    }
    validFrom.setHours(23, 59);
    const request: any = {
      amount: Number.parseInt(formatToNumber(dataForm.amount), 10),
      exceptional,
      validFrom: dayjs(validFrom).format('YYYY-MM-DDTHH:mm:ss'),
    };
    onSubmitProp?.(request);
  };
  return (
    <BaseForm<ICommerceBusinessSchemaQuotaAddFormData>
      //dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmit}
      onError={onError}
      formRef={formRef}
      formData={formData}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        marginLeft="25px"
        marginTop="25px"
      >
        <Grid item container spacing={3} xs={12} sm={8} lg={6}>
          <Grid item xs={12}>
            <Typography>{translate(`${routeTranslate}description`)}</Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <RadioInputForm
              id="radio-form"
              name="exceptional"
              inputs={radioInputsValues}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyInputForm
              id="outlined-required"
              label={translate(`${routeTranslate}inputs.amount`)}
              name="amount"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }}>
              {translate(`${routeTranslate}validFromTitle`)}
            </Typography>
          </Grid>
          {exceptional && (
            <Grid item xs={12} sm={12}>
              <DateInputForm
                label={translate(`${routeTranslate}inputs.validFrom`)}
                name="validFrom"
                views={['year', 'month', 'day']}
                inputFormat="DD/MM/YYYY"
                minDate={minDateValidFrom.toString()}
                maxDate={maxDateValidFrom.toString()}
                fullWidth
              />
            </Grid>
          )}
          {!exceptional && (
            <Grid item xs={12} sm={6}>
              <DateInputForm
                label={translate(`${routeTranslate}inputs.validFromYear`)}
                name="validFromYear"
                views={['year']}
                inputFormat="YYYY"
                minDate={minDateValidFromYear.toString()}
                maxDate={maxDateValidFromYear.toString()}
                fullWidth
              />
            </Grid>
          )}
          {!exceptional && (
            <Grid item xs={12} sm={6}>
              <SimpleSelectInputForm
                label={translate(`${routeTranslate}inputs.validFromMonth`)}
                name="validFromMonth"
                options={months}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <StyledAlert
              severity="info"
              title={
                exceptional
                  ? translate(`${routeTranslate}inputs.alert.exceptional.yes`)
                  : translate(`${routeTranslate}inputs.alert.exceptional.no`)
              }
              style={{
                padding: '10px',
                backgroundColor: '#D7E7FF',
              }}
              childrenSx={{
                fontWeight: 'normal !important',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </BaseForm>
  );
};

export default CommerceBusinessSchemaQuotaForm;
