import { useMemo } from 'react';
import { Grid, GridProps } from '@mui/material';

import DataFill, { TValueMapper } from './DataFill';

type DataFillIteratorProps = {
  object: object | { key: string; value: string }[];
  labelsToShow?: string[];
  translationKeyPrefix: string;
  valueMapper?: TValueMapper;
  gridItemProps?: GridProps;
} & GridProps;

const DataFillIterator = ({
  object,
  labelsToShow,
  translationKeyPrefix,
  valueMapper,
  gridItemProps,
  ...props
}: DataFillIteratorProps) => {
  const entries = useMemo(() => {
    if (!object) return [];
    if (Array.isArray(object)) return object.map((entry) => [entry.key, entry.value]);
    return Object.entries(object);
  }, [object]);

  return (
    <>
      <Grid item container spacing={2} alignItems="flex-start" {...props}>
        {labelsToShow === undefined
          ? entries.map(([key, value]) => (
              <DataFill
                key={key}
                title={key}
                value={value}
                translationKeyPrefix={translationKeyPrefix}
                valueMapper={valueMapper}
                {...gridItemProps}
              />
            ))
          : labelsToShow?.map((label) => (
              <DataFill
                key={label}
                title={label}
                value={object[label as keyof object]}
                translationKeyPrefix={translationKeyPrefix}
                valueMapper={valueMapper}
                {...gridItemProps}
              />
            ))}
      </Grid>
    </>
  );
};

export default DataFillIterator;
