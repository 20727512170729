import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import { Button } from 'vulpino';
import qs from 'qs';
import { GridColumns, GridRowId } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import EmptyPage from '../../../assets/imgs/emptyPage.svg';
import { RouteKey, paths } from '../../../navigation/constants';
import TitledSection from '../../../components/commons/TitledSection';
import usePermissions from '../../../hooks/usePermissions';
import Loading from '../../../components/commons/Loading';
import { getCalendarList } from '../../../api/adminCalendar';
import useRoutePage from '../../../hooks/useRoutePage';
import { ICodeMappingsRouteState } from '../../CodeMappingsPage/interfaces';
import BaseTablePage from '../../../components/commons/BaseTablePage';
import CustomDataGrid from '../../../components/commons/CustomDataGrid';
import MessageEmptyPageFirstAction from '../../../components/commons/MessageEmptyPageFirstAction';
import { IButtonConf } from '../../../common/interfaces';
import PromisedValue from '../../../components/helpers/PromisedValue';
import useDataSources, {
  DataSourceCollection,
} from '../../../hooks/useDataSources';
import { Permissions } from '../../../common/enums';

export const CalendarPage = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { getValue } = useDataSources();

  const { mergeRouteState, navigateToRoute, routeState } = useRoutePage({
    routeKey: RouteKey.CALENDAR,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const { pageNumber, pageSize } = routeState;

  const {
    data,
    isLoading: isFetching,
    error,
    refetch,
    ...queryProps
  } = useQuery({
    queryKey: ['getCalendarList', pageNumber, pageSize],
    queryFn: async () => (await getCalendarList({ pageNumber, pageSize })).data,
    keepPreviousData: true,
  });

  const handleNavigate = () => {
    navigate(paths[RouteKey.CREATE_CALENDAR]);
  };

  const location = useLocation();
  const { checkPermissions } = usePermissions();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const rows = useMemo(() => data?.result, [data]);

  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);

  const goNew = useCallback(() => {
    navigateToRoute(RouteKey.CREATE_CALENDAR, {
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  }, [navigateToRoute]);

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      onClick: goNew,
      hidden: !checkPermissions(Permissions.CALENDAR_CREATE),
    },
  ];

  const columns = useMemo<GridColumns>(() => {
    const items: GridColumns = [
      {
        field: 'name',
        headerName: t('page.calendar.columnHeaders.name'),
        minWidth: 120,
        flex: 1,
        sortable: false,
      },
      {
        field: 'description',
        headerName: t('page.calendar.columnHeaders.description'),
        minWidth: 400,
        flex: 1,
        sortable: false,
      },
      // {
      //   field: 'parentCalendarId',
      //   headerName: t('page.calendar.columnHeaders.parentCalendarId'),
      //   minWidth: 400,
      //   flex: 1,
      //   sortable: false,
      //   renderCell: ({ row }) =>
      //     row.parentCalendarId ? (
      //       <PromisedValue
      //         value={row.parentCalendarId}
      //         fn={getValue}
      //         arg={{
      //           collection: DataSourceCollection.CALENDAR,
      //           id: row.parentCalendarId,
      //         }}
      //       />
      //     ) : (
      //       t('page.calendar.none')
      //     ),
      // },
    ];

    return items;
  }, [tableName]);

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (id: GridRowId) => {
    navigateToRoute(RouteKey.VIEW_CALENDAR, {
      itemId: id.toString(),
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  };

  const emptyState = <MessageEmptyPageFirstAction onClick={goNew} />;

  const { isLoading } = usePermissions();

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <>
      {data?.result?.length > 0 ? (
        <TitledSection title={t('page.calendar.main')} variant="h1">
          <BaseTablePage
            isLoading={isFetching}
            error={error}
            routeKey={RouteKey.CALENDAR}
            data={data}
            emptyState={emptyState}
            refetch={refetch}
            {...queryProps}
          >
            <CustomDataGrid
              rows={rows ?? []}
              columns={columns}
              rowCount={rowCount}
              pageNumber={pageNumber}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 30]}
              onRowClick={({ id }) => handleView(id)}
              onPageChange={handleOnPageChange}
              onPageSizeChange={handleOnPageSizeChange}
              getRowId={(r: any) => r.id}
            />
          </BaseTablePage>
        </TitledSection>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <div
            style={{
              maxWidth: '330px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              rowGap: '20px',
            }}
          >
            <img src={EmptyPage} alt="" height="180px" />
            <Typography variant="h2">{t('page.calendar.empty')}</Typography>
            <Typography variant="body1">
              {t('page.calendar.configure')}
            </Typography>
            <Button onClick={handleNavigate} fullWidth>
              {t('page.calendar.setFirst')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
