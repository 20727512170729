import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  GridProps,
  TypographyProps,
  Skeleton,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { styled } from '@mui/system';
import RetryButton from './RetryButton';

const StyledIconContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  width: theme.spacing(5),
  height: theme.spacing(5),
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: theme.spacing(),
  marginRight: theme.spacing(),
}));

export type TDataFillValue = any;

export type TValueMapper = (
  key: string,
  value: TDataFillValue
) => ReactNode | Promise<ReactNode>;

type DataFillProps = {
  title?: string;
  value: TDataFillValue;
  translationKeyPrefix: string;
  IconComponent?: Function;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  valueMapper?: TValueMapper;
} & GridProps;

const DataFill = ({
  title,
  value,
  IconComponent,
  translationKeyPrefix,
  children,
  labelProps,
  valueProps,
  valueMapper,
  ...props
}: DataFillProps) => {
  const [translate] = useTranslation('global');
  const [mappedValue, setMappedValue] = useState(String(value));
  const [isLoading, setIsLoading] = useState(false);
  const [showRetry, setShowRetry] = useState(false);

  const fetchValue = useCallback(async () => {
    try {
      setShowRetry(false);
      setIsLoading(true);
      const mapped = (await valueMapper?.(title ?? '', value)) ?? value;
      setMappedValue(mapped);
    } catch (err) {
      setShowRetry(true);
    } finally {
      setIsLoading(false);
    }
  }, [valueMapper, title, value, setIsLoading, setShowRetry]);

  useEffect(() => {
    fetchValue();
  }, [fetchValue]);

  const mappedValueElement = (
    <Typography {...valueProps} fontWeight="bold" style={{ overflowWrap: "anywhere" }}>
      {translate(translationKeyPrefix + title + 'Mask', {
        defaultValue: mappedValue,
        value: mappedValue,
      })}
    </Typography>
  );

  return (
    <Grid
      item
      container
      xs={12}
      md={4}
      lg={3}
      pt={2}
      flexWrap="nowrap"
      {...props}
    >
      {IconComponent && (
        <StyledIconContainer item>
          <IconComponent color="primary" fontSize="medium" />
        </StyledIconContainer>
      )}
      <Grid item alignItems="center">
        {title && (
          <Grid item container>
            <Typography lineHeight={2} {...labelProps}>
              {translate(translationKeyPrefix + title, title)}
            </Typography>
            {i18next.exists(translationKeyPrefix + title + 'Tooltip') && (
              <Tooltip
                title={translate(
                  translationKeyPrefix + title + 'Tooltip',
                  'Consultar por más información.'
                )}
              >
                <IconButton sx={{ width: '30px', height: '30px' }}>
                  <HelpOutlineIcon fontSize="small" /> <br />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
        {showRetry ? (
          <RetryButton onClick={fetchValue} />
        ) : (
          <Grid item>{isLoading ? <Skeleton /> : mappedValueElement}</Grid>
        )}
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </Grid>
  );
};

export default DataFill;
