import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import BaseForm from '../commons/BaseForm';
import usePermissions from '../../hooks/usePermissions';
import {
  IFormProps,
  IFinancialOfficeDataUpdate,
  ITesteable,
} from '../../common/interfaces';

import { buildFormData, buildSchema } from './builders';
import Loading from '../commons/Loading';
import { DataSourceCollection } from '../../hooks/useDataSources';
import { FormFieldType } from '../form/FormField';
import FormFields from '../form/FormFields';
import TitledSection from '../commons/TitledSection';
import { PlatformCatalogKey } from '../../hooks/usePlatformCatalogs';

interface IFinancialOfficeFormProps
  extends IFormProps<IFinancialOfficeDataUpdate>,
    ITesteable {
  financialOfficeId?: number;
  data?: IFinancialOfficeDataUpdate;
}

export const FinancialOfficeForm = ({
  financialOfficeId,
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
  dataTestId = 'FinancialOfficeForm',
}: IFinancialOfficeFormProps) => {
  const { t } = useTranslation('global');
  const [schema, setSchema] = useState(buildSchema({ translate: t }));
  const [cityOptions, setCityOptions] = useState([]);
  const [zipCodeOptions, setZipCodeOptions] = useState([]);

  const formData = useMemo(() => buildFormData(data ?? {}), [data]);
  const methods = useForm<IFinancialOfficeDataUpdate>({
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });

  const { isLoading } = usePermissions();
  const fundingEntityId = methods.watch('fundingEntityId');
  const addressCountryId = methods.watch('address.countryId');
  const addressStateId = methods.watch('address.stateId');
  const addressCityId = methods.watch('address.cityId');
  const addressZipCodeId = methods.watch('address.zipCodeId');
  const firstRender = useRef(0);
  const isUpdate = financialOfficeId !== undefined;

  const stateParams = useMemo(
    () => ({
      countryId: addressCountryId,
    }),
    [addressCountryId]
  );

  const cityParams = useMemo(
    () => ({
      countryId: addressCountryId,
      stateId: addressStateId,
    }),
    [addressCountryId, addressStateId]
  );

  const zipCodeParams = useMemo(
    () => ({
      countryId: addressCountryId,
      stateId: addressStateId,
      cityIds: addressCityId,
    }),
    [addressCountryId, addressStateId, addressCityId]
  );

  useEffect(() => {
    if (
      typeof addressCityId !== 'number' &&
      typeof addressZipCodeId !== 'number'
    ) {
      formRef?.current?.setValue('address.cityId', undefined);
      formRef?.current?.setValue('address.zipCodeId', undefined);
    }
  }, [addressStateId]);

  useEffect(() => {
    if (addressCityId && addressZipCodeId && zipCodeOptions?.length > 0) {
      if (
        typeof addressCityId === 'number' &&
        typeof addressZipCodeId === 'number' &&
        cityOptions?.length > 0
      ) {
        const cityOption: any = cityOptions.filter((city: any) =>
          city?.id?.includes(data?.address?.cityId)
        )[0];
        const zipOption: any = zipCodeOptions.filter(
          (zip: any) => zip?.id?.id === data?.address?.zipCodeId
        )[0];
        formRef?.current?.setValue('address.cityId', cityOption?.id);
        formRef?.current?.setValue('address.zipCodeId', zipOption?.id);
      } else if (
        typeof addressCityId !== 'number' &&
        typeof addressZipCodeId !== 'number'
      ) {
        const zipOption = zipCodeOptions.filter(
          (zip: any) => zip?.id?.id === addressZipCodeId?.id
        );
        if (zipOption?.length === 0) {
          formRef?.current.setValue('address.zipCodeId', undefined);
        }
      }
    }
  }, [zipCodeOptions, cityOptions, addressCityId]);

  const onSubmit = async ({ ...dataForm }: IFinancialOfficeDataUpdate) => {
    const payload = {
      ...dataForm,
      address: {
        ...dataForm?.address,
        cityId: addressZipCodeId?.cityId,
        zipCodeId: addressZipCodeId?.id,
      },
    };
    onSubmitProp?.(payload);
  };

  const [fundingEntityOptions, setFundingEntityOptions] = useState([]);
  useEffect(() => {
    if (fundingEntityOptions?.length === 1) {
      // @ts-ignore
      const value = fundingEntityOptions[0]?.id;
      formRef?.current?.setValue('fundingEntityId', value);
    }
  }, [fundingEntityOptions]);

  return isLoading ? (
    <Loading />
  ) : (
    <BaseForm<IFinancialOfficeDataUpdate>
      //dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmit}
      onError={onError}
      formRef={formRef}
      formData={formData}
    >
      <TitledSection
        helperTextKey="page.financialOfficeEdit.helperText"
        style={{ paddingTop: '0px' }}
      >
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form"
          fields={[
            {
              name: 'description',
            },
            {
              name: 'code',
              inputProps: {
                minLength: 1,
                maxLength: 6,
              },
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form"
          fields={[
            {
              name: 'additionalData.allowDelivery',
              mandatory: false,
              type: FormFieldType.CHECKBOX,
              childrenProps: {
                sx: { marginTop: '20px' },
              },
            },
            {
              name: 'active',
              mandatory: false,
              disabled: !isUpdate,
              type: FormFieldType.CHECKBOX,
              childrenProps: {
                style: { paddingTop: '0px' },
              },
            },
          ]}
        />
      </TitledSection>
      <TitledSection titleKey="page.financialOfficeEdit.form.fundingEntityId.label">
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form"
          fields={[
            {
              name: 'fundingEntityId',
              type: FormFieldType.DATASOURCE,
              translationKey: 'fundingEntityId',
              dataSourceSelectorProps: {
                collection: DataSourceCollection.FUNDING_ENTITIES,
                setRefOptions: setFundingEntityOptions,
              },
              selectorFullWidth: true,
              disabled: isUpdate && !!fundingEntityId,
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
      </TitledSection>
      <TitledSection titleKey="page.financialOfficeEdit.form.division.label">
        <Typography
          style={{
            transform: 'translateY(-10px)',
          }}
        >
          {t('page.financialOfficeEdit.form.division.helperText')}
        </Typography>
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form.division"
          fields={[
            {
              name: 'divisionId',
              type: FormFieldType.DATASOURCE,
              dataSourceSelectorProps: {
                collection: DataSourceCollection.DIVISIONS,
              },
              selectorFullWidth: true,
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
      </TitledSection>
      <TitledSection titleKey="page.financialOfficeEdit.form.address.label">
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form"
          fields={[
            {
              name: 'address.countryId',
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.COUNTRIES,
              },
              selectorFullWidth: true,
            },
            {
              name: 'address.stateId',
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.STATES,
                isEnabled: () => !!addressCountryId,
                params: stateParams,
              },
              selectorFullWidth: true,
            },
            {
              name: 'address.cityId',
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.CITIES_FORM,
                isEnabled: () => !!addressCountryId && !!addressStateId,
                params: cityParams,
                setRefOptions: setCityOptions,
                filterField: 'description',
              },
              selectorFullWidth: true,
            },
            {
              name: 'address.zipCodeId',
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.ZIP_CODES_FORM,
                isEnabled: () =>
                  !!addressCountryId && !!addressStateId && !!addressCityId,
                params: zipCodeParams,
                setRefOptions: setZipCodeOptions,
              },
              selectorFullWidth: true,
            },
            {
              name: 'address.street',
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
      </TitledSection>
      <TitledSection titleKey="page.financialOfficeEdit.form.address.geographicalCoordinates.label">
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.financialOfficeEdit.form"
          fields={[
            {
              name: 'address.latitude',
              mandatory: false,
            },
            {
              name: 'address.longitude',
              mandatory: false,
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 6,
            lg: 6,
          }}
        />
      </TitledSection>
    </BaseForm>
  );
};
