import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useTranslation } from 'react-i18next';
import { Divider } from 'vulpino';
import type { ITesteable } from '../../common/interfaces';
import type { IUserData } from '../../api/adminUsersRoles/interfaces';
import { getRolesList, getUser } from '../../api/adminUsersRoles/service';
import MetadataSection from './Parts/MetadataSection';
import ChipsGroup from '../../components/commons/ChipsGroup';
import TitledSection from '../../components/commons/TitledSection';
import { MetadataKey, Permissions } from '../../common/enums';
import BaseViewPage from '../../components/commons/BaseViewPage';
import BaseViewHeader from '../../components/BaseViewHeader';
import { paths, RouteKey } from '../../navigation/constants';
import usePermissions from '../../hooks/usePermissions';
import { getUserAssignableRolesList } from '../../api/adminUsersRoles/service';

interface IUserViewProps extends ITesteable {}

const UserViewPage = ({ dataTestId = 'UserViewPage' }: IUserViewProps) => {
  const [translate] = useTranslation('global');

  const navigate = useNavigate();
  const params = useParams();
  const { userId } = params;

  const { checkPermissions } = usePermissions();

  const {
    data,
    error: queryError,
    isLoading,
    ...queryProps
  } = useQuery<IUserData>({
    queryKey: [`user:${userId}`],
    queryFn: async () => (userId ? (await getUser(userId)).data : {}),
    keepPreviousData: true,
  });

  const { data: rolesList } = useQuery({
    queryKey: ['getRolesList'],
    queryFn: async () => (await getRolesList()).data.result,
    keepPreviousData: true,
  });

  const onCancel = () => {
    navigate(paths[RouteKey.USERS]);
  };

  const onNavEdit = () => {
    navigate(generatePath(paths[RouteKey.USERS_EDIT], params));
  };

  const rolesChips = useMemo(() => {
    if (!rolesList) return [];
    return (data?.roles ?? []).map((userRole) => {
      const role = rolesList.find((item) => item.id === userRole.id);

      return {
        key: `role:${userRole.id}`,
        label: role?.roleName ?? userRole.id.toString(),
      };
    });
  }, [data, rolesList]);

  const metadataKeys = useMemo(
    () => Object.keys(data?.metaData ?? {}) as MetadataKey[],
    [data]
  );

  
  const { data: assignableRoles } = useQuery({
    queryKey: ['getRolesListp'],
    queryFn: async () => getUserAssignableRolesList(),
    keepPreviousData: false,
  });

  const checkIfLoggedInUserCanEditUser = () => {
    let roleIdsOfUserBeingViewed:any
    if(data){
      roleIdsOfUserBeingViewed = data?.roles.map(role => role.id)
    }
    let roleIdsOfLoggedOnUser: any
    if(assignableRoles){
     roleIdsOfLoggedOnUser = assignableRoles?.map(role => role.id)
    }
    if(roleIdsOfUserBeingViewed && roleIdsOfLoggedOnUser && !data?.preserved){
      //@ts-ignore
      let loggedOnUserCanEditUser = roleIdsOfUserBeingViewed.every(element => roleIdsOfLoggedOnUser.includes(element));
      if(loggedOnUserCanEditUser && checkPermissions(Permissions.USER_UPDATE)){        
        return false
      }
    }
    return true
  }

  let shouldHideEditButton = checkIfLoggedInUserCanEditUser()

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeTrail={[RouteKey.USERS]}
      routeKey={RouteKey.USERS_VIEW}
      data={data}
      error={queryError}
      isLoading={isLoading}
      {...queryProps}
      buttonConfList={[
        {
          onClick: onCancel,
          labelKey: 'page.role.buttonGoBackSecondaryCTA',
        },
      ]}
    >
      <BaseViewHeader
        title={data?.userName}
        dataTestId={`${dataTestId}_Header`}
        marginBottom={0}
        childrenProps={{
          marginBottom: '0px',
          borderRadius: '4px 4px 0px 4px !important',
        }}
        buttonList={[
          {
            hidden: shouldHideEditButton,
            onClick: onNavEdit,
            dataTestId: `${dataTestId}_EditButton`,
            variant: 'primary',
            endIcon: <BorderColorIcon />,
            labelKey: 'page.user.actionEdit',
          },
        ]}
      />
      {(data?.fullName || data?.nationalIdNumber || data?.email) && (
        <div style={{ width: '100%' }}>
          <Grid
            container
            style={{
              backgroundColor: '#f5f5f5',
              width: '100%',
              padding: '0px 24px 24px 24px',
              borderRadius: '0px 4px 4px 4px',
            }}
          >
            <Grid item xs={12} direction="column" margin={0}>
              <Divider />
            </Grid>

            {data?.fullName && (
              <Grid item mr={4} mt={2} md={4}>
                <Typography>{translate('page.user.fullNameLabel')}</Typography>
                <Typography fontWeight="700">{data.fullName}</Typography>
              </Grid>
            )}

            {data?.nationalIdNumber && (
              <Grid item mr={4} mt={2} md={4}>
                <Typography>
                  {translate('page.user.nationalIdNumberLabel')}
                </Typography>
                <Typography fontWeight="700">
                  {data.nationalIdNumber}
                </Typography>
              </Grid>
            )}

            {data?.email && (
              <Grid item mt={2} md={4}>
                <Typography>{translate('page.user.emailLabel')}</Typography>
                <Typography fontWeight="700">{data.email}</Typography>
              </Grid>
            )}
          </Grid>
        </div>
      )}

      <Grid item sm={6} xs={12}>
        <Grid container spacing={2}>
          <TitledSection title="Roles">
            <ChipsGroup
              chips={rolesChips}
              dataTestId={`${dataTestId}_roles`}
              emptyState={
                <Typography>{translate('page.user.noRoles')}</Typography>
              }
            />
          </TitledSection>
          <TitledSection
            variant="h4"
            title={translate('common.additionalKeysLabel')}
          >
            {metadataKeys.length ? (
              metadataKeys.map((metadataKey) => (
                <MetadataSection
                  key={metadataKey}
                  dataTestId={`${dataTestId}_${metadataKey}`}
                  value={data?.metaData[metadataKey]}
                  metadataKey={metadataKey}
                />
              ))
            ) : (
              <Typography>{translate('page.user.noMetadataKeys')}</Typography>
            )}
          </TitledSection>
        </Grid>
      </Grid>
    </BaseViewPage>
  );
};

export default UserViewPage;
