import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');
  const queryClient = useQueryClient();

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'entityType',
        attribute: 'entityType',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.CODE_MAPPINGS_ENTITY_TYPES,
      },
      {
        name: 'externalService',
        attribute: 'externalService',
        value: '',
        validation: yup.string().required(translate('common.required')),
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.CODE_MAPPINGS_EXTERNAL_SERVICES,
      },
      {
        name: 'internalId',
        attribute: 'internalId',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
      {
        name: 'externalCode',
        attribute: 'externalCode',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup.string().required(translate('common.required')),
      },
    ],
    [translate, queryClient]
  );
};

export default useFilterOptions;
