import { TextFieldProps, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';

import type { IFormField } from '../FormFields';

type DateSelectFilterProps = TextFieldProps & Omit<IFormField, 'type'> & {
  label: string;
  defaultValue?: string;
  variant?: string;
  helperText?: any;
  index: number;
  type: string;
  disableFuture?: boolean;
  minDate?: string;
  maxDate?: string;

  dataTestId?: string;
};

const DateSelectFilter = ({
  name,
  label,
  mandatory = false,
  helperText,
  index,
  type,
  disableFuture = false,
  maxDate,
  minDate,
  dataTestId,
  ...props
}: DateSelectFilterProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={`${name}.${index}`}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            {...field}
            data-testid={dataTestId}
            value={field.value?.value}
            disableFuture={disableFuture}
            minDate={minDate ? dayjs(minDate, 'DD-MM-YYYY') : undefined}
            maxDate={maxDate ? dayjs(maxDate, 'DD-MM-YYYY') : undefined}
            onChange={(event: Dayjs | null) =>
              field.onChange({
                ...field.value,
                value:
                  type === 'to' ? event?.endOf('day') : event?.startOf('day'),
                renderValue: dayjs(event).format('DD-MM-YYYY'),
              })
            }
            renderInput={(params) => (
              <TextField
                inputProps={{
                  'data-testid': dataTestId,
                }}
                {...params}
                helperText={error?.message || helperText}
                error={!!error}
                value={field.value?.value}
                {...props}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DateSelectFilter;
