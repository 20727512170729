import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import type {
  IFormRef,
  IOptionItem,
  IRoleFormData,
  ITesteable,
} from '../../common/interfaces';
import type { IRoleData } from '../../api/adminUsersRoles/interfaces';
import {
  getRole,
  getItemList,
  updateRole,
  createRole,
} from '../../api/adminUsersRoles/service';
import RoleForm from '../../components/RoleForm';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { paths, RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';

interface IRoleEditPageProps extends ITesteable {}

const RoleEditPage = ({ dataTestId = 'RoleEditPage' }: IRoleEditPageProps) => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();

  const formRef = useRef<IFormRef>();

  const { setToast } = useUI();

  const navigate = useNavigate();
  const params = useParams();
  const roleId = params.roleId ? parseInt(params.roleId, 10) : undefined;

  const { data: metadataKeysOptionList } = useQuery({
    queryKey: ['/v1/user-authorization-datasources/metadata-keys'],
    queryFn: async () =>
      (
        await getItemList(
          '/v1/user-authorization-datasources/metadata-keys',
          null
        )
      ).data,
  });

  const metadataKeysWithoutUnrestrictedOptionList = useMemo(() => {
    const filtered: IOptionItem[] = (metadataKeysOptionList ?? []).filter(
      (option: IOptionItem) => option.key !== 'UNRESTRICTED_RESOURCE_ACCESS'
    );
    const excludedList = filtered.map((metadata: IOptionItem) => {
      const labelKey = `datasources.metadataKeys.${metadata.value}`;
      metadata.value = translate(labelKey, metadata.value);
      return metadata;
    });
    return excludedList;
  }, [translate, metadataKeysOptionList]);

  const { data, ...queryProps } = useQuery<IRoleData>({
    queryKey: [`role:${roleId}`],
    queryFn: async () => (roleId ? (await getRole(roleId)).data : {}),
    keepPreviousData: true,
  });

  const goBack = () => {
    navigate(paths[RouteKey.ROLES]);
  };

  const onSubmit = async (payload: IRoleFormData) => {
    try {
      setBusy(true);

      if (roleId !== undefined) {
        await updateRole(roleId, payload);
      } else {
        await createRole(payload);
      }

      setToast({
        key: `${dataTestId}_Success`,
        message: translate('page.role.roleSaveToastSuccessMessage'),
      });
      goBack();
    } catch (error) {
      const message = getErrorMessage(
        error,
        translate('page.role.roleSaveToastErrorMessage')
      );

      setToast({
        key: `${dataTestId}_Error`,
        type: ToastType.ERROR,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey =
    roleId !== undefined ? RouteKey.ROLES_EDIT : RouteKey.ROLES_NEW;

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeKey={currentRouteKey}
      routeTrail={[RouteKey.ROLES]}
      data={data}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={24} xs={12} data-testid={`${dataTestId}_Form`}>
        <RoleForm
          dataTestId={`${dataTestId}_Form`}
          formRef={formRef}
          roleId={roleId}
          data={data}
          onError={console.log}
          onSubmit={onSubmit}
          metadataKeysOptionList={metadataKeysWithoutUnrestrictedOptionList}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default RoleEditPage;
