import * as yup from 'yup';
import { MetadataKey } from '../../common/enums';
import type { IUserData } from '../../api/adminUsersRoles/interfaces';

export const buildFormData = (data?: IUserData) => ({
  fullName: data?.fullName ?? '',
  nationalIdNumber: data?.nationalIdNumber ?? '',
  email: data?.email ?? '',
  userName: data?.userName ?? '',
  roleIds: (data?.roles ?? []).map((role) => role.id),
  metaData: data?.metaData ?? {},
});

export const buildSchema = ({
  translate,
  metadataKeys = [],
  userId,
}: {
  translate: (key: string) => string;
  metadataKeys?: string[];
  userId?: string;
}) => {
  const metadataKeysShape = {};

  [
    'CUSTOMER_CODE_LIST',
    'FINANCIAL_PRODUCT_REQUEST_ID_LIST',
    'COMMERCE_ID_LIST',
    'FINANCIAL_OFFICE_ID_LIST',
    'DIVISION_ID_LIST',
    'ORIGINATOR_ID_LIST',
    'FUNDING_ENTITY_ID_LIST',
  ].forEach((key) => {
    if (metadataKeys.includes(key)) {
      Object.assign(metadataKeysShape, {
        [key]: yup
          .array()
          .required(translate('common.required'))
          .min(1, translate('common.required')),
      });
    }
  });

  if (metadataKeys.includes(MetadataKey.BNPL_PROVIDER_ID)) {
    Object.assign(metadataKeysShape, {
      [MetadataKey.BNPL_PROVIDER_ID]: yup
        .string()
        .required(translate('common.required')),
    });
  }

  if (metadataKeys.includes(MetadataKey.UNRESTRICTED_RESOURCE_ACCESS)) {
    Object.assign(metadataKeysShape, {
      [MetadataKey.UNRESTRICTED_RESOURCE_ACCESS]: yup
        .boolean()
        .required(translate('common.required')),
    });
  }

  if (userId === undefined) {
    return yup.object().shape({
      fullName: yup
        .string()
        .required(translate('page.user.form.fullName.required'))
        .matches(/^[a-z ,.'-]+$/i, translate('page.user.form.fullName.match')),
      nationalIdNumber: yup
        .string()
        .required(translate('page.user.form.nationalIdNumber.required'))
        .matches(
          /^[0-9]*$/,
          translate('page.user.form.nationalIdNumber.match')
        ),
      userName: yup
        .string()
        .required(translate('page.user.form.userName.required')),
      password: yup
        .string()
        .required(translate('page.user.form.password.required')),
      email: yup
        .string()
        .email(translate('page.user.form.email.match'))
        .max(
          50,
          translate('page.user.form.email.max')
        )
        .nullable(),
      metaData: yup.object().shape(metadataKeysShape),
    });
  }

  return yup.object().shape({
    fullName: yup
      .string()
      .required(translate('page.user.form.fullName.required'))
      .matches(/^[a-z ,.'-]+$/i, translate('page.user.form.fullName.match')),
    nationalIdNumber: yup
      .string()
      .required(translate('page.user.form.nationalIdNumber.required'))
      .matches(/^[0-9]*$/, translate('page.user.form.nationalIdNumber.match')),
    userName: yup
      .string()
      .required(translate('page.user.form.userName.required')),
    email: yup
      .string()
      .email(translate('page.user.form.email.match'))
      .nullable(),
    metaData: yup.object().shape(metadataKeysShape),
  });
};

export default {};
