import { Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'vulpino';
import dayjs from 'dayjs';
import { RangeOptions, RangeOptionsType } from './components';
import { styles } from './styles';
import TitledSection from '../commons/TitledSection';
import { IFormElement } from '../../api/dynamicForm/interfaces';

interface DateRangeProps {
  required?: boolean;
  customValue?: string;
  options?: RangeOptionsType[];
  onError?: (err: any) => void;
  clear?: boolean;
  setClear?: (val: boolean) => void;
  defaultValue?: any;
  formElement?: IFormElement;
  onChange: (value: any) => void;
}

const INVALID_DATE_VALUE = 'Invalid Date';

export const DateRange = ({
  required,
  customValue,
  options,
  onError,
  onChange,
  clear,
  setClear,
  defaultValue,
  formElement,
}: DateRangeProps) => {
  const getValue = (dates: string, index: number) => {
    const parsed = dates.replace(/\\"/g, '"');
    const date = JSON.parse(parsed)[index];

    return dayjs(date.split('/').reverse().join('-'));
  };

  const [from, setFrom] = useState<any>(
    formElement?.resolvedValue ? getValue(formElement?.resolvedValue, 0) : null
  );
  const [to, setTo] = useState<any>(
    formElement?.resolvedValue ? getValue(formElement?.resolvedValue, 1) : null
  );
  const [radio, setRadio] = useState('');
  const { t } = useTranslation('global');

  const [minimum, setMinimum] = useState<any>(undefined);
  const [maximum, setMaximum] = useState<any>(undefined);

  const setCustomRadio = (val: string) => {
    setRadio(val);
  };

  const handleFrom = (date: any) => {
    if (customValue) setCustomRadio(customValue);
    setFrom(date);
  };

  const handleTo = (date: any) => {
    if (customValue) setCustomRadio(customValue);
    setTo(date);
  };

  const handleChange = () => {
    const startValue = from?.toISOString();
    const endValue = to?.toISOString();

    if (endValue !== INVALID_DATE_VALUE && startValue !== INVALID_DATE_VALUE) {
      // date range value format: ["DD/MM/YYYY", "DD/MM/YYYY"]
      onChange(
        `["${dayjs(from).format('DD/MM/YYYY')}", "${dayjs(to).format(
          'DD/MM/YYYY'
        )}"]`
      );
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    if (clear) {
      setFrom(null);
      setTo(null);
      setRadio('');
      if (setClear) setClear(false);
    }
  }, [clear]);

  useEffect(() => {
    if (from && dayjs(from).isValid() && to && dayjs(to).isValid()) {
      handleChange();
    }
    if (from && dayjs(from).isValid() && formElement) {
      let date = new Date(from.toISOString());
      const until = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + (formElement?.maxAmountDaysInRange ?? 0)
      );
      setMaximum(dayjs(until));
    }
    if (to && dayjs(to).isValid() && formElement) {
      let date = new Date(to.toISOString());
      const since = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - (formElement?.maxAmountDaysInRange ?? 0)
      );
      setMinimum(since);
    }
  }, [from, to]);

  return (
    <div style={styles.wrapper}>
      <Grid item container xs={12} sm={8}>
        <TitledSection variant="h4" title="Fecha" />
      </Grid>
      <Grid item container xs={12} sm={8}>
        <Typography variant="body2">
          Ingresá un rango de fechas. Podés seleccionar los rangos
          predeterminados o personalizarlo.
        </Typography>
      </Grid>
      {options && options?.length > 0 && (
        <RangeOptions
          radio={radio}
          setRadio={setRadio}
          setFrom={setFrom}
          setTo={setTo}
          options={options}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            label={`${t('component.dateRange.label.from')}${
              required ? '*' : ''
            }`}
            localizationProviderProps={{}}
            value={from}
            onChange={handleFrom}
            maxDate={to}
            minDate={minimum}
            renderInput={(props) => (
              <TextField
                {...props}
                helperText="DD/MM/YYYY"
                variant="standard"
              />
            )}
            inputFormat="DD/MM/YYYY"
            onError={(e: any) => onError && onError(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={`${t('component.dateRange.label.to')}${required ? '*' : ''}`}
            localizationProviderProps={{}}
            value={to}
            onChange={handleTo}
            minDate={from}
            maxDate={maximum}
            renderInput={(props) => (
              <TextField
                {...props}
                helperText="DD/MM/YYYY"
                variant="standard"
              />
            )}
            inputFormat="DD/MM/YYYY"
            onError={(e: any) => onError && onError(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
