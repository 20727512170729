import { env } from '../env';
import {
  ICatalogItemData,
  IGetListResponse,
  IPaginatedParams,
} from '../../common/interfaces';

import fetch from '../api';
import {
  IAdress,
  IBank,
  ICity,
  ICountry,
  IState,
  IZipCode,
} from './interfaces';

interface IGetStatesParams extends IPaginatedParams {
  countryId: number;
}

interface IGetCitiesParams extends IPaginatedParams {
  countryId: number;
  stateId: number;
  description: string;
}

interface IGetCatalogItems extends IPaginatedParams {
  tableName?: string;
}

interface IGetZipCodesParams extends IPaginatedParams {
  countryId: number;
  stateId: number;
  cityId: number;
  cityIds: number[];
}

interface IGetZipCodeByIdParams {
  countryId: number;
  stateId: number;
  cityId: number;
  zipCodeId: number;
}

export const getCountries = ({
  pageNumber = 1,
  pageSize = 1000,
}: IPaginatedParams = {}): Promise<IGetListResponse<ICountry>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/countries',
    method: 'get',
    params: {
      pageNumber,
      pageSize,
    },
  });

export const getStates = ({
  pageNumber = 1,
  pageSize = 1000,
  countryId,
}: IGetStatesParams): Promise<IGetListResponse<IState>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/countries/${countryId}/states`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
    },
  });

export const getCities = ({
  pageNumber = 1,
  pageSize = 1000,
  countryId,
  stateId,
}: IGetCitiesParams): Promise<IGetListResponse<ICity>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/countries/${countryId}/states/${stateId}/cities`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
    },
  });

export const getCityGroups = ({
  pageNumber = 1,
  pageSize = 1000,
  countryId,
  stateId,
  description,
}: IGetCitiesParams): Promise<IGetListResponse<ICity>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/countries/${countryId}/states/${stateId}/city-groups`,
    method: 'get',
    params: {
      description,
      pageNumber,
      pageSize,
    },
  });

export const getZipCodes = async ({
  pageNumber = 1,
  pageSize = 1000,
  countryId,
  stateId,
  cityIds,
}: IGetZipCodesParams): Promise<IGetListResponse<IZipCode>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/countries/${countryId}/states/${stateId}/zip-codes`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      cityIds,
    },
  });

export const getZipCodeById = ({
  countryId,
  stateId,
  cityId,
  zipCodeId,
}: IGetZipCodeByIdParams): Promise<{ data: IAdress }> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/countries/${countryId}/states/${stateId}/cities/${cityId}/zip-codes/${zipCodeId}`,
    method: 'get',
  });

export const getBanks = ({
  pageNumber = 1,
  pageSize = 1000,
}: IPaginatedParams = {}): Promise<IGetListResponse<IBank>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/banks',
    method: 'get',
    params: {
      pageNumber,
      pageSize,
    },
  });

export const getBankAccountTypes = (): Promise<
  IGetListResponse<{
    id: number;
    code: string;
  }>
> =>
  Promise.resolve({
    data: {
      totalItems: 2,
      totalPages: 1,
      result: [
        {
          id: 1,
          code: 'CC',
        },
        {
          id: 2,
          code: 'CA',
        },
      ],
    },
  });

export const getBankAccountIdentificationTypes = (): Promise<
  IGetListResponse<{
    id: number;
    code: string;
  }>
> =>
  Promise.resolve({
    data: {
      totalItems: 3,
      totalPages: 1,
      result: [
        {
          id: 1,
          code: 'ALIAS',
        },
        {
          id: 2,
          code: 'CBU',
        },
        {
          id: 3,
          code: 'CVU',
        },
      ],
    },
  });

export const getCatalogItems = ({
  pageNumber = 1,
  pageSize = 1000,
  ...params
}: IGetCatalogItems = {}): Promise<IGetListResponse<ICatalogItemData>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/catalogs`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...params,
    },
  });

export const createCatalogItem = (data: ICatalogItemData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/catalogs`,
    method: 'post',
    data,
  });

/**
 *
 * @in_progress
 */
export const updateCatalogItem = (itemId: number, data: ICatalogItemData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/catalogs/${itemId}`,
    method: 'put',
    data,
  });

export const getCatalogItem = async (
  itemId: number
): Promise<{ data: ICatalogItemData }> => {
  const items = (await getCatalogItems()).data.result;
  const data = items.find((item) => item.id === itemId);
  if (!data) throw new Error('Not found');
  return {
    data,
  };
};
