import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Chip } from 'vulpino';
import { FormElementType } from '../../api/dynamicForm/interfaces';
import { getDynamicFormDefinitions } from '../../api/dynamicForm/service';

type ReportFilterChipsProps = {
  reportDefinitionName: any;
  filters: any;
};

type ReportFilterChip = {
  key: string;
  name: string;
  value: any;
};

const toDynamicFormFilterChips = (
  formElements: any,
  filters: any,
  translate: any
) => {
  const chips: ReportFilterChip[] = [];
  const getOptionValue = (element: any, key: any) => {
    let response =
      element?.optionItems?.find((item: any) => item.key === filters[key])
        ?.value || filters[key];
    switch (element?.label) {
      case 'Estado':
        if (!Array.isArray(response) && response?.includes(', ')) {
          response = response.split(', ');
        }
        if (Array.isArray(response)) {
          response = response.map((state: any) =>
            translate('page.reports.optionsSelector.' + state, filters[key])
          );
          return response.join(', ');
        }
        response = 'page.reports.optionsSelector.' + response;
        break;
      default:
        break;
    }
    return translate(response, filters[key]);
  };
  Object.keys(filters).forEach((filterKey: any) => {
    // Do not show chip for a filter if it was not used or if the form definition no longer includes it
    if (!filters[filterKey] || !formElements[filterKey]) {
      return;
    }
    switch (formElements[filterKey].formElementType) {
      case FormElementType.MANUAL_DATE_RANGE:
        {
          const dates = filters[filterKey].replace(/[",[\]]/g, '').split(' ');
          chips.push({
            key: `${filterKey}_from`,
            name: translate('component.dateRange.label.from'),
            value: dates[0],
          });
          chips.push({
            key: `${filterKey}_to`,
            name: translate('component.dateRange.label.to'),
            value: dates[1],
          });
        }
        break;
      case FormElementType.MANUAL_COMBO:
        chips.push({
          key: filterKey,
          name: formElements[filterKey].label,
          value: getOptionValue(formElements[filterKey], filterKey),
        });
        break;
      default:
        chips.push({
          key: filterKey,
          name: formElements[filterKey].label,
          value: getOptionValue(formElements[filterKey], filterKey),
        });
    }
  });
  return chips;
};

export const ReportFilterChips = ({
  reportDefinitionName,
  filters,
}: ReportFilterChipsProps) => {
  const [t] = useTranslation('global');

  const { data: dynamicFormDefinition } = useQuery({
    queryKey: ['getDynamicFormDefinitions', reportDefinitionName],
    queryFn: async () =>
      (
        await getDynamicFormDefinitions({
          dynamicFormName: reportDefinitionName,
        })
      ).data,
  });

  const formElements = useMemo(() => {
    const elementsMap = {};
    const elementsList = (
      dynamicFormDefinition?.formElementGroups ?? []
    ).flatMap((formElementGroup: any) => formElementGroup.formElements);
    elementsList.forEach((element: any) => {
      Object.assign(elementsMap, { [element.name]: element });
    });
    return elementsMap;
  }, [dynamicFormDefinition]);

  const chipData = useMemo(() => {
    if (dynamicFormDefinition && filters) {
      return toDynamicFormFilterChips(formElements, filters, t);
    }
    return [];
  }, [dynamicFormDefinition, filters]);

  return (
    <>
      {chipData.map((chipDataElement: ReportFilterChip) => (
        <Chip
          key={chipDataElement.key}
          sx={{ marginRight: 2 }}
          label={`${chipDataElement.name}: ${chipDataElement.value}`}
        />
      ))}
    </>
  );
};

export default ReportFilterChips;
