import { useMemo } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';
import { formatToNumber } from '../../utils/formatterUtil';

type TextInputFormProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label?: string;
    labelKey?: string;
    defaultValue?: string;
    variant?: string;
    helperText?: any;
    translationPath?: string;
    mask?: string;
    onlyNumbers?: boolean;
    dataTestId?: string;
  };

const TextInputForm = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  mandatory = false,
  helperText,
  dataTestId,
  mask,
  disabled,
  onlyNumbers,
  ...props
}: TextInputFormProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const label = useTranslate(labelKey, labelProp);

  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        mask ? (
          <InputMask
            {...field}
            onChange={(event) => {
              const value = event.target?.value;
              field.onChange(onlyNumbers ? formatToNumber(value) : value);
            }}
            mask={mask}
            value={field.value ?? ''}
            disabled={disabled}
          >
            {
              // @ts-ignore
              () => (
                <TextField
                  helperText={error?.message || helperText}
                  error={!!error}
                  label={mandatory ? `${label}*` : label}
                  inputProps={{
                    'data-testid': dataTestId,
                  }}
                  {...props}
                />
              )
            }
          </InputMask>
        ) : (
          <TextField
            helperText={error?.message || helperText}
            error={!!error}
            label={mandatory ? `${label}*` : label}
            {...field}
            onChange={(event) => {
              const value = event.target?.value;
              field.onChange(onlyNumbers ? formatToNumber(value) : value);
            }}
            inputProps={{
              'data-testid': dataTestId,
            }}
            value={field.value ?? ''}
            disabled={disabled}
            {...props}
          />
        )
      }
    />
  );
};

export default TextInputForm;
