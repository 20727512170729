import dayjs from 'dayjs';
import {
  ICodeMappingData,
  ICommerceData,
  IGetListResponse,
  IPaginatedParams,
  ICommerceBusinessSchemaData,
  IBusinessSchemaData,
  ICommerceBusinessQuotasSchemaData,
  ICommerceBusinessSchemaCreateQuotaData,
  IFinancialOfficeDivisionDataUpdate,
  IFinancialOfficeDataUpdate,
  ICommerceFiltersState,
} from '../../common/interfaces';
import fetch from '../api';
import { env } from '../env';

interface IGetCommerceListParams extends IPaginatedParams {
  filters?: ICommerceFiltersState
}

interface IGetBusinessSchemaListParams extends IPaginatedParams {
  filters?: {};
}

interface IGetCommerceBusinessSchemaListParams extends IPaginatedParams {
  filters?: {};
}

interface IGetCommerceBusinessQuotasSchemaListParams extends IPaginatedParams {
  filters?: {};
}

interface IGetCodeMappings extends IPaginatedParams {
  entityType?: string;
  externalService?: string;
  internalId?: string;
}

export const getCommercesList = async ({
  pageNumber = 1,
  pageSize = 2000,
  filters,
}: IGetCommerceListParams = {}): Promise<IGetListResponse<ICommerceData>> => {
  if (filters?.createdDateFrom !== undefined) {
    filters.createdDateFrom = await dayjs(filters.createdDateFrom).format(
      'YYYY-MM-DD'
    );
  }
  if (filters?.createdDateTo !== undefined) {
    filters.createdDateTo = await dayjs(filters.createdDateTo).format(
      'YYYY-MM-DD'
    );
  }
  const response = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });
  return response;
};
export const getCommerceBusinessSchemaQuotas = async (
  {
    pageNumber = 1,
    pageSize = 1000,
    filters = {},
  }: IGetCommerceBusinessQuotasSchemaListParams,
  businessSchemaId: number
): Promise<IGetListResponse<ICommerceBusinessQuotasSchemaData>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas/${businessSchemaId}/quota-definitions`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const createCommerceBusinessSchemaQuota = (
  businessSchemaId: number,
  data: ICommerceBusinessSchemaCreateQuotaData
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas/${businessSchemaId}/quota-definitions`,
    method: 'post',
    data,
  });

interface BusinessSchema {
  id: number;
  name: string;
  description: string;
  bpmProcessName: string;
  financialProductTypes: any;
  selfManaged: boolean;
  commerceDrivenOperation: boolean;
  fundingChainRule: any;
  quotaControlEnabled: boolean;
}

interface BusinessSchemaArray {
  totalItems: number;
  data: Array<BusinessSchema>;
}

export const getBusinessSchemaList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetBusinessSchemaListParams = {}): Promise<
  IGetListResponse<IBusinessSchemaData>
> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/business-schemas`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const getCommerceBusinessSchema = (commerceBusinessSchemaId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas/${commerceBusinessSchemaId}`,
    method: 'get',
  });

export const getCommerceBusinessSchemaList = async (
  {
    pageNumber = 1,
    pageSize = 1000,
    filters = {},
  }: IGetCommerceBusinessSchemaListParams,
  itemId: any
) => {
  // @ts-ignore
  filters.commerceId = itemId;
  let searchResponse = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

  let businessSchemaArray: BusinessSchemaArray = {
    totalItems: searchResponse.data.result.length,
    data: [],
  };

  for (let i = 0; i < searchResponse.data.result.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const businessSchemaResponse = await fetch({
      baseURL: env.APIS_ONSCORE,
      url: `/v1/business-schemas/${searchResponse.data.result[i].businessSchemaId}`,
      method: 'get',
      params: {},
    });
    businessSchemaArray.data.push(businessSchemaResponse.data);
  }
  return businessSchemaArray;
};

export const getCommerceBusinessSchemas = async (
  commerceId: number,
  businessSchemaId: number
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas`,
    method: 'get',
    params: {
      commerceId,
      businessSchemaId,
    },
  });

export const getBusinessSchema = async (businessSchemaId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/business-schemas/${businessSchemaId}`,
    method: 'get',
  });

export const getCommerce = (commerceId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces/${commerceId}`,
    method: 'get',
  });

export const searchCommerces = async ({
  pageNumber = 1,
  pageSize = 2000,
  filters = {},
}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const updateCommerce = (commerceId: number, data: ICommerceData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces/${commerceId}`,
    method: 'put',
    data,
  });

export const createCommerce = (data: ICommerceData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces`,
    method: 'post',
    data,
  });

export const updateCommerceBusinessSchema = (
  commerceBusinessSchemaId: number,
  data: ICommerceBusinessSchemaData
) => {
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas/${commerceBusinessSchemaId}`,
    method: 'put',
    data,
  });
};

export const createCommerceBusinessSchema = (
  data: ICommerceBusinessSchemaData
) => {
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/commerces-business-schemas`,
    method: 'post',
    data,
  });
};

export const getCodeMappings = ({
  pageNumber = 1,
  pageSize = 1000,
  ...params
}: IGetCodeMappings = {}): Promise<IGetListResponse<ICodeMappingData>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/code-mappings`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...params,
    },
  });

export const getCodeMapping = async (itemId: number) => {
  const items = (await getCodeMappings()).data.result;
  const data = items.find((item) => item.id === itemId);
  if (!data) throw new Error('Not found');
  return {
    data,
  };
};

export const createCodeMapping = (data: ICodeMappingData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/code-mappings`,
    method: 'post',
    data,
  });

/**
 *
 * @in_progress
 */
export const updateCodeMapping = (itemId: number, data: ICodeMappingData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/code-mappings/${itemId}`,
    method: 'put',
    data,
  });

export const getFinancialOffices = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: 'v1/financial-offices',
    method: 'get',
    params: {
      sort: 'code',
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const getFinancialOffice = (financialOfficeId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-offices/${financialOfficeId}`,
    method: 'get',
  });

export const createFinancialOffice = (data?: IFinancialOfficeDataUpdate) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/financial-offices',
    method: 'post',
    data,
  });

export const deleteFinancialOffice = (financialOfficeId: number | undefined) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-offices/${financialOfficeId}`,
    method: 'delete',
  });

export const updateFinancialOffice = (
  financialOfficeId?: number,
  data?: IFinancialOfficeDataUpdate
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-offices/${financialOfficeId}`,
    method: 'put',
    data,
  });

export const getDivisions = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: 'v1/divisions',
    method: 'get',
    params: {
      sort: 'name',
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const getDivision = (financialOfficeDivisionId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/divisions/${financialOfficeDivisionId}`,
    method: 'get',
  });

export const createFinancialOfficeDivision = (
  data?: IFinancialOfficeDivisionDataUpdate
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/divisions',
    method: 'post',
    data,
  });

export const deleteFinancialOfficeDivision = (
  financialOfficeDivisionId: number | undefined
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/divisions/${financialOfficeDivisionId}`,
    method: 'delete',
  });

export const updateFinancialOfficeDivision = (
  financialOfficeDivisionId?: number,
  data?: IFinancialOfficeDivisionDataUpdate
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/divisions/${financialOfficeDivisionId}`,
    method: 'put',
    data,
  });
