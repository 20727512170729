import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProformaInvoice } from '../../common/interfaces';
import { batchFormatMoney, formatPercentage, operativeControlDateFormatter } from '../../utils/formatterUtil';
  
  interface ProformaInvoiceProps {
    rows: IProformaInvoice[]; 
  }
  
  const ProformaInvoiceTable: React.FC<ProformaInvoiceProps> = ({ rows }) => {
    const [translate] = useTranslation('global');
    const translationKeyPrefix =
    'page.operativeControl.rows.';
  
    if (!rows || rows.length === 0) {
      return <div>No data available</div>;
    }
  
    const headers = Object.keys(rows[0]);

    const formatValue = (key: string, value: any) => {
      if (key === 'dueDate'){
        return operativeControlDateFormatter(value);
      }
      if (key === 'discountRate'){
        return formatPercentage(value) + '%';
      }
      if (key === 'installmentAmount' || key === 'discountToApply' || key === 'discountedAmount'){
        return batchFormatMoney(value);
      }
      return value;
    };

    return (
      <TableContainer style={{ width: '70%' }} component={Paper}>
        <Table aria-label="dynamic table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell align="right" key={header}>{translate(translationKeyPrefix + header)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.dueDate}>
                {headers.map((header) => (
                  <TableCell key={header} style={{ textAlign: 'right' }}>
                    {formatValue(header, row[header as keyof IProformaInvoice])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default ProformaInvoiceTable;
  