import { Grid, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup } from 'vulpino';
import type { IFormField } from './FormFields';

type IRadioForm = {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
};

type TextInputFormProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    inputs: IRadioForm[];
    dataTestId?: string;
  };

const RadioInputForm = ({
  name,
  inputs,
  mandatory = false,
  dataTestId,
  disabled,
  ...props
}: TextInputFormProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup aria-labelledby="Regular-group" value={field.value}>
          <Grid container>
            {inputs?.length > 0 &&
              inputs.map((element) => (
                <Grid item xs={12 / inputs.length} key={element.label}>
                  <Radio
                    label={element.label}
                    value={element.value}
                    disabled={element.disabled}
                    onChange={(value) => field.onChange(value)}
                  />
                </Grid>
              ))}
          </Grid>
        </RadioGroup>
      )}
    />
  );
};

export default RadioInputForm;
