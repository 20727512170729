import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ICommerceData, IFormRef, ITesteable } from '../../common/interfaces';
import CommerceForm from '../../components/CommerceForm';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { paths, RouteKey } from '../../navigation/constants';
import {
  createCodeMapping,
  getCodeMappings,
  createCommerce,
  getCodeMapping,
  getCommerce,
  updateCodeMapping,
  updateCommerce,
} from '../../api/adminBusinessEnvironment/service';
import BaseViewPage from '../../components/commons/BaseViewPage';
import { MenuItemType, Permissions } from '../../common/enums';
import { env } from '../../api/env';

interface ICommerceEditPageProps extends ITesteable {}

const CommerceEditPage = ({
  dataTestId = 'CommerceEditPage',
}: ICommerceEditPageProps) => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();
  const formRef = useRef<IFormRef>();
  const { setToast, setMenuOverride } = useUI();

  const navigate = useNavigate();
  const params = useParams();
  const commerceId = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;
  const isUpdate = commerceId !== undefined;
  const { data: commerceData, ...queryProps } = useQuery<ICommerceData>({
    queryKey: [`commerce:${commerceId}`],
    queryFn: async () =>
      commerceId ? (await getCommerce(commerceId)).data : {},
    keepPreviousData: true,
  });

  const goToNext = (commerceIdValue: string, payload: ICommerceData) => {
    if (isUpdate || payload.id !== payload.headquarterId) {
      navigate(
        generatePath(paths[RouteKey.COMMERCES], {
          commerceId: commerceIdValue,
        })
      );
    } else {
      navigate(
        generatePath(paths[RouteKey.COMMERCE_BUSINESS_SCHEMA], {
          commerceId: commerceIdValue,
        })
      );
    }
  };

  const goBack = () => {
    navigate(paths[RouteKey.COMMERCES]);
  };

  const onSubmit = async (payload: ICommerceData) => {
    try {
      setBusy(true);
      let responseCommerceId;
      if (isUpdate) {
        responseCommerceId = (await updateCommerce(commerceId, payload)).data
          .id;
      } else {
        responseCommerceId = (await createCommerce(payload)).data.id;
      }
      setToast({
        message: translate('page.commerce.saveToastSuccessMessage'),
        key: `${dataTestId}_Success`,
      });
      goToNext(responseCommerceId, payload);
    } catch (error) {
      console.log(error)
      const message = getErrorMessage(
        error,
        translate('page.commerce.saveToastErrorMessage')
      );
      setToast({
        type: ToastType.ERROR,
        key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey = isUpdate
    ? RouteKey.COMMERCES_EDIT
    : RouteKey.COMMERCES_NEW;

  const changeMenu = (cardKeys: string[]) => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.COMMERCES]),
      },
      items: cardKeys.map((cardKey) => ({
        key: cardKey,
        text: translate(
          `page.businessSchemas.drawerSubItems.${cardKey}.breadcrumb`,
          translate(`page.businessSchemas.drawerSubItems.${cardKey}.title`)
        ),
        path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
          '*': cardKey,
          commerceId: commerceId?.toString(),
        }),
        type: MenuItemType.MENU,
        permissions:
          cardKey === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : cardKey === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      })),
    });
  };

  useEffect(() => {
    if (
      commerceId &&
      commerceData &&
      commerceData.id === commerceData.headquarterId
    ) {
      changeMenu(['commerceDetail', 'businessSchemas']);
    }
  }, [commerceData]);

  useEffect(() => {
    if (commerceId) {
      changeMenu(['commerceDetail']);
    }
    return () => setMenuOverride(undefined);
  }, []);

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeTrail={[RouteKey.COMMERCES]}
      routeKey={currentRouteKey}
      data={commerceData}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={12} xs={24}>
        <CommerceForm
          dataTestId={`${dataTestId}_Form`}
          formRef={formRef}
          commerceId={commerceId}
          data={commerceData}
          onError={console.log}
          onSubmit={onSubmit}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default CommerceEditPage;
