import { Circle } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled, TypographyProps } from '@mui/system';

const StyledLabel = styled(Typography)(() => ({
  alignItems: 'center',
  display: 'inline-flex',
}));

export enum Status {
  SUCCESS = 'success',
  WARNING = 'warning',
  FAILURE = 'failure',
  INFO = 'info',
}

export interface StatusBadgeProps extends TypographyProps {
  status?: Status;
  label?: string;
  sx?: any;
  color?: string;
}

const ByStatusCircleProps: {
  [key: string]: {
    color: 'success' | 'warning' | 'error' | 'info';
  };
} = {
  [Status.SUCCESS]: {
    color: 'success',
  },
  [Status.WARNING]: {
    color: 'warning',
  },
  [Status.FAILURE]: {
    color: 'error',
  },
  [Status.INFO]: {
    color: 'info',
  },
};

const StatusBadge = ({
  label = '',
  color,
  status = Status.SUCCESS,
  ...props
}: StatusBadgeProps) => {
  const circleProps = {
    ...ByStatusCircleProps[status],
  };
  if (color !== undefined)
    Object.assign(circleProps, {
      color,
    });

  return (
    <StyledLabel fontSize="small" {...props}>
      <Circle {...circleProps} sx={{ fontSize: 12, mr: 0.5 }} />
      {label}
    </StyledLabel>
  );
};

export default StatusBadge;
