import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'lodash';

import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

import FilterContainer from '../../components/commons/FilterContainer';
import type { IButtonConf } from '../../common/interfaces';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import MessageEmptyPageFirstAction from '../../components/commons/MessageEmptyPageFirstAction';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import { RouteKey } from '../../navigation/constants';
import BaseTablePage from '../../components/commons/BaseTablePage';

import useFilterOptions from './hooks/useFilterOptions';
import type {
  IDynamicFormsPageFiltersState,
  IDynamicFormsRouteState,
} from './interfaces';
import { getDynamicForms } from '../../api/dynamicForm/service';
import useDataGridColumns from '../../hooks/useDataGridColumns';

const DynamicFormsPage = ({ dataTestId = 'DynamicFormsPage' }) => {
  const [translate] = useTranslation('global');
  const location = useLocation();

  const { entityType: entityTypeQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IDynamicFormsRouteState>({
      routeKey: RouteKey.DYNAMIC_FORMS,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
      },
    });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IDynamicFormsPageFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const {
    data: queryData,
    error,
    ...queryProps
  } = useQuery({
    queryKey: ['getDynamicForms', pageNumber, pageSize, filters],
    queryFn: async () =>
      (
        await getDynamicForms({
          ...filters,
        })
      ).data,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const filterOptionList = useFilterOptions();

  const handleView = useCallback(
    (name: string) => {
      navigateToRoute(RouteKey.DYNAMIC_FORMS_VIEW, {
        name,
      });
    },
    [navigateToRoute]
  );

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <DownloadForOfflineOutlinedIcon />,
      labelKey: 'component.toolBar.downloadLabelButton',
      disabled: rowCount <= 0,
      hidden: true,
    },
  ];

  const columns = useDataGridColumns(
    [
      {
        field: 'id',
        minWidth: 160,
      },
      {
        field: 'name',
        minWidth: 160,
      },
      {
        field: 'type',
        minWidth: 160,
      },
      {
        field: 'description',
        minWidth: 320,
      },
    ],
    {
      translationKeyPrefix: 'page.dynamicForm.columnHeaders.',
    },
    []
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const emptyState = <MessageEmptyPageFirstAction />;

  return (
    <BaseTablePage
      error={error}
      routeKey={RouteKey.DYNAMIC_FORMS}
      dataTestId={dataTestId}
      data={queryData}
      emptyState={emptyState}
      hasFilters={!isEmpty(filters)}
      {...queryProps}
    >
      <FilterContainer
        title={translate('page.dynamicForm.title')}
        filterOptionList={filterOptionList}
        translationKeyPrefix="page.dynamicForm.filter"
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          onRowClick={({ row }) => handleView(row.name)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          getRowId={(r: any) => r.id}
          buttonConfList={buttonConfList}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};

export default DynamicFormsPage;
