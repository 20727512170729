import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';

import { getFinancialProductRequestHistoricalTasks } from '../../api/adminFinancialProductRequest/service';
import StyledDataGrid from '../commons/StyledDataGrid';
import StatusBadge, { Status } from '../commons/StatusBadge';
import StyledAlert from '../ui/StyledAlert';
import { IHistoricalTask } from '../../api/adminFinancialProductRequest/interfaces';

import CardContainer from './CardContainer';
import { HistoricalTaskStatus } from '../../common/enums';

type HistoricalTasksCardProps = {
  processInstanceId: string;
};

const statusMapping = {
  [HistoricalTaskStatus.FINISHED]: {
    color: Status.SUCCESS,
  },
  [HistoricalTaskStatus.PENDING]: {
    status: Status.WARNING,
  },
  [HistoricalTaskStatus.CANCELLED]: {
    status: Status.FAILURE,
  },
};

const HistoricalTasksCard = ({
  processInstanceId,
}: HistoricalTasksCardProps) => {
  const [translate] = useTranslation('global');

  const {
    data: queryData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [`financialProductRequestHistoricalTasks:${processInstanceId}`],
    queryFn: async () =>
      processInstanceId
        ? (await getFinancialProductRequestHistoricalTasks(processInstanceId))
            .data
        : undefined,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const columns: GridColDef<IHistoricalTask>[] = useMemo(
    () => [
      {
        field: 'endDateTime',
        width: 200,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.historicalTasksColumns.endDateTime'
        ),
        renderCell: ({ row }) =>
          row.endDateTime
            ? dayjs(row.endDateTime).format(translate('date.timestamp'))
            : '',
      },
      {
        field: 'status',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.historicalTasksColumns.status'
        ),
        renderCell: ({ row }) => (
          <StatusBadge
            {...statusMapping[row.status]}
            label={translate(
              `page.financialProductRequest.historicalTasks.taskStatus.${row.status}`
            )}
          />
        ),
      },
      {
        field: 'taskName',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.historicalTasksColumns.taskName'
        ),
      },
      {
        field: 'completedBy',
        width: 300,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.historicalTasksColumns.assignee'
        ),
      },
    ],
    [translate]
  );

  if (rows && !rows.length) {
    return (
      <StyledAlert
        severity="info"
        title={translate(
          'page.financialProductRequest.historicalTasks.emptyState.title'
        )}
        sx={{ width: '100%', mb: 4 }}
      >
        <Typography>
          {translate(
            'page.financialProductRequest.historicalTasks.emptyState.description'
          )}
        </Typography>
      </StyledAlert>
    );
  }

  return (
    <CardContainer isLoading={isLoading} error={error}>
      <StyledDataGrid
        loading={isLoading}
        columns={columns}
        rows={rows ?? []}
        getRowId={(row) => row.taskId}
        hideFooter
        autoHeight
      />
    </CardContainer>
  );
};

export default HistoricalTasksCard;
