import * as yup from 'yup';
import { ICommerceBusinessSchemaData } from '../../common/interfaces';
import { ICommerceBusinessSchemaFormData } from '../../common/interfaces';

export const buildFormData = ({
  commerceId,
  businessSchemaId,
  bnplProviderMerchantId,
  quotaConsumed,
  invoiceRequired,
  ...data
}: Partial<ICommerceBusinessSchemaData> = {}): Partial<ICommerceBusinessSchemaFormData> => ({
  commerceId: commerceId === undefined ? -1 : commerceId,

  businessSchemaId: businessSchemaId === undefined ? -1 : businessSchemaId,

  bnplProviderMerchantId:
    bnplProviderMerchantId === undefined ? '' : bnplProviderMerchantId,

  quotaConsumed: quotaConsumed === undefined ? -1 : quotaConsumed,

  invoiceRequired: invoiceRequired === undefined ? false : invoiceRequired,

  ...data,
});

export const buildSchema = ({
  translate,
  isBNPL = false,
}: {
  translate: (key: string) => string;
  isBNPL?: boolean;
}) => {
  const shape = {
    commerceId: yup.number().required(translate('common.required')),
    businessSchemaId: yup.number().required(translate('common.required')),
    bnplProviderMerchantId: yup.string().notRequired(),
    quotaAmount: yup.number().notRequired(),
    invoiceRequired: yup.bool().notRequired(),
  };

  if (isBNPL)
    Object.assign(shape, {
      bnplProviderMerchantId: yup
        .string()
        .required(translate('common.required'))
        .matches(/^\w+$/, translate('common.match')),
    });
  return yup.object().shape(shape);
};

export default {};
