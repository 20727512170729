import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router';
import { useQuery } from 'react-query';
import { GridRowId } from '@mui/x-data-grid';

import FilterContainer from '../../components/commons/FilterContainer';
import {
 IFilterOption,
 IOptionItem,
 IFilterAppliedOption,
 IFilterDatasourceOption,
 ILoanBatchUpdates,
 IBatchUpdatesFilters,
} from '../../common/interfaces';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import { operativeControlDateFormatter } from '../../utils/formatterUtil';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import { paths, RouteKey } from '../../navigation/constants';
import useDataGridColumns from '../../hooks/useDataGridColumns';
import { getBatchUpdates} from '../../api/adminBind/service';
import useFilterOptions from './hooks/useFilterOptions';
import Loading from '../../components/commons/Loading';

interface IRouteState {
 pageNumber: number;
 pageSize: number;
 filterAppliedList: IFilterAppliedOption[];
 filterDatasourceOptionList: any[];
}

const filterDatasourceOptionListMock: IFilterDatasourceOption[] = [
 {
   name: 'batchUpdateState',
   options: [],
 }
];

export const PaymentBatchPage = () => {

 const [translate] = useTranslation('global');

 const filterOptionList = useFilterOptions();

 const navigate = useNavigate();

 const isLoading = true;
 
 const batchIdFormatter = (value: string) => {
  const parts = value.split('-');
  if (parts.length > 1){
    return "Lote " + parts[1];
  }
  return "Lote " + parts[0];
 }
 
 const getBatchIdFormatter = (params: any) => batchIdFormatter(params.row.assignorBatchId);
 
 const getDateFormatted = (params: any) => operativeControlDateFormatter(params.row.updateDate);

 const getAssignorFormatted = (params : any) => translate(params.row.assignor);

 const getUpdateTypeFormatter = (params: any) => translate('page.paymentBatch.' + params.row.updateType);

 const getStateFormatter = (params: any) => translate('page.paymentBatch.' + params.row.state);

 const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
  routeKey: RouteKey.PAYMENT_BATCH,
  initialState: {
    pageNumber: 1,
    pageSize: 50,
    filterAppliedList: [],
    filterDatasourceOptionList: filterDatasourceOptionListMock,
  },
 });
 
 const {
  pageNumber,
  pageSize,
  filterAppliedList,
  filterDatasourceOptionList,
 } = routeState;
 
 const { filters, ...filterProps } =
   useFiltersProps<IBatchUpdatesFilters>({
     appliedFilters: filterAppliedList,
     onChange: (appliedFilters) =>
       mergeRouteState({
         filterAppliedList: appliedFilters,
       }),
   });
 
 const { data: queryData, ...queryProps } = useQuery({
  queryKey: ['paymentBatch', pageNumber, pageSize, filters],
  queryFn: async () =>
    (await getBatchUpdates({ pageNumber, pageSize, filters }))
      .data,
  keepPreviousData: true,
 });

 const rows = useMemo(() => queryData, [queryData]);
 const rowCount = useMemo(() => rows?.length ?? 0, [queryData]);
 
 const showLoader = isLoading && !rows;

 const showContent = !showLoader;

 const handleView = useCallback(
   (id: GridRowId) => {
    const urlViewRequest = generatePath(
       paths[RouteKey.PAYMENT_BATCH_VIEW],
       { batchId: id.toString()}
     );
     navigate(urlViewRequest);
   },
   [pageNumber, pageSize, filterAppliedList, filterDatasourceOptionList]
 );
 
 const columns = useDataGridColumns(
   [
    {
      field: 'assignor',
      valueGetter: getAssignorFormatted,
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignorBatchId',
      valueGetter: getBatchIdFormatter,
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: 'updateDate',
      valueGetter: getDateFormatted,
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      field: 'updateType',
      valueGetter: getUpdateTypeFormatter,
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      field: 'state',
      valueGetter: getStateFormatter,
      minWidth: 200,
      flex: 1,
      sortable: false,
    }
   ],
   {
     translationKeyPrefix:
       'page.paymentBatch.columnHeaders.',
   },
   [translate]
 ); 

 const handleOnGetDatasource = (
   filter: IFilterOption,
   options: IOptionItem[]
 ) => {
   mergeRouteState({
     filterDatasourceOptionList: filterDatasourceOptionList.map((f) => {
       if (f.name === filter.name) {
         f.options = options;
       }
       return f;
     }),
   });
 };

 const handleOnPageChange = (newPage: number) =>
   mergeRouteState({
     pageNumber: newPage,
   });


 const handleOnPageSizeChange = (newPageSize: number) =>
   mergeRouteState({
     pageSize: newPageSize,
   });

 return (
  <div>
    {showLoader && <Loading />}
    {showContent &&
    <>
     <FilterContainer
       title="Bandeja de control"
       filterOptionList={filterOptionList}
       filterDatasourceOptionList={filterDatasourceOptionList}
       translationKeyPrefix="page.paymentBatch.filter"
       onGetDatasource={handleOnGetDatasource}
       {...filterProps}
     >
       <CustomDataGrid
         rows={rows ?? []}
         columns={columns}
         rowCount={rowCount}
         pageNumber={pageNumber}
         pageSize={pageSize}
         showFirstButton
         showLastButton
         rowsPerPageOptions={[10, 30, 50, 100]}
         onRowClick={({ id }) => handleView(id)}
         onPageChange={handleOnPageChange}
         onPageSizeChange={handleOnPageSizeChange}
         getRowId={(r: ILoanBatchUpdates) => r.batchId}
         filterValue="Pendiente de aprobación"
       />
     </FilterContainer>
     </>
    }
  </div>
 );
};
