import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'roleName',
        attribute: 'roleName',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('page.role.form.roleName.required')),
      },
      {
        name: 'description',
        attribute: 'description',
        value: '',
        type: FilterOptionTypes.STRING,
        validation: yup
          .string()
          .required(translate('page.role.inputDescriptionRequired')),
      },
    ],
    [translate]
  );
};

export default useFilterOptions;
