import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';

import { generatePath, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IFormRef } from '../../common/interfaces';
import FooterButtons from '../commons/FooterButtons';
import { useUI } from '../../contexts/UIContext';

import { getErrorMessage } from '../../utils/apiUtils';
import { ToastType } from '../commons/Toast';
import CatalogSelector from '../form/CatalogSelector';
import { updateFinancialProductRequest } from '../../api/adminFinancialProductRequest/service';
import { FinancialProductRequestStatus } from '../../common/enums';
import { paths, RouteKey } from '../../navigation/constants';
import { deleteWorkflowBusinessInstance } from '../../api/adminPlatformBPM/service';

interface RejectCardProps {
  requestId?: string;
  taskId?: string;
  processInstanceId?: string;
}
const dataTestId = 'RejectCard';

const RejectCard = ({
  requestId,
  taskId,
  processInstanceId,
}: RejectCardProps) => {
  const formRef = useRef<IFormRef>();
  const [translate] = useTranslation('global');
  const { setToast } = useUI();
  const [busy, setBusy] = useState(false);
  const originPendingTask = taskId && processInstanceId;

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        rejectedCode: yup
          .string()
          .required(
            translate(
              'page.financialProductRequestPendingTask.reject.rejectedCodeRequied'
            )
          ),
      })
    ),
  });

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(
      generatePath(
        originPendingTask
          ? paths[RouteKey.FPR_PENDING_TASKS_DETAIL]
          : paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_DETAILS],
        {
          requestId,
          taskId,
          processInstanceId,
        }
      )
    );
  }, [navigate]);

  const onSubmit = useCallback(
    async (payload: any) => {
      try {
        if (!requestId || !processInstanceId) return;
        setBusy(true);
        payload = {
          ...payload,
          status: FinancialProductRequestStatus.REJECTED,
        };

        await deleteWorkflowBusinessInstance(processInstanceId, {
          deleteReason: payload.rejectedCode,
        })
          .then(() => updateFinancialProductRequest(requestId, payload))
          .catch((error) => {
            if (error.status === 404) {
              updateFinancialProductRequest(requestId, payload);
            } else {
              const message = getErrorMessage(
                error,
                translate(
                  'page.financialProductRequestPendingTask.reject.error'
                )
              );

              setToast({
                type: ToastType.ERROR,
                key: `${dataTestId}_Error`,
                message,
              });
            }
          });
        goBack();
      } catch (error) {
        const message = getErrorMessage(
          error,
          translate('page.financialProductRequestPendingTask.reject.error')
        );

        setToast({
          type: ToastType.ERROR,
          key: `${dataTestId}_Error`,
          message,
        });
      } finally {
        setBusy(false);
      }
    },
    [requestId]
  );

  if (!requestId || !processInstanceId) return null;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit, console.log)}
        style={{ width: '100%' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={11} sm={6}>
            <CatalogSelector
              name="rejectedCode"
              label={translate(
                'page.financialProductRequestPendingTask.reject.rejectedCode'
              )}
              fullWidth
              selectorFullWidth
              tableName="rejectedReason"
              mandatory
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <FooterButtons
              buttonConfList={[
                {
                  variant: 'outlined',
                  dataTestId: `${dataTestId}_CancelButton`,
                  onClick: goBack,
                  labelKey: 'common.cancel',
                },
                {
                  labelKey: 'common.reject',
                  dataTestId: `${dataTestId}_SubmitButton`,
                  loading: busy,
                  onClick: methods.handleSubmit(onSubmit, console.log),
                },
              ]}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RejectCard;
