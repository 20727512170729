import {
  TextFieldProps,
  TextField,
  Checkbox,
  ListItemText,
  MenuItem,
} from '@mui/material';

interface IOptionItem {
  id: string;
  label: string;
}

type MultiSelectProps = TextFieldProps & {
  label?: string;
  defaultValue?: string;
  variant?: string;
  helperText?: any;
  options?: IOptionItem[];
  onChange?: (event: any) => any;
  value?: any[];
};

const MultiSelect = ({
  label,
  helperText,
  options,
  value = [],
  onChange,
  ...props
}: MultiSelectProps) => {
  let a;
  return (
    <TextField
      label={label}
      select
      variant="standard"
      SelectProps={{
        multiple: true,
        value,
        onChange: (event) => onChange?.(event),
        renderValue: (selectedArray) => {
          const optionsSelected: string[] | undefined = options
            ?.filter(
              (combo) =>
                (selectedArray as string[]).find((s) => s === combo.id) !==
                undefined
            )
            .map((selected) => selected.label);
          return optionsSelected?.join(', ');
        },
      }}
      {...props}
    >
      {options?.map((option: IOptionItem) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={(value ?? []).indexOf(option.id) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MultiSelect;
