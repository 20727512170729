import { ReactNode, Ref, useEffect, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import type { ITesteable } from '../../common/interfaces';
import { IFormRef } from '../../common/interfaces';
import TitledSection from './TitledSection';

type BaseFormProps<TFormData> = {
  children?: ReactNode;
  methods: UseFormReturn<any, TFormData>;
  onSubmit?: (formData: TFormData) => void;
  onError?: (error: any) => void;
  formData?: Partial<TFormData>;
  formRef?: Ref<IFormRef | undefined>;
  title?: string;
} & ITesteable;

const defaultHandler = () => {};

const BaseForm = <TFormData,>({
  onSubmit = defaultHandler,
  onError,
  methods,
  dataTestId = 'BaseForm',
  children,
  formRef,
  formData,
  title,
}: BaseFormProps<TFormData>) => {
  useImperativeHandle(
    formRef,
    () => ({
      ...methods,
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  useEffect(() => {
    Object.entries(formData ?? {}).forEach(([key, value]) => {
      // @ts-ignore
      methods.setValue(key, value);
    });
  }, [formData, methods.setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Grid container data-testid={dataTestId}>
          <TitledSection variant="h2" spacing={3} title={title}>
            {children}
          </TitledSection>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default BaseForm;
