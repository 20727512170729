import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import type {
  IFormRef,
  ITesteable,
  ICodeMappingFormData,
  ICodeMappingData,
} from '../../common/interfaces';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';
import useRoutePage from '../../hooks/useRoutePage';
import CodeMappingForm from '../../components/CodeMappingForm';
import {
  createCodeMapping,
  getCodeMappings,
  updateCodeMapping,
} from '../../api/adminBusinessEnvironment/service';

interface ICodeMappingEditPageProps extends ITesteable {}

const getCodeMapping = async (itemId: number): Promise<any> => {
  const items = (await getCodeMappings()).data.result;
  const data = items.find((item) => item.id === itemId);
  if (!data) throw new Error('Not found');
  return {
    data,
  };
};

const CodeMappingEditPage = ({
  dataTestId = 'CodeMappingEditPage',
}: ICodeMappingEditPageProps) => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();

  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const currentRouteKey =
    itemId !== undefined
      ? RouteKey.CODE_MAPPING_EDIT
      : RouteKey.CODE_MAPPING_NEW;

  const { navigateToRoute } = useRoutePage({
    routeKey: currentRouteKey,
  });

  const formRef = useRef<IFormRef>();

  const { setToast } = useUI();

  const location = useLocation();

  const { entityType: entityTypeQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const entityType =
    typeof entityTypeQuery === 'string' ? entityTypeQuery : undefined;

  const { data, ...queryProps } = useQuery<ICodeMappingData>({
    queryKey: [`codeMapping:${itemId}`],
    queryFn: async () =>
      itemId
        ? (await getCodeMapping(itemId)).data
        : {
            entityType,
          },
    keepPreviousData: true,
  });

  const goBack = useCallback(
    () =>
      navigateToRoute(RouteKey.CODE_MAPPINGS, {
        search: entityType ? `?entityType=${entityType}` : undefined,
      }),
    [navigateToRoute, entityType]
  );

  const onSubmit = async (payload: ICodeMappingFormData) => {
    try {
      setBusy(true);

      if (itemId !== undefined) {
        await updateCodeMapping(itemId, payload);
      } else {
        await createCodeMapping(payload);
      }

      setToast({
        key: `${dataTestId}_Success`,
        message: translate('page.codeMapping.saveToastSuccessMessage'),
      });
      goBack();
    } catch (error) {
      const message = getErrorMessage(
        error,
        translate('page.codeMapping.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  return (
    <BaseViewPage
      routeKey={currentRouteKey}
      routeTrail={[RouteKey.CODE_MAPPINGS]}
      data={data}
      dataTestId={dataTestId}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={12} xs={24}>
        <CodeMappingForm
          dataTestId={`${dataTestId}_Form`}
          entityType={entityType}
          formRef={formRef}
          itemId={itemId}
          data={data}
          onSubmit={onSubmit}
          onError={console.log}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default CodeMappingEditPage;
