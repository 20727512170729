import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import { ITesteable } from '../../../common/interfaces';
import ChipsGroup from '../../../components/commons/ChipsGroup';
import { MetadataKey } from '../../../common/enums';
import TitledSection from '../../../components/commons/TitledSection';
import useMetadataKeysUI from '../../../hooks/useMetadataKeysUI';

interface MetadataSectionProps extends ITesteable {
  value: string | string[];
  metadataKey: MetadataKey;
}

const MetadataSection = ({
  value,
  dataTestId = 'MetadataSection',
  metadataKey,
}: MetadataSectionProps) => {
  const [translate] = useTranslation('global');

  const metadataKeysOption = useMetadataKeysUI({
    metadataKeys: [metadataKey],
  })[0];

  const { data: queryData } = useQuery(
    metadataKeysOption?.props?.queryOptions ?? {
      queryFn: () => {},
    }
  );

  const chips = useMemo(() => {
    if (metadataKeysOption.type === 'checkbox')
      return [
        {
          key: 'checkbox',
          label: translate(value ? 'common.yes' : 'common.no'),
        },
      ];

    return (Array.isArray(value) ? value : [value])
      .filter((item) => !isEqual(item, {}))
      .map((item) => {
        const row = (queryData ?? []).find((find: any) => find.key === item);
        const label = row ? row.value : item;
        return {
          key: item,
          label,
        };
      });
  }, [translate, value, metadataKeysOption, queryData]);

  return (
    <TitledSection
      dataTestId={dataTestId}
      title={translate(`datasources.metadataKeys.${metadataKey}`)}
      variant="h5"
    >
      <ChipsGroup chips={chips} />
    </TitledSection>
  );
};

export default MetadataSection;
