import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { styles } from '../../styles';

export enum RangeOptionsType {
  LAST_MONTH = 'LAST_MONTH',
  LAST_HALF_OF_MONTH = 'LAST_HALF_OF_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  TODAY = 'TODAY',
  CUSTOM = 'CUSTOM',
  LAST_7_DAYS_EXCLUDING_TODAY = 'LAST_7_DAYS_EXCLUDING_TODAY',
  LAST_FORTNIGHT = 'LAST_FORTNIGHT',
}

interface RangeOptionsProps {
  options: RangeOptionsType[];
  setFrom: any;
  setTo: any;
  radio: string;
  setRadio: (val: string) => void;
}

export const RangeOptions = ({
  options,
  setFrom,
  setTo,
  radio,
  setRadio,
}: RangeOptionsProps) => {
  const { t } = useTranslation();

  const handleRadioChange = (item: RangeOptionsType) => {
    switch (item) {
      case RangeOptionsType.TODAY: {
        let today = new Date().toISOString().slice(0, 10);
        setFrom(dayjs(today));
        setTo(dayjs(today));
        break;
      }
      case RangeOptionsType.LAST_MONTH: {
        let date = new Date();

        let prevStartDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
          .toISOString()
          .slice(0, 10);
        let preEndDate = new Date(
          date.getFullYear(),
          date.getMonth() - 1 + 1,
          0
        )
          .toISOString()
          .slice(0, 10);

        setFrom(dayjs(prevStartDate));
        setTo(dayjs(preEndDate));
        break;
      }
      case RangeOptionsType.LAST_7_DAYS: {
        let date = new Date();

        let lastWeek = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        );

        let today = new Date().toISOString().slice(0, 10);

        setFrom(dayjs(lastWeek));
        setTo(dayjs(today));
        break;
      }
      case RangeOptionsType.LAST_FORTNIGHT:
      case RangeOptionsType.LAST_HALF_OF_MONTH: {
        let date = new Date();

        let lastHalf = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 15
        );

        let today = new Date().toISOString().slice(0, 10);

        setFrom(dayjs(lastHalf));
        setTo(dayjs(today));
        break;
      }
      case RangeOptionsType.LAST_7_DAYS_EXCLUDING_TODAY: {
        let date = new Date();
        let yesterday = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 1
        );

        let lastWeek = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 8
        );

        setFrom(dayjs(lastWeek));
        setTo(dayjs(yesterday));
        break;
      }
      default:
        break;
    }
  };

  return (
    <div style={styles.inputWrapper}>
      <FormControl>
        <RadioGroup
          row
          style={{ gap: '15px' }}
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          value={radio}
          onChange={(e) => {
            setRadio(e.target.value);
          }}
        >
          {options.map((item) => (
            <div key={item} style={styles.inputContainer}>
              <FormControlLabel
                value={item}
                control={<Radio />}
                label={t(`component.dateRange.inputs.${item}`)}
                onChange={() => handleRadioChange(item)}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
