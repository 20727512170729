import * as yup from 'yup';

export const buildFormData = ({ ...data }: any = {}): any => ({
  active: true,
  ...data,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    name: yup.string().required(translate('common.required')),
    description: yup.string().required(translate('common.required')),
    active: yup.bool().required(translate('common.required')),
  };
  return yup.object().shape(shape);
};

export default {};
