import { omit } from 'lodash';
import type {
  IDocumentData,
  IDocumentDetails,
  IDocumentFileWithDocument,
  IDocumentInfo,
  IFinancialProductRequestDetail,
  IFinancialProductRequestFiltersState,
  IFinancialProductRequestUpdateFormData,
  IGetListResponse,
  IGetResponse,
  IPaginatedParams,
  TAuditTrail,
} from '../../common/interfaces';
import fetch from '../api';
import { env } from '../env';

import type {
  IHistoricalTask,
  IIndexedProcessVariable,
  IProcessVariable,
  ITrace,
} from './interfaces';

import { IIndexedTrace } from './interfaces';

interface IGetFinancialProductRequestListParams extends IPaginatedParams {
  filters?: IFinancialProductRequestFiltersState;
}

interface IDocumentDetailsWithDocumentInfo extends IDocumentDetails {
  document: IDocumentInfo;
}

export const getFinancialProductRequestList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetFinancialProductRequestListParams = {}): Promise<
  IGetListResponse<IFinancialProductRequestDetail>
> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      sort: `startDate:desc`,
      ...filters,
    },
  });

export const getFinancialProductRequest = (
  financialProductRequestId: string
): Promise<{
  data: IFinancialProductRequestDetail;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests/${financialProductRequestId}`,
    method: 'get',
  });

export const getFinancialProductRequestDocument = (
  financialProductRequestId: string,
  documentId: string
): Promise<IGetResponse<IDocumentDetails>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests/${financialProductRequestId}/documents/${documentId}`,
    method: 'get',
  });

export const getFinancialProductRequestDocumentFileUrl = (
  financialProductRequestId: string,
  documentId: string,
  fileId: string
): string =>
  `${env.APIS_ONSCORE}/v1/financial-products-requests/${financialProductRequestId}/documents/${documentId}/files/${fileId}`;

export const getFinancialProductRequestDocumentFileBlob = (
  financialProductRequestId: string,
  documentId: string,
  fileId: string
): Promise<any> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests/${financialProductRequestId}/documents/${documentId}/files/${fileId}`,
    method: 'get',
    responseType: 'blob',
  });

export const getFinancialProductRequestDocumentFiles = async (
  financialProductRequestId: string,
  documentData: IDocumentData
): Promise<IDocumentFileWithDocument[]> => {
  const documentInfos = Object.values(documentData);

  const documents = await Promise.all<IDocumentDetailsWithDocumentInfo>(
    documentInfos.map((documentInfo) =>
      getFinancialProductRequestDocument(
        financialProductRequestId,
        documentInfo.documentProviderId
      ).then((response) => ({
        document: documentInfo,
        ...response.data,
      }))
    )
  );

  return documents.reduce<IDocumentFileWithDocument[]>(
    (reduced, { document: documentInfo, ...document }) => [
      ...reduced,
      ...document.files.map((file) => ({
        ...file,
        document: {
          ...documentInfo,
          ...omit(document, 'files'),
        },
      })),
    ],
    []
  );
};

export const getFinancialProductRequestAuditTrails = (
  financialProductRequestId: string,
  filters: any
): Promise<IGetListResponse<TAuditTrail>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests/${financialProductRequestId}/audit-trails`,
    method: 'get',
    params: {
      pageNumber: 1,
      pageSize: 30,
      sort: 'auditDate:DESC',
      ...filters,
    },
  });

export const getFinancialProductRequestHistoricalTasks = (
  processInstanceId: string,
  filters: any = {}
): Promise<IGetListResponse<IHistoricalTask>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/workflow-business-instances/${processInstanceId}/historical-tasks`,
    method: 'get',
    params: {
      pageNumber: 1,
      pageSize: 30,
      ...filters,
    },
  });

export const getFinancialProductRequestProcessVariables = async (
  processInstanceId: string,
  { pageNumber = 1, pageSize = 25, filters = {} }
): Promise<IGetListResponse<IProcessVariable>> => {
  const page = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/workflow-business-instances/${processInstanceId}/variables`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      ...filters,
    },
  });
  return {
    data: {
      result: page.data.result.map((row: any, index: any) => ({
        index,
        ...row,
      })),
      totalItems: page.data.totalItems,
      totalPages: page.data.totalPages,
    },
  };
};

export const getProcessInstanceTraces = async (
  filters: any = {}
): Promise<IGetListResponse<IIndexedTrace>> => {

  const response = await fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/traces`,
    method: 'get',
    params: {
      pageNumber: 1,
      pageSize: 1000,
      ...filters,
    },
  });

  return {
    data: {
      result: response.data.result.map((row: any, index: any) => ({
        index,
        ...row,
      })),
      totalItems: response.data.result.length,
      totalPages: 1,
    },
  };
}

export const updateFinancialProductRequest = (
  financialProductRequestId: string,
  patchData: IFinancialProductRequestUpdateFormData
) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/financial-products-requests/${financialProductRequestId}`,
    method: 'patch',
    data: patchData,
  });
