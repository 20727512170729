import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useMemo, useState } from 'react';
import qs from 'qs';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'vulpino';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { deleteEvent, getEvent } from '../../../../api/adminCalendar';
import BaseViewHeader from '../../../../components/BaseViewHeader';
import BaseViewPage from '../../../../components/commons/BaseViewPage';
import { RouteKey, paths } from '../../../../navigation/constants';
import useRoutePage from '../../../../hooks/useRoutePage';
import { useUI } from '../../../../contexts/UIContext';
import { ToastType } from '../../../../components/commons/Toast';
import usePermissions from '../../../../hooks/usePermissions';
import { Permissions } from '../../../../common/enums';

export const EventView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;
  const eventId = params.eventId ? parseInt(params.eventId, 10) : undefined;
  const [isOpen, setIsOpen] = useState(false);

  const { checkPermissions } = usePermissions();

  const { data, ...queryProps } = useQuery({
    queryKey: [`getEvent:${eventId}`],
    queryFn: async () => (await getEvent(itemId, eventId)).data,
  });

  const location = useLocation();

  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.VIEW_CALENDAR,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const search = tableName ? `?tableName=${tableName}` : undefined;

  const handleEdit = () => {
    navigateToRoute(RouteKey.EDIT_EVENT, {
      search,
      ...params,
    });
  };

  const { setToast } = useUI();
  const navigate = useNavigate();

  const handleDelete = async () => {
    deleteEvent(itemId, eventId)
      .then(() => {
        setToast({
          type: ToastType.SUCCESS,
          message: t('page.calendar.events.toast.delete'),
        });
      })
      .catch((e) =>
        setToast({
          type: ToastType.ERROR,
          message: t('page.calendar.events.toast.deleteError'),
        })
      )
      .finally(() => {
        setIsOpen(false);
        navigateToRoute(RouteKey.VIEW_CALENDAR, {
          itemId,
        });
      });
  };

  const handleCancel = () => {
    navigateToRoute(RouteKey.VIEW_CALENDAR, {
      itemId,
    });
  };

  return (
    <>
      <BaseViewPage
        routeKey={RouteKey.VIEW_EVENT}
        routeTrail={[RouteKey.CALENDAR]}
        data={data}
        {...queryProps}
      >
        <BaseViewHeader
          title={data?.name}
          suptitle={
            <Typography variant="h2">
              {t('page.calendar.events.event')}
            </Typography>
          }
          buttonList={[
            {
              hidden:
                dayjs(data?.date).isBefore(dayjs()) ||
                !checkPermissions([
                  Permissions.CALENDAR_CREATE,
                  Permissions.CALENDAR_UPDATE,
                ]),
              onClick: handleEdit,
              variant: 'secondary',
              endIcon: (
                <BorderColorIcon style={{ marginLeft: 10, marginRight: 10 }} />
              ),
              labelKey: 'page.calendar.actionEdit',
            },
            {
              hidden:
                dayjs(data?.date).isBefore(dayjs()) ||
                !checkPermissions([
                  Permissions.CALENDAR_CREATE,
                  Permissions.CALENDAR_UPDATE,
                ]),
              onClick: () => setIsOpen(true),
              variant: 'tertiary',
              endIcon: <DeleteOutlinedIcon />,
              labelKey: 'page.calendar.actionDelete',
            },
          ]}
        >
          <Grid container mt={4} alignItems="center" xs={12}>
            <Grid item>
              <Typography variant="body2">
                {t('page.calendar.events.date')}
              </Typography>
            </Grid>
            <Grid item mr={1}>
              <Typography variant="body2" fontWeight="bold">
                {dayjs(data?.date).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </BaseViewHeader>
      </BaseViewPage>
      <Grid container xs={12} spacing={2} justifyContent="flex-start">
        <Grid item xs={6}>
          <Button fullWidth onClick={handleCancel} autoFocus>
            {t('page.calendar.back')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent dividers>
          <DialogTitle textAlign="center" id="alert-dialog-title">
            <Grid container textAlign="center" justifyContent="center" py={2}>
              {t('page.calendar.events.modal.deleteTitle')}
            </Grid>
          </DialogTitle>
        </DialogContent>

        <DialogActions alignContent="center">
          <Grid container xs={12} spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="secondary"
                onClick={() => setIsOpen(false)}
              >
                {t('page.calendar.modal.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth onClick={handleDelete} autoFocus>
                {t('page.calendar.modal.accept')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
