import LogoutIcon from '@mui/icons-material/Logout';
import TuneIcon from '@mui/icons-material/Tune';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import keycloak from '../keycloak';
import { MenuItemType, Permissions } from '../common/enums';

// Keys values should not contain invalid characters
export enum RouteKey {
  INDEX = '',
  LOGIN = 'login',
  LOGOUT = 'logout',
  HOME = 'home',
  USERS = 'users',
  ROLES = 'roles',
  ROLES_NEW = 'roles_new',
  ROLES_VIEW = 'roles_view',
  ROLES_EDIT = 'roles_edit',
  USERS_NEW = 'users_new',
  USERS_VIEW = 'users_view',
  USERS_EDIT = 'users_edit',
  COMMERCES = 'commerces',
  COMMERCES_NEW = 'commerces_new',
  COMMERCES_VIEW = 'commerces_view',
  COMMERCES_EDIT = 'commerces_edit',
  COMMERCE_BUSINESS_SCHEMA_VIEW = 'commerce_business_schema_view',
  COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW = 'commerce_business_schema_quota_new',
  COMMERCE_BUSINESS_SCHEMA = 'commerce_business_schema',
  COMMERCES_BUSINESS_SCHEMA_EDIT = 'commerces_business_schema_edit',
  COMMERCES_BUSINESS_SCHEMA_NEW = 'commerces_business_schema_new',
  FINANCIAL_PRODUCTS_REQUESTS = 'financial_products_requests',
  FINANCIAL_PRODUCTS_REQUESTS_VIEW = 'financial_products_requests_view',
  FINANCIAL_PRODUCTS_REQUESTS_DETAILS = 'financial_products_requests_details',
  FINANCIAL_PRODUCTS_REQUESTS_REJECT = 'financial_products_requests_reject',
  CATALOG_ITEMS = 'catalog_items',
  CATALOG_ITEM_NEW = 'catalog_items_new',
  CATALOG_ITEM_EDIT = 'catalog_items_edit',
  CATALOG_ITEM_VIEW = 'catalog_items_view',
  CODE_MAPPINGS = 'code_mappings',
  CODE_MAPPING_NEW = 'code_mappings_new',
  CODE_MAPPING_EDIT = 'code_mappings_edit',
  CODE_MAPPING_VIEW = 'code_mappings_view',
  FPR_PROCESS_VARIABLES = 'fpr_process_variables',
  FPR_PENDING_TASKS = 'financial_product_request_pending_tasks',
  FPR_PENDING_TASKS_FINANCIAL_PRODUCTS_REQUESTS_REJECT = 'financial_product_request_pending_tasks_financial_product_request_reject',
  FPR_PENDING_TASKS_DETAIL = 'financial_product_request_pending_tasks_detail',
  FPR_PENDING_TASKS_VIEW = 'financial_product_request_pending_tasks_view',
  DYNAMIC_FORMS = 'dynamic_forms',
  DYNAMIC_FORMS_VIEW = 'dynamic_forms_view',
  REPORTS = 'reports',
  REPORTS_FILTERS = 'filters',
  REPORTS_GENERATE = 'generate',
  CALENDAR = 'calendar',
  CREATE_CALENDAR = 'new_calendar',
  VIEW_CALENDAR = 'view_calendar',
  EDIT_CALENDAR = 'edit_calendar',
  CREATE_EVENT = 'new_event',
  VIEW_EVENT = 'view_event',
  EDIT_EVENT = 'edit_event',
  RATES = 'rates',
  VIEW_RATES = 'view_rates',
  VIEW_RATE_GROUP = 'view_rate_group',
  FINANCIAL_OFFICES = 'financial_offices',
  FINANCIAL_OFFICE_NEW = 'financial_office_new',
  FINANCIAL_OFFICE_VIEW = 'financial_office_view',
  FINANCIAL_OFFICE_EDIT = 'financial_office_edit',
  FINANCIAL_OFFICE_DIVISION_NEW = 'financial_office_division_new',
  FINANCIAL_OFFICE_DIVISION_VIEW = 'financial_office_division_view',
  FINANCIAL_OFFICE_DIVISION_EDIT = 'financial_office_division_edit',
  OPERATIVE_CONTROL = 'operative_control',
  OPERATIVE_CONTROL_VIEW = 'operative_control_view',
  OPERATIVE_CONTROL_CONFIRM = 'operative_control_confirm',
  PAYMENT_BATCH = 'payment_batch',
  PAYMENT_BATCH_VIEW = 'payment_batch_view',
  PAYMENT_BATCH_CONFIRM = 'payment_batch_confirm',
}

export const paths: {
  [key in RouteKey]: string;
} = {
  [RouteKey.INDEX]: '/',
  [RouteKey.LOGIN]: '/login',
  [RouteKey.LOGOUT]: '/logout',
  [RouteKey.HOME]: '/home',
  [RouteKey.USERS]: '/users',
  [RouteKey.ROLES]: '/roles',
  [RouteKey.ROLES_NEW]: '/roles/new',
  [RouteKey.ROLES_VIEW]: '/roles/:roleId',
  [RouteKey.ROLES_EDIT]: '/roles/:roleId/edit',
  [RouteKey.USERS_NEW]: '/users/new',
  [RouteKey.USERS_VIEW]: '/users/:userId',
  [RouteKey.USERS_EDIT]: '/users/:userId/edit',
  [RouteKey.COMMERCES]: '/commerces',
  [RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW]:
    '/commerces/:commerceId/businessSchemas/:businessSchemaId',
  [RouteKey.COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW]:
    '/commerces/:commerceId/businessSchemas/:businessSchemaId/quota/new',
  [RouteKey.COMMERCES_BUSINESS_SCHEMA_EDIT]:
    '/commerces/:commerceId/commerceBusinessSchemas/:commerceBusinessSchemaId/edit',
  [RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW]:
    '/commerces/:commerceId/commerceBusinessSchemas/new',
  [RouteKey.COMMERCES_NEW]: '/commerces/new',
  [RouteKey.COMMERCE_BUSINESS_SCHEMA]: '/commerces/:commerceId/businessSchemas',
  [RouteKey.COMMERCES_VIEW]: '/commerces/:commerceId/*',
  [RouteKey.COMMERCES_EDIT]: '/commerces/:commerceId/edit',
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS]: '/financialProductsRequests',
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_DETAILS]:
    '/financialProductsRequests/:requestId/details',
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW]:
    '/financialProductsRequests/:requestId/*',
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_REJECT]:
    '/financialProductsRequests/:requestId/reject',
  [RouteKey.CATALOG_ITEMS]: '/catalogs',
  [RouteKey.CATALOG_ITEM_NEW]: '/catalogs/new',
  [RouteKey.CATALOG_ITEM_EDIT]: '/catalogs/:itemId/edit',
  [RouteKey.CATALOG_ITEM_VIEW]: '/catalogs/:itemId',
  [RouteKey.CODE_MAPPINGS]: '/codeMappings',
  [RouteKey.CODE_MAPPING_NEW]: '/codeMappings/new',
  [RouteKey.CODE_MAPPING_EDIT]: '/codeMappings/:itemId/edit',
  [RouteKey.CODE_MAPPING_VIEW]: '/codeMappings/:itemId',
  [RouteKey.FPR_PROCESS_VARIABLES]: '', // Unneeded route path
  [RouteKey.FPR_PENDING_TASKS]: '/pendingTasks',
  [RouteKey.FPR_PENDING_TASKS_FINANCIAL_PRODUCTS_REQUESTS_REJECT]:
    '/pendingTasks/:requestId/:processInstanceId/:taskId/reject',
  [RouteKey.FPR_PENDING_TASKS_DETAIL]:
    '/pendingTasks/:requestId/:processInstanceId/:taskId/pendingTask',
  [RouteKey.FPR_PENDING_TASKS_VIEW]:
    '/pendingTasks/:requestId/:processInstanceId/:taskId/*',
  [RouteKey.DYNAMIC_FORMS]: '/dynamicForms',
  [RouteKey.DYNAMIC_FORMS_VIEW]: '/dynamicForms/:name',
  [RouteKey.REPORTS]: '/reports',
  [RouteKey.REPORTS_FILTERS]: '/reports/filters',
  [RouteKey.REPORTS_GENERATE]: '/reports/generate',
  [RouteKey.CALENDAR]: '/calendar',
  [RouteKey.CREATE_CALENDAR]: '/calendar/new',
  [RouteKey.VIEW_CALENDAR]: '/calendar/:itemId',
  [RouteKey.EDIT_CALENDAR]: '/calendar/:itemId/edit',
  [RouteKey.CREATE_EVENT]: '/calendar/:itemId/event/new',
  [RouteKey.VIEW_EVENT]: '/calendar/:itemId/event/:eventId',
  [RouteKey.EDIT_EVENT]: '/calendar/:itemId/event/:eventId/edit',
  [RouteKey.RATES]: '/rates',
  [RouteKey.VIEW_RATES]: '/rates/:itemId',
  [RouteKey.VIEW_RATE_GROUP]: '/rates/:itemId/group/:groupId',
  [RouteKey.FINANCIAL_OFFICES]: '/financialOffice',
  [RouteKey.FINANCIAL_OFFICE_NEW]: '/financialOffice/create',
  [RouteKey.FINANCIAL_OFFICE_VIEW]: '/financialOffice/:financialOfficeId',
  [RouteKey.FINANCIAL_OFFICE_EDIT]:
    '/financialOffice/:financialOfficeId/update',
  [RouteKey.FINANCIAL_OFFICE_DIVISION_NEW]: '/financialOffice/division/create',
  [RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW]:
    '/financialOffice/division/:financialOfficeDivisionId',
  [RouteKey.FINANCIAL_OFFICE_DIVISION_EDIT]:
    '/financialOffice/division/:financialOfficeDivisionId/update',
  [RouteKey.OPERATIVE_CONTROL]:
   '/operativeControl',
  [RouteKey.OPERATIVE_CONTROL_VIEW]:
  '/operativeControl/:batchId/*',
  [RouteKey.OPERATIVE_CONTROL_CONFIRM]:
  '/operativeControl/confirm/:batchId/*',
  [RouteKey.PAYMENT_BATCH]: '/paymentBatch',
  [RouteKey.PAYMENT_BATCH_VIEW]: '/paymentBatch/:batchId/*',
  [RouteKey.PAYMENT_BATCH_CONFIRM]: '/paymentBatch/confirm/:batchId/*'
};

export const settings: {
  [key in RouteKey]: {};
} = {
  [RouteKey.INDEX]: {},
  [RouteKey.LOGIN]: {},
  [RouteKey.LOGOUT]: {},
  [RouteKey.HOME]: {},
  [RouteKey.USERS]: {},
  [RouteKey.ROLES]: {},
  [RouteKey.ROLES_NEW]: {},
  [RouteKey.ROLES_VIEW]: {},
  [RouteKey.ROLES_EDIT]: {},
  [RouteKey.USERS_VIEW]: {},
  [RouteKey.USERS_EDIT]: {},
  [RouteKey.USERS_NEW]: {},
  [RouteKey.COMMERCES]: {},
  [RouteKey.COMMERCES_NEW]: {},
  [RouteKey.COMMERCES_VIEW]: {},
  [RouteKey.COMMERCES_EDIT]: {},
  [RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW]: {},
  [RouteKey.COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW]: {},
  [RouteKey.COMMERCE_BUSINESS_SCHEMA]: {},
  [RouteKey.COMMERCES_BUSINESS_SCHEMA_EDIT]: {},
  [RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW]: {},
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS]: {},
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW]: {},
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_DETAILS]: {},
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_REJECT]: {},
  [RouteKey.CATALOG_ITEMS]: {},
  [RouteKey.CATALOG_ITEM_NEW]: {},
  [RouteKey.CATALOG_ITEM_EDIT]: {},
  [RouteKey.CATALOG_ITEM_VIEW]: {},
  [RouteKey.CODE_MAPPINGS]: {},
  [RouteKey.CODE_MAPPING_NEW]: {},
  [RouteKey.CODE_MAPPING_EDIT]: {},
  [RouteKey.CODE_MAPPING_VIEW]: {},
  [RouteKey.FPR_PROCESS_VARIABLES]: {},
  [RouteKey.FPR_PENDING_TASKS]: {},
  [RouteKey.FPR_PENDING_TASKS_FINANCIAL_PRODUCTS_REQUESTS_REJECT]: {},
  [RouteKey.FPR_PENDING_TASKS_DETAIL]: {},
  [RouteKey.FPR_PENDING_TASKS_VIEW]: {},
  [RouteKey.DYNAMIC_FORMS]: {},
  [RouteKey.DYNAMIC_FORMS_VIEW]: {},
  [RouteKey.REPORTS]: {},
  [RouteKey.REPORTS_FILTERS]: {},
  [RouteKey.REPORTS_GENERATE]: {},
  [RouteKey.CALENDAR]: {},
  [RouteKey.CREATE_CALENDAR]: {},
  [RouteKey.VIEW_CALENDAR]: {},
  [RouteKey.EDIT_CALENDAR]: {},
  [RouteKey.CREATE_EVENT]: {},
  [RouteKey.VIEW_EVENT]: {},
  [RouteKey.EDIT_EVENT]: {},
  [RouteKey.RATES]: {},
  [RouteKey.VIEW_RATES]: {},
  [RouteKey.VIEW_RATE_GROUP]: {},
  [RouteKey.FINANCIAL_OFFICES]: {},
  [RouteKey.FINANCIAL_OFFICE_NEW]: {},
  [RouteKey.FINANCIAL_OFFICE_VIEW]: {},
  [RouteKey.FINANCIAL_OFFICE_EDIT]: {},
  [RouteKey.FINANCIAL_OFFICE_DIVISION_NEW]: {},
  [RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW]: {},
  [RouteKey.FINANCIAL_OFFICE_DIVISION_EDIT]: {},
  [RouteKey.OPERATIVE_CONTROL]: {},
  [RouteKey.OPERATIVE_CONTROL_VIEW]: {},
  [RouteKey.OPERATIVE_CONTROL_CONFIRM]: {},
  [RouteKey.PAYMENT_BATCH]: {},
  [RouteKey.PAYMENT_BATCH_VIEW]: {},
  [RouteKey.PAYMENT_BATCH_CONFIRM]: {}
};

export type IMenuSubListItem = {
  text?: string;
  path?: string;
  type: MenuItemType.MENU | MenuItemType.GROUP | MenuItemType.DIVIDER;
  icon?: JSX.Element;
  onClick?: any;
  routeKey: RouteKey;
  key?: string;
  permissions: Permissions | Permissions[];
};

export type IMenuSectionListItem =
  | ({
      text?: string;
      path?: string;
      permissions?: Permissions;
      type: MenuItemType.MENU | MenuItemType.GROUP;
      icon?: JSX.Element;
      onClick?: any;
      items?: IMenuSubListItem[];
    } & (
      | {
          key: string;
          routeKey?: undefined;
        }
      | {
          key?: undefined;
          routeKey: RouteKey;
        }
    ))
  | {
      key?: undefined;
      routeKey?: undefined;
      type: MenuItemType.DIVIDER;
    };

export interface IMenu {
  headerItem?: IMenuSectionListItem;
  items: IMenuSectionListItem[];
}

export const MenuItemsNav: IMenuSectionListItem[] = [
  {
    type: MenuItemType.MENU,
    permissions: Permissions.SKIP_PERMISSIONS,
    icon: <LogoutIcon />,
    key: 'logout',
    text: 'route.logout.label',
    onClick: () => keycloak.logout(),
  },
];

export const drawerMenu: IMenu = {
  items: [
    {
      routeKey: RouteKey.FPR_PENDING_TASKS,
      permissions: Permissions.FPR_PENDING_TASK_READ,
      type: MenuItemType.MENU,
      icon: <PendingActionsIcon />,
    },
    {
      routeKey: RouteKey.OPERATIVE_CONTROL,
      permissions: Permissions.LOAN_BATCH_OPERATIVE_CONTROL_READ,
      type: MenuItemType.MENU,
      icon: <PendingActionsIcon />
    }, 
    {
      routeKey: RouteKey.PAYMENT_BATCH,
      permissions: Permissions.PAYMENT_BATCH_OPERATIVE_CONTROL_READ,
      type: MenuItemType.MENU,
      icon: <PendingActionsIcon />
    }, 
    {
      routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
      permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      type: MenuItemType.MENU,
      icon: <InboxOutlinedIcon />,
    },
    {
      routeKey: RouteKey.REPORTS,
      permissions: Permissions.REPORT_EXECUTION_READ,
      type: MenuItemType.MENU,
      icon: <InsertChartOutlinedIcon />,
    },
    {
      key: 'access',
      text: `menu.access`,
      type: MenuItemType.GROUP,
      icon: <TuneIcon />,
      items: [
        {
          routeKey: RouteKey.ROLES,
          type: MenuItemType.MENU,
          permissions: Permissions.ROLE_READ,
        },
        {
          routeKey: RouteKey.USERS,
          type: MenuItemType.MENU,
          permissions: Permissions.USER_READ,
        },
      ],
    },
    {
      key: 'configuration',
      text: `menu.configuration`,
      type: MenuItemType.GROUP,
      icon: <SettingsOutlinedIcon />,
      items: [
        {
          routeKey: RouteKey.COMMERCES,
          type: MenuItemType.MENU,
          permissions: Permissions.COMMERCE_READ,
        },
        {
          routeKey: RouteKey.CATALOG_ITEMS,
          type: MenuItemType.MENU,
          permissions: Permissions.CATALOG_CREATE,
        },
        {
          routeKey: RouteKey.CODE_MAPPINGS,
          type: MenuItemType.MENU,
          permissions: Permissions.CODE_MAPPING_READ,
        },
        {
          routeKey: RouteKey.CALENDAR,
          type: MenuItemType.MENU,
          permissions: [
            Permissions.CALENDAR_READ,
            Permissions.CALENDAR_CREATE,
            Permissions.CALENDAR_UPDATE,
            Permissions.CALENDAR_DELETE,
          ],
        },
        {
          routeKey: RouteKey.RATES,
          type: MenuItemType.MENU,
          permissions: [
            Permissions.INTEREST_RATE_READ,
            Permissions.INTEREST_RATE_CREATE,
            Permissions.INTEREST_RATE_UPDATE,
          ],
        },
        {
          routeKey: RouteKey.FINANCIAL_OFFICES,
          type: MenuItemType.MENU,
          permissions: [
            Permissions.COMMERCE_READ,
            // Permissions.FINANCIAL_OFFICE_READ,
            // Permissions.FINANCIAL_OFFICE_CREATE,
            // Permissions.FINANCIAL_OFFICE_UPDATE,
          ],
        },
        // {
        //   routeKey: RouteKey.DYNAMIC_FORMS,
        //   type: MenuItemType.MENU,
        // },
      ],
    },
  ],
};
