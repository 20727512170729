import { useTranslation } from 'react-i18next';
import { GridColumns } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useQuery } from 'react-query';
import qs from 'qs';
import dayjs from 'dayjs';
import BaseTablePage from '../../../commons/BaseTablePage';
import CustomDataGrid from '../../../commons/CustomDataGrid';
import FilterContainer from '../../../commons/FilterContainer';
import { RouteKey } from '../../../../navigation/constants';
import useFilterOptions from './useFilterOptions';
import { getInterestRateList } from '../../../../api/adminRates';
import useFiltersProps from '../../../../hooks/useFiltersProps';
import useRoutePage from '../../../../hooks/useRoutePage';
import {
  IRatesGroupFiltersState,
  IFilterAppliedOption,
  IFilterDatasourceOption,
} from '../../../../common/interfaces';
import Loading from '../../../commons/Loading';
import usePermissions from '../../../../hooks/usePermissions';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const RateList = () => {
  const { t } = useTranslation('global');
  const params = useParams();
  const interestRateTableId = params.itemId
    ? parseInt(params.itemId, 10)
    : undefined;

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.VIEW_RATES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IRatesGroupFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const {
    data,
    isLoading: isFetching,
    error,
    refetch,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList',
      pageNumber,
      pageSize,
      { interestRateTableId, ...filters },
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: { interestRateTableId, ...filters },
        })
      ).data,
    keepPreviousData: false,
  });

  const location = useLocation();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const getTableData = (interestRateTableGroups: any) => {
    let response: any[] = [];
    let counterId = 0;
    interestRateTableGroups?.forEach((interestRateTableGroup: any) => {
      const interestRateTableGroupName = interestRateTableGroup?.interestRateGroup?.name;
      const interestRateTableGroupId = interestRateTableGroup?.interestRateGroup?.id;
      const interestRateTableGroupLastModifiedDate =
        interestRateTableGroup?.interestRateGroup?.lastModifiedDate ||
        interestRateTableGroup?.lastModifiedDate; // REMOVE "|| ..." AFTER BE UPDATE interestRateTableGroup ...
      const interestRates = interestRateTableGroup?.interestRates.sort(
        (interestRate: any) => interestRate.fromTerm
      );

      const groupData = interestRates.map(
        (interestRate: any, index: number) => {
          counterId += 1;
          return {
            id: counterId,
            interestRateTableGroupName,
            interestRateTableGroupId,
            interestRateTableGroupLastModifiedDate,
            isFirst: index === Math.trunc((interestRates.length - 1) / 2), // 0
            isLast: index === interestRates.length - 1,
            ...interestRate,
          };
        }
      );
      groupData.forEach((interestData: any) => {
        response.push(interestData);
      });
    });
    return response && response.length > 0 ? response : [];
  };

  const rows = useMemo(() => getTableData(data?.result), [data, getTableData]);
  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);

  const columns = useMemo<GridColumns>(() => {
    const items: GridColumns = [
      {
        field: 'interestRateTableGroupName',
        headerName: t(
          'page.ratesGroup.interestRates.columnHeaders.interestRateTableGroupName'
        ),
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row.isFirst ? row.interestRateTableGroupName : ''),
        cellClassName: ({ row }) =>
          `${row.isLast ? '' : 'borderlessBottom verticalCenterCellValue'}`,
      },
      {
        field: 'interestRateTableGroupLastModifiedDate',
        headerName: t(
          'page.ratesGroup.interestRates.columnHeaders.lastModifiedDate'
        ),
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.isFirst && row?.interestRateTableGroupLastModifiedDate
            ? dayjs(row.interestRateTableGroupLastModifiedDate).format('DD/MM/YYYY')
            : '',
        cellClassName: ({ row }) =>
          `withBorderRight ${
            row.isLast ? '' : 'borderlessBottom verticalCenterCellValue'
          }`,
      },
      {
        field: 'term',
        headerName: t('page.ratesGroup.interestRates.columnHeaders.term'),
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          t('page.ratesGroup.interestRates.columnTermValue', {
            fromTerm: row.fromTerm,
            toTerm: row.toTerm,
          }),
      },
      {
        field: 'rate',
        headerName: t('page.ratesGroup.interestRates.columnHeaders.rate'),
        minWidth: 80,
        flex: 1,
        sortable: false,
      },
    ];

    return items;
  }, [tableName]);

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleView = (row: any) => {
    navigateToRoute(RouteKey.VIEW_RATE_GROUP, {
      itemId: interestRateTableId,
      groupId: row.interestRateTableGroupId,
    });
  };

  const filterOptionList = useFilterOptions();
  const { isLoading } = usePermissions();

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <BaseTablePage
      isLoading={isFetching}
      error={error}
      routeKey={RouteKey.CALENDAR}
      data={data}
      refetch={refetch}
      {...queryProps}
    >
      <FilterContainer
        title={t('page.ratesGroup.interestRates.filter.title')}
        filterOptionList={filterOptionList}
        filterDatasourceOptionList={filterDatasourceOptionList}
        translationKeyPrefix="page.ratesGroup.interestRates.filter."
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          onRowClick={({ row }) => handleView(row)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          getRowId={(r: any) => r?.id}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};
