import { Chip, ChipProps as MUIChipProps } from '@mui/material';
import { styled } from '@mui/system';
import { HighlightOff } from '@mui/icons-material';
import { useMemo } from 'react';
import { ITesteable } from '../../common/interfaces';

const StyledChip = styled(Chip)(() => ({
  fontSize: '14px',
}));

interface ChipProps extends Omit<MUIChipProps, 'sx'> {
  key: string;
}

interface ChipsGroupProps extends ITesteable, Pick<MUIChipProps, 'color'> {
  chips?: ChipProps[];
  chipSx?: any;
  onRemoveChip?: (key: string) => void;
  emptyState?: JSX.Element;
}

const ChipsGroup = ({
  dataTestId = 'ChipsGroup',
  chips = [],
  chipSx,
  onRemoveChip,
  emptyState,
  ...props
}: ChipsGroupProps) => {
  if (chips.length === 0 && emptyState) return emptyState;

  const commonProps = useMemo(
    () => ({
      sx: chipSx || { mb: 1, mr: 1 },
    }),
    [chipSx]
  );

  return (
    <>
      {chips.map(({ key, ...chipProps }) => (
        <StyledChip
          key={key}
          data-testid={`${dataTestId}_${key}`}
          deleteIcon={<HighlightOff />}
          onDelete={onRemoveChip ? () => onRemoveChip(key) : undefined}
          {...commonProps}
          {...chipProps}
          {...props}
        />
      ))}
    </>
  );
};

export default ChipsGroup;
