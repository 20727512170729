import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps, MenuItem } from '@mui/material';

import type { IOptionItem } from '../../../common/interfaces';
import type { IFormField } from '../FormFields';

type SimpleSelectFilterProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    index: number;
    label?: string;
    children?: any;
    helperText?: any;
    onChange?: any;
    dataTestId?: string;
    options?: Array<IOptionItem>;
  };

const SimpleSelectFilter = ({
  label = '',
  name,
  index,
  mandatory = false,
  children,
  helperText,
  onChange,
  options,
  dataTestId,
  ...props
}: SimpleSelectFilterProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={`${name}.${index}`}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          select
          label={mandatory ? `${label} *` : label}
          fullWidth
          helperText={error?.message ?? helperText}
          error={!!error}
          {...field}
          SelectProps={{
            // @ts-ignore
            'data-testid': dataTestId,
          }}
          inputProps={{
            'data-testid': `${dataTestId}_Input`,
          }}
          value={field.value?.value}
          onChange={(event) => {
            const optionSelected: string[] | undefined = options
              ?.filter((combo) => event.target.value === combo.key)
              .map((selected) => selected.value);
            if (!optionSelected) return;
            field.onChange({
              ...field.value,
              value: event.target.value,
              renderValue: optionSelected,
            });
          }}
          {...props}
        >
          {options?.map((opt: IOptionItem) => (
            <MenuItem key={opt.key} value={opt.key}>
              {opt.value}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SimpleSelectFilter;
