import { TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryOptions, useQueryClient } from 'react-query';
import type { ITesteable } from '../../common/interfaces';

export interface ISingleAutoCompleteProps<TOption, TValue>
  extends Omit<
      AutocompleteProps<any, any, any, any, any>,
      'onChange' | 'options' | 'renderInput'
    >,
    ITesteable {
  options?: TOption[];
  queryOptions?: QueryOptions<any[]>;
  label?: string;
  mandatory?: boolean;
  multiple?: boolean;
  helperText?: string;
  optionsMapper?: (options: TOption[]) => TOption[];
  getOptionLabel?: (option: TOption) => string;
  getOptionValue?: (option: TOption) => TValue;
  value: TValue;
  error?: any;
  onNewOption?: (inputValue: string) => Function | undefined;
  onChange: (values?: TValue) => void;
  hideInputLabel?: boolean;
  disabled?: boolean;
  variant?: any;
}

const AutocComplete = <TOption, TValue>({
  value,
  error,
  onChange,
  options: initialOptions,
  queryOptions = {},
  label,
  optionsMapper,
  // @ts-ignore
  getOptionValue = () => null,
  mandatory = false,
  multiple = false,
  onNewOption,
  helperText,
  hideInputLabel = false,
  dataTestId,
  disabled = false,
  variant = undefined,
  ...props
}: ISingleAutoCompleteProps<TOption, TValue>) => {
  const queryClient = useQueryClient();
  const [translate] = useTranslation('global');
  const [rawOptions, setOptions] = useState<TOption[]>(initialOptions ?? []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (queryOptions.queryFn) {
      (async () => {
        try {
          setIsLoading(true);
          const data = await queryClient.fetchQuery(queryOptions);
          if (data) {
            setOptions(data);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
      setOptions(initialOptions ?? []);
    }
  }, [queryClient, initialOptions, queryOptions]);

  const options = useMemo(() => {
    if (rawOptions && optionsMapper) {
      return optionsMapper(rawOptions);
    }
    return rawOptions;
  }, [rawOptions]);

  const isDisabled =
    isLoading || (!options?.length && !onNewOption) || disabled;

  const filter = useMemo(() => createFilterOptions<TOption | Function>(), []);

  return (
    <Autocomplete
      {...props}
      data-testid={dataTestId}
      disabled={isDisabled}
      options={options ?? []}
      noOptionsText={translate('common.noOptions')}
      renderInput={(params) => (
        <TextField
          helperText={error?.message || helperText}
          error={!!error}
          variant={variant}
          label={hideInputLabel ? undefined : mandatory ? `${label}*` : label}
          {...params}
        />
      )}
      filterOptions={(filterOptions, params) => {
        const filtered = filter(filterOptions, params);
        if (!onNewOption) return filtered;
        const fn = onNewOption(params.inputValue);
        if (fn) filtered.push(fn);
        return filtered;
      }}
      value={
        [value].map((optionValue: TValue) => {
          if (!options?.length) return null;
          const find = options?.find(
            (option) =>
              optionValue &&
              JSON.stringify(optionValue) ===
                JSON.stringify(getOptionValue(option))
          );
          return find ?? null;
        })[0] ?? null
      }
      isOptionEqualToValue={(option1, option2) =>
        getOptionValue(option1) === getOptionValue(option2)
      }
      onChange={(_, data: TOption) => {
        try {
          onChange(getOptionValue(data));
        } catch (err) {
          onChange(undefined);
        }
      }}
    />
  );
};

export default AutocComplete;
