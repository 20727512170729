import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Alert } from 'vulpino';
import { getReportList } from '../../api/adminReports';
import useRoutePage from '../../hooks/useRoutePage';
import { RouteKey } from '../../navigation/constants';
import Loading from '../commons/Loading';
import ReportExecutionCard from '../pagePartial/ReportExecutionCard';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filters?: {
    userName?: string;
  };
}

interface ReportListProps {
  username: string;
}

export const ReportList = ({ username }: ReportListProps) => {
  const { t } = useTranslation();

  const { routeState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.REPORTS,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filters: {
        userName: username,
      },
    },
  });

  const { pageNumber, pageSize, filters } = routeState;

  const { data: reportExecutionList, isLoading } = useQuery({
    queryKey: ['getReportList', pageNumber, pageSize],
    queryFn: async () =>
      (await getReportList({ pageNumber, pageSize, filters })).data,
    keepPreviousData: true,
  });

  return isLoading ? (
    <Loading />
  ) : reportExecutionList?.result?.length < 1 ? (
    <Alert
      severity="info"
      sx={{
        marginTop: 2,
        border: 1,
        borderColor: '#3988FF',
      }}
    >
      {t('page.reports.noReports')}
    </Alert>
  ) : (
    reportExecutionList?.result.map((reportExecution: any) => (
      <ReportExecutionCard reportExecution={reportExecution} />
    ))
  );
};
