import type {
  IGetListResponse,
  IPaginatedParams,
  IFPRPendingTask,
  ITask,
} from '../../common/interfaces';
import { IFPRPendingTasksPageFiltersState } from '../../pages/FPRPendingTasksPage/interfaces';
import { pathReplace } from '../../utils/apiUtils';

import fetch from '../api';
import { env } from '../env';
import {
  PENDING_TASKS_GET_ONE_PATH,
  PENDING_TASKS_SEARCH_PATH,
} from './constants';

interface IGetFPRPendingTasksParams extends IPaginatedParams {
  filters?: IFPRPendingTasksPageFiltersState;
}

export const getFPRPendingTasks = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetFPRPendingTasksParams = {}): Promise<
  IGetListResponse<IFPRPendingTask>
> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: PENDING_TASKS_SEARCH_PATH,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      sort: `startDate:desc`,
      ...filters,
    },
  });

export const getFPRPendingTask = (
  processInstanceId: string,
  taskId: string
): Promise<{
  data: ITask;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: pathReplace(PENDING_TASKS_GET_ONE_PATH, {
      processInstanceId,
      taskId,
    }),
    method: 'get',
  });

export const putFPRPendingTask = (
  processInstanceId: string,
  taskId: string,
  map: any
): Promise<any> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: pathReplace(PENDING_TASKS_GET_ONE_PATH, {
      processInstanceId,
      taskId,
    }),
    method: 'put',
    data: {
      inputMap: map,
    },
  });

export const deleteWorkflowBusinessInstance = (
  processInstanceId: string,
  deleteData: any
): Promise<any> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/workflow-business-instances/${processInstanceId}`,
    method: 'delete',
    data: deleteData,
  });
