import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import type {
  IFormRef,
  ICatalogItemData,
  ITesteable,
  ICatalogItemFormData,
} from '../../common/interfaces';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';
import useRoutePage from '../../hooks/useRoutePage';
import CatalogItemForm from '../../components/CatalogItemForm';
import {
  createCatalogItem,
  getCatalogItem,
  updateCatalogItem,
} from '../../api/adminPlatformCatalogs/service';

interface ICatalogItemEditPageProps extends ITesteable {}

const CatalogItemEditPage = ({
  dataTestId = 'CatalogItemEditPage',
}: ICatalogItemEditPageProps) => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();

  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const currentRouteKey =
    itemId !== undefined
      ? RouteKey.CATALOG_ITEM_EDIT
      : RouteKey.CATALOG_ITEM_NEW;

  const { navigateToRoute } = useRoutePage({
    routeKey: currentRouteKey,
  });

  const formRef = useRef<IFormRef>();

  const { setToast } = useUI();

  const location = useLocation();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const { data, ...queryProps } = useQuery<ICatalogItemData>({
    queryKey: [`catalogItem:${itemId}`],
    queryFn: async () =>
      itemId
        ? (await getCatalogItem(itemId)).data
        : ({
            tableName,
          } as ICatalogItemData),
    keepPreviousData: true,
  });

  const goBack = useCallback(
    () =>
      navigateToRoute(RouteKey.CATALOG_ITEMS, {
        search: tableName ? `?tableName=${tableName}` : undefined,
      }),
    [navigateToRoute, tableName]
  );

  const onSubmit = async (payload: ICatalogItemFormData) => {
    try {
      setBusy(true);

      if (itemId !== undefined) {
        await updateCatalogItem(itemId, payload);
      } else {
        await createCatalogItem(payload);
      }

      setToast({
        key: `${dataTestId}_Success`,
        message: translate('page.catalog.saveToastSuccessMessage'),
      });
      goBack();
    } catch (error) {
      const message = getErrorMessage(
        error,
        translate('page.catalog.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  return (
    <BaseViewPage
      routeKey={currentRouteKey}
      routeTrail={[RouteKey.CATALOG_ITEMS]}
      data={data}
      dataTestId={dataTestId}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={12} xs={24}>
        <CatalogItemForm
          dataTestId={`${dataTestId}_Form`}
          tableName={tableName}
          formRef={formRef}
          itemId={itemId}
          data={data}
          onSubmit={onSubmit}
          onError={console.log}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default CatalogItemEditPage;
