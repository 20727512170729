import usePlatformCatalogs, {
  PlatformCatalogKey,
} from '../../hooks/usePlatformCatalogs';

import PromisedValue from './PromisedValue';

const PromisedPlatformCatalog = ({
  catalog,
  id,
  params,
}: {
  catalog: PlatformCatalogKey;
  id?: number;
  params?: {};
}) => {
  const { getCatalogValue } = usePlatformCatalogs();

  if (!id) return null;

  return (
    <PromisedValue
      value={id}
      fn={getCatalogValue}
      arg={{
        catalog,
        id,
        params,
      }}
    />
  );
};

export default PromisedPlatformCatalog;
