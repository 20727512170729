export enum FinancialProductType {
  LOAN = 'LOAN',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum TermOfPayment {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  BIWEEKLY = 'BIWEEKLY',
}

export enum LoanProductStatus {
  PRE_APPROVED = 'PRE_APPROVED',
  APPROVED = 'APPROVED',
  READY_FOR_CREATION = 'READY_FOR_CREATION',
  CREATED = 'CREATED',
  DISBURSED = 'DISBURSED',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  RETURNED = 'RETURNED'
}

export enum CreditCardProductStatus {
  PRE_APPROVED = 'PRE_APPROVED',
  APPROVED = 'APPROVED',
  READY_FOR_CREATION = 'READY_FOR_CREATION',
  CC_CREATION = 'CC_CREATION',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  SHIPPING = 'SHIPPING',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED'
}

export enum FinancialProductRequestStatus {
  ONBOARDING = 'ONBOARDING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  ACTIVATION_PROCESS = 'ACTIVATION_PROCESS',
  FINALIZED = 'FINALIZED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  ABORTED = 'ABORTED',
}

export enum HistoricalTaskStatus {
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export enum FilterOptionTypes {
  CHECKBOX = 'CHECKBOX',
  STRING = 'STRING',
  /**
   * @deprecated
   **/
  SELECT = 'SELECT',
  /**
   * @deprecated
   **/
  MULTISELECT = 'MULTISELECT',
  DATE_FROM = 'DATE_FROM',
  DATE_TO = 'DATE_TO',
  DATASOURCE = 'DATASOURCE',
}

export enum MetadataKey {
  BNPL_PROVIDER_ID = 'BNPL_PROVIDER_ID',
  BPM_CANDIDATE_GROUPS = 'BPM_CANDIDATE_GROUPS',
  COMMERCE_ID_LIST = 'COMMERCE_ID_LIST',
  CUSTOMER_CODE_LIST = 'CUSTOMER_CODE_LIST',
  DIVISION_ID_LIST = 'DIVISION_ID_LIST',
  FINANCIAL_OFFICE_ID_LIST = 'FINANCIAL_OFFICE_ID_LIST',
  FINANCIAL_PRODUCT_REQUEST_ID_LIST = 'FINANCIAL_PRODUCT_REQUEST_ID_LIST',
  FUNDING_ENTITY_ID_LIST = 'FUNDING_ENTITY_ID_LIST',
  ORIGINATOR_ID_LIST = 'ORIGINATOR_ID_LIST',
  UNRESTRICTED_RESOURCE_ACCESS = 'UNRESTRICTED_RESOURCE_ACCESS',
  REPORT_TRANSACTION_ID_LIST = "REPORT_TRANSACTION_ID_LIST",
  ROLES_ALLOWED_TO_ASSIGN = "ROLES_ALLOWED_TO_ASSIGN"
}

export enum ContactInfoType {
  CELLPHONE = 'CELLPHONE',
  EMAIL = 'EMAIL',
}

export enum DocumentStatus {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  REJECTED = 'REJECTED',
}

export enum ValidationStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum ReportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum MenuItemType {
  MENU = 'MENU',
  GROUP = 'GROUP',
  DIVIDER = 'DIVIDER',
}

export enum FPRPendingTaskType {
  DYNAMIC_FORM = 'dynamicForm',
}

export enum Permissions {
  ROLE_READ = 'ROLE_READ',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_DELETE = 'ROLE_DELETE',
  ROLE_UPDATE = 'ROLE_UPDATE',
  USER_READ = 'USER_READ',
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  CATALOG_CREATE = 'CATALOG_CREATE',
  CATALOG_READ = 'CATALOG_READ',
  CATALOG_UPDATE = 'CATALOG_UPDATE',
  COMMERCE_READ = 'COMMERCE_READ',
  COMMERCE_CREATE = 'COMMERCE_CREATE',
  COMMERCE_UPDATE = 'COMMERCE_UPDATE',
  CODE_MAPPING_CREATE = 'CODE_MAPPING_CREATE',
  CODE_MAPPING_UPDATE = 'CODE_MAPPING_UPDATE',
  CODE_MAPPING_READ = 'CODE_MAPPING_READ',
  FINANCIAL_PRODUCT_REQUEST_READ = 'FINANCIAL_PRODUCT_REQUEST_READ',
  FINANCIAL_PRODUCT_REQUEST_UPDATE = 'FINANCIAL_PRODUCT_REQUEST_UPDATE',
  FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ = 'FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ',
  FINANCIAL_PRODUCT_REQUEST_CONTEXT_DATA_READ = 'FINANCIAL_PRODUCT_REQUEST_CONTEXT_DATA_READ',
  FPR_PENDING_TASK_READ = 'FPR_PENDING_TASK_READ',
  FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ = 'FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ',
  WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ = 'WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ',
  SKIP_PERMISSIONS = 'SKIP_PERMISSIONS',
  WORKFLOW_BUSINESS_INSTANCE_TASK_UPDATE = 'WORKFLOW_BUSINESS_INSTANCE_TASK_UPDATE',
  REPORT_EXECUTION_READ = 'REPORT_EXECUTION_READ',
  REPORT_EXECUTION_CREATE = 'REPORT_EXECUTION_CREATE',
  CALENDAR_UPDATE = 'CALENDAR_UPDATE',
  CALENDAR_CREATE = 'CALENDAR_CREATE',
  CALENDAR_READ = 'CALENDAR_READ',
  CALENDAR_DELETE = 'CALENDAR_DELETE',
  INTEREST_RATE_READ = 'INTEREST_RATE_READ',
  INTEREST_RATE_CREATE = 'INTEREST_RATE_CREATE',
  INTEREST_RATE_UPDATE = 'INTEREST_RATE_UPDATE',
  WORKFLOW_BUSINESS_INSTANCE_VARIABLE_READ = "WORKFLOW_BUSINESS_INSTANCE_VARIABLE_READ",
  FINANCIAL_PRODUCT_REQUEST_VALIDATION_INFO_READ = "FINANCIAL_PRODUCT_REQUEST_VALIDATION_INFO_READ",
  TRACE_READ = "TRACE_READ",
  FINANCIAL_OFFICE_READ = 'FINANCIAL_OFFICE_READ',
  FINANCIAL_OFFICE_CREATE = 'FINANCIAL_OFFICE_CREATE',
  FINANCIAL_OFFICE_UPDATE = 'FINANCIAL_OFFICE_UPDATE',
  DIVISION_CREATE = 'DIVISION_CREATE',
  DIVISION_READ = 'DIVISION_READ',
  DIVISION_UPDATE = 'DIVISION_UPDATE',
  FRONTEND_WORKFLOW_BUSINESS_INSTANCE_VARIABLE_READ = "FRONTEND_WORKFLOW_BUSINESS_INSTANCE_VARIABLE_READ",
  FRONTEND_FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ = 'FRONTEND_FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ',
  FRONTEND_FINANCIAL_OFFICE_READ = 'FRONTEND_FINANCIAL_OFFICE_READ',
  FRONTEND_CODE_MAPPING_READ = 'FRONTEND_CODE_MAPPING_READ',
  LOAN_BATCH_OPERATIVE_CONTROL_READ = 'LOAN_BATCH_OPERATIVE_CONTROL_READ',
  PAYMENT_BATCH_OPERATIVE_CONTROL_READ = 'PAYMENT_BATCH_OPERATIVE_CONTROL_READ',
}
