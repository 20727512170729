import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'vulpino';
import { getInterestRateList } from '../../../api/adminRates';
import BaseViewHeader from '../../../components/BaseViewHeader';
import BaseViewPage from '../../../components/commons/BaseViewPage';
import { RouteKey } from '../../../navigation/constants';
import useRoutePage from '../../../hooks/useRoutePage';
import { RateList } from '../../../components/pagePartial/Rates/RatesGroup/RateList';
import { PaymentPlace } from '../../../components/pagePartial/Rates/RatesGroup/PaymentPlace';
import Loading from '../../../components/commons/Loading';
import usePermissions from '../../../hooks/usePermissions';

export const RatesGroup = () => {
  const { t } = useTranslation();
  const params = useParams();
  const interestRateTableId = {
    interestRateTableId: params.itemId ? parseInt(params.itemId, 10) : {},
  };

  const { routeState, mergeRouteState, navigateToRoute } = useRoutePage({
    routeKey: RouteKey.VIEW_RATES,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const options = [
    {
      label: t('page.ratesGroup.tabs.first'),
      value: '1',
    },
    {
      label: t('page.ratesGroup.tabs.second'),
      value: '2',
    },
  ];

  const { pageNumber, pageSize } = routeState;

  const {
    data,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList',
      pageNumber,
      pageSize,
      interestRateTableId,
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: interestRateTableId,
        })
      ).data,
    keepPreviousData: false,
  });

  const { isLoading } = usePermissions();
  const routeKey: any = `${t('route.view_rates.label')}${
    data?.result[0]?.interestRateTable?.name
      ? `: ${data?.result[0]?.interestRateTable?.name}`
      : ''
  }`;

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <>
      <BaseViewPage
        isLoading={isFetching}
        error={error}
        routeKey={routeKey}
        routeTrail={[RouteKey.RATES]}
        data={data?.result[0]}
        {...queryProps}
      >
        <BaseViewHeader
          title={data?.result[0]?.interestRateTable?.name}
          suptitle={
            <Typography variant="h2">{t('page.ratesGroup.title')}</Typography>
          }
        >
          <Grid container>
            <Grid item>
              <Typography sx={{ fontSize: '20px', marginTop: '2px' }}>
                {data?.result[0]?.interestRateTable?.description}
              </Typography>
            </Grid>
          </Grid>
        </BaseViewHeader>
      </BaseViewPage>

      <Tabs
        style={{ borderBottom: '3px solid #E0E0E0' }}
        value={value}
        options={options}
        onChange={handleChange}
        tabProps={{ sx: { p: 2, marginLeft: 3 } }}
      >
        <Box>
          <RateList />
        </Box>
        <Box>
          <PaymentPlace />
        </Box>
      </Tabs>
    </>
  );
};
