import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { Button, Stepper } from 'vulpino';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUI } from '../../../contexts/UIContext';
import { RouteKey, paths } from '../../../navigation/constants';
import MultipleAutoComplete from '../../../components/commons/MultipleAutoComplete';
import { generateReport } from '../Service';
import TitledSection from '../../../components/commons/TitledSection';
import BreadcrumbsNavigator from '../../../components/commons/BreadcrumbsNavigator';
import { ToastType } from '../../../components/commons/Toast';
import { IGenerateReportRequest } from '../interfaces';
import ReportFilterChips from '../../../components/commons/ReportFilterChips';

export const GeneratePage = () => {
  const { reportContext, setToast } = useUI();
  const { t } = useTranslation('global');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!reportContext || Object.keys(reportContext).length === 0) {
      navigate(paths[RouteKey.REPORTS]);
    }
  }, []);

  const steps = [
    {
      label: t('page.reports.filters.steps.firstStep'),
    },
    {
      label: t('page.reports.filters.steps.secondStep'),
    },
  ];

  const onNewOption = useCallback((inputValue: string) => {
    if (!inputValue) return undefined;
    return () => ({
      value: inputValue,
      label: t('common.addX', { key: inputValue }),
    });
  }, []);

  const schema = yup.object().shape({
    email: yup.array().of(yup.string().email()),
  });

  const methods = useForm({
    defaultValues: {
      email: [],
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    let filtersString: any = reportContext.filters;

    if (reportContext?.filters) {
      Object.keys(reportContext.filters).forEach((key) => {
        if (Array.isArray(reportContext.filters[key])) {
          filtersString[key] = reportContext.filters[key].join(', ');
        }
      });
    }

    const generateReportRequest: IGenerateReportRequest = {
      reportDefinitionId: reportContext.reportDefinitionId,
      fileCompression: true,
      filtersUsed: filtersString,
      recipients: formData.email,
    };
    await generateReport(generateReportRequest);
    setToast({
      type: ToastType.SUCCESS,
      message: 'Reporte generado correctamente',
    });

    setIsLoading(false);

    navigate(paths[RouteKey.REPORTS], {
      replace: true,
    });
  };

  const handleClick = () => {
    methods.handleSubmit(onSubmit)();
  };

  const handleEdit = () => {
    navigate(-1);
  };

  return !reportContext ? (
    <Navigate to={paths[RouteKey.REPORTS]} />
  ) : (
    <div>
      <BreadcrumbsNavigator
        title="Nuevo reporte"
        routes={[RouteKey.REPORTS, RouteKey.REPORTS_GENERATE]}
        current={RouteKey.REPORTS_GENERATE}
      />
      <TitledSection
        variant="h1"
        title={t(
          `page.reports.filters.title.${reportContext.reportDefinitionName}`
        )}
      />
      <Box bgcolor="#f5f5f5" padding="18px" marginTop="20px" borderRadius="5px">
        <Stepper steps={steps} activeStep={1} orientation="horizontal" />
      </Box>
      <Grid container item xs={12} marginBottom={2}>
        <h3>
          {t('page.reports.generate.filterTitle')}{' '}
          <Button onClick={handleEdit} disabled={isLoading} variant="tertiary">
            {t('page.reports.generate.edit')}
          </Button>
        </h3>
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <ReportFilterChips
          reportDefinitionName={
            reportContext.reportDefinitionName === 'traces_report'
              ? 'trace_report'
              : reportContext.reportDefinitionName
          }
          filters={reportContext?.filters}
        />
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <h3>{t('page.reports.generate.genTitle')}</h3>
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <Typography variant="body2">
          {t('page.reports.generate.subtitle')}
        </Typography>
      </Grid>
      <FormProvider {...methods}>
        <Controller
          name="email"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <MultipleAutoComplete
              value={field.value ?? []}
              label="Email"
              onChange={field.onChange}
              error={error}
              freeSolo
              helperText={t('page.reports.generate.helper')}
              onNewOption={onNewOption}
              {...{
                getOptionLabel: (option) =>
                  isFunction(option) ? option().label : option,
                getOptionValue: (option) =>
                  isFunction(option) ? option().value : option,
              }}
            />
          )}
        />
      </FormProvider>
      <Grid container item xs={6}>
        <Grid item xs={6} marginTop={2}>
          <Button
            onClick={handleClick}
            loadingProps={{
              leading: true,
            }}
            loading={isLoading}
            fullWidth
            variant="primary"
          >
            Generar Reporte
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
