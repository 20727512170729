import { useEffect, useMemo, useState } from 'react';
import { pick } from 'lodash';
import { useQuery } from 'react-query';
import { ICardDefinition } from '../components/pagePartial/interfaces';
import BaseViewPage from '../components/commons/BaseViewPage';
import FPRHeader from '../components/pagePartial/FPRHeader';
import CommerceDataCard, {
  ICommerceDataCard,
} from '../components/pagePartial/CommerceDataCard';
import SelectedFinancialProductsCard from '../components/pagePartial/SelectedFinancialProductsCard';
import CartInfoCard from '../components/pagePartial/CartInfoCard';
import RequestContextCard from '../components/pagePartial/RequestContextCard';
import ContactInfoCard from '../components/pagePartial/ContactInfoCard';
import CustomerDataCard from '../components/pagePartial/CustomerDataCard';
import DocumentDataCard from '../components/pagePartial/DocumentDataCard';
import TermsAndConditionsAcceptancesCard from '../components/pagePartial/TermsAndConditionsAcceptancesCard';
import ProcessVariablesCard from '../components/pagePartial/ProcessVariablesCard';
import IdentityVerificationInfoCard from '../components/pagePartial/IdentityVerificationInfoCard';
import ValidationInfoCard from '../components/pagePartial/ValidationInfoCard';
import AuditTrailsCard from '../components/pagePartial/AuditTrailsCard';
import HistoricalTasksCard from '../components/pagePartial/HistoricalTasksCard';
import PendingTaskCard from '../components/pagePartial/PendingTaskCard';
import CommerceDetail from '../components/pagePartial/Commerces/CommerceDetail';
import CommerceBusinessSchemas from '../components/pagePartial/Commerces/CommerceBusinessSchemas';
import CommerceBusinessSchemasDetail from '../components/pagePartial/Commerces/CommerceBusinessSchemasDetail';
import { getCommerce } from '../api/adminBusinessEnvironment/service';
import TracesCard from '../components/pagePartial/TracesCard';
import { Permissions } from '../common/enums';
import StyledButton from '../components/ui/StyledButton';
import { RouteKey } from '../navigation/constants';

interface IUseFPRCardsProps {
  requestData: any;
  requestId?: string;
  taskId?: string;
  taskData?: any;
  commerceId?: any;
  businessSchemaId?: any;
}

export const sections: ICardDefinition[] = [
  {
    key: 'pendingTask',
    condition: ({ taskId }) => !!taskId,
    render: ({
      taskData,
      requestData,
      baseViewPageProps,
      dataTestId,
      taskId,
      goBack,
      processInstanceId,
      requestId,
    }) => (
      <BaseViewPage {...baseViewPageProps} title={undefined}>
        <FPRHeader
          data={requestData}
          dataTestId={`${dataTestId}_Header`}
          nameAsData
          showDates
          // supButtons={
          //   [
          //     <StyledButton
          //       variant="outlined"
          //       color="error"
          //       labelKey="page.financialProductRequestPendingTask.rejectRequest"
          //       toRouteKey={RouteKey.FPR_PENDING_TASKS_FINANCIAL_PRODUCTS_REQUESTS_REJECT}
          //       toRouteParams={{
          //         requestId,
          //         taskId,
          //         processInstanceId,
          //       }}
          //     />,
          //   ]
          // }
          // permissionsSupButtons={[Permissions.FINANCIAL_PRODUCT_REQUEST_UPDATE]}
        />
        {taskData?.dynamicFormName && taskId && processInstanceId && (
          <PendingTaskCard
            dynamicFormName={taskData?.dynamicFormName}
            taskId={taskId}
            processInstanceId={processInstanceId}
            financialProductRequestId={requestId}
            goBack={goBack}
          />
        )}
      </BaseViewPage>
    ),
  },
  {
    key: 'details',
    condition: ({ commerceId }) => !commerceId,
    render: ({
      baseViewPageProps,
      requestData,
      commerceData,
      dataTestId,
      requestId,
    }) => (
      <BaseViewPage {...baseViewPageProps}>
        <FPRHeader
          data={requestData}
          dataTestId={`${dataTestId}_Header`}
          showStatus
          showDates
        />
        <CommerceDataCard data={commerceData} />
        <SelectedFinancialProductsCard
          selectedFinancialProductList={requestData?.selectedFinancialProducts}
          requestContextData={requestData?.requestContextData}
        />
        <CartInfoCard data={requestData?.commerceInfo} />
        <RequestContextCard data={requestData?.requestContextData} />
      </BaseViewPage>
    ),
  },
  {
    key: 'customerData',
    condition: ({ commerceId }) => !commerceId,
    render: ({ baseViewPageProps, requestData, dataTestId }) => (
      <BaseViewPage {...baseViewPageProps}>
        <FPRHeader data={requestData} dataTestId={`${dataTestId}_Header`} />
        <ContactInfoCard contactInfo={requestData?.contactInfo ?? []} />
        <CustomerDataCard data={requestData?.customerData} />
      </BaseViewPage>
    ),
  },
  {
    key: 'documentation',
    condition: ({ commerceId }) => !commerceId,
    render: ({ baseViewPageProps, requestData }) => (
      <BaseViewPage {...baseViewPageProps}>
        <DocumentDataCard data={requestData} />
        <TermsAndConditionsAcceptancesCard
          data={requestData?.termsAndConditionsAcceptances ?? []}
        />
      </BaseViewPage>
    ),
  },
  {
    key: 'processVariables',
    condition: ({ commerceId }) => !commerceId,
    render: ({ baseViewPageProps, requestData }) => (
      <BaseViewPage
        key="processVariables"
        {...baseViewPageProps}
        title={undefined}
      >
        <ProcessVariablesCard
          processInstanceId={requestData?.businessProcessInfo.processInstanceId}
        />
      </BaseViewPage>
    ),
  },
  {
    key: 'traces',
    condition: ({ commerceId }) => !commerceId,
    render: ({ baseViewPageProps, requestData }) => (
      <BaseViewPage key="traces" {...baseViewPageProps} title={undefined}>
        <TracesCard
          processInstanceId={requestData?.businessProcessInfo.processInstanceId}
        />
      </BaseViewPage>
    ),
  },
  {
    key: 'validations',
    condition: ({ commerceId }) => !commerceId,
    render: ({ baseViewPageProps, requestData }) => (
      <BaseViewPage {...baseViewPageProps}>
        <IdentityVerificationInfoCard
          data={requestData?.identityVerificationInfo}
        />
        <ValidationInfoCard
          key="validationInfo"
          data={requestData?.validationInfo ?? []}
        />
      </BaseViewPage>
    ),
  },
  {
    key: 'auditTrails', // historial de estados
    condition: ({ requestData, commerceId }) => !!requestData && !commerceId,
    render: ({ baseViewPageProps, requestId }) => (
      <BaseViewPage {...baseViewPageProps}>
        <AuditTrailsCard key="auditTrails" requestId={requestId} />
      </BaseViewPage>
    ),
  },
  {
    key: 'historicalTasks',
    condition: ({ requestData, commerceId }) =>
      requestData?.businessProcessInfo?.processInstanceId && !commerceId,
    render: ({ baseViewPageProps, requestData }) => (
      <BaseViewPage {...baseViewPageProps}>
        <HistoricalTasksCard
          key="historicalTasks"
          processInstanceId={requestData?.businessProcessInfo.processInstanceId}
        />
      </BaseViewPage>
    ),
  },
  {
    key: 'commerceDetail',
    condition: ({ commerceId, businessSchemaId }) =>
      commerceId && !businessSchemaId,
    render: ({ baseViewPageProps }) => <CommerceDetail />,
  },
  {
    key: 'businessSchemas',
    condition: ({ commerceId, businessSchemaId, isHeadquarter }) =>
      commerceId && !businessSchemaId && isHeadquarter,
    render: ({ baseViewPageProps }) => <CommerceBusinessSchemas />,
  },
  {
    key: 'businessSchemasDetail',
    condition: ({ commerceId, businessSchemaId, isHeadquarter }) =>
      commerceId && businessSchemaId && isHeadquarter,
    render: ({ baseViewPageProps }) => <CommerceBusinessSchemasDetail />,
  },
];

const useFPRCards = ({
  requestData,
  requestId,
  taskId,
  taskData,
  commerceId,
  businessSchemaId,
}: IUseFPRCardsProps) => {
  const commerceData = useMemo(() => {
    const memoData: ICommerceDataCard = {
      ...pick(requestData, ['businessSchemaId', 'originatorId']),
      ...pick(requestData?.commerceInfo ?? {}, [
        'approvalDatetime',
        'approvalUsername',
        'commerceId',
        'deliveryDatetime',
        'deliveryUsername',
        'headquarterId',
      ]),
      ...pick(requestData?.fundingEntityInfo ?? {}, [
        'divisionId',
        'financialOfficeId',
        'fundingEntityId',
      ]),
    };

    if (
      requestData?.commerceInfo?.approvalUsername ||
      requestData?.commerceInfo?.approvalDatetime
    )
      Object.assign(memoData, {
        approval: {
          username: requestData?.commerceInfo?.approvalUsername,
          datetime: requestData?.commerceInfo?.approvalDatetime,
        },
      });

    if (
      requestData?.commerceInfo?.deliveryUsername ||
      requestData?.commerceInfo?.deliveryDatetime
    )
      Object.assign(memoData, {
        delivery: {
          username: requestData?.commerceInfo?.deliveryUsername,
          datetime: requestData?.commerceInfo?.deliveryDatetime,
        },
      });

    return memoData;
  }, [requestData]);

  const { data: commerce = undefined } = commerceId
    ? useQuery({
        queryFn: async () => (await getCommerce(commerceId!)).data,
      })
    : {};

  const [isHeadquarter, setIsHeadquarter] = useState(false);

  useEffect(() => {
    if (commerce && commerce.id === commerce.headquarterId) {
      setIsHeadquarter(true);
    }
  }, [commerce]);

  return useMemo(
    () => ({
      cards: sections.filter((section) =>
        section.condition
          ? section.condition({
              requestData,
              commerceData,
              taskId,
              taskData,
              commerceId,
              businessSchemaId,
              isHeadquarter,
            })
          : true
      ),
      commerceData,
    }),
    [requestData, commerceData, requestId, taskData, taskId, isHeadquarter]
  );
};

export default useFPRCards;
