import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';

import { ITermsAndConditionsAcceptance } from '../../common/interfaces';

import CardContainer from './CardContainer';
import StyledHR from '../commons/StyledHR';

const StyledValue = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 'bold',
}));

type TermsAndConditionsAcceptancesCardProps = {
  data: ITermsAndConditionsAcceptance[];
};

const TermsAndConditionsAcceptancesCard = ({
  data = [],
}: TermsAndConditionsAcceptancesCardProps) => {
  const [translate] = useTranslation('global');

  return (
    <CardContainer
      title={translate(
        'page.financialProductRequest.termsAndConditionsAcceptances.title'
      )}
    >
      {data.map((item, index) => (
        <Grid container key={item.version}>
          {index > 0 && <StyledHR sx={{ mt: 2}} />}
          <Grid item xs={12} mb={1}>
            <Typography fontWeight="bold">{item.code}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Versión de términos y condiciones:
              <StyledValue>{item.version}</StyledValue>
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography>
              Fecha de aceptación de términos y condiciones:
              <StyledValue>{dayjs(item.date).format(translate('date.short'))}</StyledValue>
            </Typography>
          </Grid>
        </Grid>
      ))}
    </CardContainer>
  );
};

export default TermsAndConditionsAcceptancesCard;
