import i18next from 'i18next';
import { IApiError } from '../common/interfaces';

export const getError = (errorResponse: any = undefined): IApiError => {
  if (errorResponse === undefined) {
    return { status: 500 };
  }
  return errorResponse;
};

export const translateApiErrorCode = (response: IApiError) => {
  if (response?.data?.errorCode !== undefined) {
    return i18next.t('error.api.' + response.data.errorCode.toString()) || response.data.errorCode;
  }
  return '';
};

export const getErrorMessage = (error: any, defaultMessage = ''): string => {
  const errorMessage = getError(error);
  return errorMessage !== undefined
    ? translateApiErrorCode(errorMessage)
    : defaultMessage;
};

export const getErrorMessageFromValidationErrors = (
  error: any,
  defaultMessage = ''
): string => {
  if (error?.data?.validationErrors) {
    error.data.errorCode =
      error?.data?.validationErrors[0]?.errorDetail ??
      error?.data?.validationErrors?.errorDetail ??
      error.data.errorCode;
  }
  return getErrorMessage(error, defaultMessage);
};

export const pathReplace = (path: string, obj: object) => {
  let replaced = path;
  Object.entries(obj).forEach(([key, value]: [string, string]) => {
    replaced = replaced.replace(new RegExp('{' + key + '}', 'g'), value);
  });
  return replaced;
};

export default getError;
