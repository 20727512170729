import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import type {
  IFormRef,
  ITesteable,
  IUserFormData,
} from '../../common/interfaces';
import type { IUserData } from '../../api/adminUsersRoles/interfaces';
import {
  createUser,
  getUser,
  updateUser,
} from '../../api/adminUsersRoles/service';
import UserForm from '../../components/UserForm';
import { getErrorMessageFromValidationErrors } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { paths, RouteKey } from '../../navigation/constants';
import BaseViewPage from '../../components/commons/BaseViewPage';

interface IUserEditPageProps extends ITesteable {}

const UserEditPage = ({ dataTestId = 'UserEditPage' }: IUserEditPageProps) => {
  const [translate] = useTranslation('global');

  const formRef = useRef<IFormRef>();

  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();

  const { setToast } = useUI();

  const navigate = useNavigate();
  const params = useParams();
  const { userId } = params;
  const { data, ...queryProps } = useQuery<IUserData>({
    queryKey: [`user:${userId}`],
    queryFn: async () => (userId ? (await getUser(userId)).data : {}),
    keepPreviousData: true,
  });

  const goBack = () => {
    navigate(paths[RouteKey.USERS]);
  };

  const onSubmit = async (payload: IUserFormData) => {
    try {
      setBusy(true);
      if (userId !== undefined) {
        await updateUser(userId, {
          active: true,
          ...payload,
        });
      } else {
        await createUser(payload);
      }

      setToast({
        message: translate('page.user.userSaveToastSuccessMessage'),
        key: `${dataTestId}_Success`,
      });
      goBack();
    } catch (error) {
      const message = getErrorMessageFromValidationErrors(
        error,
        translate('page.user.userSaveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey =
    userId !== undefined ? RouteKey.USERS_EDIT : RouteKey.USERS_NEW;

  return (
    <BaseViewPage
      dataTestId={dataTestId}
      routeKey={currentRouteKey}
      routeTrail={[RouteKey.USERS]}
      data={data}
      {...queryProps}
      buttonConfList={[
        {
          dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item container xs={12}>
        <Grid item sm={6} xs={12}>
          <UserForm
            dataTestId={`${dataTestId}_Form`}
            userId={userId}
            data={data}
            onError={console.log}
            onSubmit={onSubmit}
            formRef={formRef}
          />
        </Grid>
      </Grid>
    </BaseViewPage>
  );
};

export default UserEditPage;
