import { generatePath, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ITesteable } from '../../common/interfaces';
import { paths, RouteKey } from '../../navigation/constants';
import { getCommerce } from '../../api/adminBusinessEnvironment/service';
import { MenuItemType, Permissions } from '../../common/enums';
import { useUI } from '../../contexts/UIContext';
import useFPRCards from '../../hooks/useFPRCards';

interface CommerceBusinessSchemaViewPageProps extends ITesteable {}

const CommerceBusinessSchemaViewPage = ({
  dataTestId = 'CommerceBusinessSchemaViewPage',
}: CommerceBusinessSchemaViewPageProps) => {
  const [translate] = useTranslation('global');
  const params = useParams();
  const businessSchemaId = parseInt(params.businessSchemaId ?? '', 10);
  const commerceId = parseInt(params.commerceId ?? '', 10);
  const { data: requestData, ...queryProps } = useQuery({
    queryKey: [`getCommerce:${commerceId}`],
    queryFn: async () => (await getCommerce(commerceId!)).data,
  });

  const { setMenuOverride } = useUI();

  const { cards } = useFPRCards({
    requestData,
    requestId: undefined,
    commerceId,
    businessSchemaId,
  });

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(paths[RouteKey.COMMERCES]),
      },
      items: ['commerceDetail', 'businessSchemas'].map((cardKey) => ({
        key: cardKey,
        text: translate(
          `page.businessSchemas.drawerSubItems.${cardKey}.breadcrumb`,
          translate(`page.businessSchemas.drawerSubItems.${cardKey}.title`)
        ),
        path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
          '*': cardKey,
          commerceId: commerceId?.toString(),
        }),
        type: MenuItemType.MENU,
        permissions:
          cardKey === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : cardKey === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, commerceId, businessSchemaId]);

  const activeSectionKey = params.businessSchemaId
    ? 'businessSchemasDetail'
    : '';
  const activeSection = useMemo(
    () =>
      cards.find(
        (entry) => activeSectionKey === '' || activeSectionKey === entry.key
      ),
    [cards, activeSectionKey]
  );

  const baseViewPageProps = useMemo(
    () => ({
      dataTestId,
      routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW,
      title: translate(
        `page.financialProductRequest.${activeSectionKey}.title`,
        ''
      ),
      data: requestData,
      ...queryProps,
    }),
    [requestData, activeSectionKey, queryProps]
  );

  return (
    <>
      {activeSection?.render({
        requestData,
        commerceData: 'data',
        requestId: undefined,
        dataTestId,
        baseViewPageProps,
      })}
    </>
  );
};

export default CommerceBusinessSchemaViewPage;
