import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled, GridProps } from '@mui/system';
import { UseQueryResult } from 'react-query';
import { ReactNode, useEffect, useMemo } from 'react';
import getError from '../../utils/apiUtils';
import MessageErrorResponse from '../commons/MessageErrorResponse';
import { RouteKey } from '../../navigation/constants';
import { useUI } from '../../contexts/UIContext';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRadius: '5px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`,
}));

export interface CardContainerProps
  extends GridProps,
    Partial<Pick<UseQueryResult, 'isLoading' | 'error' | 'refetch'>> {
  children: ReactNode;
  title?: string;
  afterTitle?: ReactNode;
  spacing?: number;
}

const CardContainer = ({
  title,
  afterTitle,
  children,
  error,
  refetch,
  isLoading = false,
  ...props
}: CardContainerProps) => {
  const errorResponse = useMemo(
    () => (error ? getError(error) : undefined),
    [error]
  );

  const showContent = errorResponse === undefined && !isLoading;

  const { setGlobalError } = useUI();

  useEffect(() => {
    // @ts-ignore
    setGlobalError(error);
  }, [error]);

  return (
    <StyledGrid container {...props}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" mb={2}>
            {title}
            {afterTitle}
          </Typography>
        </Grid>
      )}
      {isLoading && (
        <Grid
          item
          xs={12}
          justifyContent="center"
          sx={{ display: 'flex', p: 2 }}
        >
          <CircularProgress size={64} />
        </Grid>
      )}
      {showContent && children}
      {errorResponse !== undefined && (
        <MessageErrorResponse
          response={errorResponse}
          onClick={refetch}
          routeKey={RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW}
          isInitError
        />
      )}
    </StyledGrid>
  );
};

export default CardContainer;
