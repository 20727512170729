import { useQuery } from 'react-query';
import { getUserPermissions } from '../api/adminUsersRoles/service';
import { Permissions } from '../common/enums';
import { RouteKey } from '../navigation/constants';

const usePermissions = () => {
  const { data, isLoading, error } = useQuery(
    ['getUserPermissions'],
    getUserPermissions
  );

  const permissions = data?.data?.permissions;

  const username = data?.data?.username;

  return {
    username,
    permissions,
    isLoading,
    error,
    checkPermissions: (role: Permissions | Permissions[]) => {
      if (typeof role === 'object') {
        return role.some((rl) => permissions?.includes(rl));
      }

      return permissions?.includes(role);
    },
    getFirstAvailableRoute: (routes: any) => {
      const availableRoutes = routes.filter((route: any) =>
        permissions?.includes(route.permissions)
      );
      return (
        availableRoutes?.[0]?.routeKey ?? RouteKey.FINANCIAL_PRODUCTS_REQUESTS
      );
    },
    checkRoutePermissions: (route: string) => {
      switch (route) {
        case 'documentation': {
          return permissions?.includes(
            Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
          );
        }
        case 'auditTrails': {
          return permissions?.includes(
            Permissions.FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ
          );
        }
        case 'historicalTasks': {
          return permissions?.includes(
            Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
          );
        }
        default:
          return permissions?.includes(
            Permissions.FINANCIAL_PRODUCT_REQUEST_READ
          );
      }
    },
  };
};

export default usePermissions;
