import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router';
import { useQuery } from 'react-query';
import { GridRowParams, GridRowId, GridColumns } from '@mui/x-data-grid';

import FilterContainer from '../../components/commons/FilterContainer';
import {
  IFilterOption,
  IFinancialProductRequestData,
  IFinancialProductRequestFiltersState,
  IOptionItem,
  IFilterAppliedOption,
  IFilterDatasourceOption,
} from '../../common/interfaces';
import { getFinancialProductRequestList } from '../../api/adminFinancialProductRequest/service';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import { dateFormatter } from '../../utils/formatterUtil';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import useFilterOptions from './hooks/useFilterOptions';
import { paths, RouteKey } from '../../navigation/constants';
import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';
import PromisedValue from '../../components/helpers/PromisedValue';
import RequestStatusBadge from '../../components/RequestStatusBadge';
import BaseTablePage from '../../components/commons/BaseTablePage';
import { formatMoney } from '../../utils/formatterUtil';
import useDataGridColumns from '../../hooks/useDataGridColumns';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: any[];
}

const filterDatasourceOptionListMock: IFilterDatasourceOption[] = [
  {
    name: 'originatorId',
    options: [],
  },
  {
    name: 'businessSchemaId',
    options: [],
  },
  {
    name: 'financialProductRequestStatus',
    options: [],
  },
  {
    name: 'financialOfficesIds',
    options: [],
  },
  {
    name: 'commerceIds',
    options: [],
  },
  {
    name: 'divisionIds',
    options: [],
  },
];

const FinancialProductRequestsPage = ({
  dataTestId = 'FinancialProductRequestsPage',
}) => {
  const [translate] = useTranslation('global');

  const { getValue } = useDataSources();

  const filterOptionList = useFilterOptions();

  const navigate = useNavigate();

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
    initialState: {
      pageNumber: 1,
      pageSize: 50,
      filterAppliedList: [],
      filterDatasourceOptionList: filterDatasourceOptionListMock,
    },
  });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } =
    useFiltersProps<IFinancialProductRequestFiltersState>({
      appliedFilters: filterAppliedList,
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const { data: queryData, ...queryProps } = useQuery({
    queryKey: ['financialProductRequest', pageNumber, pageSize, filters],
    queryFn: async () =>
      (await getFinancialProductRequestList({ pageNumber, pageSize, filters }))
        .data,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleView = useCallback(
    (id: GridRowId) => {
      const urlViewRequest = generatePath(
        paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW],
        { requestId: id.toString() }
      );
      navigate(urlViewRequest);
    },
    [pageNumber, pageSize, filterAppliedList, filterDatasourceOptionList]
  );

  const getDateFormatted = (params: any) => dateFormatter(params.row.startDate);

  const showAmount = (element: any) => {
    switch (element.status) {
      case 'ACTIVATION_PROCESS':
      case 'FINALIZED':
        if (
          element.selectedFinancialProducts &&
          element.selectedFinancialProducts.length > 0
        ) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  };

  const columns = useDataGridColumns(
    [
      {
        field: 'publicId',
        minWidth: 150,
        flex: 1,
        sortable: false,
      },
      {
        field: 'startDate',
        valueGetter: getDateFormatted,
        minWidth: 200,
        flex: 1,
        sortable: false,
      },
      {
        field: 'customerCode',
        minWidth: 150,
        flex: 1,
        sortable: false,
      },
      {
        field: 'customerFullName',
        minWidth: 200,
        flex: 1,
        sortable: false,
      },
      {
        field: 'capitalAmount',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          showAmount(row)
            ? row.selectedFinancialProducts.map((product: any) => {
                if (product.financialProductType === 'LOAN') {
                  return formatMoney(product.capitalAmount);
                }
                return '';
              })
            : '',
      },
      {
        field: 'businessSchemaId',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <PromisedValue
            value={row.businessSchemaId}
            fn={getValue}
            arg={{
              collection: DataSourceCollection.BUSINESS_SCHEMAS,
              id: row.businessSchemaId,
            }}
          />
        ),
      },
      {
        field: 'commerce',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <PromisedValue
            value={row.commerceInfo.commerceId}
            fn={getValue}
            arg={{
              collection: DataSourceCollection.COMMERCES,
              id: row.commerceInfo.commerceId,
            }}
          />
        ),
      },
      {
        field: 'status',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <RequestStatusBadge status={row.status} />,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 100,
        flex: 0.5,
        align: 'right',
        getActions: (params: GridRowParams<IFinancialProductRequestData>) => [],
      },
    ],
    {
      translationKeyPrefix:
        'page.financialProductRequest.columnHeaders.',
    },
    [translate]
  );

  const handleOnGetDatasource = (
    filter: IFilterOption,
    options: IOptionItem[]
  ) => {
    mergeRouteState({
      filterDatasourceOptionList: filterDatasourceOptionList.map((f) => {
        if (f.name === filter.name) {
          f.options = options;
        }
        return f;
      }),
    });
  };

  const handleOnPageChange = (newPage: number) =>
    mergeRouteState({
      pageNumber: newPage,
    });

  const handleOnPageSizeChange = (newPageSize: number) =>
    mergeRouteState({
      pageSize: newPageSize,
    });

  return (
    <BaseTablePage
      routeKey={RouteKey.FINANCIAL_PRODUCTS_REQUESTS}
      data={queryData}
      dataTestId={dataTestId}
      {...queryProps}
    >
      <FilterContainer
        title={translate('page.financialProductRequest.title')}
        filterOptionList={filterOptionList}
        filterDatasourceOptionList={filterDatasourceOptionList}
        translationKeyPrefix="page.financialProductRequest.filter"
        onGetDatasource={handleOnGetDatasource}
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          showFirstButton
          showLastButton
          rowsPerPageOptions={[10, 30, 50, 100]}
          onRowClick={({ id }) => handleView(id)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          getRowId={(r: any) => r.id}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};

export default FinancialProductRequestsPage;
