import { Grid, GridProps } from '@mui/material';

import { ITesteable } from '../../common/interfaces';
import FormField, { FormFieldProps } from './FormField';

enum FormFieldType {
  INPUT = 'INPUT',
}

export interface IFormField {
  type?: FormFieldType;
  /**
   * Defines the form name for the field.
   */
  name: string;

  /**
   * Shows an indicator that lets the user know that the field value is required.
   */
  mandatory?: boolean;

  /**
   * Hides the field. Intended to be used with some expression that evaluates to true if the field should not render.
   */
  hidden?: boolean;
}

interface FormFieldsProps extends ITesteable {
  fields?: FormFieldProps[];
  translationPrefix: string;
  noContainer?: boolean;
  childrenProps?: GridProps;
}

const FormFields = ({
  dataTestId = 'FormFields',
  translationPrefix,
  fields = [],
  noContainer = false,
  childrenProps,
}: FormFieldsProps) => {
  const children = fields.map((props) => (
    <FormField
      dataTestId={`${dataTestId}_${props.name}`}
      key={props.name}
      translationPrefix={translationPrefix}
      childrenProps={childrenProps}
      {...props}
    />
  ));

  return noContainer ? (
    <>{children}</>
  ) : (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};

export default FormFields;
