import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoResultImage from '../../../../assets/imgs/noResult.svg';

const NoRowsOverlay = () => {
  const [translate] = useTranslation('global');
  return (
    <Grid item container xs={12} justifyContent="center">
      <Grid item container justifyContent="center" direction="column">
        <img src={NoResultImage} alt="No hay resultados" height="120px" />
        <Box m={3} />
        <Typography variant="h2" align="center">
          {translate('component.customDataGrid.customNoRowsOverlayTitle')}
        </Typography>
        <Box m={1} />
        <Typography align="center">
          {translate('component.customDataGrid.customNoRowsOverlaySubtitle')}
        </Typography>
        <Box m={2} />
      </Grid>
    </Grid>
  );
};

export default NoRowsOverlay;
