import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ITesteable } from '../../common/interfaces';
import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';
import useTranslate from '../../hooks/useTranslate';
import MultipleAutoComplete from '../commons/MultipleAutoComplete';
import SingleAutoComplete from '../commons/SingleAutoComplete';
import AutoCompleteSelector from './AutoCompleteSelector';
import type { IFormField } from './FormFields';

export interface DataSourceSelectorProps
  extends Omit<IFormField, 'type'>,
    ITesteable {
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  collection: DataSourceCollection;
  multiple?: boolean;
  renderMode?: 'autocomplete' | 'select';
  valueMapper?: (value: any, options: any[]) => any;
  optionsMapper?: (options: any[]) => any[];
  translationKeyPrefix?: string;
  translationKeyPrefixSimple?: string;
  selectorFullWidth?: boolean;
  disabled?: boolean;
  setRefOptions?: Function;
}

export interface IDataSourceOptionItem {
  id: string;
  label: string;
}

const DataSourceSelector = ({
  label: labelProp,
  labelKey,
  collection,
  mandatory = false,
  multiple = false,
  renderMode = 'autocomplete',
  valueMapper,
  translationKeyPrefix,
  translationKeyPrefixSimple,
  selectorFullWidth = false,
  disabled = false,
  setRefOptions,
  ...props
}: DataSourceSelectorProps) => {
  const { getOptions } = useDataSources();
  const [options, setOptions] = useState<IDataSourceOptionItem[]>([]);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const { control } = useFormContext();
  const [t] = useTranslation('global');

  const label = useTranslate(labelKey, labelProp ?? '', {
    mandatory,
  });

  const mapOptionsSearchSelector = (request: IDataSourceOptionItem[]) => {
    let response: any[] = [];
      if (request) {
        request.forEach((option) => {
          response.push({ key: option.id, value: option.label });
        });
      }
    return response;
  };

  useEffect(() => {
    (async () => {
      const data = await getOptions({
        collection,
      });
      data.forEach((item) => {
        item.label = translationKeyPrefixSimple
          ? t(`${translationKeyPrefixSimple}.${item.id}`)
          : t(
              `${translationKeyPrefix}Label_${labelKey}_${item.id}`,
              item.label
            );
      });
      setOptions(data);
      setRefOptions?.(data);
      setSearchOptions(mapOptionsSearchSelector(data));
    })();
  }, [getOptions]);

  if (renderMode === 'select' && !multiple && options) {
    return (
      <Controller
        control={control}
        name={props.name || 'fieldToFilterSelected'}
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => (
          <SingleAutoComplete
            {...props}
            defaultValue=""
            value={
              Array.isArray(value?.value)
                ? value?.value[0] ?? ''
                : value?.value ?? ''
            }
            onChange={(ev) => {
              if (!ev) {
                onChange('');
                return;
              }
              onChange(valueMapper ? valueMapper(ev, searchOptions) : ev);
            }}
            error={error ? { message: '' } : false}
            options={searchOptions}
            getOptionValue={(option) => option?.key}
            getOptionLabel={(option) => option?.value}
            disabled={disabled}
            variant="standard"
            label={label}
          />
        )}
      />
    );
  }

  if (renderMode === 'select' && multiple) {
    return (
      <Controller
        control={control}
        name={props.name || 'fieldToFilterSelected'}
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => (
          <MultipleAutoComplete
            {...props}
            defaultValue={[]}
            value={value?.value ?? []}
            onChange={(ev) => {
              if (!ev) {
                onChange([]);
                return;
              }
              onChange(valueMapper ? valueMapper(ev, searchOptions) : ev);
            }}
            error={error ? { message: '' } : false}
            options={searchOptions}
            getOptionValue={(option) => option?.key}
            getOptionLabel={(option) => option?.value}
            disabled={disabled}
            variant="standard"
            label={label}
          />
        )}
      />
    );
  }

  return (
    <AutoCompleteSelector<IDataSourceOptionItem, string>
      {...props}
      label={label}
      options={options}
      multiple={multiple}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.label}
      selectorFullWidth={selectorFullWidth}
      defaultValue={options?.length === 1 ? options[0]?.id : ''}
      disabled={disabled}
    />
  );
};

export default DataSourceSelector;
