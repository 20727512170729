import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import {
  getBankAccountIdentificationTypes,
  getBankAccountTypes,
  getBanks,
  getCatalogItems,
  getCities,
  getCityGroups,
  getCountries,
  getStates,
  getZipCodes,
} from '../api/adminPlatformCatalogs/service';

export enum PlatformCatalogKey {
  COUNTRIES = 'COUNTRIES',
  STATES = 'STATES',
  CITIES = 'CITIES',
  CITIES_FORM = 'CITIES_FORM',
  ZIP_CODES = 'ZIP_CODES',
  ZIP_CODES_FORM = 'ZIP_CODES_FORM',
  BANKS = 'BANKS',
  BANK_ACCOUNT_TYPES = 'BANK_ACCOUNT_TYPES',
  BANK_ACCOUNT_ID_TYPES = 'BANK_ACCOUNT_ID_TYPES',
}

const catalogs = {
  [PlatformCatalogKey.COUNTRIES]: {
    getQueryOptions: (params = {}) => ({
      queryKey: ['getCountries', params],
      queryFn: () => getCountries(),
    }),
  },
  [PlatformCatalogKey.STATES]: {
    getQueryOptions: (params = {}) => ({
      queryKey: ['getStates', params],
      // @ts-ignore
      queryFn: () => getStates(params),
    }),
  },
  [PlatformCatalogKey.CITIES]: {
    getQueryOptions: (params: any = {}) => ({
      queryKey: ['getCities', params],
      queryFn: () => getCities(params),
    }),
  },
  [PlatformCatalogKey.CITIES_FORM]: {
    getQueryOptions: (params: any = {}) => ({
      queryKey: ['getCityGroups', params],
      queryFn: () => getCityGroups(params),
    }),
  },
  [PlatformCatalogKey.ZIP_CODES]: {
    getQueryOptions: (params: any = {}) => ({
      queryKey: ['getZipCodes', params],
      queryFn: () => getZipCodes(params),
    }),
  },
  [PlatformCatalogKey.ZIP_CODES_FORM]: {
    getQueryOptions: (params: any = {}) => ({
      queryKey: ['getZipCodesForm', params],
      queryFn: async () => {
        let response = await getZipCodes(params);
        let result: any = [];
        if (response?.data?.result) {
          response.data.result.forEach((element: any) => {
            result.push({ id: element, code: element.code });
          });
          response.data.result = result;
        }
        return response;
      },
    }),
  },
  [PlatformCatalogKey.BANKS]: {
    getQueryOptions: (params = {}) => ({
      queryKey: ['getBanks', params],
      queryFn: () => getBanks(),
    }),
  },
  [PlatformCatalogKey.BANK_ACCOUNT_TYPES]: {
    getQueryOptions: (params = {}) => ({
      queryKey: ['getBankAccountTypes'],
      queryFn: () => getBankAccountTypes(),
    }),
  },
  [PlatformCatalogKey.BANK_ACCOUNT_ID_TYPES]: {
    getQueryOptions: (params = {}) => ({
      queryKey: ['getBankAccountIdentificationTypes'],
      queryFn: () => getBankAccountIdentificationTypes(),
    }),
  },
};

const usePlatformCatalogs = () => {
  const queryClient = useQueryClient();

  const getOptions = useCallback(
    async ({
      catalog,
      params = {},
    }: {
      catalog: PlatformCatalogKey;
      params?: {};
    }) => {
      const queryOptions = catalogs[catalog].getQueryOptions(params);

      // @ts-ignore
      const { data: queryData } = await queryClient.fetchQuery(queryOptions);

      return queryData.result.map((row: any) => ({
        // @ts-ignore
        label: (row.shortDescription as string) ?? row.description ?? row.code,
        id: row.id,
      }));
    },
    [queryClient]
  );

  const getCatalogValue = useCallback(
    async ({
      catalog,
      id,
      params = {},
    }: {
      catalog: PlatformCatalogKey;
      id?: any;
      params?: {};
    }) => {
      if (id === undefined) return undefined;
      const queryOptions = catalogs[catalog].getQueryOptions(params);

      // @ts-ignore
      const { data: queryData } = await queryClient.fetchQuery(queryOptions);
      const row = queryData.result.find((find: any) => find.id === id);

      // @ts-ignore
      return (row.shortDescription as string) ?? row.description ?? row.code;
    },
    [queryClient]
  );

  const getDescriptionValue = useCallback(
    async ({ tableName, code }: { tableName: string; code: string }) => {
      const queryOptions = {
        queryKey: `catalog:${tableName}`,
        queryFn: () =>
          getCatalogItems({
            tableName,
          }),
      };
      const { data: queryData } = await queryClient.fetchQuery(queryOptions);
      const find = queryData.result.find((row) => row.code === code);
      return find ? find.description : code;
    },
    [queryClient]
  );

  return {
    getOptions,
    getCatalogValue,
    getDescriptionValue,
  };
};

export default usePlatformCatalogs;
