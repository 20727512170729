import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';

import { IContactInfo } from '../../common/interfaces';
import DataFill from '../commons/DataFill';
import StatusBadge from '../commons/StatusBadge';

import CardContainer from './CardContainer';

interface ContactInfoCardProps {
  contactInfo: IContactInfo[];
}

const ContactInfoCard = ({ contactInfo = [] }: ContactInfoCardProps) => {
  const [translate] = useTranslation('global');

  if (!contactInfo.length) return null;

  return (
    <CardContainer
      title={translate('page.financialProductRequest.contactInfo.title')}
    >
      {contactInfo.map((data) => {
        const labelKey = `${data.type.toLowerCase()}_${
          data.priorityOrder === 1 ? 'primary' : 'secondary'
        }`;

        const IconComponent = (() => {
          if (data.type === 'CELLPHONE') return LocalPhoneOutlinedIcon;
          if (data.type === 'EMAIL') return EmailOutlinedIcon;
          return undefined;
        })();

        return (
          <DataFill
            md={6}
            lg={4}
            IconComponent={IconComponent}
            key={data.id}
            title={labelKey}
            value={data.value}
            translationKeyPrefix="page.financialProductRequest."
            labelProps={{
              sx: {
                fontSize: 14,
                lineHeight: 1.5,
              },
            }}
            valueProps={{
              sx: {
                fontSize: 18,
              },
            }}
          >
            {data.verified && <StatusBadge label={translate('common.verified')} />}
          </DataFill>
        );
      })}
    </CardContainer>
  );
};

export default ContactInfoCard;
