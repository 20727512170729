import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FilterOptionTypes } from '../../../common/enums';
import { IFilterOption } from '../../../common/interfaces';
import { DataSourceCollection } from '../../../hooks/useDataSources';

const useFilterOptions = () => {
  const [translate] = useTranslation('global');

  return useMemo<IFilterOption[]>(
    () => [
      {
        name: 'loanBatchStatus',
        attribute: 'loanBatchStatus',
        value: [],
        type: FilterOptionTypes.DATASOURCE,
        collection: DataSourceCollection.LOAN_BATCH_STATUS,
        multiple: true,
        validation: yup
        .array()
        .min(
          1,
          translate('page.operativeControl.inputBatchIdRequired')
        ),
      }
    ],
    [translate]
  );
};

export default useFilterOptions;
