import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

interface PromisedValueProps<TArg> {
  fn: (args: TArg) => Promise<any>;
  arg?: TArg;
  value?: any;
}

const PromisedValue = <TArgs,>({
  value: fallbackValue = null,
  fn,
  arg = undefined,
}: PromisedValueProps<TArgs>) => {
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        // @ts-ignore
        const resolved = arg ? await fn(arg) : await fn();
        setValue(resolved);
      } catch (err) {
        setValue(fallbackValue);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [fn, arg]);

  if (isLoading) {
    return <Skeleton width={75} />;
  }

  return value ?? fallbackValue;
};

export default PromisedValue;
