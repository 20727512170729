import { RouteKey } from "../navigation/constants";

export const referenceConstant = {
  [RouteKey.ROLES]: {
    //titleSuccess: 'error.api.userContactDataTitleSuccess',
    //subtitleSuccess: 'error.api.userContactDataSubtitleSuccess',
    //buttonLabelSuccess: 'error.api.userContactDataButtonLabelSuccess',
    titleError: 'page.role.rolesAdminTitleError',
    subtitleError: 'page.role.rolesAdminSubtitleError',
    buttonLabelError: 'page.role.rolesAdminButtonLabelError',
  },
  [RouteKey.ROLES_EDIT]: {
    //titleSuccess: 'error.api.userContactDataTitleSuccess',
    //subtitleSuccess: 'error.api.userContactDataSubtitleSuccess',
    //buttonLabelSuccess: 'error.api.userContactDataButtonLabelSuccess',
    titleError: 'page.role.rolesAdminTitleError',
    subtitleError: 'page.role.rolesAdminSubtitleError',
    buttonLabelError: 'page.role.rolesAdminButtonLabelError',
  },
  [RouteKey.ROLES_VIEW]: {
    //titleSuccess: 'error.api.userContactDataTitleSuccess',
    //subtitleSuccess: 'error.api.userContactDataSubtitleSuccess',
    //buttonLabelSuccess: 'error.api.userContactDataButtonLabelSuccess',
    titleError: 'page.role.rolesAdminTitleError',
    subtitleError: 'page.role.rolesAdminSubtitleError',
    buttonLabelError: 'page.role.rolesAdminButtonLabelError',
  },
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS]: {
    //titleSuccess: 'error.api.userContactDataTitleSuccess',
    //subtitleSuccess: 'error.api.userContactDataSubtitleSuccess',
    //buttonLabelSuccess: 'error.api.userContactDataButtonLabelSuccess',
    titleError: 'page.financialProductRequest.requestAdminTitleError',
    subtitleError: 'page.financialProductRequest.requestAdminSubtitleError',
    buttonLabelError:
      'page.financialProductRequest.requestAdminButtonLabelError',
  },
  [RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW]: {
    //titleSuccess: 'error.api.userContactDataTitleSuccess',
    //subtitleSuccess: 'error.api.userContactDataSubtitleSuccess',
    //buttonLabelSuccess: 'error.api.userContactDataButtonLabelSuccess',
    titleError: 'page.financialProductRequest.requestAdminTitleError',
    subtitleError: 'page.financialProductRequest.requestAdminSubtitleError',
    buttonLabelError:
      'page.financialProductRequest.requestAdminButtonLabelError',
  },
};

export default {};
