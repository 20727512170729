import { ThemeProvider } from '@mui/system';
import { merge } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getTenantI18n, getTenantTheme, getTenantSettings } from '../api/tenantService';
import useResolveTenantName from '../hooks/useResolveTenantName';
import globalEs from '../translations/es/global.json';

const initialState = {
  name: '',
  settings: {
    logo: undefined,
  },
}

interface ITenantContext {
  name: string;
  settings: {
    logo?: string;
  }
}

const TenantContext = createContext<ITenantContext>(initialState);

export const TenantProvider = ({ children }: { children: ReactNode }) => {
  const name = useResolveTenantName();
  const [settings, setSettings] = useState(initialState.settings);

  const { i18n } = useTranslation();

  const { data: i18nData } = useQuery({
    queryKey: [`i18n:${name}`],
    queryFn: () => getTenantI18n(name),
  });

  const { data: themeData } = useQuery({
    queryKey: [`theme:${name}`],
    queryFn: () => getTenantTheme(name),
  });

  const { data: settingsData } = useQuery({
    queryKey: [`settings:${name}`],
    queryFn: () => getTenantSettings(name),
  });

  useEffect(() => {
    Object.keys(i18nData ?? {}).forEach(language => {
      Object.keys(i18nData[language] ?? {}).forEach(namespace => {
        i18n.addResourceBundle(language, namespace, merge({}, globalEs, i18nData[language][namespace]));
      })
    });
    i18n.changeLanguage(i18n.language);
  }, [i18nData]);

  useEffect(() => {
    if (settingsData) setSettings(settingsData);
  }, [settingsData])

  const value = useMemo(
    () => ({
      name,
      settings,
    }),
    [name, settings]
  );

  const themeOverride = useCallback((oldTheme: any) => merge(oldTheme, themeData), [themeData]);

  return (
    <TenantContext.Provider value={value}>
      <ThemeProvider theme={themeOverride}>
        {children}
      </ThemeProvider>
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
