import { CSSProperties } from 'react';

interface StyleProps {
  [name: string]: CSSProperties;
}

export const styles: StyleProps = {
  inputContainer: {
    border: '1px solid #D8D8D8',
    paddingLeft: '10px',
    borderRadius: '4px',
  },
  inputWrapper: {
    margin: '20px 0',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0px',
  },
  dateContainer: {
    display: 'flex',
    gap: '25px',
    alignItems: 'center',
    marginTop: '20px',
  },
};
