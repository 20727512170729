/* eslint-disable arrow-body-style */
import { Alert, AlertProps, AlertTitle, SxProps, Theme } from '@mui/material';

interface StyledAlertProps extends AlertProps {
  childrenSx?: SxProps<Theme>;
}

const StyledAlert = ({
  title,
  children,
  childrenSx,
  ...props
}: StyledAlertProps) => {
  return (
    <Alert {...props} sx={{ my: 2, width: '100%' }}>
      {title && (
        <AlertTitle sx={{ fontWeight: 'bold', mb: 0, ...childrenSx }}>
          {title}
        </AlertTitle>
      )}
      {children}
    </Alert>
  );
};

export default StyledAlert;
