export const getTextMetrics = (text: string, {
  font
}: any = {}) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return undefined;

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text);
}

export default {};
