import { lowerCase, uniq } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPermissionData } from '../api/adminUsersRoles/interfaces';

export interface IRichPermissionData extends IPermissionData {
  label: string;
  key: string;
  selected: boolean;
}

interface IByAppPermissions {
  [key: string]: IRichPermissionData[];
}

interface IUseParsePermissions {
  permissions?: IPermissionData[];
  selected?: number[];
}

export const useParsePermissions = ({
  permissions = [],
  selected = [],
}: IUseParsePermissions) => {
  const [translate] = useTranslation('global');

  const mappedApps = useMemo(() => {
    const appIds: string[] = uniq(permissions.map((entry: any) => entry.appId));

    return appIds.map((key) => ({
      key,
      label: translate(`datasources.apps.${key}`, lowerCase(key)),
    }));
  }, [permissions]);
  const mappedPermissions = useMemo(
    () =>
      permissions.reduce<IByAppPermissions>((ret, permission) => {
        ret[permission.appId] = ret[permission.appId] ?? [];
        ret[permission.appId].push({
          ...permission,
          key: permission.permissionName,
          selected: selected.includes(permission.id),
          label: translate(
            `datasources.permissions.${permission.permissionName}`,
            lowerCase(permission.permissionName)
          ),
        });
        return ret;
      }, {}),
    [permissions, selected]
  );
  return {
    apps: mappedApps,
    permissions: mappedPermissions,
  };
};

export default useParsePermissions;
