import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import {
  getBusinessSchemasDataSource,
  getCodeMappingsEntityTypesDataSource,
  getCodeMappingsExternalServicesDataSource,
  getCommercesDataSource,
  getDivisionsDataSource,
  getFinancialOfficesDataSource,
  getFundingEntitiesDataSource,
  getOriginatorsDataSource,
  getRolesDataSource,
  getStatusesDataSource,
  getCalendarDataSource,
  getQuotaDefinitionTypes,
  getInterestRateTablesDataSource,
  getInterestRateGroupsDataSource,
  getCommerceCategoryData,
  getStatesDatasource,
  getHeadquartersDataSource,
  getGenericBoolean,
  getCommercesUserDataSource,
} from '../api/adminDataSources/service';
import { IDataSourceResponse } from '../common/interfaces';

export enum DataSourceCollection {
  ORIGINATORS = 'ORIGINATORS',
  BUSINESS_SCHEMAS = 'BUSINESS_SCHEMAS',
  COMMERCES = 'COMMERCES',
  COMMERCES_USER = 'COMMERCES_USER',
  HEADQUARTERS = 'HEADQUARTERS',
  DIVISIONS = 'DIVISIONS',
  STATUSES = 'STATUSES',
  FUNDING_ENTITIES = 'FUNDING_ENTITIES',
  FINANCIAL_OFFICES = 'FINANCIAL_OFFICES',
  CODE_MAPPINGS_ENTITY_TYPES = 'CODE_MAPPINGS_ENTITY_TYPES',
  CODE_MAPPINGS_EXTERNAL_SERVICES = 'CODE_MAPPINGS_EXTERNAL_SERVICES',
  ROLES = 'ROLES',
  CALENDAR = 'CALENDAR',
  QUOTA_TYPE = 'QUOTA_TYPE',
  RATE_TABLES = 'RATE_TABLES',
  RATE_GROUPS = 'RATE_GROUPS',
  COMMERCE_CATEGORY = 'COMMERCE_CATEGORY',
  STATES = 'STATES',
  GENERIC_BOOLEAN = 'GENERIC_BOOLEAN',
  LOAN_BATCH_STATUS = 'LOAN_BATCH_STATUS',
  BATCH_UPDATE_STATUS = 'BATCH_UPDATE_STATUS',
}

const loanBatchStatusData: IDataSourceResponse = {
  data: [
    { key: 'INICIADO', value: 'iniciado' },
    { key: 'PENDIENTE_APROBACION', value: 'pendiente de aprobación' },
    { key: 'APROBADO', value: 'aprobado'},
    { key: 'CEDIDO', value: 'cedido' },
    { key: 'RECHAZADO', value: 'rechazado'},
  ]
};

const batchUpdateStatusData: IDataSourceResponse = {
  data: [
    { key: 'COMPLETADO', value: 'completado'},
    { key: 'PENDIENTE_APROBACION', value: 'pendiente de aprobación' },
    { key: 'RECHAZADO', value: 'rechazado' }
  ]
};

const getLoanBatchStatusDataSource = (): Promise<IDataSourceResponse> => Promise.resolve(loanBatchStatusData);

const getBatchUpdateStatusDataSource = (): Promise<IDataSourceResponse> => Promise.resolve(batchUpdateStatusData);

const collections = {
  [DataSourceCollection.BATCH_UPDATE_STATUS]: {
    queryOptions: {
      queryKey: ['getBatchUpdateStatusDataSource'],
      queryFn: () => getBatchUpdateStatusDataSource(),
    },
  },
  [DataSourceCollection.LOAN_BATCH_STATUS]: {
    queryOptions: {
      queryKey: ['getLoanBatchStatusDataSource'],
      queryFn: () => getLoanBatchStatusDataSource(),
    },
  },
  [DataSourceCollection.ORIGINATORS]: {
    queryOptions: {
      queryKey: ['getOriginatorsDataSource'],
      queryFn: () => getOriginatorsDataSource(),
    },
  },
  [DataSourceCollection.BUSINESS_SCHEMAS]: {
    queryOptions: {
      queryKey: ['getBusinessSchemasDataSource'],
      queryFn: () => getBusinessSchemasDataSource(),
    },
  },
  [DataSourceCollection.COMMERCES]: {
    queryOptions: {
      queryKey: ['getCommercesDataSource'],
      queryFn: () => getCommercesDataSource(),
    },
  },
  [DataSourceCollection.COMMERCES_USER]: {
    queryOptions: {
      queryKey: ['getCommercesUserDataSource'],
      queryFn: () => getCommercesUserDataSource(),
    },
  },
  [DataSourceCollection.HEADQUARTERS]: {
    queryOptions: {
      queryKey: ['getHeadquartersDataSource'],
      queryFn: () => getHeadquartersDataSource(),
    },
  },
  [DataSourceCollection.DIVISIONS]: {
    queryOptions: {
      queryKey: ['getDivisionsDataSource'],
      queryFn: () => getDivisionsDataSource(),
    },
  },
  [DataSourceCollection.FUNDING_ENTITIES]: {
    queryOptions: {
      queryKey: ['getFundingEntitiesDataSource'],
      queryFn: () => getFundingEntitiesDataSource(),
    },
  },
  [DataSourceCollection.FINANCIAL_OFFICES]: {
    queryOptions: {
      queryKey: ['getFinancialOfficesDataSource'],
      queryFn: () => getFinancialOfficesDataSource(),
    },
  },
  [DataSourceCollection.CODE_MAPPINGS_ENTITY_TYPES]: {
    queryOptions: {
      queryKey: ['getCodeMappingsEntityTypesDataSource'],
      queryFn: () => getCodeMappingsEntityTypesDataSource(),
    },
  },
  [DataSourceCollection.CODE_MAPPINGS_EXTERNAL_SERVICES]: {
    queryOptions: {
      queryKey: ['getCodeMappingsExternalServicesDataSource'],
      queryFn: () => getCodeMappingsExternalServicesDataSource(),
    },
  },
  [DataSourceCollection.STATUSES]: {
    queryOptions: {
      queryKey: ['getStatusesDataSource'],
      queryFn: () => getStatusesDataSource(),
    },
  },
  [DataSourceCollection.ROLES]: {
    queryOptions: {
      queryKey: ['getRolesDataSource'],
      queryFn: () => getRolesDataSource(),
    },
  },
  [DataSourceCollection.CALENDAR]: {
    queryOptions: {
      queryKey: ['getCalendarDataSource'],
      queryFn: () => getCalendarDataSource(),
    },
  },
  [DataSourceCollection.QUOTA_TYPE]: {
    queryOptions: {
      queryKey: ['getQuotaDefinitionTypes'],
      queryFn: () => getQuotaDefinitionTypes(),
    },
  },
  [DataSourceCollection.RATE_TABLES]: {
    queryOptions: {
      queryKey: ['getInterestRateTablesDataSource'],
      queryFn: () => getInterestRateTablesDataSource(),
    },
  },
  [DataSourceCollection.RATE_GROUPS]: {
    queryOptions: {
      queryKey: ['getInterestRateGroupsDataSource'],
      queryFn: () => getInterestRateGroupsDataSource(),
    },
  },
  [DataSourceCollection.COMMERCE_CATEGORY]: {
    queryOptions: {
      queryKey: ['getCommerceCategoryData'],
      queryFn: () => getCommerceCategoryData(),
    },
  },
  [DataSourceCollection.STATES]: {
    queryOptions: {
      queryKey: ['getStatesDatasource'],
      queryFn: () => getStatesDatasource(),
    },
  },
  [DataSourceCollection.GENERIC_BOOLEAN]: {
    queryOptions: {
      queryKey: ['getGenericBoolean'],
      queryFn: () => getGenericBoolean(),
    },
  },
};

const useDataSources = () => {
  const queryClient = useQueryClient();

  const getValue = useCallback(
    async ({
      collection,
      id,
    }: {
      collection: DataSourceCollection;
      id: string;
    }) => {
      const { queryOptions } = collections[collection];
      const { data: queryData } = await queryClient.fetchQuery(queryOptions);
      const find = queryData.find((row) => row.key === String(id));
      return find ? find.value : id;
    },
    [queryClient]
  );

  const getOptions = useCallback(
    async ({ collection }: { collection: DataSourceCollection }) => {
      const { queryOptions } = collections[collection];
      const { data: queryData } = await queryClient.fetchQuery(queryOptions);
      return queryData.map((row) => ({
        label: row.value,
        id: row.key,
      }));
    },
    [queryClient]
  );

  return {
    getValue,
    getOptions,
  };
};

export default useDataSources;
