import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import globalEs from './translations/es/global.json';

import AppRouter from './navigation/AppRouter';
import theme from './assets/theme/theme';
import { UIProvider } from './contexts/UIContext';
import { NavigationStateProvider } from './contexts/useNavigationStateContext';
import { TenantProvider } from './contexts/TenantContext';

// react-i18next setup
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: { global: globalEs },
    // en: {global: globalEn}
  },
  ns: ['global'],
  defaultNS: 'global',
});

// react-query setup
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export const Provided = ({ children }: { children: ReactNode }) => (
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <UIProvider>
        <QueryClientProvider client={queryClient}>
          <TenantProvider>
            <NavigationStateProvider>{children}</NavigationStateProvider>
          </TenantProvider>
        </QueryClientProvider>
      </UIProvider>
    </ThemeProvider>
  </I18nextProvider>
);

const App = () => (
  <Provided>
    <header>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
    </header>
    <CssBaseline />
    <AppRouter />
  </Provided>
);
export default App;
