import { useEffect, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getFinancialProductRequest } from '../../api/adminFinancialProductRequest/service';
import { paths, RouteKey } from '../../navigation/constants';
import { useUI } from '../../contexts/UIContext';
import { MenuItemType, Permissions } from '../../common/enums';
import BaseViewPage from '../../components/commons/BaseViewPage';
import RejectCard from '../../components/pagePartial/RejectCard';

const activeSectionKey = 'reject';

const FinancialProductRequestRejectPage = ({
  dataTestId = 'FinancialProductRequestRejectPage',
}) => {
  const params = useParams();
  const [translate] = useTranslation('global');

  const { setMenuOverride } = useUI();

  const { requestId, taskId, processInstanceId } = params;
  const originPendingTask = taskId && processInstanceId;

  const { data: requestData, ...queryProps } = useQuery({
    queryKey: [`financialProductRequest:${requestId}`],
    queryFn: async () =>(await getFinancialProductRequest(requestId!)).data,
    keepPreviousData: true,
  });

  const cards = useMemo(
    () => [
      {
        key: 'reject',
        render: ({ baseViewPageProps }: any) => (
          <BaseViewPage {...baseViewPageProps}>
            <RejectCard
              requestId={requestId}
              processInstanceId={
                processInstanceId || 
                requestData?.businessProcessInfo.processInstanceId
              }
              taskId={taskId}
            />
          </BaseViewPage>
        ),
      },
    ],
    [requestId, requestData]
  );

  useEffect(() => {
    setMenuOverride({
      headerItem: {
        key: 'back',
        type: MenuItemType.MENU,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        icon: <ArrowBackIcon />,
        text: translate('common.back'),
        path: generatePath(
          originPendingTask
            ? paths[RouteKey.FPR_PENDING_TASKS_DETAIL]
            : paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_DETAILS],
          {
            requestId,
            taskId,
            processInstanceId,
          }
        ),
      },
      items: cards.map((card) => ({
        key: card.key,
        permissions:
          card.key === 'documentation'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
            : card.key === 'auditTrails'
            ? Permissions.FINANCIAL_PRODUCT_REQUEST_AUDIT_TRAIL_READ
            : card.key === 'historicalTasks'
            ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
            : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        text: translate(
          `page.financialProductRequestPendingTask.${card.key}.breadcrumb`,
          translate(`page.financialProductRequestPendingTask.${card.key}.title`)
        ),
        path: generatePath(paths[RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW], {
          '*': card.key,
          requestId,
        }),
        type: MenuItemType.MENU,
      })),
    });
    return () => setMenuOverride(undefined);
  }, [translate, cards, requestId]);

  const baseViewPageProps = useMemo(
    () => ({
      dataTestId,

      routeKey: RouteKey.FPR_PENDING_TASKS_VIEW,
      breadcrumb: translate(
        `page.financialProductRequestPendingTask.${activeSectionKey}.breadcrumb`,
        translate(
          `page.financialProductRequestPendingTask.${activeSectionKey}.title`
        )
      ),
      title: translate(
        `page.financialProductRequestPendingTask.${activeSectionKey}.title`,
        ''
      ),
      routeTrail: originPendingTask
        ? [RouteKey.FPR_PENDING_TASKS, RouteKey.FPR_PENDING_TASKS_DETAIL]
        : [RouteKey.FINANCIAL_PRODUCTS_REQUESTS, RouteKey.FINANCIAL_PRODUCTS_REQUESTS_DETAILS],
      routePathParams: { requestId, taskId, processInstanceId },
      data: requestData,
      ...queryProps,
    }),
    [
      requestData,
      activeSectionKey,
      queryProps,
      requestId,
      taskId,
      processInstanceId,
    ]
  );

  const activeSection = useMemo(
    () => cards.find((entry) => activeSectionKey === entry.key),
    [cards, activeSectionKey]
  );

  return (
    <>
      {activeSection?.render({
        requestData,
        requestId,
        dataTestId,
        baseViewPageProps,
      })}
    </>
  );
};

export default FinancialProductRequestRejectPage;
