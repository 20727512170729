import { TextField, TextFieldProps } from '@mui/material';

type SimpleSelectProps = TextFieldProps & {
  label?: string;
  mandatory?: boolean;
  children?: any;
  helperText?: any;
};

const SimpleSelect = ({
  label = '',
  mandatory = false,
  helperText,
  ...props
}: SimpleSelectProps) => (
  <TextField
    select
    variant="outlined"
    label={mandatory ? `${label} *` : label}
    fullWidth
    helperText={helperText}
    {...props}
  />
);

export default SimpleSelect;
