import { GridProps, InputBaseProps } from '@mui/material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextInputForm from './TextInputForm';
import { ITesteable } from '../../common/interfaces';

import PlatformCatalogSelector, {
  PlatformCatalogSelectorProps,
} from './PlatformCatalogSelector';
import DataSourceSelector, {
  DataSourceSelectorProps,
} from './DataSourceSelector';
import CheckBoxControl from './CheckBoxControl';

export enum FormFieldType {
  INPUT = 'INPUT',
  DATASOURCE = 'DATASOURCE',
  PLATFORM_CATALOG = 'PLATFORM_CATALOG',
  CHECKBOX = 'CHECKBOX',
}

export interface FormFieldProps extends ITesteable {
  name: string;
  mandatory?: boolean;
  hidden?: boolean;
  containerProps?: GridProps;
  type?: FormFieldType;
  translationKey?: string;
  platformCatalogSelectorProps?: Pick<
    PlatformCatalogSelectorProps<any>,
    'catalog' | 'isEnabled' | 'params' | 'setRefOptions' | 'filterField'
  >;
  dataSourceSelectorProps?: Pick<
    DataSourceSelectorProps,
    'collection' | 'translationKeyPrefixSimple' | 'setRefOptions'
  >;
  selectorFullWidth?: boolean;
  translationPrefix?: string;
  childrenProps?: GridProps;
  disabled?: boolean;
  inputProps?: InputBaseProps['inputProps'];
}

const FormField = ({
  containerProps,
  type = FormFieldType.INPUT,
  platformCatalogSelectorProps,
  dataSourceSelectorProps,
  selectorFullWidth = false,
  name,
  mandatory = true,
  hidden,
  translationPrefix,
  dataTestId,
  childrenProps,
  translationKey,
  disabled = false,
  inputProps = {},
  ...props
}: FormFieldProps) => {
  const [translate] = useTranslation('global');

  if (hidden) return null;
  const fieldKey = translationKey ?? name;

  return (
    <Grid
      item
      xs={12}
      key={name}
      {...props}
      {...childrenProps}
      {...containerProps}
    >
      {type === FormFieldType.INPUT && (
        <TextInputForm
          name={name}
          mandatory={mandatory}
          label={translate(`${translationPrefix}.${fieldKey}.label`)}
          helperText={translate(
            `${translationPrefix}.${fieldKey}.helperText`,
            ''
          )}
          fullWidth
          inputProps={{
            minLength: 6,
            maxLength: 50,
            'data-testid': dataTestId,
            ...inputProps,
          }}
          {...props}
        />
      )}
      {type === FormFieldType.DATASOURCE && dataSourceSelectorProps && (
        <DataSourceSelector
          name={name}
          mandatory={mandatory}
          dataTestId={dataTestId}
          labelKey={`${translationPrefix}.${fieldKey}.label`}
          fullWidth
          selectorFullWidth={selectorFullWidth}
          disabled={disabled}
          {...props}
          {...dataSourceSelectorProps}
        />
      )}
      {type === FormFieldType.PLATFORM_CATALOG &&
        platformCatalogSelectorProps && (
          <PlatformCatalogSelector
            name={name}
            mandatory={mandatory}
            labelKey={`${translationPrefix}.${fieldKey}.label`}
            dataTestId={dataTestId}
            fullWidth
            selectorFullWidth={selectorFullWidth}
            {...props}
            {...platformCatalogSelectorProps}
          />
        )}
      {type === FormFieldType.CHECKBOX && (
        <CheckBoxControl
          name={name}
          mandatory={mandatory}
          label={translate(`${translationPrefix}.${fieldKey}.label`)}
          helperText={translate(
            `${translationPrefix}.${fieldKey}.helperText`,
            ''
          )}
          disabled={disabled}
          {...props}
        />
      )}
    </Grid>
  );
};

export default FormField;
