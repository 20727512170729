import Grid from '@mui/material/Grid';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Divider } from 'vulpino';
import BaseViewPage from '../../commons/BaseViewPage';
import BaseViewHeader from '../../BaseViewHeader';
import DataFill from '../../commons/DataFill';
import DataFillIterator from '../../commons/DataFillIterator';
import { RouteKey } from '../../../navigation/constants';
import usePermissions from '../../../hooks/usePermissions';
import {
  getCommerce,
  getBusinessSchema,
  getCommerceBusinessSchemas,
} from '../../../api/adminBusinessEnvironment/service';
import CommerceBusinessSchemasQuotas from './CommerceBusinessSchemasQuotas';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(0.5),
  width: '100%',
}));

const CommerceBusinessSchemasDetail = () => {
  const params = useParams();
  const [translate] = useTranslation('global');
  const { checkPermissions } = usePermissions();
  const businessSchemaId = parseInt(params.businessSchemaId ?? '', 10);
  const commerceId = parseInt(params.commerceId ?? '', 10);

  const { data: commerceData, ...queryProps } = useQuery({
    queryKey: [`getCommerce:${commerceId}`],
    queryFn: async () => (await getCommerce(commerceId!)).data,
  });

  const { data: businessSchemaData } = useQuery({
    queryKey: [`getBusinessSchema:${businessSchemaId}`],
    queryFn: async () => (await getBusinessSchema(businessSchemaId!)).data,
  });

  const { data: commerceBusinessSchemaData } = useQuery({
    queryKey: [`getCommerceBusinesSchema:${commerceId},${businessSchemaId}`],
    queryFn: async () =>
      (await getCommerceBusinessSchemas(commerceId!, businessSchemaId!)).data
        ?.result[0],
  });

  const isBNPL = businessSchemaData?.name?.toLowerCase().includes('bnpl');
  return (
    businessSchemaData && (
      <BaseViewPage
        routeTrail={[RouteKey.COMMERCES, RouteKey.COMMERCE_BUSINESS_SCHEMA]}
        routeKey={RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW}
        routePathParams={{ commerceId }}
        data={commerceData}
        {...queryProps}
        breadcrumb={businessSchemaData.description}
      >
        <BaseViewHeader
          emptyForwardSpaces
          buttonListDirection="row"
          // buttonList={[
          //   {
          //     onClick: () => console.log('works delete'),
          //     //dataTestId: `${dataTestId}_EditButton`,
          //     endIcon: <DeleteOutlinedIcon />,
          //     labelKey: 'page.businessSchemas.detail.actionDelete',
          //     hidden: !checkPermissions(Permissions.COMMERCE_UPDATE),
          //     variant: 'tertiary',
          //   },
          //   {
          //     onClick: () => console.log('works edit'),
          //     //dataTestId: `${dataTestId}_EditButton`,
          //     endIcon: <BorderColorIcon />,
          //     labelKey: 'page.businessSchemas.detail.actionEdit',
          //     hidden: !checkPermissions(Permissions.COMMERCE_UPDATE),
          //     variant: 'secondary',
          //   },
          // ]}
          boxProps={{
            pt: 0,
          }}
        >
          <Grid>
            <DataFill
              md={8}
              lg={8}
              translationKeyPrefix="page.businessSchemas.detail"
              title=".titleParagraph1"
              value={businessSchemaData?.description}
              labelProps={{
                sx: {
                  fontSize: 18,
                  fontWeight: 'bold',
                },
              }}
              valueProps={{
                sx: {
                  fontSize: 22,
                  fontWeight: 'normal',
                },
              }}
            />
          </Grid>
        </BaseViewHeader>

        <StyledBox p={3} pt={0} mb={3}>
          <Grid item xs={12} direction="column" mb={2} mt={2}>
            <Divider />
          </Grid>

          <Typography>
            {translate(`page.businessSchemas.detail.subtitleParagraph1`)}:
            <b> {commerceData?.name?.toString()}</b>
          </Typography>
        </StyledBox>

        <Grid
          item
          sm={12}
          sx={{
            border: '1px solid #E0E0E0',
            borderRadius: '5px',
            padding: '0px 24px 24px 24px',
          }}
        >
          <DataFill
            md={8}
            lg={8}
            translationKeyPrefix="page.businessSchemas.detail"
            title=".titleParagraph2"
            value=""
            labelProps={{
              sx: {
                fontSize: 18,
                fontWeight: 'bold',
              },
            }}
          />
          <DataFillIterator
            mt={1}
            translationKeyPrefix="page.businessSchemas.detail"
            object={
              isBNPL
                ? [
                    {
                      key: '.idCommerce',
                      value: commerceBusinessSchemaData?.bnplProviderMerchantId,
                    },
                  ]
                : [
                    {
                      key: '.invoiceRequired',
                      value: commerceBusinessSchemaData?.invoiceRequired
                        ? 'Si'
                        : 'No',
                    },
                    {
                      key: '.quotaControl',
                      value: businessSchemaData?.quotaControlEnabled
                        ? 'Si'
                        : 'No',
                    },
                  ]
            }
            gridItemProps={{
              lg: 3,
              md: 4,
              xs: 6,
            }}
          />
        </Grid>
        {commerceBusinessSchemaData && !isBNPL && (
          <CommerceBusinessSchemasQuotas
            commerceBusinessSchemasId={commerceBusinessSchemaData.id}
          />
        )}
      </BaseViewPage>
    )
  );
};

export default CommerceBusinessSchemasDetail;
