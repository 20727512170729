import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { get, capitalize } from 'lodash';
import { useMemo } from 'react';

interface StyledDataGridProps extends DataGridProps {
  hideColumnsHeaders?: boolean;
  rowHighlight?: boolean;
}

const StyledDataGrid = ({
  hideColumnsHeaders = false,
  rowHighlight = false,
  sx,
  ...props
}: StyledDataGridProps) => {
  const [translate] = useTranslation('global');

  const componentProps = useMemo(() => get(props, 'component', {}), [props]);

  const componentsProps = useMemo(
    () => get(props, 'componentsProps', {}),
    [props]
  );
  const paginationProps = useMemo(
    () => get(componentsProps, 'pagination', {}),
    [componentsProps]
  );

  const computedProps = useMemo(() => {
    const computed = {
      sx: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      },
    };
    Object.assign(computed.sx, {
      '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
        backgroundColor: !rowHighlight ? 'inherit' : undefined,
        cursor: props.onRowClick ? 'pointer' : undefined,
      },
    });

    if (hideColumnsHeaders) {
      Object.assign(computed, {
        headerHeight: 0,
      });
      Object.assign(computed.sx, sx, {
        '&.MuiDataGrid-root': {
          borderColor: 'transparent !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          display: 'none',
        },
      });
    }
    return computed;
  }, [rowHighlight, hideColumnsHeaders, sx, props.onRowClick]);

  return (
    <DataGrid
      disableColumnMenu
      disableSelectionOnClick
      localeText={{
        noRowsLabel: translate(
          'component.customDataGrid.customNoRowsOverlayTitle'
        ),
      }}
      {...props}
      {...computedProps}
      components={{
        ...componentProps,
      }}
      componentsProps={{
        ...componentsProps,
        pagination: {
          ...paginationProps,
          labelRowsPerPage: translate(
            'component.customDataGrid.labelRowsPerPage'
          ),
          getItemAriaLabel: (type: any) =>
            translate(`component.customDataGrid.goTo${capitalize(type)}Page`),
          labelDisplayedRows: (data: any) =>
            translate('component.customDataGrid.labelDisplayedRows', {
              from: data.from,
              to: data.to,
              count: data.count,
            }),
        },
      }}
    />
  );
};

export default StyledDataGrid;
