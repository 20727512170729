import { IDataSourceResponse } from '../../common/interfaces';
import { getRolesList } from '../adminUsersRoles/service';
import fetch from '../api';
import { env } from '../env';

export const getOriginatorsDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/originators',
    method: 'get',
  });

export const getBusinessSchemasDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/business-schemas',
    method: 'get',
  });

export const getStatusesDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/financial-products-requests-datasources/status',
    method: 'get',
  });

export const getFinancialOfficesDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/financial-offices',
    method: 'get',
  });

export const getCommercesDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/commerces',
    method: 'get',
  });

export const getCommercesUserDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/commerces-user',
    method: 'get',
  });

export const getHeadquartersDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/headquarters',
    method: 'get',
  });

export const getDivisionsDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/divisions',
    method: 'get',
  });

export const getFundingEntitiesDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/funding-entities',
    method: 'get',
  });

export const getReportDefinitionsDatasource =
  (): Promise<IDataSourceResponse> =>
    fetch({
      baseURL: env.APIS_ONSCORE,
      url: '/v1/reports-datasources/report-definitions',
      method: 'get',
    });

export const getCalendarDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/business-environment-datasources/calendars',
    method: 'get',
  });

export const getBNPLProvidersDataSource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v2/ecommerce-integration-datasources/bnpl-providers',
    method: 'get',
  });

export const getInterestRateTablesDataSource =
  (): Promise<IDataSourceResponse> =>
    fetch({
      baseURL: env.APIS_ONSCORE,
      url: '/v2/ecommerce-integration-datasources/interest-rate-tables',
      method: 'get',
    });

export const getInterestRateGroupsDataSource =
  (): Promise<IDataSourceResponse> =>
    fetch({
      baseURL: env.APIS_ONSCORE,
      url: '/v1/business-environment-datasources/interest-rate-groups',
      method: 'get',
    });

export const getQuotaDefinitionTypes = (): Promise<IDataSourceResponse> =>
  Promise.resolve({
    data: [
      {
        key: 'IN_FORCE_AT_MOMENT',
        value: 'Si',
      },
      // {
      //   key: 'NOT_IN_FORCE',
      //   value: 'No',
      // },
      // {
      //   key: 'IN_FORCE',
      //   value: 'Próximos',
      // },
    ],
  });

export const getBankAccountTypes = (): Promise<IDataSourceResponse> =>
  Promise.resolve({
    data: [
      {
        key: 'CC',
        value: 'Cuenta Corriente',
      },
      {
        key: 'CA',
        value: 'Caja de Ahorro',
      },
    ],
  });

export const getBankAccountIdentificationTypes =
  (): Promise<IDataSourceResponse> =>
    Promise.resolve({
      data: [
        {
          key: 'alias',
          value: 'Alias',
        },
        {
          key: 'CBU',
          value: 'CBU',
        },
        {
          key: 'CVU',
          value: 'CVU',
        },
      ],
    });

export const getCodeMappingsEntityTypesDataSource =
  (): Promise<IDataSourceResponse> =>
    fetch({
      baseURL: env.APIS_ONSCORE,
      url: '/v1/business-environment-datasources/code-mappings/entity-types',
      method: 'get',
    });

export const getCodeMappingsExternalServicesDataSource =
  (): Promise<IDataSourceResponse> =>
    fetch({
      baseURL: env.APIS_ONSCORE,
      url: '/v1/business-environment-datasources/code-mappings/external-services',
      method: 'get',
    });

export const getStatesDatasource = (): Promise<IDataSourceResponse> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/catalogs-datasources/states',
    method: 'get',
  });

export const getRolesDataSource = async (): Promise<IDataSourceResponse> => {
  const { result } = (await getRolesList()).data;
  return {
    data: result.map((role) => ({
      key: String(role.id),
      value: role.roleName,
    })),
  };
};

export const getCommerceCategoryData = async (): Promise<IDataSourceResponse> =>
  Promise.resolve({
    data: [
      { key: 'RETAIL_MULTICATEGORY', value: 'RETAIL_MULTICATEGORY' },
      { key: 'IT_TECH', value: 'IT_TECH' },
      { key: 'APPLIANCES', value: 'APPLIANCES' },
      { key: 'MOTORCYCLES_BYCICLES', value: 'MOTORCYCLES_BYCICLES' },
      { key: 'BUILDING_SUPPLIES', value: 'BUILDING_SUPPLIES' },
      { key: 'AUTOMOTIVE', value: 'AUTOMOTIVE' },
      { key: 'HOUSE_FURNITURE_DECO', value: 'HOUSE_FURNITURE_DECO' },
      { key: 'HARDWARE_STORE', value: 'HARDWARE_STORE' },
      { key: 'CLOTHING', value: 'CLOTHING' },
      { key: 'TOYS', value: 'TOYS' },
      { key: 'PAINT_SHOP', value: 'PAINT_SHOP' },
      { key: 'TURISM', value: 'TURISM' },
      { key: 'HEALTH', value: 'HEALTH' },
      { key: 'OTHER', value: 'OTHER' },
    ],
  });

export const getGenericBoolean = (): Promise<IDataSourceResponse> =>
  Promise.resolve({
    data: [
      {
        key: 'true',
        value: 'Si',
      },
      {
        key: 'false',
        value: 'No',
      },
    ],
  });
