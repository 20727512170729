import { useMemo } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IFilterAppliedOption } from '../../common/interfaces';
import ChipsGroup from './ChipsGroup';

type FilterChipsProps = {
  filterAppliedList: IFilterAppliedOption[];
  translationKeyPrefix: string;
  onRemoveChip: (filterName: string) => void;
  filterValue?: string;
};

export const FilterChips = ({
  filterAppliedList,
  translationKeyPrefix,
  onRemoveChip,
  filterValue
}: FilterChipsProps) => {
  const [translate] = useTranslation('global');
  const getLabelValue = (filterApplied: IFilterAppliedOption) => {
    const value = filterApplied.renderValue
      ? filterApplied.renderValue
      : filterApplied.value;
    switch (value) {
      case 'true':
        return 'Si';
      case 'false':
        return 'No';
      default:
        return value;
    }
  };

  const chips = useMemo(() => {
    let a;
    return filterAppliedList.map((filterApplied) => {
      const labelKey = translationKeyPrefix
        .concat('Label_')
        .concat(filterApplied.name);
      const labelTranslated = translate(labelKey);
      return {
        key: filterApplied.name,
        label: `${labelTranslated}: ${getLabelValue(filterApplied)}`,
      };
    });
  }, [filterAppliedList, translate]);

  const labelValue = filterValue === undefined ? translate('component.chipGroup.filterSelectedEmptyMessage') : filterValue;

  return (
    <Grid item container xs={12} alignItems="center">
      <Typography variant="body2">
        {translate('component.chipGroup.filterSelectedAlertMessage')}
      </Typography>
      <ChipsGroup
        chips={chips}
        chipSx={{ m: 0.5 }}
        onRemoveChip={onRemoveChip}
        emptyState={
          <Chip
            label={labelValue}
          />
        }
      />
    </Grid>
  );
};

export default FilterChips;
