import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import { styled } from '@mui/system';

import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';
import TitledSection from '../commons/TitledSection';
import StyledAlert from '../ui/StyledAlert';

const StyledBox = styled(Box)(({ theme }) => ({
  border: '1px solid black',
  padding: theme.spacing(2),
  borderRadius: '4px',
}));

type FileUploadControlProps = Omit<IFormField, 'type'> & {
  label?: string;
  labelKey?: string;
  defaultValue?: string;
  variant?: string;
  helperText?: any;
  translationPath?: string;
  mask?: string;
  dataTestId?: string;
  disabled?: boolean;
};

const FileUploadControl = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  mandatory = false,
  disabled = false,
  helperText,
  dataTestId,
  mask,
  ...props
}: FileUploadControlProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const label = useTranslate(labelKey, labelProp);

  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ width: '100%' }}>
          <StyledAlert severity="info" title="// TODO" />
          <TitledSection title={label} sx={{ width: '100%' }} spacing={2}>
            <Grid item xs={6}>
              <Dropzone
                onDrop={(acceptedFiles) => field.onChange(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <StyledBox>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drop</p>
                    </div>
                  </StyledBox>
                )}
              </Dropzone>
            </Grid>
            <Grid item xs={6}>
              {(field.value ?? []).map((file: File) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>{error?.message ?? helperText}</FormHelperText>
            </Grid>
          </TitledSection>
        </FormControl>
      )}
    />
  );
};

export default FileUploadControl;
