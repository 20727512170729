/* eslint-disable no-underscore-dangle */
export const APIS_ONSCORE = 'APIS_ONSCORE';

export const jars = [
  // @ts-ignore
  window.env ?? {},
]; 

interface Env {
  APIS_ONSCORE: string;
}

export const env: Env = new Proxy({
  APIS_ONSCORE: '',
}, {
  get: (target, key: string) => {
    const find = jars.find(jar => jar[key] !== undefined);

  // @ts-ignore
    return find ? find[key] : target[key];
  },
});
