import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Button, DatePicker, TextInput } from 'vulpino';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import TitledSection from '../../../../components/commons/TitledSection';
import BreadcrumbsNavigator from '../../../../components/commons/BreadcrumbsNavigator';
import { RouteKey, paths } from '../../../../navigation/constants';
import { createEvent, getEvent } from '../../../../api/adminCalendar';
import { useUI } from '../../../../contexts/UIContext';
import { ToastType } from '../../../../components/commons/Toast';
import useRoutePage from '../../../../hooks/useRoutePage';

export const EventForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [date, setDate] = useState<any>(null);

  const params = useParams();
  const itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;
  const eventId = params.eventId ? parseInt(params.eventId, 10) : undefined;

  const { navigateToRoute } = useRoutePage({
    routeKey: RouteKey.CREATE_EVENT,
  });

  const { data, ...queryProps } = useQuery({
    queryKey: [`getEvent:${eventId}`],
    queryFn: async () => (await getEvent(itemId, eventId)).data,
  });

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDate(dayjs(data?.date));
    }
  }, [data]);

  const { setToast } = useUI();

  const saveCalendar = async (e: any) => {
    e.preventDefault();
    const body = {
      name,
      date,
    };

    createEvent(body, itemId, eventId)
      .then(() => {
        setToast({
          type: ToastType.SUCCESS,
          message: t(
            eventId
              ? 'page.calendar.events.toast.edit'
              : 'page.calendar.events.toast.new'
          ),
        });
        navigateToRoute(RouteKey.VIEW_CALENDAR, {
          itemId,
        });
      })
      .catch((err) => {
        setToast({
          type: ToastType.ERROR,
          message: t('page.calendar.events.toast.error'),
        });
      });
  };

  const [tomorrow, setTomorrow] = useState<any>(dayjs().add(1, 'day'));

  return (
    <div>
      <BreadcrumbsNavigator
        title={t('page.calendar.events.newEvent')}
        routes={[RouteKey.CALENDAR, RouteKey.CREATE_EVENT]}
        current={RouteKey.CREATE_EVENT}
      />
      <TitledSection title={t('page.calendar.events.newEvent')} variant="h1">
        <form onSubmit={saveCalendar}>
          <Grid container rowGap={4}>
            <Grid item md={12} xs={12} mt={2}>
              <Typography variant="body1">
                {t('page.calendar.events.config')}
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid direction="column" spacing={2} container>
                <Grid item>
                  <TextInput
                    fullWidth
                    required
                    label={t('page.calendar.events.form.name')}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid direction="column" spacing={2} mx={2} container>
                <Grid item>
                  <DatePicker
                    label={`${t('page.calendar.events.form.date')}*`}
                    localizationProviderProps={{}}
                    value={date}
                    onChange={setDate}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        helperText="DD/MM/YYYY"
                        variant="outlined"
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={tomorrow}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={6} spacing={2} mt={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  navigate(paths[RouteKey.CALENDAR], { replace: true });
                }}
                fullWidth
                variant="secondary"
              >
                {t('page.calendar.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                disabled={!name}
                fullWidth
                variant="primary"
              >
                {t('page.calendar.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </TitledSection>
    </div>
  );
};
