import { useTranslation } from 'react-i18next';
import { FinancialProductRequestStatus } from '../common/enums';
import StatusBadge, {
  Status,
  StatusBadgeProps,
} from './commons/StatusBadge';

const statusMapping = {
  [FinancialProductRequestStatus.ONBOARDING]: {
    status: Status.INFO,
  },
  [FinancialProductRequestStatus.PENDING_APPROVAL]: {
    status: Status.WARNING,
  },
  [FinancialProductRequestStatus.APPROVED]: {
    status: Status.WARNING,
  },
  [FinancialProductRequestStatus.ACTIVATION_PROCESS]: {
    status: Status.WARNING,
  },
  [FinancialProductRequestStatus.FINALIZED]: {
    status: Status.SUCCESS,
  },
  [FinancialProductRequestStatus.REJECTED]: {
    status: Status.FAILURE,
  },
  [FinancialProductRequestStatus.EXPIRED]: {
    status: Status.FAILURE,
  },
  [FinancialProductRequestStatus.ABORTED]: {
    status: Status.FAILURE,
  },
};

interface IRequestStatusBadgeProps extends Omit<StatusBadgeProps, 'status'> {
  status: FinancialProductRequestStatus;
}

const RequestStatusBadge = ({ status, ...props }: IRequestStatusBadgeProps) => {
  const [translate] = useTranslation('global');

  return (
    <StatusBadge
      {...props}
      {...statusMapping[status]}
      label={translate(`page.financialProductRequest.status.${status}`)}
    />
  );
};

export default RequestStatusBadge;
