import { GridColumns, GridRowId } from '@mui/x-data-grid';
import { Chip, Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { RouteKey, paths } from '../../../navigation/constants';
import { getCommerceBusinessSchemaQuotas } from '../../../api/adminBusinessEnvironment/service';
import useRoutePage from '../../../hooks/useRoutePage';
import {
  IBusinessSchemaQuotaFiltersState,
  IButtonConf,
  IFilterAppliedOption,
} from '../../../common/interfaces';
import useFiltersProps from '../../../hooks/useFiltersProps';
import BaseTablePage from '../../commons/BaseTablePage';
import FilterContainer from '../../commons/FilterContainer';
import CustomDataGrid from '../../commons/CustomDataGrid';
import useFilterOptions from '../../../pages/CommerceBusinessSchemaViewPage/hooks/useFilterOptions';
import usePermissions from '../../../hooks/usePermissions';
import { FilterOptionTypes, Permissions } from '../../../common/enums';
import { DataSourceCollection } from '../../../hooks/useDataSources';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
}

const quotaInForceAtMoment = 'IN_FORCE_AT_MOMENT';

const CommerceBusinessSchemasQuotas = ({
  commerceBusinessSchemasId,
}: {
  commerceBusinessSchemasId?: any;
}) => {
  const [translate] = useTranslation('global');
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  const defaultFilterList = [
    {
      name: 'quota_in_force_at_moment',
      attribute: 'quotaDefinitionType',
      value: quotaInForceAtMoment,
      type: FilterOptionTypes.DATASOURCE,
      collection: DataSourceCollection.QUOTA_TYPE,
      renderValue: 'Si',
    },
  ];

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const params = useParams();

  useEffect(() => {
    mergeRouteState({ filterAppliedList: defaultFilterList });
  }, []);

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const fixChipsValue = (request: IFilterAppliedOption[]) => {
    let response = request;
    response.forEach((element) => {
      switch (element?.name) {
        case 'exceptional':
          element.renderValue = element.value ? 'Si' : 'No';
          break;
        case 'validFrom':
          element.renderValue = element.value
            ? dayjs(new Date(element.value.toString()))
                .format(translate('DD/MM/YYYY'))
                .toString()
            : '-';
          break;
        default:
          break;
      }
    });
    return response;
  };

  const { filters, ...filterProps } =
    useFiltersProps<IBusinessSchemaQuotaFiltersState>({
      appliedFilters: fixChipsValue(filterAppliedList),
      onChange: (appliedFilters) =>
        mergeRouteState({
          filterAppliedList: appliedFilters,
        }),
    });

  const {
    data: queryData,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      `getCommerceBusinessSchemaQuotas${commerceBusinessSchemasId}`,
      pageNumber,
      pageSize,
      filters,
    ],
    queryFn: async () =>
      (
        await getCommerceBusinessSchemaQuotas(
          { pageNumber, pageSize, filters },
          commerceBusinessSchemasId!
        )
      ).data,
    keepPreviousData: false,
  });

  const filterOptionList = useFilterOptions();
  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);
  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleView = useCallback(
    (id: GridRowId) => {
      console.log('Click Column works ' + id);
    },
    [navigate]
  );

  const handleAdd = () => {
    const businessSchemaId = parseInt(params.businessSchemaId ?? '', 10);
    const commerceId = parseInt(params.commerceId ?? '', 10);
    navigate(
      generatePath(paths[RouteKey.COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW], {
        commerceId,
        businessSchemaId,
      })
    );
  };

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <DownloadForOfflineOutlinedIcon />,
      labelKey: 'component.toolBar.downloadLabelButton',
      disabled: rowCount <= 0,
      hidden: true,
    },
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      hidden: !checkPermissions(Permissions.COMMERCE_CREATE),
      onClick: handleAdd,
    },
  ];

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'amount',
        headerName: translate(
          'page.businessSchemas.quotas.columnHeaders.amount'
        ),
        minWidth: 150,
        flex: 1,
        sortable: false,
      },
      {
        field: 'validFrom',
        headerName: translate(
          'page.businessSchemas.quotas.columnHeaders.validFrom'
        ),
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.validFrom
            ? dayjs(row.validFrom).format(translate('DD/MM/YYYY'))
            : '-',
      },
      {
        field: 'validTo',
        headerName: translate(
          'page.businessSchemas.quotas.columnHeaders.validTo'
        ),
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.validTo
            ? dayjs(row.validTo).format(translate('DD/MM/YYYY'))
            : '-',
      },
      {
        field: 'exceptional',
        headerName: translate(
          'page.businessSchemas.quotas.columnHeaders.exceptional'
        ),
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row.exceptional ? 'Si' : 'No'),
      },
      {
        field: 'blank',
        headerName: translate(
          'page.businessSchemas.quotas.columnHeaders.blank'
        ),
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.type === quotaInForceAtMoment && (
            <Chip
              label={translate('page.businessSchemas.quotas.types.' + row.type)}
              sx={{ backgroundColor: '#E6F9F0' }}
            />
          ),
      },
    ],
    [translate]
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  return (
    <BaseTablePage
      error={error}
      routeKey={RouteKey.COMMERCE_BUSINESS_SCHEMA}
      // dataTestId={dataTestId}
      data={queryData}
      xs={{ padding: '20px 0' }}
      {...queryProps}
    >
      <Grid
        item
        sm={12}
        sx={{
          border: '1px solid #E0E0E0',
          borderRadius: '5px',
          padding: '0px 24px 24px 24px',
        }}
      >
        <FilterContainer
          title={translate('page.businessSchemas.quotas.title')}
          titleSx={{
            fontSize: '18px !important',
            fontWeight: 'bold',
          }}
          filterOptionList={filterOptionList ?? []}
          translationKeyPrefix="page.businessSchemas.quotas.filter."
          {...filterProps}
        >
          <CustomDataGrid
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCount}
            pageNumber={pageNumber}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            onRowClick={({ id }) => handleView(id)}
            onPageChange={handleOnPageChange}
            onPageSizeChange={handleOnPageSizeChange}
            getRowId={(r: any) => r.id}
            buttonConfList={buttonConfList}
            showFirstButton
            showLastButton
          />
        </FilterContainer>
      </Grid>
    </BaseTablePage>
  );
};

export default CommerceBusinessSchemasQuotas;
