import { Grid, Typography } from '@mui/material';
import { Box, BoxProps, styled } from '@mui/system';
import { ReactNode } from 'react';
import { IButtonConf, ITesteable, VButtonConf } from '../common/interfaces';
import TitledSection, { TitledSectionProps } from './commons/TitledSection';
import FooterButtons from './commons/FooterButtons';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.spacing(0.5),
  width: '100%',
}));

export interface BaseViewHeaderProps
  extends ITesteable,
    Omit<TitledSectionProps, 'title'> {
  title?: ReactNode;
  suptitle?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  buttons?: IButtonConf[];
  buttonList?: VButtonConf[];
  buttonListDirection?: 'row' | 'column';
  boxProps?: BoxProps;
  emptyForwardSpaces?: boolean;
}

const BaseViewHeader = ({
  suptitle,
  title,
  subtitle,
  children,
  dataTestId,
  buttons = [],
  buttonList = [],
  buttonListDirection = 'column',
  boxProps,
  emptyForwardSpaces,
  ...props
}: BaseViewHeaderProps) => (
  <TitledSection
    variant="h1"
    mb={emptyForwardSpaces ? 0 : 3}
    dataTestId={dataTestId}
    {...props}
  >
    <StyledBox p={3} pb={emptyForwardSpaces ? 0 : 3} {...boxProps}>
      <Grid container>
        <Grid item sx={{ flexGrow: 1 }}>
          {suptitle}
          <Typography variant="h1">{title}</Typography>
          {subtitle}
          {children}
        </Grid>
        {buttons.length > 0 ||
          (buttonList.length > 0 && (
            <Grid
              item
              md={4}
              lg={3}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <FooterButtons
                direction={buttonListDirection}
                buttonList={buttonList}
                buttonConfList={buttons}
                mt={0}
              />
            </Grid>
          ))}
      </Grid>
    </StyledBox>
  </TitledSection>
);

export default BaseViewHeader;
