import { TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import MultiSelect from '../../commons/MultiSelect';
import type { IOptionItem } from '../../../common/interfaces';
import type { IFormField } from '../FormFields';


type MultiSelectFilterProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label?: string;
    defaultValue?: string;
    variant?: string;
    helperText?: any;
    options?: IOptionItem[];
    valueMapper?: (value: any, options: any[]) => any;
    dataTestId?: string;
  };

const MultiSelectFilter = ({
  name,
  label,
  mandatory = false,
  helperText,
  dataTestId = 'MultiSelectFilter',
  options = [],
  valueMapper,
  ...props
}: MultiSelectFilterProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const value = field.value?.value ?? [];
        return (
          <MultiSelect
            label={mandatory ? `${label}*` : label}
            value={value}
            error={!!error}
            fullWidth
            {...props}
            onChange={(ev) => {
              const targetValue = ev.target.value ?? [];
              field.onChange(valueMapper ? valueMapper(targetValue, options): targetValue);
            }}
            options={(options ?? []).map((option) => ({
              id: option.key,
              label: option.value,
            }))}
          />
        );
      }}
    />
  );
};

export default MultiSelectFilter;
