import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useQuery } from 'react-query';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Grid, Typography, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TextInputForm from '../form/TextInputForm';
import {
  IFormProps,
  ICommerceFormData,
  ICommerceData,
  ITesteable,
} from '../../common/interfaces';
import TitledSection from '../commons/TitledSection';
import { buildFormData, buildSchema } from './builders';
import DataSourceSelector from '../form/DataSourceSelector';
import { DataSourceCollection } from '../../hooks/useDataSources';
import TagSelector from '../form/TagSelector';
import { PlatformCatalogKey } from '../../hooks/usePlatformCatalogs';
import FooterButtons from '../commons/FooterButtons';
import BaseForm from '../commons/BaseForm';
import FormFields from '../form/FormFields';
import { FormFieldType } from '../form/FormField';
import SearchSelector, { SearchableTable } from '../form/SearchSelector';
import { getFundingEntitiesDataSource } from '../../api/adminDataSources/service';
import { getTenantName } from '../../utils/core';

const StyledAdditionalEntry = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IS_HEADQUARTER_VALUE = -1;

interface ICommerceFormProps extends IFormProps<ICommerceData>, ITesteable {
  commerceId?: number;
  data?: ICommerceData;
}

const CommerceForm = ({
  commerceId,
  dataTestId = 'CommerceForm',
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: ICommerceFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate }));
  const [cityOptions, setCityOptions] = useState([]);
  const [zipCodeOptions, setZipCodeOptions] = useState([]);

  const { data: defaultBankAccountData, isLoading } = useQuery({
    queryKey: ['getDefaultFundingEntity'],
    queryFn: async () => {
      let tenantName = getTenantName();
      let fundingEntities = (await getFundingEntitiesDataSource()).data;
      let defaultFundingEntityId = fundingEntities.filter(
        (fundingEntity) => fundingEntity.value === tenantName
      )[0].key;
      return {
        bankAccounts: [
          {
            fundingEntityId: Number(defaultFundingEntityId),
            bankId: 34,
            accountType: 1,
          },
        ],
      };
    },
  });

  const methods = useForm<ICommerceFormData>({
    defaultValues: buildFormData(
      //@ts-ignore
      isLoading ? undefined : defaultBankAccountData
    ),
    resolver: yupResolver(schema),
  });

  const determineIfFormShouldUseDefaultDataOrExistingData = (
    existingCommerceData: any
  ) => {
    if (
      defaultBankAccountData &&
      Object.keys(existingCommerceData).length === 0
    ) {
      return defaultBankAccountData;
    }
    return existingCommerceData;
  };

  const formData = useMemo(
    () =>
      buildFormData(determineIfFormShouldUseDefaultDataOrExistingData(data)),
    [data, defaultBankAccountData]
  );

  const additionalData = methods.watch('additionalDataEntries');
  const bankAccounts = methods.watch('bankAccounts');
  const addressCountryId = methods.watch('address.countryId');
  const addressStateId = methods.watch('address.stateId');
  const addressCityId = methods.watch('address.cityId');
  const addressZipCodeId = methods.watch('address.zipCodeId');
  const firstRender = useRef(0);

  useEffect(() => {
    setSchema(buildSchema({ translate }));
  }, [translate]);

  useEffect(() => {
    if (
      typeof addressCityId !== 'number' &&
      typeof addressZipCodeId !== 'number'
    ) {
      formRef?.current?.setValue('address.cityId', undefined);
      formRef?.current?.setValue('address.zipCodeId', undefined);
    }
  }, [addressStateId]);

  useEffect(() => {
    if (addressCityId && addressZipCodeId && zipCodeOptions?.length > 0) {
      if (
        typeof addressCityId === 'number' &&
        typeof addressZipCodeId === 'number' &&
        cityOptions?.length > 0
      ) {
        const cityOption: any = cityOptions.filter((city: any) =>
          city?.id?.includes(data?.address?.cityId)
        )[0];
        const zipOption: any = zipCodeOptions.filter(
          (zip: any) => zip?.id?.id === data?.address?.zipCodeId
        )[0];
        formRef?.current?.setValue('address.cityId', cityOption?.id);
        formRef?.current?.setValue('address.zipCodeId', zipOption?.id);
      } else if (
        typeof addressCityId !== 'number' &&
        typeof addressZipCodeId !== 'number'
      ) {
        const zipOption = zipCodeOptions.filter(
          (zip: any) => zip?.id?.id === addressZipCodeId?.id
        );
        if (zipOption?.length === 0) {
          formRef?.current.setValue('address.zipCodeId', undefined);
        }
      }
    }
  }, [zipCodeOptions, cityOptions, addressCityId]);

  const onSubmit = async ({
    additionalDataEntries = [],
    financialOfficeId,
    headquarterId,
    bankAccounts: bankAccountsEntries,
    externalCode,
    ...dataForm
  }: ICommerceFormData) => {
    const payload = {
      ...dataForm,
      address: {
        ...dataForm?.address,
        cityId: addressZipCodeId?.cityId,
        zipCodeId: addressZipCodeId?.id,
      },
      bankAccounts: bankAccountsEntries.map(
        ({ fundingEntityId, ...bankAccount }) => ({
          fundingEntityId:
            fundingEntityId !== undefined
              ? parseInt(fundingEntityId, 10)
              : fundingEntityId,
          ...bankAccount,
        })
      ),
      headquarterId:
        headquarterId === IS_HEADQUARTER_VALUE ? undefined : headquarterId,
      additionalData: additionalDataEntries.reduce(
        (reduced, { key, value }) => ({
          ...reduced,
          [key]: value,
        }),
        {}
      ),
      financialOfficesIds: financialOfficeId
        ? [parseInt(financialOfficeId, 10)]
        : [],
      externalCode,
    };
    onSubmitProp?.(payload);
  };

  const stateParams = useMemo(
    () => ({
      countryId: addressCountryId,
    }),
    [addressCountryId]
  );

  const cityParams = useMemo(
    () => ({
      countryId: addressCountryId,
      stateId: addressStateId,
    }),
    [addressCountryId, addressStateId]
  );

  const zipCodeParams = useMemo(
    () => ({
      countryId: addressCountryId,
      stateId: addressStateId,
      cityIds: addressCityId,
    }),
    [addressCountryId, addressStateId, addressCityId]
  );

  const addBankAccount = useCallback(() => {
    methods.setValue('bankAccounts', [...(bankAccounts ?? []), {}]);
  }, [bankAccounts]);

  const removeBankAccount = useCallback(
    (index: number) => {
      const changed = [...bankAccounts];
      changed.splice(index, 1);
      methods.setValue('bankAccounts', changed);
    },
    [bankAccounts]
  );

  const addAdditionalEntry = useCallback(() => {
    methods.setValue('additionalDataEntries', [
      ...(additionalData ?? []),
      {
        key: '',
        value: '',
      },
    ]);
  }, [additionalData]);

  const removeAdditionalEntry = useCallback(
    (index: number) => {
      const changed = [...additionalData];
      changed.splice(index, 1);
      methods.setValue('additionalDataEntries', changed);
    },
    [additionalData]
  );

  return (
    <BaseForm<ICommerceFormData>
      dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmit}
      onError={onError}
      formRef={formRef}
      formData={formData}
      title={translate(
        commerceId
          ? 'route.commerces_edit.breadcrumb'
          : 'route.commerces_new.breadcrumb'
      )}
    >
      <TitledSection centered>
        <FormFields
          noContainer
          dataTestId={dataTestId}
          translationPrefix="page.commerce.form"
          fields={[
            {
              name: 'name',
            },
            {
              name: 'externalCode',
              containerProps: {
                mt: 3,
              },
            },
            {
              name: 'description',
              mandatory: false,
              containerProps: {
                mt: 3,
              },
            },
            {
              name: 'commerceTaxId',
              containerProps: {
                mt: 3,
              },
            },
            {
              name: 'category',
              type: FormFieldType.DATASOURCE,
              dataSourceSelectorProps: {
                collection: DataSourceCollection.COMMERCE_CATEGORY,
                translationKeyPrefixSimple: 'datasources.CommerceCategory',
              },
              containerProps: {
                mt: 3,
              },
              selectorFullWidth: true,
            },
          ]}
        />
      </TitledSection>

      <TitledSection
        titleKey="page.commerce.form.headquarterId.label"
        tooltip={translate('page.commerce.form.headquarterId.label')}
        helperText={translate(
          'page.commerce.form.headquarterId.helperText',
          ''
        )}
        centered
      >
        <SearchSelector
          table={SearchableTable.COMMERCES}
          filter={(row) =>
            (row.id === null || row.id === row.headquarterId) &&
            row.id !== data?.id
          }
          optionsMapper={(options) => [
            {
              id: IS_HEADQUARTER_VALUE,
              label: translate('page.commerce.isHeadquarter'),
            },
            ...options,
          ]}
          name="headquarterId"
          labelKey="page.commerce.form.headquarterId.label"
          fullWidth
        />
      </TitledSection>
      <TitledSection
        titleKey="page.commerce.form.financialOfficesIds.label"
        tooltip={translate('page.commerce.form.financialOfficesIds.label')}
        centered
      >
        <DataSourceSelector
          collection={DataSourceCollection.FINANCIAL_OFFICES}
          name="financialOfficeId"
          labelKey="page.commerce.form.financialOfficesIds.label"
          fullWidth
          dataTestId={`${dataTestId}_financialOfficeId`}
        />
      </TitledSection>
      <TitledSection
        titleKey="page.commerce.form.address.label"
        helperTextKey="page.commerce.form.address.helperText"
      >
        <FormFields
          dataTestId={dataTestId}
          translationPrefix="page.commerce.form"
          fields={[
            {
              name: `address.countryId`,
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.COUNTRIES,
              },
              selectorFullWidth: true,
            },
            {
              name: `address.stateId`,
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.STATES,
                isEnabled: () => !!addressCountryId,
                params: stateParams,
              },
              selectorFullWidth: true,
            },
            {
              name: `address.cityId`,
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.CITIES_FORM,
                isEnabled: () => !!addressCountryId && !!addressStateId,
                params: cityParams,
                setRefOptions: setCityOptions,
                filterField: 'description',
              },
              selectorFullWidth: true,
            },
            {
              name: `address.zipCodeId`,
              type: FormFieldType.PLATFORM_CATALOG,
              platformCatalogSelectorProps: {
                catalog: PlatformCatalogKey.ZIP_CODES_FORM,
                isEnabled: () =>
                  !!addressCountryId && !!addressStateId && !!addressCityId,
                params: zipCodeParams,
                setRefOptions: setZipCodeOptions,
              },
              selectorFullWidth: true,
            },
            {
              name: 'address.street',
            },
          ]}
          childrenProps={{
            xs: 12,
            md: 8,
            lg: 6,
          }}
        />
      </TitledSection>
      <TitledSection
        titleKey="page.commerce.bankAccounts.title"
        helperTextKey="page.commerce.bankAccounts.helperText"
        spacing={2}
      >
        {bankAccounts.map((bankAccount, index) => (
          <TitledSection
            // eslint-disable-next-line react/no-array-index-key
            key={`bankAccount:${index}/${bankAccounts.length}`}
            title={translate('page.commerce.accountIndex', {
              index: index + 1,
            })}
            titleAfter={
              bankAccounts.length > 1 ? (
                <Button
                  sx={({ palette }) => ({
                    color: palette.primary.main,
                    fontSize: 14,
                    fontWeight: 100,
                    ml: 1,
                  })}
                  onClick={() => removeBankAccount(index)}
                >
                  {translate('common.delete')}
                </Button>
              ) : undefined
            }
            variant="h5"
          >
            <FormFields
              dataTestId={dataTestId}
              translationPrefix="page.commerce.form"
              fields={[
                {
                  name: `bankAccounts[${index}].fundingEntityId`,
                  type: FormFieldType.DATASOURCE,
                  translationKey: 'fundingEntityId',
                  dataSourceSelectorProps: {
                    collection: DataSourceCollection.FUNDING_ENTITIES,
                  },
                  selectorFullWidth: true,
                },
                {
                  name: `bankAccounts[${index}].bankId`,
                  type: FormFieldType.PLATFORM_CATALOG,
                  translationKey: 'bankId',
                  platformCatalogSelectorProps: {
                    catalog: PlatformCatalogKey.BANKS,
                  },
                  selectorFullWidth: true,
                },
                {
                  name: `bankAccounts[${index}].accountType`,
                  type: FormFieldType.PLATFORM_CATALOG,
                  translationKey: 'accountType',
                  platformCatalogSelectorProps: {
                    catalog: PlatformCatalogKey.BANK_ACCOUNT_TYPES,
                  },
                  selectorFullWidth: true,
                },
                {
                  name: `bankAccounts[${index}].accountNumber`,
                  translationKey: 'accountNumber',
                },
                {
                  name: `bankAccounts[${index}].identificationType`,
                  type: FormFieldType.PLATFORM_CATALOG,

                  translationKey: 'identificationType',
                  platformCatalogSelectorProps: {
                    catalog: PlatformCatalogKey.BANK_ACCOUNT_ID_TYPES,
                  },
                  selectorFullWidth: true,
                  mandatory: false,
                },
                {
                  name: `bankAccounts[${index}].identificationNumber`,
                  translationKey: 'identificationNumber',
                  mandatory: false,
                },
              ]}
              childrenProps={{
                xs: 12,
                md: 8,
                lg: 6,
              }}
            />
          </TitledSection>
        ))}

        <Box
          sx={{
            ml: 2,
            width: '100%',
          }}
        >
          <Alert
            severity="warning"
            sx={({ palette }) => ({
              mt: 2,
              width: '100%',
              backgroundColor: palette.warning.light,
            })}
          >
            <Typography>
              {translate('page.commerce.commerceTaxIdDisclaimer')}
            </Typography>
          </Alert>
          <FooterButtons
            mx={0}
            ml={0}
            spacing={0}
            buttonConfList={[
              {
                variant: 'text',
                labelKey: 'page.commerce.addBankAccount',
                onClick: addBankAccount,
                fullWidth: false,
                startIcon: <AddCircleOutlineRoundedIcon />,
              },
            ]}
          />
        </Box>
      </TitledSection>
      <TitledSection
        titleKey="page.commerce.advancedSettings"
        accordion
        variant="h5"
        sx={{
          ml: 2,
        }}
        spacing={3}
      >
        <TitledSection
          titleKey="page.commerce.form.tags.label"
          tooltip={translate('page.commerce.form.tags.label')}
        >
          <TagSelector
            name="tags"
            labelKey="page.commerce.form.tags.label"
            fullWidth
          />
        </TitledSection>
        <TitledSection
          titleKey="page.commerce.form.additionalData.label"
          tooltip={translate('page.commerce.form.additionalData.label')}
        >
          {additionalData.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledAdditionalEntry key={`additionalData:${index}`}>
              <TitledSection variant="h5" flexGrow={1} spacing={2}>
                <Grid item xs={6}>
                  <TextInputForm
                    labelKey="page.commerce.form.key.label"
                    name={`additionalDataEntries[${index}].key`}
                    mandatory
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInputForm
                    labelKey="page.commerce.form.value.label"
                    name={`additionalDataEntries[${index}].value`}
                    mandatory
                    fullWidth
                  />
                </Grid>
              </TitledSection>
              <Button
                sx={() => ({
                  mt: 2,
                  ml: 2,
                })}
                onClick={() => removeAdditionalEntry(index)}
                variant="text"
                color="primary"
                startIcon={<DeleteOutlineOutlinedIcon />}
              >
                {translate('common.delete')}
              </Button>
            </StyledAdditionalEntry>
          ))}
          <FooterButtons
            mx={0}
            spacing={0}
            buttonConfList={[
              {
                labelKey: 'page.commerce.addAdditionalEntry',
                onClick: addAdditionalEntry,
                fullWidth: false,
                variant: 'text',
                color: 'primary',
                startIcon: <AddCircleOutlineIcon />,
              },
            ]}
          />
        </TitledSection>
      </TitledSection>
    </BaseForm>
  );
};

export default CommerceForm;
