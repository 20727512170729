import { Box, Grid, Typography, styled } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { ReactNode } from "react";


const TH_BACKGROUND_COLOR = '#f5f5f5';
const TH_BORDER_COLOR = '#e0e0e0';

const TABLE_HEIGHT = '500px';

const PreviewContainer = styled(Box)(() => ({
  border: `1px solid ${TH_BORDER_COLOR}`,
  height: TABLE_HEIGHT,
  display: 'flex',
  flexDirection: 'column',
}));

const PreviewHeader = styled(Box)(({ theme }) => ({
  backgroundColor: TH_BACKGROUND_COLOR,
  borderBottom: `1px solid ${TH_BORDER_COLOR}`,
  height: '56px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
}));

const PreviewBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 0,
  overflow: 'auto',
}));

interface IPreviewColumn<TRow extends GridValidRowModel>
  extends Omit<GridColDef<TRow>, 'renderCell' | 'field'> {
  render: (row?: TRow) => ReactNode;
}

interface PreviewPanelProps<TRow extends GridValidRowModel> {
  previewColumn?: IPreviewColumn<TRow>;
  row?: TRow;
}

const PreviewPanel = <TRow extends GridValidRowModel,>({ previewColumn, row }: PreviewPanelProps<TRow>) => {
  if (!previewColumn) return null;

  return (
    <Grid item xs={6}>
      <PreviewContainer>
        <PreviewHeader>
          <Typography variant="caption" fontWeight="bold">{previewColumn.headerName}</Typography>
        </PreviewHeader>
        <PreviewBody>{previewColumn.render(row)}</PreviewBody>
      </PreviewContainer>
    </Grid>
  );
}

export default PreviewPanel;
