import { useImperativeHandle, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import TextInputForm from '../form/TextInputForm';
import type {
  IFormProps,
  IOptionItem,
  IRoleFormData,
  ITesteable,
} from '../../common/interfaces';
import type { IRoleData } from '../../api/adminUsersRoles/interfaces';
import AutoCompleteSelector from '../form/AutoCompleteSelector';
import PermissionsSelector from '../PermissionsSelector';
import TitledSection from '../commons/TitledSection';
import { MetadataKey } from '../../common/enums';
import { buildFormData, buildSchema } from './builders';

interface IRoleFormProps extends IFormProps<IRoleFormData>, ITesteable {
  roleId?: number;
  data?: IRoleData;
  metadataKeysOptionList?: Array<IOptionItem>;
}

const RoleForm = ({
  dataTestId = 'RoleForm',
  roleId,
  data,
  metadataKeysOptionList = [],
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: IRoleFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate }));

  const methods = useForm<IRoleFormData>({
    defaultValues: buildFormData(data),
    resolver: yupResolver(schema),
  });

  const metadataRequiredKeysValue = methods.watch('metadataRequiredKeys');
  const permissionIdsValue = methods.watch('permissionIds');

  const unRestricted = (metadataRequiredKeysValue ?? []).includes(
    MetadataKey.UNRESTRICTED_RESOURCE_ACCESS
  );

  useEffect(() => {
    setSchema(buildSchema({ translate }));
  }, [translate]);

  const restrictedMetadataKeysOptionsList = useMemo(
    () =>
      (metadataKeysOptionList ?? [])
        .filter(
          (option: IOptionItem) =>
            option.key !== MetadataKey.UNRESTRICTED_RESOURCE_ACCESS
        )
        .map((metadata: IOptionItem) => ({
          ...metadata,
          value: translate(
            `datasources.metadataKeys.${metadata.value}`,
            metadata.value
          ),
        })),
    [metadataKeysOptionList, translate]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      methods.setValue('metadataRequiredKeys', [
        ...metadataRequiredKeysValue,
        MetadataKey.UNRESTRICTED_RESOURCE_ACCESS,
      ]);
    } else {
      methods.setValue(
        'metadataRequiredKeys',
        metadataRequiredKeysValue.filter(
          (key) => key !== MetadataKey.UNRESTRICTED_RESOURCE_ACCESS
        )
      );
    }
  };

  const handlePermissionsChange = (permissionIds: number[]) => {
    methods.setValue('permissionIds', permissionIds);
  };

  const onSubmit = async (dataForm: IRoleFormData) => {
    const metadataRequiredKeys = dataForm.metadataRequiredKeys.includes(
      MetadataKey.UNRESTRICTED_RESOURCE_ACCESS
    )
      ? [MetadataKey.UNRESTRICTED_RESOURCE_ACCESS]
      : dataForm.metadataRequiredKeys;
    const payload = {
      ...dataForm,
      metadataRequiredKeys,
    };
    onSubmitProp?.(payload);
  };

  useImperativeHandle(
    formRef,
    () => ({
      ...methods,
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2}>
          <TitledSection
            variant="h2"
            title={translate(
              roleId ? 'page.role.actionEdit' : 'page.role.formNameLabel'
            )}
          >
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInputForm
                    name="roleName"
                    label={translate('page.role.form.roleName.label')}
                    mandatory
                    helperText={translate(
                      'page.role.form.roleName.helperText',
                      ''
                    )}
                    fullWidth
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      'data-testid': `${dataTestId}_roleName`,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputForm
                    name="description"
                    label={translate('page.role.form.description.label')}
                    fullWidth
                    helperText={translate(
                      'page.role.form.description.helperText',
                      ''
                    )}
                    multiline
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TitledSection>
          <TitledSection
            variant="h4"
            title={translate('page.role.permissionsLabel')}
            helperText={translate('page.role.permissionsHelperText')}
          >
            <Box sx={{ mt: 2, mb: 2 }}>
              <PermissionsSelector
                value={permissionIdsValue}
                onChange={handlePermissionsChange}
              />
            </Box>
          </TitledSection>
          <TitledSection
            variant="h4"
            title={translate('common.additionalKeysLabel')}
            helperText={translate('page.role.additionalKeysHelperText')}
            spacing={3}
          >
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={unRestricted}
                      name="unRestricted"
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={translate('page.role.unrestrictedLabel')}
                />
              </FormGroup>
            </Grid>
            {!unRestricted && (
              <Grid item xs={12}>
                <AutoCompleteSelector
                  multiple
                  name="metadataRequiredKeys"
                  options={restrictedMetadataKeysOptionsList}
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.value}
                  label={translate('common.additionalKeysLabel')}
                />
              </Grid>
            )}
          </TitledSection>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RoleForm;
