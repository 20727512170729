import { env } from '../../../api/env';
import fetch from '../../../api/api';
import {
  IGenerateReportRequest,
  IReportDefinitionFilter,
  IReportDefinitionResponse,
} from '../interfaces';
import { IGetListResponse } from '../../../common/interfaces';

export const searchReportDefinitions = (
  filter: IReportDefinitionFilter
): Promise<IGetListResponse<IReportDefinitionResponse>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/report-definitions`,
    method: 'get',
  });

export const getReportDefinitionById = (
  id: number
): Promise<{
  data: IReportDefinitionResponse;
}> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/report-definitions/${id}`,
    method: 'get',
  });

// TODO: Remover -preview (error 401)
export const generateReport = (data: IGenerateReportRequest) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/report-executions`,
    method: 'post',
    data,
  });

export const downloadReport = (id: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/report-executions-download-files/${id}`,
    method: 'get',
    responseType: 'blob',
  });
