import { Box } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router';
import { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import TextInputForm from '../form/TextInputForm';
import { IFormProps, ITesteable } from '../../common/interfaces';
import TitledSection from '../commons/TitledSection';
import { buildFormData, buildSchema } from './builders';
import BaseForm from '../commons/BaseForm';
import SearchSelector, { SearchableTable } from '../form/SearchSelector';
import { ICommerceBusinessSchemaData } from '../../common/interfaces';
import { ICommerceBusinessSchemaFormData } from '../../common/interfaces';
import CheckBoxControl from '../form/CheckBoxControl';
import StyledAlert from '../ui/StyledAlert';
import {
  getBusinessSchemaList,
  getCommerce,
  getCommerceBusinessSchemaList 
} from '../../api/adminBusinessEnvironment/service';
import { RouteKey, paths } from '../../navigation/constants';

interface ICommerceBusinessSchemaFormProps
  extends IFormProps<ICommerceBusinessSchemaData>,
    ITesteable {
  commerceBusinessSchemaId?: number;
  data?: ICommerceBusinessSchemaData;
}

const CommerceBusinessSchemaForm = ({
  commerceBusinessSchemaId,
  //dataTestId = 'CommerceBusinessSchemaForm',
  data,
  onError,
  onSubmit: onSubmitProp,
  formRef,
}: ICommerceBusinessSchemaFormProps) => {
  const params = useParams();
  const commerceIdParam = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;

  const [isBNPL, setIsBNPL] = useState(false);
  const [isVentaIndirecta, setIsVentaIndirecta] = useState(false);
  const navigate = useNavigate();
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(buildSchema({ translate, isBNPL }));

  const methods = useForm<ICommerceBusinessSchemaFormData>({
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });

  const { data: commerce } = useQuery({
    queryFn: async () => (await getCommerce(commerceIdParam!)).data,
  });

  const { data: businessSchemaData, ...businessSchemaQueryProps } = useQuery({
    queryKey: ['getBusinessSchemaListx'],
    queryFn: () => getBusinessSchemaList(),
  });

  const { data: commerceBusinessSchemaData, ...commerceBusinessSchemaQueryProps } = useQuery({
    queryKey: ['getCommerceBusinessSchemaList'],
    queryFn: () => getCommerceBusinessSchemaList ({pageNumber:1, pageSize:100}, commerceIdParam),
  });

  const formData = useMemo(() => buildFormData(data ?? {}), [data]);

  const businessSchemaName = methods.watch('businessSchemaName');

  const [idsToFilter, setIdsToFilter] = useState([-1])

  useEffect(() => {
    commerceBusinessSchemaData?.data?.forEach((item, index) => {
      setIdsToFilter((prevState) => [...prevState, item.id]);
    });
    
    if (businessSchemaData && businessSchemaName) {
      for (let i = 0; i < businessSchemaData.data.result.length; i += 1) {
        if (
          // @ts-ignore
          businessSchemaName === businessSchemaData.data.result[i].id &&
          businessSchemaData.data.result[i].name === 'BNPL'
        ) {
          setIsBNPL(true);
        } else if (isBNPL) {
          setIsBNPL(false);
        }
        if (
          // @ts-ignore
          businessSchemaName === businessSchemaData.data.result[i].id &&
          businessSchemaData.data.result[i].name === 'Venta Indirecta'
        ) {
          setIsVentaIndirecta(true);
        } else if (isVentaIndirecta) {
          setIsVentaIndirecta(false);
        }
      }
    }
  }, [businessSchemaName, businessSchemaData, commerceBusinessSchemaData]);

  useEffect(() => {
    setSchema(buildSchema({ translate, isBNPL }));
  }, [translate, isBNPL]);

  useEffect(() => {
    const isHeadquarter = commerce?.id === commerce?.headquarterId;

    if (!isHeadquarter) {
      navigate(
        generatePath(paths[RouteKey.COMMERCES_VIEW], {
          commerceId: commerceIdParam,
        })
      );
    }
  }, [commerce]);

  const filterAlreadyAssociatedBusinessSchemas = (row: any) =>{
    if(idsToFilter.includes(row.id)){
      return false
    }
    return true
  }

  const onSubmit = async ({
    id,
    commerceId,
    businessSchemaId,
    bnplProviderMerchantId,
    quotaAmount,
    invoiceRequired,
    ...dataForm
  }: any) => {
    const payload = {
      id,
      commerceId: commerceIdParam,
      businessSchemaId: businessSchemaName,
      bnplProviderMerchantId:
        bnplProviderMerchantId.length > 0 ? bnplProviderMerchantId : undefined,
      invoiceRequired,
      quotaAmount,
      ...dataForm,
    };
    onSubmitProp?.(payload);
  };

  return (
    <BaseForm<ICommerceBusinessSchemaFormData>
      //dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmit}
      onError={onError}
      formRef={formRef}
      formData={formData}
      title={translate(
        commerceBusinessSchemaId
          ? 'route.businessSchema_edit.breadcrumb'
          : 'route.businessSchema_new.breadcrumb'
      )}
    >
      <TitledSection
        titleKey="page.businessSchemas.form.label"
        centered
        titleSx={{
          fontWeight: 400,
          marginBottom: '20px',
        }}
      >
        <Box width="70%">
          <SearchSelector
            table={SearchableTable.BUSINESS_SCHEMA}
            name="businessSchemaName"
            labelKey="page.businessSchemas.form.businessSchema.label"
            fullWidth
            mandatory
            filter={filterAlreadyAssociatedBusinessSchemas}
          />
        </Box>
      </TitledSection>

      {isBNPL && (
        <Box paddingBottom="60px" paddingLeft="20px" paddingTop="30px">
          <TitledSection titleKey="page.businessSchemas.form.bnpl.title">
            <Typography paddingBottom="25px">
              {translate('page.businessSchemas.form.bnpl.description')}
            </Typography>
            <Box width="50%">
              <TextInputForm
                name="bnplProviderMerchantId"
                labelKey={translate(
                  'page.businessSchemas.form.bnpl.placeholder'
                )}
                mandatory
                fullWidth
              />
            </Box>
          </TitledSection>
        </Box>
      )}

      {isVentaIndirecta && (
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          marginLeft="25px"
          marginTop="25px"
          marginBottom="30px"
        >
          <CheckBoxControl
            name="invoiceRequired"
            label={translate('page.businessSchemas.form.vi.invoiceRequired')}
          />

          <TitledSection
            titleKey={translate('page.businessSchemas.form.vi.quota.title')}
            centered
          >
            <Box display="flex" width="100%" flexDirection="column">
              <Typography marginBottom="15px">
                {translate('page.businessSchemas.form.vi.quota.description')}
              </Typography>

              <TextInputForm
                id="outlined-required"
                label={translate('page.businessSchemas.form.vi.quota.title')}
                name="quotaAmount"
              />

              <StyledAlert
                severity="info"
                title={translate('page.businessSchemas.form.vi.quota.alert')}
                style={{ padding: '15px' }}
              />
            </Box>
          </TitledSection>
        </Box>
      )}
    </BaseForm>
  );
};

export default CommerceBusinessSchemaForm;
