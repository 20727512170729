import * as yup from 'yup';

export const buildFormData = ({ ...data }: any = {}): any => ({
  active: true,
  ...data,
  additionalData: { allowDelivery: !!data?.additionalData?.allowDelivery },
  divisionId: data?.divisionId ? data.divisionId.toString() : undefined,
  fundingEntityId: data?.fundingEntityId
    ? data.fundingEntityId.toString()
    : undefined,
});

export const buildSchema = ({
  translate,
}: {
  translate: (key: string) => string;
}) => {
  const shape = {
    code: yup.string().required(translate('common.required')),
    description: yup.string().required(translate('common.required')),
    active: yup.bool().required(translate('common.required')),
    additionalData: yup.object().shape({
      allowDelivery: yup.bool().required(translate('common.required')),
    }),
    divisionId: yup
      .number()
      .typeError(translate('common.numberType'))
      .required(translate('common.required')),
    fundingEntityId: yup
      .number()
      .typeError(translate('common.numberType'))
      .required(translate('common.required')),
    address: yup.object().shape({
      street: yup
        .string()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      cityId: yup
        .mixed()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      stateId: yup
        .number()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      countryId: yup
        .number()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      zipCodeId: yup
        .mixed()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      longitude: yup
        .number()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
      latitude: yup
        .number()
        .typeError(translate('common.numberType'))
        .required(translate('common.required')),
    }),
  };
  return yup.object().shape(shape);
};

export default {};
