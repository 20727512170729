import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import BaseForm from '../commons/BaseForm';
import usePermissions from '../../hooks/usePermissions';
import { RouteKey } from '../../navigation/constants';
import { getInterestRateList } from '../../api/adminRates';
import useFiltersProps from '../../hooks/useFiltersProps';
import useRoutePage from '../../hooks/useRoutePage';
import {
  IRatesGroupFiltersState,
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IRateTableUpdateFormData,
  IFormProps,
  IFormRef,
} from '../../common/interfaces';
import FooterButtons from '../commons/FooterButtons';
import TextInputForm from '../form/TextInputForm';

import { buildFormData, buildSchema } from './builders';

interface ICommerceBusinessSchemaQuotaFormProps
  extends IFormProps<IRateTableUpdateFormData> {
  data?: IRateTableUpdateFormData;
  onCancel?: any;
  onConfirm?: any;
}

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const RateTableForm = ({
  data,
  onError,
  onSubmit: onSubmitProp,
  onCancel,
  onConfirm,
}: ICommerceBusinessSchemaQuotaFormProps) => {
  const { t } = useTranslation('global');
  const formRef = useRef<IFormRef>();
  const params = useParams();
  const interestRateTableId = params.itemId
    ? parseInt(params.itemId, 10)
    : undefined;

  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.VIEW_RATES,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { checkPermissions } = usePermissions();
  const { filters, ...filterProps } = useFiltersProps<IRatesGroupFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters: any[]) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const {
    data: interestRateList,
    isLoading: isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: [
      'getInterestRateList',
      pageNumber,
      pageSize,
      { interestRateTableId },
    ],
    queryFn: async () =>
      (
        await getInterestRateList({
          pageNumber,
          pageSize,
          filters: { interestRateTableId },
        })
      ).data,
    keepPreviousData: false,
  });

  const [schema, setSchema] = useState(buildSchema({ translate: t }));
  const methods = useForm<IRateTableUpdateFormData>({
    defaultValues: buildFormData(),
    resolver: yupResolver(schema),
  });
  const formData = useMemo(() => buildFormData(data ?? {}), [data]);

  const onSubmit: any = (dataForm: IRateTableUpdateFormData) => {
    onSubmitProp?.(dataForm);
  };

  return (
    <BaseForm<IRateTableUpdateFormData>
      //dataTestId={dataTestId}
      methods={methods}
      onSubmit={onSubmit}
      onError={onError}
      formRef={formRef}
      formData={formData}
      title=""
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <TextInputForm
            name="minimumTerm"
            labelKey={t('page.ratesGroup.paymentPlace.form.labels.min')}
            mandatory
            fullWidth
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputForm
            name="maximumTerm"
            labelKey={t('page.ratesGroup.paymentPlace.form.labels.max')}
            mandatory
            fullWidth
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputForm
            name="termInterval"
            labelKey={t('page.ratesGroup.paymentPlace.form.labels.interval')}
            mandatory
            fullWidth
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} sm={10} sx={{ paddingTop: '18px !important' }}>
          <FooterButtons
            buttonConfList={[
              {
                labelKey: 'common.cancel',
                variant: 'outlined',
                onClick: onCancel,
              },
              {
                labelKey: 'common.confirm',
                loading: isFetching,
                onClick: () => formRef?.current?.submit(),
              },
            ]}
          />
        </Grid>
      </Grid>
    </BaseForm>
  );
};
