import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Stepper } from 'vulpino';
import TitledSection from '../../../components/commons/TitledSection';
import { RouteKey, paths } from '../../../navigation/constants';
import { useUI } from '../../../contexts/UIContext';
import DynamicForm from '../../../components/form/DynamicForm';
import { IFormRef } from '../../../common/interfaces';
import BreadcrumbsNavigator from '../../../components/commons/BreadcrumbsNavigator';

export const FilterPage = () => {
  const { t } = useTranslation('global');
  const { reportContext, setReportContext } = useUI();
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);
  const [clear, setClear] = useState(false);
  const [busy, setBusy] = useState(false);

  const params = useLocation().state;

  const formRef = useRef<IFormRef>();

  const steps = [
    {
      label: t('page.reports.filters.steps.firstStep'),
    },
    {
      label: t('page.reports.filters.steps.secondStep'),
    },
  ];

  const navigate = useNavigate();

  const goToGeneratePage = () => {
    navigate(paths[RouteKey.REPORTS_GENERATE]);
  };

  const removeFilters = () => {
    setReportContext({ ...reportContext, filters: {} });
    setClear(true);
  };

  const onSubmit = (formValues: any) => {
    setReportContext({ ...reportContext, filters: formValues });
    goToGeneratePage();
  };

  useEffect(() => {
    if (!reportContext || Object.keys(reportContext).length === 0) {
      navigate(paths[RouteKey.REPORTS]);
    }
  }, []);

  return (
    <>
      <BreadcrumbsNavigator
        title="Nuevo reporte"
        routes={[RouteKey.REPORTS, RouteKey.REPORTS_FILTERS]}
        current={RouteKey.REPORTS_FILTERS}
      />
      <TitledSection
        variant="h1"
        title={t(
          `page.reports.filters.title.${reportContext.reportDefinitionName}`
        )}
      />
      <Box bgcolor="#f5f5f5" padding="18px" marginTop="20px" borderRadius="5px">
        <Stepper steps={steps} orientation="horizontal" />
      </Box>
      {reportContext.reportDefinitionName && (
        <DynamicForm
          dynamicFormName={
            reportContext.reportDefinitionName === 'traces_report'
              ? 'trace_report'
              : reportContext.reportDefinitionName
          }
          formRef={formRef}
          onSubmit={onSubmit}
          onValid={setValid}
          busy={busy}
          clear={clear}
          setClear={setClear}
          setError={setError}
          translateOptions="page.reports.optionsSelector."
        />
      )}
      <Grid container item xs={6} spacing={2} marginTop="20px">
        <Grid item xs={6}>
          <Button onClick={removeFilters} fullWidth variant="secondary">
            {t('page.reports.filters.clear')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={!valid || error}
            onClick={() => {
              formRef?.current?.submit();
            }}
            fullWidth
            variant="primary"
          >
            {t('page.reports.filters.apply')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
