import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router';
import {
  GridSelectionModel,
  GridRowParams,
  GridRowId,
  GridColumns,
} from '@mui/x-data-grid';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useQuery } from 'react-query';

import FilterContainer from '../../components/commons/FilterContainer';
import type {
  IRoleFiltersState,
  IButtonConf,
  IFilterAppliedOption,
} from '../../common/interfaces';
import type { IRoleData } from '../../api/adminUsersRoles/interfaces';
import { deleteRole, getRolesList } from '../../api/adminUsersRoles/service';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import MessageEmptyPageFirstAction from '../../components/commons/MessageEmptyPageFirstAction';
import { ToastType } from '../../components/commons/Toast';
import useRoutePage from '../../hooks/useRoutePage';
import useFiltersProps from '../../hooks/useFiltersProps';
import { useUI } from '../../contexts/UIContext';
import { paths, RouteKey } from '../../navigation/constants';

import useFilterOptions from './hooks/useFilterOptions';
import BaseTablePage from '../../components/commons/BaseTablePage';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
}

const RolesPage = ({ dataTestId = 'RolesPage' }) => {
  const [translate] = useTranslation('global');
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const navigate = useNavigate();
  const { setToast } = useUI();

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.ROLES,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
    },
  });

  const { pageNumber, pageSize, filterAppliedList } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IRoleFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const { data: queryData, ...queryProps } = useQuery({
    queryKey: ['getRolesList', pageNumber, pageSize, filters],
    queryFn: async () =>
      (await getRolesList({ pageNumber, pageSize, filters })).data,
    keepPreviousData: true,
  });

  // Computed
  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const filterOptionList = useFilterOptions();

  const goToNewRole = () => {
    navigate(paths[RouteKey.ROLES_NEW]);
  };

  const { checkPermissions } = usePermissions();

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <DownloadForOfflineOutlinedIcon />,
      labelKey: 'component.toolBar.downloadLabelButton',
      disabled: rowCount <= 0,
      hidden: true,
    },
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      onClick: goToNewRole,
      hidden: !checkPermissions(Permissions.ROLE_CREATE),
    },
  ];

  const handleDeleteRole = useCallback(
    (id: GridRowId) => async () => {
      try {
        if (typeof id === 'string') return;
        await deleteRole(id);
        queryProps.refetch();
        setToast({
          message: translate('page.role.roleDeleteToastSuccessMessage'),
        });
      } catch (error) {
        setToast({
          type: ToastType.ERROR,
          message: translate('page.role.roleDeleteToastErrorMessage'),
        });
      }
    },
    [navigate, setToast, queryProps.refetch, translate]
  );

  const handleView = useCallback(
    (id: GridRowId) => {
      const urlViewRole = generatePath(paths[RouteKey.ROLES_VIEW], {
        roleId: id.toString(),
      });
      navigate(urlViewRole);
    },
    [navigate]
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'roleName',
        headerName: translate('page.role.columnHeaders.roleName'),
        minWidth: 400,
        flex: 1,
        sortable: false,
      },
      {
        field: 'description',
        headerName: translate('page.role.columnHeaders.description'),
        minWidth: 400,
        flex: 1,
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 0.5,
        minWidth: 120,
        align: 'right',
        getActions: (params: GridRowParams<IRoleData>) => [],
      },
    ],
    [handleView, handleDeleteRole]
  );

  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };

  const handleOnSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  const emptyState = (
    <MessageEmptyPageFirstAction
      title={translate('page.role.emptyState.title')}
      subtitle={translate('page.role.emptyState.subTitle')}
      buttonLabel={translate('page.role.emptyState.buttonLabel')}
      onClick={goToNewRole}
    />
  );

  return (
    <BaseTablePage
      routeKey={RouteKey.ROLES}
      dataTestId={dataTestId}
      data={queryData}
      {...queryProps}
    >
      <FilterContainer
        title={translate('page.role.title')}
        filterOptionList={filterOptionList}
        translationKeyPrefix="page.role.filter"
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          selectionModel={selectionModel}
          onRowClick={({ id }) => handleView(id)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          onSelectionModelChange={handleOnSelectionModelChange}
          getRowId={(r: any) => r.id}
          buttonConfList={buttonConfList}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};

export default RolesPage;
