import { useMemo } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, Grid, styled, Typography } from "@mui/material";
import { paths, RouteKey } from "../../navigation/constants";
import { PaymentNavBar } from "../../components/PaymentNavBar/NavBar";
import { getBatchUpdates } from "../../api/adminBind/service";
import Loading from "../../components/commons/Loading";

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

export const PaymentBatchViewPage = () => {
const [translate] = useTranslation('global');
const navigate = useNavigate();
const parameters = useParams();
const { batchId } = parameters;
const isLoading = true;

const goBack = () => {
  const urlViewRequest = generatePath(
    paths[RouteKey.PAYMENT_BATCH]
  );
  navigate(urlViewRequest);
};

const handleSubmit = () => {
    navigate(generatePath(paths[RouteKey.PAYMENT_BATCH_CONFIRM],
      { batchId}));
  };

const filters = {
  batchUpdateStatus: '' 
};

const { data: queryData} = useQuery({
  queryKey: ['batchUpdates'],
  queryFn: async () =>
    (await getBatchUpdates({filters}))
      .data,
  keepPreviousData: true,
  });

const rows = useMemo(() => queryData, [queryData]);
const loanUpdate = rows?.find((elem) => elem.batchId.toString() === batchId?.toString());

const loanUpdateStatusPendingApproval = loanUpdate?.state === 'PENDING_APPROVAL';

const showLoader = isLoading && !rows;

const showContent = !showLoader;

return (
  <div id="liquidation">
    {showLoader && <Loading />}
    {showContent &&
    <>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <StyledLink
      to={
        (generatePath(paths[RouteKey.PAYMENT_BATCH]))
      }
    >
      Bandeja de control / 
    </StyledLink>
    <Typography color="text.primary" fontWeight="bold" marginLeft="10px">
      Lote {loanUpdate?.assignorBatchId}
    </Typography>
  </div>
    <PaymentNavBar 
      batchId={batchId}
      updateType={loanUpdate?.updateType!}
      assignorBatchId={loanUpdate?.assignorBatchId!}
      assignor={loanUpdate?.assignor!}
    />
    <div style={{width: '1500px'}}>
    <Grid container marginTop={3}>
      {
        loanUpdateStatusPendingApproval &&
        <Grid item xs={1}>
        <Button onClick={goBack} variant="outlined" color="error">
          {translate('page.paymentBatch.reject')}
        </Button>
      </Grid>
      }
      <Grid item xs={1}>
        <Button onClick={goBack} variant="outlined">
          {translate('page.paymentBatch.cancel')}
        </Button>
      </Grid>
      {
        loanUpdateStatusPendingApproval && 
        <Grid item xs={1}>
        <Button onClick={handleSubmit} variant="contained">
          {translate('page.paymentBatch.confirm')}
        </Button>
      </Grid>
      }
    </Grid>
    </div>
    </>
    }
  </div>

);
}