import { useMemo } from "react";
import { Box, BoxProps, styled, useTheme } from "@mui/material";

import { useUI } from "../../contexts/UIContext";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
}))

interface OverlayContainerProps extends BoxProps {
  padding?: number;
}

const OverlayContainer = ({ padding = 0, ...props }: OverlayContainerProps) => {
  const { offset } = useUI();
  const theme = useTheme();

  const overlayStyle = useMemo(() => ({
    top: `calc(${offset.top}px + ${theme.spacing(padding)}) !important`,
    left: `calc(${offset.left}px + ${theme.spacing(padding)}) !important`,
    right: `${theme.spacing(padding)} !important`,
    bottom: `${theme.spacing(padding)} !important`,
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  }), [offset, padding]);

  return <StyledContainer {...props} sx={overlayStyle} />;
}

export default OverlayContainer;
