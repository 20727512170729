import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { Button, Alert } from 'vulpino';
import qs from 'qs';
import { GridColumns, GridRowId, GridRowModel, GridValidRowModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { AddCircleOutline } from '@mui/icons-material';
import dayjs from 'dayjs';

import { RouteKey } from '../../../../../navigation/constants';
import usePermissions from '../../../../../hooks/usePermissions';
import Loading from '../../../../../components/commons/Loading';
import { getEventList } from '../../../../../api/adminCalendar';
import useRoutePage from '../../../../../hooks/useRoutePage';
import BaseTablePage from '../../../../../components/commons/BaseTablePage';
import CustomDataGrid from '../../../../../components/commons/CustomDataGrid';
import {
  IButtonConf,
  IFilterAppliedOption,
  IFilterDatasourceOption,
} from '../../../../../common/interfaces';
import { Permissions } from '../../../../../common/enums';
import FilterContainer from '../../../../../components/commons/FilterContainer';
import useFilterOptions from './useFilterOptions';
import useFiltersProps from '../../../../../hooks/useFiltersProps';
import useDataSources, {
  DataSourceCollection,
} from '../../../../../hooks/useDataSources';
import PromisedValue from '../../../../../components/helpers/PromisedValue';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

export const EventList = () => {
  const { t } = useTranslation('global');
  const params = useParams();
  let itemId = params.itemId ? parseInt(params.itemId, 10) : undefined;

  const { getValue } = useDataSources();
  const { routeState, mergeRouteState, navigateToRoute } =
    useRoutePage<IRouteState>({
      routeKey: RouteKey.VIEW_CALENDAR,
      initialState: {
        pageNumber: 1,
        pageSize: 10,
        filterAppliedList: [],
        filterDatasourceOptionList: [],
      },
    });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } = useFiltersProps<any>({ 
   appliedFilters: filterAppliedList,  

   onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  

  const {
    data,
    isLoading: isFetching,
    error,
    refetch,
    ...queryProps
  } = useQuery({
    queryKey: ['getEventList',itemId, pageNumber, pageSize, filters],
    queryFn: async () =>
      (
        await getEventList(itemId, {
          merged: true,
          pageNumber,
          pageSize,
          ...filters,
        })
      ).data,
      keepPreviousData: true,
  });



  const location = useLocation();
  const { checkPermissions } = usePermissions();

  const { tableName: tableNameQuery } = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location]
  );

  const tableName =
    typeof tableNameQuery === 'string' ? tableNameQuery : undefined;

  const rows = useMemo(() => data?.result, [data]);

  const rowCount = useMemo(() => data?.totalItems ?? 0, [data]);

  const columns = useMemo<GridColumns>(() => {
    const items: GridColumns = [
      {
        field: 'name',
        headerName: t('page.calendar.columnHeaders.name'),
        minWidth: 120,
        flex: 1,
        sortable: false,
      },
      {
        field: 'date',
        headerName: t('page.calendar.columnHeaders.date'),
        minWidth: 400,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => dayjs(row?.date).format('DD/MM/YYYY'),
      },
      // {
      //   field: 'calendarId',
      //   headerName: t('page.calendar.columnHeaders.calendar'),
      //   minWidth: 400,
      //   flex: 1,
      //   sortable: false,
      //   renderCell: ({ row }) =>
      //     row.calendarId ? (
      //       <PromisedValue
      //         value={row.calendarId}
      //         fn={getValue}
      //         arg={{
      //           collection: DataSourceCollection.CALENDAR,
      //           id: row.calendarId,
      //         }}
      //       />
      //     ) : (
      //       t('page.calendar.none')
      //     ),
      // },
    ];

    return items;
  }, [t,tableName]);


  const handleOnPageChange = (newPage: number) => {
    mergeRouteState({
      pageNumber: newPage,
    });
  };

  const handleOnPageSizeChange = (newPageSize: number) => {
    mergeRouteState({
      pageSize: newPageSize,
    });
  };



  const handleView = (id: GridRowId) => {
   const selectedRow = rows.find((row:any) => row.id===id); 
   itemId=selectedRow.calendarId;
    navigateToRoute(RouteKey.VIEW_EVENT, {
      itemId,
      eventId: id.toString(),
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  };

  const handleNewEvent = () => {
    navigateToRoute(RouteKey.CREATE_EVENT, {
      itemId,
      search: tableName ? `?tableName=${tableName}` : undefined,
    });
  };

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      onClick: handleNewEvent,
      hidden: !checkPermissions([
        Permissions.CALENDAR_CREATE,
        Permissions.CALENDAR_UPDATE,
      ]),
      variant: 'contained',
    },
  ];

  const filterOptionList = useFilterOptions();

  return (
    <>
      {(filters && Object.keys(filters).length > 0) ||
      data?.result?.length > 0 ? (
        <BaseTablePage
          isLoading={isFetching}
          error={error}
          routeKey={RouteKey.CALENDAR}
          data={data}
          refetch={refetch}
          {...queryProps}
        >
          <FilterContainer
            title={t('page.calendar.events.title')}
            filterOptionList={filterOptionList}
            filterDatasourceOptionList={filterDatasourceOptionList}
            translationKeyPrefix="page.calendar.events.filter"
            {...filterProps}
          >
            <CustomDataGrid
              rows={rows ?? []}
              columns={columns}
              rowCount={rowCount}
              pageNumber={pageNumber}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 30]}
              onRowClick={({ id }) => handleView(id)}
              onPageChange={handleOnPageChange}
              onPageSizeChange={handleOnPageSizeChange}
              getRowId={(r: any) => r.id}
              buttonConfList={buttonConfList}
            />
          </FilterContainer>
        </BaseTablePage>
      ) : (
        <Alert
          action={
            <Button onClick={handleNewEvent} endIcon={<AddCircleOutline />}>
              Nuevo
            </Button>
          }
          severity="info"
          sx={{
            marginTop: 2,
            border: 1,
            borderColor: '#3988FF',
            alignItems: 'center',
          }}
        >
          {t('page.calendar.events.noEvent')}
        </Alert>
      )}
    </>
  );
};
