import { useMemo, useState } from 'react';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from 'vulpino';
import useTranslate from '../../hooks/useTranslate';
import type { IFormField } from './FormFields';
import { capitalizeEveryWord } from '../../utils/formatterUtil';

type TextInputFormProps = TextFieldProps &
  Omit<IFormField, 'type'> & {
    label?: string;
    labelKey?: string;
    translationPath?: string;
    dataTestId?: string;
    inputFormat?: string;
    views?: CalendarPickerView[];
    minDate?: string;
    maxDate?: string;
  };

const errorStyle = {
  marginTop: '3px',
  color: '#FB1531',
  fontSize: '12px',
};

const DateInputForm = ({
  name,
  label: labelProp,
  labelKey: labelKeyProp,
  translationPath,
  dataTestId,
  disabled,
  inputFormat,
  views,
  minDate,
  maxDate,
  ...props
}: TextInputFormProps) => {
  const labelKey = useMemo(() => {
    if (translationPath) return `${translationPath}.form.${name}.label`;
    return labelKeyProp;
  }, [labelKeyProp, name, translationPath]);

  const [translate] = useTranslation('global');
  const label = useTranslate(labelKey, labelProp);
  const [err, setErr] = useState<DateValidationError | null>(null);
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <DatePicker
            {...field}
            label={label}
            localizationProviderProps={{
              adapterLocale: 'es',
            }}
            renderInput={(elements) => (
              <TextField
                {...elements}
                sx={{ width: '100%' }}
                variant="outlined"
                inputProps={{
                  ...elements.inputProps,
                  value: capitalizeEveryWord(elements?.inputProps?.value),
                }}
              />
            )}
            disabled={disabled}
            views={views}
            minDate={minDate}
            maxDate={maxDate}
            inputFormat={inputFormat}
            onChange={(element) => {
              if (!element) {
                element = '';
              }
              field.onChange(element);
            }}
            onError={(element) => setErr(element)}
          />
          {(err || error) && (
            <Typography sx={errorStyle}>
              {error?.message || translate('common.invalid')}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export default DateInputForm;
