import { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router';
import {
  GridSelectionModel,
  GridRowParams,
  GridRowId,
  GridColumns,
} from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { getUsersList } from '../../api/adminUsersRoles/service';
import CustomDataGrid from '../../components/commons/CustomDataGrid';
import type {
  IButtonConf,
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFilterOption,
  IOptionItem,
  IUserFiltersState,
} from '../../common/interfaces';
import type { IUserData } from '../../api/adminUsersRoles/interfaces';
import useRoutePage from '../../hooks/useRoutePage';
import FilterContainer from '../../components/commons/FilterContainer';
import useFiltersProps from '../../hooks/useFiltersProps';
import useFilterOptions from './hooks/useFilterOptions';
import ColumnRoles from './ColumnRoles';
import { paths, RouteKey } from '../../navigation/constants';
import BaseTablePage from '../../components/commons/BaseTablePage';
import usePermissions from '../../hooks/usePermissions';
import { Permissions } from '../../common/enums';
import Loading from '../../components/commons/Loading';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

const UsersPage = ({ dataTestId = 'UsersPage' }) => {
  const [translate] = useTranslation('global');
  const navigate = useNavigate();

  const { isLoading, checkPermissions } = usePermissions();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.USERS,
    initialState: {
      pageNumber: 1,
      pageSize: 10,
      filterAppliedList: [],
      filterDatasourceOptionList: [
        {
          name: 'roleIds',
          options: [],
        },
      ],
    },
  });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IUserFiltersState>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const {
    data: queryData,
    isFetching,
    error,
    ...queryProps
  } = useQuery({
    queryKey: ['getUsersList', pageNumber, pageSize, filters],
    queryFn: async () =>
      (await getUsersList({ pageNumber, pageSize, filters })).data,
    keepPreviousData: true,
  });

  const rows = useMemo(() => queryData?.result, [queryData]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleOnSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  const handleOnPageChange = (newPage: number) =>
    mergeRouteState({
      pageNumber: newPage,
    });

  const handleOnPageSizeChange = (newPageSize: number) =>
    mergeRouteState({
      pageSize: newPageSize,
    });

  const goToNewUser = () => {
    navigate(paths[RouteKey.USERS_NEW]);
  };

  const handleView = useCallback((id: GridRowId) => {
    navigate(
      generatePath(paths[RouteKey.USERS_VIEW], {
        userId: id.toString(),
      })
    );
  }, []);

  const columns = useMemo<GridColumns<IUserData>>(
    () => [
      {
        field: 'userName',
        headerName: translate('page.user.columnHeaders.userName'),
        minWidth: 300,
        maxWidth: 300,
        flex: 1,
        sortable: false,
      },
      {
        field: 'active',
        headerName: translate('page.user.columnHeaders.active'),
        maxWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (params) =>
          params.row.active ? translate('common.yes') : translate('common.no'),
      },
      {
        field: 'roles',
        headerName: translate('page.user.columnHeaders.roles'),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <ColumnRoles
            columnWidth={params.colDef.computedWidth}
            roles={params.row.roles ?? []}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 0.5,
        minWidth: 80,
        align: 'right',
        getActions: (params: GridRowParams) => [],
      },
    ],
    [translate]
  );

  const filterOptionList = useFilterOptions();

  const handleOnGetDatasource = (
    filter: IFilterOption,
    options: IOptionItem[]
  ) => {
    mergeRouteState({
      filterDatasourceOptionList: filterDatasourceOptionList.map((f) => {
        if (f.name === filter.name) {
          f.options = options;
        }
        return f;
      }),
    });
  };

  const buttonConfList: IButtonConf[] = [
    {
      endIcon: <AddCircleOutlineOutlinedIcon />,
      labelKey: 'component.toolBar.newLabelButton',
      onClick: goToNewUser,
      hidden: !checkPermissions(Permissions.USER_CREATE),
    },
  ];

  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    const err: any = error;
    if (err?.data?.code === 412) {
      setErrorMessage({
        title: translate('errors.configuration.title'),
        subtitle: translate(`error.api.${err?.data?.cause}`),
      });
    }
  }, [error]);

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <BaseTablePage
      routeKey={RouteKey.USERS}
      dataTestId={dataTestId}
      data={queryData}
      errorMessage={errorMessage}
      error={error}
      {...queryProps}
    >
      <FilterContainer
        title={translate('page.user.title')}
        filterOptionList={filterOptionList}
        translationKeyPrefix="page.user.filter."
        onGetDatasource={handleOnGetDatasource}
        filterDatasourceOptionList={filterDatasourceOptionList}
        {...filterProps}
      >
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns}
          rowCount={rowCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          selectionModel={selectionModel}
          onRowClick={({ id }) => handleView(id)}
          onPageChange={handleOnPageChange}
          onPageSizeChange={handleOnPageSizeChange}
          onSelectionModelChange={handleOnSelectionModelChange}
          getRowId={(r: any) => r.id}
          buttonConfList={buttonConfList}
        />
      </FilterContainer>
    </BaseTablePage>
  );
};

export default UsersPage;
