import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import {
  ICommerceData,
  IFormRef,
  ICommerceBusinessSchemaData,
} from '../../common/interfaces';
import { getErrorMessage } from '../../utils/apiUtils';
import { useUI } from '../../contexts/UIContext';
import { ToastType } from '../../components/commons/Toast';
import { paths, RouteKey } from '../../navigation/constants';
import {
  getCommerce,
  getCommerceBusinessSchema,
  createCommerceBusinessSchema,
  updateCommerceBusinessSchema,
} from '../../api/adminBusinessEnvironment/service';
import BaseViewPage from '../../components/commons/BaseViewPage';
import CommerceBusinessSchemaForm from '../../components/CommerceBusinessSchemaForm';
import { MenuItemType, Permissions } from '../../common/enums';

const BusinessSchemaEditPage = () => {
  const [translate] = useTranslation('global');
  const [busy, setBusy] = useState(false);
  const queryClient = useQueryClient();

  const formRef = useRef<IFormRef>();

  const { setToast, setMenuOverride } = useUI();

  const navigate = useNavigate();
  const params = useParams();

  const commerceId = params.commerceId
    ? parseInt(params.commerceId, 10)
    : undefined;

  const commerceBusinessSchemaId = params.commerceBusinessSchemaId
    ? parseInt(params.commerceBusinessSchemaId, 10)
    : undefined;

  const { data: commmerceData, ...commerceQueryProps } =
    useQuery<ICommerceData>({
      queryKey: [`commerce:${commerceId}`],
      queryFn: async () =>
        commerceId ? (await getCommerce(commerceId)).data : {},
      keepPreviousData: true,
    });

  const {
    data: commerceBusinessSchemaData,
    ...commerceBusinessSchemaQueryProps
  } = useQuery<ICommerceBusinessSchemaData>({
    queryKey: [`commerceBusinessSchema:${commerceBusinessSchemaId}`],
    queryFn: async () =>
      commerceBusinessSchemaId
        ? (await getCommerceBusinessSchema(commerceBusinessSchemaId)).data
        : {},
    keepPreviousData: true,
  });

  const goBack = () => {
    navigate(
      generatePath(paths[RouteKey.COMMERCE_BUSINESS_SCHEMA], { commerceId })
    );
  };

  const onSubmit = async (payload: ICommerceBusinessSchemaData) => {
    try {
      setBusy(true);

      if (commerceBusinessSchemaId !== undefined) {
        await updateCommerceBusinessSchema(commerceBusinessSchemaId, payload);
      } else {
        await createCommerceBusinessSchema(payload);
      }

      setToast({
        message: translate('page.businessSchemas.saveToastSuccessMessage'),
        //key: `${dataTestId}_Success`,
      });
      goBack();
    } catch (error) {
      const message = getErrorMessage(
        error,
        translate('page.businessSchemas.saveToastErrorMessage')
      );

      setToast({
        type: ToastType.ERROR,
        //key: `${dataTestId}_Error`,
        message,
      });
    } finally {
      setBusy(false);
    }
  };

  const currentRouteKey =
    commerceBusinessSchemaId !== undefined
      ? RouteKey.COMMERCES_BUSINESS_SCHEMA_EDIT
      : RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW;

  useEffect(() => {
    if (commerceId) {
      setMenuOverride({
        headerItem: {
          key: 'back',
          type: MenuItemType.MENU,
          permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
          icon: <ArrowBackIcon />,
          text: translate('common.back'),
          path: generatePath(paths[RouteKey.COMMERCES]),
        },
        items: ['commerceDetail', 'businessSchemas'].map((cardKey) => ({
          key: cardKey,
          text: translate(
            `page.businessSchemas.drawerSubItems.${cardKey}.breadcrumb`,
            translate(`page.businessSchemas.drawerSubItems.${cardKey}.title`)
          ),
          path: generatePath(paths[RouteKey.COMMERCES_VIEW], {
            '*': cardKey,
            commerceId: commerceId?.toString(),
          }),
          type: MenuItemType.MENU,
          permissions:
            cardKey === 'documentation'
              ? Permissions.FINANCIAL_PRODUCT_REQUEST_DOCUMENTS_READ
              : cardKey === 'historicalTasks'
              ? Permissions.WORKFLOW_BUSINESS_INSTANCE_HISTORICAL_TASK_READ
              : Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
        })),
      });
    }
    return () => setMenuOverride(undefined);
  }, []);

  return (
    <BaseViewPage
      //dataTestId={dataTestId}
      routeTrail={[RouteKey.COMMERCES]}
      routeKey={currentRouteKey}
      data={commerceBusinessSchemaData}
      {...commerceBusinessSchemaQueryProps}
      buttonConfList={[
        {
          //dataTestId: `${dataTestId}_CancelButton`,
          variant: 'outlined',
          onClick: goBack,
          labelKey: 'common.cancel',
        },
        {
          labelKey: 'common.save',
          //dataTestId: `${dataTestId}_SubmitButton`,
          onClick: () => formRef?.current?.submit(),
          loading: busy,
        },
      ]}
    >
      <Grid item sm={12} xs={24}>
        <CommerceBusinessSchemaForm
          //dataTestId={`${dataTestId}_Form`}
          formRef={formRef}
          commerceBusinessSchemaId={commerceBusinessSchemaId}
          data={commerceBusinessSchemaData}
          // eslint-disable-next-line
          onError={console.log}
          onSubmit={onSubmit}
        />
      </Grid>
    </BaseViewPage>
  );
};

export default BusinessSchemaEditPage;
