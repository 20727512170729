import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useMediaQuery } from '@react-hook/media-query';
import { Drawer } from '@mui/material';

import { useUI } from '../contexts/UIContext';
import { MenuItemType } from '../common/enums';

import MenuSectionListItem from './MenuSectionListItem';
import { drawerMenu } from './constants';

export const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }: { theme: any }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MenuDrawer = ({
  openMenu,
  onHandleItemClick,
}: {
  openMenu: boolean;
  onHandleItemClick: (x: boolean) => void;
}) => {
  const matchesDesktop = useMediaQuery('only screen and (min-width: 760px)');

  const { menuOverride } = useUI();

  const handleItemClick = () => {
    onHandleItemClick(false);
  };

  const menu = menuOverride ?? drawerMenu;

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={matchesDesktop ? 'persistent' : 'temporary'}
        anchor="left"
        open={openMenu}
      >
        <DrawerHeader />
        {menu.headerItem && (
          <MenuSectionListItem
            sx={({ palette }) => ({
              flexGrow: 0,
              mt: 2,
              borderBottom: `1px solid ${palette.grey[300]}`,
            })}
            menuItems={[menu.headerItem]}
            onHandleItemClick={handleItemClick}
          />
        )}
        <List>
          {menu.items.map((entry, index) => {
            if (entry.type === MenuItemType.DIVIDER) {
              // eslint-disable-next-line react/no-array-index-key
              return <Divider key={String(index)} />;
            }
            return (
              <MenuSectionListItem
                key={entry.routeKey || entry.key}
                menuItems={[entry]}
                onHandleItemClick={handleItemClick}
              />
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
